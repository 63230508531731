type Municipality = {
  href: string;
  kode: string;
  navn: string;
}

export type DawaZipCode = {
  href: string;
  nr: string;
  navn: string;
  stormodtageradresser: string | null;
  bbox: number[];
  visueltcenter: number[];
  kommuner: Municipality[];
  ændret: string;
  geo_ændret: string;
  geo_version: number;
  dagi_id: string;
}

export default [
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1050",
    "nr": "1050",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.5841266,
      55.67871944,
      12.58827962,
      55.68185111
    ],
    "visueltcenter": [
      12.58600133,
      55.68065246
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191050"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1051",
    "nr": "1051",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58727138,
      55.67873773,
      12.59417472,
      55.68095605
    ],
    "visueltcenter": [
      12.59022696,
      55.6798377
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-11-10T21:36:32.078Z",
    "geo_version": 2,
    "dagi_id": "191051"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1052",
    "nr": "1052",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58836803,
      55.67813302,
      12.59274477,
      55.67954326
    ],
    "visueltcenter": [
      12.58954953,
      55.67906706
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191052"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1053",
    "nr": "1053",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.59005794,
      55.67748162,
      12.59131831,
      55.67856512
    ],
    "visueltcenter": [
      12.59073024,
      55.67798554
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191053"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1054",
    "nr": "1054",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58756005,
      55.67691468,
      12.59008506,
      55.67921557
    ],
    "visueltcenter": [
      12.58906639,
      55.67763459
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191054"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1055",
    "nr": "1055",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.5861399,
      55.67620892,
      12.58930145,
      55.67983278
    ],
    "visueltcenter": [
      12.58807383,
      55.67723256
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191055"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1056",
    "nr": "1056",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58709529,
      55.67892192,
      12.58918614,
      55.67998257
    ],
    "visueltcenter": [
      12.58793902,
      55.67946993
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191056"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1057",
    "nr": "1057",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58632486,
      55.67701049,
      12.59165074,
      55.67974155
    ],
    "visueltcenter": [
      12.58959143,
      55.67833309
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191057"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1058",
    "nr": "1058",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58419617,
      55.67507788,
      12.59463214,
      55.67944598
    ],
    "visueltcenter": [
      12.58792693,
      55.67595336
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191058"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1059",
    "nr": "1059",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58560275,
      55.67612915,
      12.58783096,
      55.67776146
    ],
    "visueltcenter": [
      12.58672365,
      55.67675239
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191059"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1060",
    "nr": "1060",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58239708,
      55.67578293,
      12.58710101,
      55.67946784
    ],
    "visueltcenter": [
      12.5837984,
      55.67651434
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191060"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1061",
    "nr": "1061",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57982099,
      55.6766095,
      12.58277651,
      55.67811397
    ],
    "visueltcenter": [
      12.5810871,
      55.67749602
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2021-01-11T22:24:27.357Z",
    "geo_ændret": "2021-01-11T22:24:27.357Z",
    "geo_version": 4,
    "dagi_id": "191061"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1062",
    "nr": "1062",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58164715,
      55.67718285,
      12.58245277,
      55.67762541
    ],
    "visueltcenter": [
      12.5820684,
      55.67745504
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191062"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1063",
    "nr": "1063",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58225465,
      55.67738474,
      12.58602746,
      55.67875878
    ],
    "visueltcenter": [
      12.58481601,
      55.67812648
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191063"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1064",
    "nr": "1064",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58370154,
      55.67809465,
      12.58462468,
      55.67874842
    ],
    "visueltcenter": [
      12.58421619,
      55.67855701
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191064"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1065",
    "nr": "1065",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58073855,
      55.67780499,
      12.58178843,
      55.67822257
    ],
    "visueltcenter": [
      12.58120927,
      55.67792419
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2021-01-11T22:24:27.357Z",
    "geo_ændret": "2021-01-11T22:24:27.357Z",
    "geo_version": 3,
    "dagi_id": "191065"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1066",
    "nr": "1066",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.5812756,
      55.67678128,
      12.58347697,
      55.67823235
    ],
    "visueltcenter": [
      12.58283658,
      55.67738024
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191066"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1067",
    "nr": "1067",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58035515,
      55.67796899,
      12.5827441,
      55.67944564
    ],
    "visueltcenter": [
      12.58180816,
      55.67872776
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191067"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1068",
    "nr": "1068",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58212016,
      55.67697676,
      12.58408724,
      55.67843056
    ],
    "visueltcenter": [
      12.58355191,
      55.67746813
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191068"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1069",
    "nr": "1069",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58207688,
      55.67726088,
      12.58476785,
      55.67970902
    ],
    "visueltcenter": [
      12.58417532,
      55.67777633
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-05-15T21:14:07.617Z",
    "geo_ændret": "2018-05-15T21:14:07.617Z",
    "geo_version": 2,
    "dagi_id": "191069"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1070",
    "nr": "1070",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58229552,
      55.67821787,
      12.58583901,
      55.67919061
    ],
    "visueltcenter": [
      12.58352498,
      55.67881568
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191070"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1071",
    "nr": "1071",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58209735,
      55.67765371,
      12.58420943,
      55.67848317
    ],
    "visueltcenter": [
      12.58375053,
      55.67826694
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191071"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1072",
    "nr": "1072",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58027092,
      55.6781463,
      12.58107667,
      55.67867838
    ],
    "visueltcenter": [
      12.58065996,
      55.67845615
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191072"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1073",
    "nr": "1073",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58013005,
      55.67863202,
      12.5811331,
      55.67893974
    ],
    "visueltcenter": [
      12.58063157,
      55.67878588
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191073"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1074",
    "nr": "1074",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.5821222,
      55.67891193,
      12.58489688,
      55.68004133
    ],
    "visueltcenter": [
      12.5835962,
      55.67941235
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-05-15T21:14:07.617Z",
    "geo_ændret": "2018-05-15T21:14:07.617Z",
    "geo_version": 2,
    "dagi_id": "191074"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1100",
    "nr": "1100",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57958471,
      55.67854035,
      12.58473421,
      55.68083562
    ],
    "visueltcenter": [
      12.58283276,
      55.67989336
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191100"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1101",
    "nr": "1101",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58079786,
      55.67997207,
      12.58401497,
      55.68226616
    ],
    "visueltcenter": [
      12.5831775,
      55.68044771
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2019-06-17T21:06:51.663Z",
    "geo_ændret": "2019-06-17T21:06:51.663Z",
    "geo_version": 3,
    "dagi_id": "191101"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1102",
    "nr": "1102",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.5824,
      55.68043914,
      12.58306763,
      55.68093142
    ],
    "visueltcenter": [
      12.58279789,
      55.68084435
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191102"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1103",
    "nr": "1103",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58330906,
      55.68030998,
      12.58463274,
      55.68116783
    ],
    "visueltcenter": [
      12.58412972,
      55.68076455
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191103"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1104",
    "nr": "1104",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58324991,
      55.68091916,
      12.58472441,
      55.68153431
    ],
    "visueltcenter": [
      12.58393567,
      55.68124172
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191104"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1105",
    "nr": "1105",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58167535,
      55.67968729,
      12.58262711,
      55.6808388
    ],
    "visueltcenter": [
      12.58212355,
      55.68003329
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191105"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1106",
    "nr": "1106",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58078333,
      55.68013077,
      12.58203277,
      55.68071928
    ],
    "visueltcenter": [
      12.58120094,
      55.68045276
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191106"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1107",
    "nr": "1107",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58187783,
      55.68063819,
      12.58382718,
      55.68189112
    ],
    "visueltcenter": [
      12.58319268,
      55.68161934
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191107"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1110",
    "nr": "1110",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58133222,
      55.6811914,
      12.58305405,
      55.68220522
    ],
    "visueltcenter": [
      12.5818153,
      55.68146481
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2019-06-17T21:06:51.663Z",
    "geo_ændret": "2019-06-17T21:06:51.663Z",
    "geo_version": 3,
    "dagi_id": "191110"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1111",
    "nr": "1111",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58027477,
      55.68144432,
      12.58154158,
      55.68259429
    ],
    "visueltcenter": [
      12.58080608,
      55.68219996
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191111"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1112",
    "nr": "1112",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57628769,
      55.67920716,
      12.5821116,
      55.68237722
    ],
    "visueltcenter": [
      12.57974966,
      55.68123981
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2019-03-22T22:12:29.218Z",
    "geo_ændret": "2019-03-22T22:12:29.218Z",
    "geo_version": 4,
    "dagi_id": "191112"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1113",
    "nr": "1113",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57927045,
      55.67950781,
      12.58100793,
      55.68061258
    ],
    "visueltcenter": [
      12.58017369,
      55.68001592
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-11-10T21:36:32.078Z",
    "geo_version": 2,
    "dagi_id": "191113"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1114",
    "nr": "1114",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57826836,
      55.68024246,
      12.58028207,
      55.68107203
    ],
    "visueltcenter": [
      12.57891556,
      55.68069802
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-05-15T21:14:07.617Z",
    "geo_ændret": "2018-05-15T21:14:07.617Z",
    "geo_version": 3,
    "dagi_id": "191114"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1115",
    "nr": "1115",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57764504,
      55.68066812,
      12.57899419,
      55.68150422
    ],
    "visueltcenter": [
      12.5782846,
      55.68103123
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2020-11-19T22:08:50.853Z",
    "geo_ændret": "2020-11-19T22:08:50.853Z",
    "geo_version": 3,
    "dagi_id": "191115"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1116",
    "nr": "1116",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57866167,
      55.68124611,
      12.58066758,
      55.68268016
    ],
    "visueltcenter": [
      12.58005882,
      55.68222456
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191116"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1117",
    "nr": "1117",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.5794581,
      55.68047765,
      12.58212423,
      55.68206184
    ],
    "visueltcenter": [
      12.58157142,
      55.6810324
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191117"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1118",
    "nr": "1118",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58014521,
      55.68080677,
      12.5813173,
      55.68137503
    ],
    "visueltcenter": [
      12.58080964,
      55.68104247
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191118"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1119",
    "nr": "1119",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57516985,
      55.68137713,
      12.5785992,
      55.68351318
    ],
    "visueltcenter": [
      12.57714609,
      55.6823894
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-11-16T21:26:47.866Z",
    "geo_version": 2,
    "dagi_id": "191119"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1120",
    "nr": "1120",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57736055,
      55.68185506,
      12.5799091,
      55.68293581
    ],
    "visueltcenter": [
      12.57870875,
      55.68236562
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-05-15T21:14:07.617Z",
    "geo_ændret": "2018-05-15T21:14:07.617Z",
    "geo_version": 2,
    "dagi_id": "191120"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1121",
    "nr": "1121",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57848886,
      55.68262261,
      12.57929899,
      55.68304936
    ],
    "visueltcenter": [
      12.57891573,
      55.68277836
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191121"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1122",
    "nr": "1122",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57914191,
      55.68220836,
      12.58011321,
      55.68292102
    ],
    "visueltcenter": [
      12.57978841,
      55.68253368
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-05-15T21:14:07.617Z",
    "geo_ændret": "2018-05-15T21:14:07.617Z",
    "geo_version": 2,
    "dagi_id": "191122"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1123",
    "nr": "1123",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.56366698,
      55.68133467,
      12.58574947,
      55.68751251
    ],
    "visueltcenter": [
      12.57745579,
      55.68426232
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-11-20T22:13:37.996Z",
    "geo_ændret": "2018-11-20T22:13:37.996Z",
    "geo_version": 3,
    "dagi_id": "191123"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1124",
    "nr": "1124",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57450819,
      55.68265706,
      12.57784518,
      55.68377319
    ],
    "visueltcenter": [
      12.57689183,
      55.68303024
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191124"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1125",
    "nr": "1125",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57560224,
      55.68197198,
      12.57686085,
      55.68284799
    ],
    "visueltcenter": [
      12.5763264,
      55.6824351
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191125"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1126",
    "nr": "1126",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57464015,
      55.68195881,
      12.57570464,
      55.68244474
    ],
    "visueltcenter": [
      12.57533822,
      55.68215407
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2019-10-03T21:10:51.775Z",
    "geo_ændret": "2019-10-03T21:10:51.775Z",
    "geo_version": 1,
    "dagi_id": "191126"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1127",
    "nr": "1127",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57450765,
      55.68212802,
      12.57655291,
      55.68331102
    ],
    "visueltcenter": [
      12.57557089,
      55.68291443
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2019-10-22T21:09:46.002Z",
    "geo_ændret": "2019-10-22T21:09:46.002Z",
    "geo_version": 1,
    "dagi_id": "191127"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1128",
    "nr": "1128",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.5741614,
      55.68252358,
      12.57503999,
      55.68308342
    ],
    "visueltcenter": [
      12.57472861,
      55.68278092
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191128"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1129",
    "nr": "1129",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57395477,
      55.68280997,
      12.5748693,
      55.68353105
    ],
    "visueltcenter": [
      12.57433901,
      55.68317382
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191129"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1130",
    "nr": "1130",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57308274,
      55.68290083,
      12.5749321,
      55.68424768
    ],
    "visueltcenter": [
      12.57364349,
      55.68337542
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191130"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1131",
    "nr": "1131",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57340007,
      55.68365781,
      12.57444146,
      55.68422319
    ],
    "visueltcenter": [
      12.5738097,
      55.68385549
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191131"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1150",
    "nr": "1150",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57385997,
      55.67898064,
      12.58038603,
      55.68231823
    ],
    "visueltcenter": [
      12.57713782,
      55.68116195
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2020-11-19T22:08:50.853Z",
    "geo_ændret": "2020-11-19T22:08:50.853Z",
    "geo_version": 8,
    "dagi_id": "191150"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1151",
    "nr": "1151",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57567239,
      55.67859687,
      12.57845161,
      55.68048428
    ],
    "visueltcenter": [
      12.57640167,
      55.67925834
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2018-01-16T22:10:42.686Z",
    "geo_version": 3,
    "dagi_id": "191151"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1152",
    "nr": "1152",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57631784,
      55.67987769,
      12.57768429,
      55.68082572
    ],
    "visueltcenter": [
      12.57705358,
      55.68037643
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2018-04-18T21:08:35.132Z",
    "geo_version": 2,
    "dagi_id": "191152"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1153",
    "nr": "1153",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.5754334,
      55.67860665,
      12.57837438,
      55.6805874
    ],
    "visueltcenter": [
      12.57741313,
      55.67913736
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2018-01-16T22:10:42.686Z",
    "geo_version": 2,
    "dagi_id": "191153"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1154",
    "nr": "1154",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57493426,
      55.67917563,
      12.57666415,
      55.68034596
    ],
    "visueltcenter": [
      12.5758435,
      55.67961793
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2018-01-16T22:10:42.686Z",
    "geo_version": 2,
    "dagi_id": "191154"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1155",
    "nr": "1155",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.5751409,
      55.67981302,
      12.57583303,
      55.68021225
    ],
    "visueltcenter": [
      12.57544104,
      55.68007367
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2018-01-16T22:10:42.686Z",
    "geo_version": 2,
    "dagi_id": "191155"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1156",
    "nr": "1156",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57504813,
      55.6793482,
      12.57554343,
      55.67969661
    ],
    "visueltcenter": [
      12.57530761,
      55.67957221
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191156"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1157",
    "nr": "1157",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57441748,
      55.67867874,
      12.57641481,
      55.67969095
    ],
    "visueltcenter": [
      12.57544281,
      55.67907168
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191157"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1158",
    "nr": "1158",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57327582,
      55.67846071,
      12.57389834,
      55.67883662
    ],
    "visueltcenter": [
      12.57361214,
      55.67861951
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191158"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1159",
    "nr": "1159",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57219924,
      55.67826867,
      12.57663519,
      55.68126848
    ],
    "visueltcenter": [
      12.57608635,
      55.68077513
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-07-23T21:12:25.101Z",
    "geo_ændret": "2018-07-23T21:12:25.101Z",
    "geo_version": 4,
    "dagi_id": "191159"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1160",
    "nr": "1160",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57597473,
      55.67793714,
      12.57980771,
      55.67989242
    ],
    "visueltcenter": [
      12.57872573,
      55.67891577
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-07-10T21:09:46.035Z",
    "geo_ændret": "2018-07-10T21:09:46.035Z",
    "geo_version": 4,
    "dagi_id": "191160"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1161",
    "nr": "1161",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57370412,
      55.67795638,
      12.57642079,
      55.67923847
    ],
    "visueltcenter": [
      12.5756279,
      55.67850064
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2019-02-13T22:04:25.816Z",
    "geo_ændret": "2019-02-13T22:04:25.816Z",
    "geo_version": 8,
    "dagi_id": "191161"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1162",
    "nr": "1162",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57387202,
      55.67873219,
      12.57466117,
      55.67913004
    ],
    "visueltcenter": [
      12.57424006,
      55.67892784
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-11-05T22:14:33.117Z",
    "geo_ændret": "2018-11-05T22:14:33.117Z",
    "geo_version": 4,
    "dagi_id": "716035"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1164",
    "nr": "1164",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57286451,
      55.67793151,
      12.57403801,
      55.67855512
    ],
    "visueltcenter": [
      12.57338344,
      55.67826381
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-12-01T21:24:59.627Z",
    "geo_version": 2,
    "dagi_id": "191164"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1165",
    "nr": "1165",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.56917103,
      55.67844888,
      12.57386988,
      55.68272463
    ],
    "visueltcenter": [
      12.57068192,
      55.68076308
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-11-20T22:13:37.996Z",
    "geo_ændret": "2018-11-20T22:13:37.996Z",
    "geo_version": 5,
    "dagi_id": "191165"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1166",
    "nr": "1166",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57245968,
      55.67880326,
      12.57392987,
      55.67936486
    ],
    "visueltcenter": [
      12.57281215,
      55.67899335
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191166"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1167",
    "nr": "1167",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57120114,
      55.67885873,
      12.57180021,
      55.67925183
    ],
    "visueltcenter": [
      12.57155172,
      55.6791299
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191167"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1168",
    "nr": "1168",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57156647,
      55.67952136,
      12.57308555,
      55.68030619
    ],
    "visueltcenter": [
      12.57235905,
      55.67990118
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-05-15T21:14:07.617Z",
    "geo_ændret": "2018-05-15T21:14:07.617Z",
    "geo_version": 2,
    "dagi_id": "191168"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1169",
    "nr": "1169",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57356584,
      55.6797369,
      12.57589278,
      55.68148899
    ],
    "visueltcenter": [
      12.57454621,
      55.680565
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191169"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1170",
    "nr": "1170",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57411072,
      55.67989013,
      12.57506915,
      55.68039228
    ],
    "visueltcenter": [
      12.57446263,
      55.68008905
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191170"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1171",
    "nr": "1171",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57121701,
      55.679163,
      12.57439725,
      55.68278079
    ],
    "visueltcenter": [
      12.57230959,
      55.68177555
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-11-20T22:13:37.996Z",
    "geo_ændret": "2018-11-20T22:13:37.996Z",
    "geo_version": 4,
    "dagi_id": "191171"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1172",
    "nr": "1172",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57140956,
      55.6799252,
      12.57548137,
      55.68155228
    ],
    "visueltcenter": [
      12.5736165,
      55.68095745
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-05-15T21:14:07.617Z",
    "geo_ændret": "2018-05-15T21:14:07.617Z",
    "geo_version": 3,
    "dagi_id": "191172"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1173",
    "nr": "1173",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57272831,
      55.68118211,
      12.57470998,
      55.68221771
    ],
    "visueltcenter": [
      12.57365135,
      55.68174413
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191173"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1174",
    "nr": "1174",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57175751,
      55.68187388,
      12.57392151,
      55.68273894
    ],
    "visueltcenter": [
      12.5724811,
      55.68241134
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191174"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1175",
    "nr": "1175",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57285768,
      55.68195007,
      12.57509815,
      55.68291031
    ],
    "visueltcenter": [
      12.57381402,
      55.6825404
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2019-10-03T21:10:51.775Z",
    "geo_ændret": "2019-10-03T21:10:51.775Z",
    "geo_version": 1,
    "dagi_id": "191175"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1200",
    "nr": "1200",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57904306,
      55.67771976,
      12.58103837,
      55.67882187
    ],
    "visueltcenter": [
      12.5798222,
      55.67820341
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191200"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1201",
    "nr": "1201",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57684452,
      55.67755223,
      12.57939953,
      55.678693
    ],
    "visueltcenter": [
      12.57787668,
      55.67813397
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-07-10T21:09:46.035Z",
    "geo_ændret": "2018-07-10T21:09:46.035Z",
    "geo_version": 4,
    "dagi_id": "191201"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1202",
    "nr": "1202",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57715151,
      55.6771682,
      12.58003017,
      55.6781806
    ],
    "visueltcenter": [
      12.57894282,
      55.6777881
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191202"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1203",
    "nr": "1203",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57503815,
      55.67572857,
      12.57799725,
      55.6773681
    ],
    "visueltcenter": [
      12.57680004,
      55.6765462
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191203"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1204",
    "nr": "1204",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57484355,
      55.67603987,
      12.57620097,
      55.67683549
    ],
    "visueltcenter": [
      12.57548042,
      55.67653214
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191204"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1205",
    "nr": "1205",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57605352,
      55.67674441,
      12.57761122,
      55.67743963
    ],
    "visueltcenter": [
      12.57678089,
      55.67723642
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191205"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1206",
    "nr": "1206",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57663485,
      55.67728592,
      12.57736792,
      55.67783572
    ],
    "visueltcenter": [
      12.57706457,
      55.67754793
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191206"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1207",
    "nr": "1207",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.5759389,
      55.67782219,
      12.57707927,
      55.6784606
    ],
    "visueltcenter": [
      12.57661838,
      55.67810969
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191207"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1208",
    "nr": "1208",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57323458,
      55.67607931,
      12.57696771,
      55.67806766
    ],
    "visueltcenter": [
      12.57500757,
      55.6769724
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-05-17T21:17:17.429Z",
    "geo_ændret": "2018-05-17T21:17:17.429Z",
    "geo_version": 2,
    "dagi_id": "191208"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1209",
    "nr": "1209",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57436349,
      55.67744437,
      12.57637535,
      55.67864233
    ],
    "visueltcenter": [
      12.57572903,
      55.67789078
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191209"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1210",
    "nr": "1210",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57363111,
      55.67650687,
      12.57662327,
      55.67841288
    ],
    "visueltcenter": [
      12.57425967,
      55.67809804
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2019-02-13T22:04:25.816Z",
    "geo_ændret": "2019-02-13T22:04:25.816Z",
    "geo_version": 3,
    "dagi_id": "191210"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1211",
    "nr": "1211",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57372036,
      55.67711113,
      12.57545182,
      55.67811281
    ],
    "visueltcenter": [
      12.5742407,
      55.67746346
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191211"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1212",
    "nr": "1212",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57783067,
      55.67661107,
      12.57950342,
      55.67748757
    ],
    "visueltcenter": [
      12.57874486,
      55.6770308
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2020-04-29T21:07:33.980Z",
    "geo_ændret": "2020-04-29T21:07:33.980Z",
    "geo_version": 3,
    "dagi_id": "191212"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1213",
    "nr": "1213",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57718108,
      55.67621863,
      12.57874239,
      55.67710922
    ],
    "visueltcenter": [
      12.57779783,
      55.67665117
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191213"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1214",
    "nr": "1214",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57716521,
      55.67399606,
      12.58081121,
      55.67528479
    ],
    "visueltcenter": [
      12.57975543,
      55.67457161
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-11-17T21:28:49.961Z",
    "geo_version": 2,
    "dagi_id": "191214"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1215",
    "nr": "1215",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58163211,
      55.67485114,
      12.58671686,
      55.67665116
    ],
    "visueltcenter": [
      12.58576691,
      55.67544188
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2018-01-16T22:10:42.686Z",
    "geo_version": 2,
    "dagi_id": "191215"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1216",
    "nr": "1216",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58181418,
      55.67407704,
      12.58688037,
      55.67618876
    ],
    "visueltcenter": [
      12.58304021,
      55.67524053
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191216"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1218",
    "nr": "1218",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57562106,
      55.67370618,
      12.58337715,
      55.6777235
    ],
    "visueltcenter": [
      12.58039426,
      55.67606465
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2020-04-29T21:07:33.980Z",
    "geo_ændret": "2020-04-29T21:07:33.980Z",
    "geo_version": 4,
    "dagi_id": "191218"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1219",
    "nr": "1219",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57726027,
      55.67011736,
      12.586825,
      55.67542783
    ],
    "visueltcenter": [
      12.58340336,
      55.67370675
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2017-11-01T22:10:42.685Z",
    "geo_version": 4,
    "dagi_id": "191219"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1220",
    "nr": "1220",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57394693,
      55.67245008,
      12.58109258,
      55.67623161
    ],
    "visueltcenter": [
      12.57862049,
      55.67358331
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-12-06T23:17:47.810Z",
    "geo_version": 5,
    "dagi_id": "191220"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1221",
    "nr": "1221",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57987822,
      55.67148938,
      12.58747082,
      55.67588951
    ],
    "visueltcenter": [
      12.58192337,
      55.67283897
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "413310"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1250",
    "nr": "1250",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58843298,
      55.67987604,
      12.59690758,
      55.68268955
    ],
    "visueltcenter": [
      12.58975823,
      55.68202801
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191250"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1251",
    "nr": "1251",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.59239008,
      55.6783403,
      12.59644524,
      55.68075588
    ],
    "visueltcenter": [
      12.59498748,
      55.67960602
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191251"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1252",
    "nr": "1252",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.59446045,
      55.6803565,
      12.59785712,
      55.6828241
    ],
    "visueltcenter": [
      12.59617117,
      55.68145466
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191252"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1253",
    "nr": "1253",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.59118887,
      55.67964814,
      12.60063804,
      55.68700473
    ],
    "visueltcenter": [
      12.59699341,
      55.6847672
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2020-08-14T21:12:37.212Z",
    "geo_ændret": "2020-08-14T21:12:37.212Z",
    "geo_version": 4,
    "dagi_id": "191253"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1254",
    "nr": "1254",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58903782,
      55.68013309,
      12.591577,
      55.68164495
    ],
    "visueltcenter": [
      12.59034599,
      55.68100155
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-11-17T21:28:49.961Z",
    "geo_version": 2,
    "dagi_id": "191254"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1255",
    "nr": "1255",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58762027,
      55.68074045,
      12.58997102,
      55.68163225
    ],
    "visueltcenter": [
      12.58863161,
      55.68116853
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-11-17T21:28:49.961Z",
    "geo_version": 3,
    "dagi_id": "191255"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1256",
    "nr": "1256",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.59023739,
      55.68142306,
      12.60165258,
      55.68818311
    ],
    "visueltcenter": [
      12.59417151,
      55.68579757
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2020-08-14T21:12:37.212Z",
    "geo_ændret": "2020-08-14T21:12:37.212Z",
    "geo_version": 6,
    "dagi_id": "191256"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1257",
    "nr": "1257",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.59101185,
      55.68301766,
      12.59518179,
      55.6853253
    ],
    "visueltcenter": [
      12.59278982,
      55.68446372
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191257"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1259",
    "nr": "1259",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.59720467,
      55.68505059,
      12.63582658,
      55.71427214
    ],
    "visueltcenter": [
      12.6154165,
      55.70489621
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-09-02T21:12:21.101Z",
    "geo_ændret": "2018-09-02T21:12:21.101Z",
    "geo_version": 2,
    "dagi_id": "191259"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1260",
    "nr": "1260",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58677582,
      55.68081387,
      12.59428028,
      55.68786782
    ],
    "visueltcenter": [
      12.59009759,
      55.68382322
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2019-01-15T22:07:36.262Z",
    "geo_ændret": "2019-01-15T22:07:36.262Z",
    "geo_version": 4,
    "dagi_id": "191260"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1261",
    "nr": "1261",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58609898,
      55.68139507,
      12.58788327,
      55.68255718
    ],
    "visueltcenter": [
      12.58694628,
      55.68191836
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191261"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1263",
    "nr": "1263",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.59009591,
      55.68645458,
      12.59861878,
      55.6897731
    ],
    "visueltcenter": [
      12.59622407,
      55.68876737
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2019-08-14T21:09:46.135Z",
    "geo_ændret": "2019-08-14T21:09:46.135Z",
    "geo_version": 4,
    "dagi_id": "191263"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1264",
    "nr": "1264",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58493572,
      55.68132674,
      12.59161182,
      55.69127388
    ],
    "visueltcenter": [
      12.58756966,
      55.68424184
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2019-03-19T22:13:33.902Z",
    "geo_ændret": "2019-03-19T22:13:33.902Z",
    "geo_version": 6,
    "dagi_id": "191264"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1265",
    "nr": "1265",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58836492,
      55.68390867,
      12.59216434,
      55.68569855
    ],
    "visueltcenter": [
      12.58943983,
      55.68485743
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2019-03-19T22:13:33.902Z",
    "geo_ændret": "2019-03-19T22:13:33.902Z",
    "geo_version": 3,
    "dagi_id": "191265"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1266",
    "nr": "1266",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.5904098,
      55.68749504,
      12.59170253,
      55.68831851
    ],
    "visueltcenter": [
      12.59098837,
      55.68789729
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191266"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1267",
    "nr": "1267",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58979507,
      55.68794496,
      12.59167356,
      55.68892491
    ],
    "visueltcenter": [
      12.5903806,
      55.68828183
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191267"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1268",
    "nr": "1268",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58963864,
      55.68849961,
      12.5911715,
      55.68916348
    ],
    "visueltcenter": [
      12.59030867,
      55.68881006
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191268"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1270",
    "nr": "1270",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58735708,
      55.68766529,
      12.59356225,
      55.69160643
    ],
    "visueltcenter": [
      12.5908799,
      55.68984032
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2019-08-14T21:09:46.135Z",
    "geo_ændret": "2019-08-14T21:09:46.135Z",
    "geo_version": 3,
    "dagi_id": "191270"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1271",
    "nr": "1271",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58953309,
      55.6890715,
      12.59016779,
      55.68938522
    ],
    "visueltcenter": [
      12.58981807,
      55.68924322
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2019-01-15T22:07:36.262Z",
    "geo_ændret": "2019-01-15T22:07:36.262Z",
    "geo_version": 3,
    "dagi_id": "191271"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1300",
    "nr": "1300",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58302452,
      55.68185731,
      12.58909822,
      55.68852578
    ],
    "visueltcenter": [
      12.58427504,
      55.68267225
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-11-20T22:13:37.996Z",
    "geo_ændret": "2018-11-20T22:13:37.996Z",
    "geo_version": 3,
    "dagi_id": "191300"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1301",
    "nr": "1301",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58454737,
      55.68271443,
      12.58651714,
      55.68376165
    ],
    "visueltcenter": [
      12.58587955,
      55.68334448
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-11-17T21:28:49.961Z",
    "geo_version": 2,
    "dagi_id": "191301"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1302",
    "nr": "1302",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58256612,
      55.6826282,
      12.58965729,
      55.68529226
    ],
    "visueltcenter": [
      12.58409674,
      55.68448319
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191302"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1303",
    "nr": "1303",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58747758,
      55.68569392,
      12.58908573,
      55.68633788
    ],
    "visueltcenter": [
      12.58837636,
      55.68594749
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191303"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1304",
    "nr": "1304",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.5816758,
      55.68218171,
      12.58687564,
      55.68711005
    ],
    "visueltcenter": [
      12.58306965,
      55.68333481
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-11-17T21:28:49.961Z",
    "geo_version": 2,
    "dagi_id": "191304"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1306",
    "nr": "1306",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57928022,
      55.68254734,
      12.58728359,
      55.6904331
    ],
    "visueltcenter": [
      12.58135321,
      55.68430724
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191306"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1307",
    "nr": "1307",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.56973261,
      55.68465316,
      12.58680865,
      55.69138973
    ],
    "visueltcenter": [
      12.57998797,
      55.68643425
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-05-18T21:15:28.822Z",
    "geo_ændret": "2018-05-18T21:15:28.822Z",
    "geo_version": 4,
    "dagi_id": "191307"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1308",
    "nr": "1308",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58214642,
      55.6858896,
      12.58742165,
      55.68716787
    ],
    "visueltcenter": [
      12.58479357,
      55.6865329
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191308"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1309",
    "nr": "1309",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58248241,
      55.68670848,
      12.58448065,
      55.68780892
    ],
    "visueltcenter": [
      12.58342163,
      55.68721135
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191309"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1310",
    "nr": "1310",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58281787,
      55.68478699,
      12.59429639,
      55.68802262
    ],
    "visueltcenter": [
      12.59029867,
      55.68593955
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191310"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1311",
    "nr": "1311",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58309726,
      55.68639088,
      12.5894245,
      55.68845151
    ],
    "visueltcenter": [
      12.58380631,
      55.68811515
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191311"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1312",
    "nr": "1312",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58538181,
      55.68703183,
      12.58621862,
      55.68790185
    ],
    "visueltcenter": [
      12.58583966,
      55.68763774
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191312"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1313",
    "nr": "1313",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58347673,
      55.68671823,
      12.58976485,
      55.68852257
    ],
    "visueltcenter": [
      12.58894598,
      55.68703948
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191313"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1314",
    "nr": "1314",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58595996,
      55.68720482,
      12.58792734,
      55.68833007
    ],
    "visueltcenter": [
      12.58633595,
      55.68750826
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-04-13T20:29:46.699Z",
    "geo_version": 2,
    "dagi_id": "191314"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1315",
    "nr": "1315",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58728386,
      55.68741809,
      12.58833582,
      55.68783854
    ],
    "visueltcenter": [
      12.58791833,
      55.68763127
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191315"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1316",
    "nr": "1316",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58167875,
      55.68641639,
      12.58498786,
      55.6896461
    ],
    "visueltcenter": [
      12.58282835,
      55.68830711
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2020-03-04T22:13:58.161Z",
    "geo_ændret": "2020-03-04T22:13:58.161Z",
    "geo_version": 5,
    "dagi_id": "191316"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1317",
    "nr": "1317",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58075153,
      55.68843579,
      12.58408891,
      55.68952337
    ],
    "visueltcenter": [
      12.58191343,
      55.68896717
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2020-03-04T22:13:58.161Z",
    "geo_ændret": "2020-03-04T22:13:58.161Z",
    "geo_version": 5,
    "dagi_id": "191317"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1318",
    "nr": "1318",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58380711,
      55.68819591,
      12.58551291,
      55.68880431
    ],
    "visueltcenter": [
      12.58478262,
      55.68846582
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191318"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1319",
    "nr": "1319",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58387026,
      55.68722949,
      12.59028994,
      55.68899467
    ],
    "visueltcenter": [
      12.58712218,
      55.68802871
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-04-13T20:29:46.699Z",
    "geo_version": 2,
    "dagi_id": "191319"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1320",
    "nr": "1320",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58594004,
      55.68809035,
      12.58913844,
      55.68902552
    ],
    "visueltcenter": [
      12.58748743,
      55.68846691
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191320"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1321",
    "nr": "1321",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58879468,
      55.68768986,
      12.58999073,
      55.68825183
    ],
    "visueltcenter": [
      12.5895451,
      55.68791672
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191321"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1322",
    "nr": "1322",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58436116,
      55.6880686,
      12.59007281,
      55.68972024
    ],
    "visueltcenter": [
      12.58942362,
      55.68835925
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191322"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1323",
    "nr": "1323",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58482119,
      55.6893856,
      12.58665288,
      55.68994689
    ],
    "visueltcenter": [
      12.58573703,
      55.68966625
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191323"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1324",
    "nr": "1324",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58628348,
      55.68854465,
      12.58955834,
      55.6896173
    ],
    "visueltcenter": [
      12.58712585,
      55.68930756
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191324"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1325",
    "nr": "1325",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58555643,
      55.68894853,
      12.58950456,
      55.69027876
    ],
    "visueltcenter": [
      12.58847527,
      55.68932439
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-11-17T21:28:49.961Z",
    "geo_version": 2,
    "dagi_id": "191325"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1326",
    "nr": "1326",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58601014,
      55.68939286,
      12.5891296,
      55.6904122
    ],
    "visueltcenter": [
      12.58839238,
      55.68962824
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191326"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1327",
    "nr": "1327",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58658722,
      55.68981117,
      12.5881423,
      55.69088135
    ],
    "visueltcenter": [
      12.58723576,
      55.69037471
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191327"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1328",
    "nr": "1328",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58726081,
      55.6896226,
      12.58893539,
      55.69122906
    ],
    "visueltcenter": [
      12.58836924,
      55.68991619
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191328"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1329",
    "nr": "1329",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58417843,
      55.68871528,
      12.58599683,
      55.6893082
    ],
    "visueltcenter": [
      12.58464108,
      55.68914774
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191329"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1350",
    "nr": "1350",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57346757,
      55.68404226,
      12.58758373,
      55.69176783
    ],
    "visueltcenter": [
      12.57590522,
      55.68659617
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-05-18T21:15:28.822Z",
    "geo_ændret": "2018-05-18T21:15:28.822Z",
    "geo_version": 4,
    "dagi_id": "191350"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1352",
    "nr": "1352",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.5694568,
      55.68899094,
      12.57396633,
      55.69097477
    ],
    "visueltcenter": [
      12.57310725,
      55.68939296
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191352"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1353",
    "nr": "1353",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.56886405,
      55.68540212,
      12.57572412,
      55.68943092
    ],
    "visueltcenter": [
      12.57119575,
      55.68704697
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-05-18T21:15:28.822Z",
    "geo_ændret": "2018-05-18T21:15:28.822Z",
    "geo_version": 3,
    "dagi_id": "191353"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1354",
    "nr": "1354",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.56884531,
      55.68863768,
      12.57347395,
      55.69075108
    ],
    "visueltcenter": [
      12.57096165,
      55.68980117
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191354"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1355",
    "nr": "1355",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.5677798,
      55.68807042,
      12.57298379,
      55.69055894
    ],
    "visueltcenter": [
      12.5712291,
      55.68871643
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191355"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1356",
    "nr": "1356",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.56503675,
      55.68617153,
      12.5694213,
      55.68840847
    ],
    "visueltcenter": [
      12.56804206,
      55.6871711
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2018-01-02T22:13:15.915Z",
    "geo_version": 2,
    "dagi_id": "191356"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1357",
    "nr": "1357",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.56477425,
      55.6865481,
      12.57181365,
      55.69147634
    ],
    "visueltcenter": [
      12.56812794,
      55.6886384
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2018-01-02T22:13:15.915Z",
    "geo_version": 2,
    "dagi_id": "191357"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1358",
    "nr": "1358",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.56505165,
      55.67850801,
      12.57419554,
      55.68490577
    ],
    "visueltcenter": [
      12.56695637,
      55.68056747
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-05-18T21:15:28.822Z",
    "geo_ændret": "2018-05-18T21:15:28.822Z",
    "geo_version": 2,
    "dagi_id": "191358"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1359",
    "nr": "1359",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.56338228,
      55.68276131,
      12.56727007,
      55.6840989
    ],
    "visueltcenter": [
      12.56421074,
      55.68365009
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-11-10T21:36:32.078Z",
    "geo_version": 2,
    "dagi_id": "191359"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1360",
    "nr": "1360",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.56356176,
      55.68251469,
      12.57446489,
      55.68672712
    ],
    "visueltcenter": [
      12.56656367,
      55.6856827
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191360"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1361",
    "nr": "1361",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.56710477,
      55.68170319,
      12.57216315,
      55.68514573
    ],
    "visueltcenter": [
      12.56876693,
      55.68296065
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191361"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1362",
    "nr": "1362",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.56696574,
      55.68263171,
      12.57087356,
      55.68539059
    ],
    "visueltcenter": [
      12.56993139,
      55.68488443
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191362"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1363",
    "nr": "1363",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.56443552,
      55.68278074,
      12.57132596,
      55.68572539
    ],
    "visueltcenter": [
      12.56557099,
      55.68513437
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-07-10T21:09:46.035Z",
    "geo_ændret": "2018-07-10T21:09:46.035Z",
    "geo_version": 2,
    "dagi_id": "191363"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1364",
    "nr": "1364",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.56316574,
      55.67880742,
      12.56985414,
      55.68596959
    ],
    "visueltcenter": [
      12.56643374,
      55.68207564
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-07-10T21:09:46.035Z",
    "geo_ændret": "2018-07-10T21:09:46.035Z",
    "geo_version": 3,
    "dagi_id": "191364"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1365",
    "nr": "1365",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.56376871,
      55.68168565,
      12.56523431,
      55.68251124
    ],
    "visueltcenter": [
      12.56444325,
      55.68208169
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191365"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1366",
    "nr": "1366",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.56076454,
      55.68036431,
      12.56887141,
      55.68653826
    ],
    "visueltcenter": [
      12.56325243,
      55.68207959
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-07-10T21:09:46.035Z",
    "geo_ændret": "2018-07-10T21:09:46.035Z",
    "geo_version": 2,
    "dagi_id": "191366"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1367",
    "nr": "1367",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.56103242,
      55.68270195,
      12.56455196,
      55.68395856
    ],
    "visueltcenter": [
      12.56359571,
      55.68310746
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191367"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1368",
    "nr": "1368",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.5613706,
      55.68094225,
      12.56452727,
      55.68225228
    ],
    "visueltcenter": [
      12.56378826,
      55.68138249
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191368"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1369",
    "nr": "1369",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.55786281,
      55.67977809,
      12.56416682,
      55.68309392
    ],
    "visueltcenter": [
      12.55953017,
      55.68214443
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-04-09T08:23:44.102Z",
    "geo_version": 2,
    "dagi_id": "191369"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1370",
    "nr": "1370",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.56019259,
      55.68122335,
      12.56540559,
      55.6858466
    ],
    "visueltcenter": [
      12.56346092,
      55.68484818
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191370"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1371",
    "nr": "1371",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.56291844,
      55.68517881,
      12.56628547,
      55.6868142
    ],
    "visueltcenter": [
      12.56404534,
      55.68594651
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191371"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1400",
    "nr": "1400",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58601151,
      55.66912275,
      12.60068949,
      55.67535699
    ],
    "visueltcenter": [
      12.59681168,
      55.67038519
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2019-09-13T21:13:15.692Z",
    "geo_ændret": "2019-09-13T21:13:15.692Z",
    "geo_version": 5,
    "dagi_id": "191400"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1401",
    "nr": "1401",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58651728,
      55.67141337,
      12.59899708,
      55.67917106
    ],
    "visueltcenter": [
      12.59566443,
      55.67729107
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2019-09-13T21:13:15.692Z",
    "geo_ændret": "2019-09-13T21:13:15.692Z",
    "geo_version": 5,
    "dagi_id": "191401"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1402",
    "nr": "1402",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58297171,
      55.67037689,
      12.59007366,
      55.67623291
    ],
    "visueltcenter": [
      12.58542262,
      55.67280147
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191402"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1403",
    "nr": "1403",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.59248266,
      55.67448475,
      12.59549007,
      55.67641735
    ],
    "visueltcenter": [
      12.5941033,
      55.67539344
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191403"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1406",
    "nr": "1406",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.59233351,
      55.67463849,
      12.59396399,
      55.67570433
    ],
    "visueltcenter": [
      12.59344137,
      55.67493602
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-09-01T21:48:48.579Z",
    "geo_version": 2,
    "dagi_id": "191406"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1407",
    "nr": "1407",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.59214296,
      55.67231678,
      12.59725353,
      55.67523671
    ],
    "visueltcenter": [
      12.59549054,
      55.67360101
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2020-04-29T21:07:33.980Z",
    "geo_ændret": "2020-04-29T21:07:33.980Z",
    "geo_version": 4,
    "dagi_id": "191407"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1408",
    "nr": "1408",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58635177,
      55.67081545,
      12.59335382,
      55.67558927
    ],
    "visueltcenter": [
      12.59088619,
      55.67363421
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-09-01T21:42:42.766Z",
    "geo_version": 2,
    "dagi_id": "191408"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1409",
    "nr": "1409",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58589058,
      55.67307328,
      12.58890668,
      55.67446767
    ],
    "visueltcenter": [
      12.58776067,
      55.67350436
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2019-09-13T21:13:15.692Z",
    "geo_ændret": "2019-09-13T21:13:15.692Z",
    "geo_version": 4,
    "dagi_id": "191409"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1410",
    "nr": "1410",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.59076062,
      55.67166109,
      12.5915921,
      55.67233413
    ],
    "visueltcenter": [
      12.59114295,
      55.67196294
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191410"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1411",
    "nr": "1411",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.5790615,
      55.66792267,
      12.58988897,
      55.67285335
    ],
    "visueltcenter": [
      12.58304583,
      55.6706881
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-09-01T21:42:42.766Z",
    "geo_version": 2,
    "dagi_id": "191411"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1412",
    "nr": "1412",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58563708,
      55.67039265,
      12.58639821,
      55.67078429
    ],
    "visueltcenter": [
      12.58609487,
      55.67059202
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191412"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1413",
    "nr": "1413",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58609772,
      55.67024813,
      12.5874449,
      55.67093382
    ],
    "visueltcenter": [
      12.58669684,
      55.67064899
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191413"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1414",
    "nr": "1414",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58746605,
      55.67042967,
      12.59418105,
      55.67493961
    ],
    "visueltcenter": [
      12.58974986,
      55.67225731
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191414"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1415",
    "nr": "1415",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58710161,
      55.66970474,
      12.59832295,
      55.67713149
    ],
    "visueltcenter": [
      12.59499761,
      55.67444226
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2020-04-29T21:07:33.980Z",
    "geo_ændret": "2020-04-29T21:07:33.980Z",
    "geo_version": 4,
    "dagi_id": "191415"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1416",
    "nr": "1416",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.5902862,
      55.67164716,
      12.59623496,
      55.6744885
    ],
    "visueltcenter": [
      12.59454445,
      55.673046
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-11-18T21:24:34.771Z",
    "geo_version": 2,
    "dagi_id": "191416"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1417",
    "nr": "1417",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.59170686,
      55.67247443,
      12.59302702,
      55.67315544
    ],
    "visueltcenter": [
      12.59236256,
      55.67274948
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191417"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1418",
    "nr": "1418",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58961792,
      55.67020318,
      12.59217283,
      55.67161062
    ],
    "visueltcenter": [
      12.59016855,
      55.67133117
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-11-10T21:36:32.078Z",
    "geo_version": 2,
    "dagi_id": "191418"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1419",
    "nr": "1419",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58829235,
      55.67038765,
      12.58946366,
      55.67075992
    ],
    "visueltcenter": [
      12.58895097,
      55.67053449
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191419"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1420",
    "nr": "1420",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58815742,
      55.66976901,
      12.59514035,
      55.67409848
    ],
    "visueltcenter": [
      12.59000159,
      55.67073872
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191420"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1421",
    "nr": "1421",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58794027,
      55.67006232,
      12.58855678,
      55.67054204
    ],
    "visueltcenter": [
      12.58811181,
      55.67028252
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191421"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1422",
    "nr": "1422",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.59021801,
      55.66974998,
      12.60188534,
      55.67792254
    ],
    "visueltcenter": [
      12.60083,
      55.67583778
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2020-12-22T22:14:47.461Z",
    "geo_ændret": "2020-12-22T22:14:47.461Z",
    "geo_version": 5,
    "dagi_id": "191422"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1423",
    "nr": "1423",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.59181276,
      55.6702592,
      12.5934917,
      55.6712016
    ],
    "visueltcenter": [
      12.59282667,
      55.67073581
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191423"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1424",
    "nr": "1424",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.58672985,
      55.66768087,
      12.59781161,
      55.67265878
    ],
    "visueltcenter": [
      12.59220558,
      55.66931315
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-05-18T21:15:28.822Z",
    "geo_ændret": "2018-05-18T21:15:28.822Z",
    "geo_version": 4,
    "dagi_id": "191424"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1425",
    "nr": "1425",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.59308621,
      55.67108854,
      12.595665,
      55.67238369
    ],
    "visueltcenter": [
      12.59425877,
      55.67184012
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191425"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1426",
    "nr": "1426",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.59581125,
      55.67153616,
      12.59791928,
      55.67293417
    ],
    "visueltcenter": [
      12.59670167,
      55.67223173
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191426"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1427",
    "nr": "1427",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.59596537,
      55.67473926,
      12.59800965,
      55.67552429
    ],
    "visueltcenter": [
      12.59740604,
      55.67501129
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-11-21T21:25:41.001Z",
    "geo_version": 2,
    "dagi_id": "191427"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1428",
    "nr": "1428",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.5967773,
      55.67518288,
      12.59896836,
      55.67661732
    ],
    "visueltcenter": [
      12.59745699,
      55.67551144
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191428"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1429",
    "nr": "1429",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.59739867,
      55.67448637,
      12.59963277,
      55.67628761
    ],
    "visueltcenter": [
      12.59825398,
      55.67527963
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-11-21T21:25:41.001Z",
    "geo_version": 2,
    "dagi_id": "191429"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1430",
    "nr": "1430",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.59767273,
      55.67564695,
      12.60035858,
      55.67779021
    ],
    "visueltcenter": [
      12.59864182,
      55.67701184
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191430"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1432",
    "nr": "1432",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.60032351,
      55.67277968,
      12.65955666,
      55.71451861
    ],
    "visueltcenter": [
      12.61655877,
      55.69425974
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2021-02-17T22:17:17.176Z",
    "geo_ændret": "2021-02-17T22:17:17.176Z",
    "geo_version": 11,
    "dagi_id": "191432"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1433",
    "nr": "1433",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.63246167,
      55.66126882,
      12.85455136,
      55.79485029
    ],
    "visueltcenter": [
      12.72430087,
      55.72946145
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191433"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1434",
    "nr": "1434",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.5993471,
      55.67669983,
      12.60649704,
      55.6838803
    ],
    "visueltcenter": [
      12.60345664,
      55.68032633
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2019-12-03T22:14:43.785Z",
    "geo_ændret": "2019-12-03T22:14:43.785Z",
    "geo_version": 2,
    "dagi_id": "191347"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1435",
    "nr": "1435",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.60259779,
      55.67968762,
      12.60726628,
      55.68213314
    ],
    "visueltcenter": [
      12.60607236,
      55.6803494
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2019-09-13T21:13:15.692Z",
    "geo_ændret": "2019-09-13T21:13:15.692Z",
    "geo_version": 2,
    "dagi_id": "191501"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1436",
    "nr": "1436",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.59513385,
      55.67445975,
      12.60826224,
      55.68089874
    ],
    "visueltcenter": [
      12.6035624,
      55.6760139
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2019-12-03T22:14:43.785Z",
    "geo_ændret": "2019-12-03T22:14:43.785Z",
    "geo_version": 2,
    "dagi_id": "191502"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1437",
    "nr": "1437",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.60018616,
      55.67795264,
      12.60965891,
      55.68437592
    ],
    "visueltcenter": [
      12.60755523,
      55.6822005
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2019-11-02T22:14:59.800Z",
    "geo_ændret": "2019-11-02T22:14:59.800Z",
    "geo_version": 1,
    "dagi_id": "191503"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1438",
    "nr": "1438",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.59720906,
      55.67963346,
      12.6040237,
      55.68616704
    ],
    "visueltcenter": [
      12.60018111,
      55.68254869
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191529"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1439",
    "nr": "1439",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.60127096,
      55.68317852,
      12.61328487,
      55.69055973
    ],
    "visueltcenter": [
      12.60639558,
      55.68671916
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2020-03-19T22:17:29.747Z",
    "geo_ændret": "2020-03-19T22:17:29.747Z",
    "geo_version": 8,
    "dagi_id": "191530"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1440",
    "nr": "1440",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.5957347,
      55.67034158,
      12.6127224,
      55.68249361
    ],
    "visueltcenter": [
      12.60137342,
      55.67308696
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2021-02-17T22:17:17.176Z",
    "geo_ændret": "2021-02-17T22:17:17.176Z",
    "geo_version": 6,
    "dagi_id": "191534"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1441",
    "nr": "1441",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.59842973,
      55.66921566,
      12.61470114,
      55.68388097
    ],
    "visueltcenter": [
      12.6128732,
      55.67992992
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "413088"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1450",
    "nr": "1450",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57208772,
      55.67668453,
      12.57408557,
      55.67815663
    ],
    "visueltcenter": [
      12.57305928,
      55.67728132
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2018-01-16T22:10:42.686Z",
    "geo_version": 3,
    "dagi_id": "191450"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1451",
    "nr": "1451",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.56868769,
      55.67932057,
      12.57041523,
      55.68091981
    ],
    "visueltcenter": [
      12.56919359,
      55.6800785
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191451"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1452",
    "nr": "1452",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.56760426,
      55.67903778,
      12.5690292,
      55.68006365
    ],
    "visueltcenter": [
      12.56829492,
      55.67954104
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191452"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1453",
    "nr": "1453",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.5664681,
      55.67819986,
      12.57160818,
      55.68049259
    ],
    "visueltcenter": [
      12.57080331,
      55.67990502
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191453"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1454",
    "nr": "1454",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.56819351,
      55.67765438,
      12.57037837,
      55.67905862
    ],
    "visueltcenter": [
      12.56955821,
      55.67806442
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191454"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1455",
    "nr": "1455",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.56695871,
      55.67753982,
      12.57140034,
      55.67944757
    ],
    "visueltcenter": [
      12.57032224,
      55.67883409
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-05-18T21:15:28.822Z",
    "geo_ændret": "2018-05-18T21:15:28.822Z",
    "geo_version": 2,
    "dagi_id": "191455"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1456",
    "nr": "1456",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.56803917,
      55.67650166,
      12.57151195,
      55.67873532
    ],
    "visueltcenter": [
      12.56925402,
      55.67731979
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191456"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1457",
    "nr": "1457",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57094068,
      55.67761131,
      12.57305085,
      55.67870109
    ],
    "visueltcenter": [
      12.57191269,
      55.67821919
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-12-01T21:24:59.627Z",
    "geo_version": 2,
    "dagi_id": "191457"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1458",
    "nr": "1458",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57014106,
      55.67652149,
      12.57265142,
      55.67766978
    ],
    "visueltcenter": [
      12.57201105,
      55.67697626
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191458"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1459",
    "nr": "1459",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.56920699,
      55.67632584,
      12.57292398,
      55.67816487
    ],
    "visueltcenter": [
      12.57180355,
      55.67753312
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2020-01-27T22:16:10.946Z",
    "geo_ændret": "2020-01-27T22:16:10.946Z",
    "geo_version": 5,
    "dagi_id": "191459"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1460",
    "nr": "1460",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57034884,
      55.67624276,
      12.57159907,
      55.67699014
    ],
    "visueltcenter": [
      12.57083548,
      55.67662509
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2020-01-27T22:16:10.946Z",
    "geo_ændret": "2020-01-27T22:16:10.946Z",
    "geo_version": 3,
    "dagi_id": "191460"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1461",
    "nr": "1461",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.5723532,
      55.67660092,
      12.57325016,
      55.67694064
    ],
    "visueltcenter": [
      12.57298975,
      55.67681948
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191461"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1462",
    "nr": "1462",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57068938,
      55.67566694,
      12.57239534,
      55.67677052
    ],
    "visueltcenter": [
      12.57177767,
      55.6762414
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-12-05T23:22:32.486Z",
    "geo_version": 3,
    "dagi_id": "191462"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1463",
    "nr": "1463",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57101005,
      55.67504277,
      12.5734033,
      55.67647469
    ],
    "visueltcenter": [
      12.57219443,
      55.67572208
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191463"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1464",
    "nr": "1464",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57215746,
      55.6761766,
      12.5734553,
      55.67681582
    ],
    "visueltcenter": [
      12.5726221,
      55.67644962
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-12-01T21:24:59.627Z",
    "geo_version": 2,
    "dagi_id": "191464"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1465",
    "nr": "1465",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57319673,
      55.67590397,
      12.57378344,
      55.67626596
    ],
    "visueltcenter": [
      12.57342745,
      55.67606578
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191465"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1466",
    "nr": "1466",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57350527,
      55.67614248,
      12.57507309,
      55.67732864
    ],
    "visueltcenter": [
      12.57412316,
      55.67686347
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-05-17T21:17:17.429Z",
    "geo_ændret": "2018-05-17T21:17:17.429Z",
    "geo_version": 2,
    "dagi_id": "191466"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1467",
    "nr": "1467",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57343245,
      55.67560884,
      12.57481492,
      55.67643828
    ],
    "visueltcenter": [
      12.57413846,
      55.67607304
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191467"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1468",
    "nr": "1468",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57206452,
      55.67492407,
      12.57453911,
      55.675988
    ],
    "visueltcenter": [
      12.57336739,
      55.67552423
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-12-16T23:19:50.845Z",
    "geo_version": 4,
    "dagi_id": "191468"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1470",
    "nr": "1470",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57282482,
      55.67437588,
      12.57584801,
      55.67588898
    ],
    "visueltcenter": [
      12.57386014,
      55.67506238
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-12-16T23:19:50.845Z",
    "geo_version": 4,
    "dagi_id": "191470"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1471",
    "nr": "1471",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57390246,
      55.67378403,
      12.57665144,
      55.67487031
    ],
    "visueltcenter": [
      12.57499967,
      55.67431551
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-11-17T21:28:49.961Z",
    "geo_version": 3,
    "dagi_id": "191471"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1472",
    "nr": "1472",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57534747,
      55.6732053,
      12.57783236,
      55.67424902
    ],
    "visueltcenter": [
      12.57632308,
      55.67368537
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191472"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1473",
    "nr": "1473",
    "navn": "København K",
    "stormodtageradresser": null,
    "bbox": [
      12.57727792,
      55.67181881,
      12.5799941,
      55.67304381
    ],
    "visueltcenter": [
      12.57834447,
      55.67232734
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2017-11-01T22:10:42.685Z",
    "geo_version": 6,
    "dagi_id": "191473"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1550",
    "nr": "1550",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.56621199,
      55.67426651,
      12.57212996,
      55.6771282
    ],
    "visueltcenter": [
      12.56794358,
      55.67630637
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-12-05T23:22:32.486Z",
    "geo_version": 2,
    "dagi_id": "191550"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1551",
    "nr": "1551",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.56384617,
      55.67826229,
      12.56669437,
      55.6797345
    ],
    "visueltcenter": [
      12.56484383,
      55.67922359
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-05-18T21:15:28.822Z",
    "geo_ændret": "2018-05-18T21:15:28.822Z",
    "geo_version": 2,
    "dagi_id": "191551"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1552",
    "nr": "1552",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.56545235,
      55.6715837,
      12.57953555,
      55.67863065
    ],
    "visueltcenter": [
      12.57367758,
      55.67433051
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2020-08-14T21:12:37.212Z",
    "geo_ændret": "2020-08-14T21:12:37.212Z",
    "geo_version": 8,
    "dagi_id": "191552"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1553",
    "nr": "1553",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.5626176,
      55.66984883,
      12.58065759,
      55.67991234
    ],
    "visueltcenter": [
      12.56908785,
      55.67455675
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2019-01-15T22:07:36.262Z",
    "geo_ændret": "2019-01-15T22:07:36.262Z",
    "geo_version": 4,
    "dagi_id": "191553"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1554",
    "nr": "1554",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.56335069,
      55.6762551,
      12.56726282,
      55.6779435
    ],
    "visueltcenter": [
      12.56487951,
      55.67705334
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191554"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1555",
    "nr": "1555",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.57202608,
      55.67373112,
      12.57603464,
      55.67567774
    ],
    "visueltcenter": [
      12.57512169,
      55.674964
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-04-09T08:23:44.102Z",
    "geo_version": 2,
    "dagi_id": "191555"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1556",
    "nr": "1556",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.57170846,
      55.67230805,
      12.57436848,
      55.67409622
    ],
    "visueltcenter": [
      12.5735107,
      55.67354201
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191556"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1557",
    "nr": "1557",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.57401609,
      55.67274934,
      12.57533936,
      55.67343753
    ],
    "visueltcenter": [
      12.57487219,
      55.6731643
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2020-08-14T21:12:37.212Z",
    "geo_ændret": "2020-08-14T21:12:37.212Z",
    "geo_version": 3,
    "dagi_id": "191557"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1558",
    "nr": "1558",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.57638616,
      55.6716794,
      12.57750609,
      55.67221902
    ],
    "visueltcenter": [
      12.57714319,
      55.67189966
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191558"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1559",
    "nr": "1559",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.57676186,
      55.67099208,
      12.57839035,
      55.67195349
    ],
    "visueltcenter": [
      12.57764517,
      55.67150035
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191559"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1560",
    "nr": "1560",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55733634,
      55.66074792,
      12.57823729,
      55.67151288
    ],
    "visueltcenter": [
      12.5708171,
      55.66668985
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-11-06T22:07:21.740Z",
    "geo_ændret": "2018-11-06T22:07:21.740Z",
    "geo_version": 9,
    "dagi_id": "191560"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1561",
    "nr": "1561",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55783481,
      55.65939367,
      12.5685981,
      55.66420945
    ],
    "visueltcenter": [
      12.56237124,
      55.6608846
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-11-05T22:14:33.117Z",
    "geo_ændret": "2018-11-05T22:14:33.117Z",
    "geo_version": 3,
    "dagi_id": "191561"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1562",
    "nr": "1562",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.571361,
      55.66945212,
      12.57630222,
      55.67122397
    ],
    "visueltcenter": [
      12.57484744,
      55.67042671
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-12-06T23:17:47.810Z",
    "geo_version": 2,
    "dagi_id": "191562"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1563",
    "nr": "1563",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.57322738,
      55.67059965,
      12.57476236,
      55.67108033
    ],
    "visueltcenter": [
      12.57359465,
      55.67081742
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191563"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1564",
    "nr": "1564",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.57368665,
      55.67083763,
      12.57672942,
      55.67272912
    ],
    "visueltcenter": [
      12.57621894,
      55.67242943
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191564"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1567",
    "nr": "1567",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.56992659,
      55.66962791,
      12.57195687,
      55.67061885
    ],
    "visueltcenter": [
      12.57132645,
      55.67019634
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2020-12-14T22:09:35.708Z",
    "geo_ændret": "2020-12-14T22:09:35.708Z",
    "geo_version": 8,
    "dagi_id": "191567"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1568",
    "nr": "1568",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.56947718,
      55.66853601,
      12.57380045,
      55.67121729
    ],
    "visueltcenter": [
      12.57277693,
      55.66908712
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2019-07-03T21:05:34.440Z",
    "geo_ændret": "2019-07-03T21:05:34.440Z",
    "geo_version": 7,
    "dagi_id": "191568"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1569",
    "nr": "1569",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.57008817,
      55.67090886,
      12.57164647,
      55.67158982
    ],
    "visueltcenter": [
      12.57067539,
      55.67128988
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191569"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1570",
    "nr": "1570",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.5626593,
      55.67258311,
      12.56543739,
      55.67441659
    ],
    "visueltcenter": [
      12.56449889,
      55.67358392
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191570"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1571",
    "nr": "1571",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.57108771,
      55.67005723,
      12.57388019,
      55.67153871
    ],
    "visueltcenter": [
      12.57232129,
      55.67075323
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2020-12-14T22:09:35.708Z",
    "geo_ændret": "2020-12-14T22:09:35.708Z",
    "geo_version": 10,
    "dagi_id": "191571"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1572",
    "nr": "1572",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.57242092,
      55.67085963,
      12.57414354,
      55.67197418
    ],
    "visueltcenter": [
      12.5730003,
      55.671606
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-11-05T22:14:33.117Z",
    "geo_ændret": "2018-11-05T22:14:33.117Z",
    "geo_version": 4,
    "dagi_id": "191572"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1573",
    "nr": "1573",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.57265924,
      55.67056208,
      12.57716778,
      55.67248978
    ],
    "visueltcenter": [
      12.57409664,
      55.67173669
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-12-06T23:17:47.810Z",
    "geo_version": 2,
    "dagi_id": "191573"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1574",
    "nr": "1574",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.57122107,
      55.67103938,
      12.57362927,
      55.6727245
    ],
    "visueltcenter": [
      12.57217128,
      55.67158961
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-11-05T22:14:33.117Z",
    "geo_ændret": "2018-11-05T22:14:33.117Z",
    "geo_version": 3,
    "dagi_id": "191574"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1575",
    "nr": "1575",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.56979587,
      55.67139152,
      12.57188402,
      55.67245033
    ],
    "visueltcenter": [
      12.57057649,
      55.67200881
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191575"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1576",
    "nr": "1576",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.56897388,
      55.67046376,
      12.57142008,
      55.67206704
    ],
    "visueltcenter": [
      12.56944432,
      55.67113694
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191576"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1577",
    "nr": "1577",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55439374,
      55.66095633,
      12.57324054,
      55.67483267
    ],
    "visueltcenter": [
      12.56799998,
      55.66901082
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2021-02-22T22:10:35.831Z",
    "geo_ændret": "2021-02-22T22:10:35.831Z",
    "geo_version": 16,
    "dagi_id": "191577"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1600",
    "nr": "1600",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.56024131,
      55.67941128,
      12.5637744,
      55.68094358
    ],
    "visueltcenter": [
      12.56258051,
      55.67988681
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191600"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1601",
    "nr": "1601",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55738127,
      55.67468365,
      12.5612274,
      55.68205242
    ],
    "visueltcenter": [
      12.55937917,
      55.67877817
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-09-02T21:12:21.101Z",
    "geo_ændret": "2018-09-02T21:12:21.101Z",
    "geo_version": 5,
    "dagi_id": "191601"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1602",
    "nr": "1602",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55771262,
      55.67455819,
      12.56247248,
      55.68069459
    ],
    "visueltcenter": [
      12.5612736,
      55.67873082
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2018-01-16T22:10:42.686Z",
    "geo_version": 3,
    "dagi_id": "191602"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1603",
    "nr": "1603",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.56157472,
      55.67881361,
      12.5632226,
      55.67964161
    ],
    "visueltcenter": [
      12.56222843,
      55.67925094
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191603"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1604",
    "nr": "1604",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.56006538,
      55.67717782,
      12.56245446,
      55.67815032
    ],
    "visueltcenter": [
      12.5613943,
      55.67743294
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191604"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1605",
    "nr": "1605",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55997482,
      55.67566147,
      12.56151826,
      55.6763629
    ],
    "visueltcenter": [
      12.56106245,
      55.67602364
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-12-06T23:17:47.810Z",
    "geo_version": 2,
    "dagi_id": "191605"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1606",
    "nr": "1606",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.56102069,
      55.67417371,
      12.56353387,
      55.67953239
    ],
    "visueltcenter": [
      12.56232778,
      55.67806697
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191606"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1607",
    "nr": "1607",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.56250287,
      55.67724026,
      12.56537561,
      55.67956887
    ],
    "visueltcenter": [
      12.56377805,
      55.67858553
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191607"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1608",
    "nr": "1608",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.56404899,
      55.67550189,
      12.56715399,
      55.67669716
    ],
    "visueltcenter": [
      12.56532316,
      55.67611495
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2019-01-15T22:07:36.262Z",
    "geo_ændret": "2019-01-15T22:07:36.262Z",
    "geo_version": 5,
    "dagi_id": "191608"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1609",
    "nr": "1609",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.56296998,
      55.67509885,
      12.5661969,
      55.67731353
    ],
    "visueltcenter": [
      12.56377996,
      55.67579789
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-12-13T23:24:26.040Z",
    "geo_version": 3,
    "dagi_id": "191609"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1610",
    "nr": "1610",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54969448,
      55.67273054,
      12.5605934,
      55.67592802
    ],
    "visueltcenter": [
      12.5577223,
      55.67456576
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-09-02T21:12:21.101Z",
    "geo_ændret": "2018-09-02T21:12:21.101Z",
    "geo_version": 4,
    "dagi_id": "191610"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1611",
    "nr": "1611",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.56293341,
      55.67474109,
      12.56476066,
      55.67819796
    ],
    "visueltcenter": [
      12.56362751,
      55.67518232
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191611"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1612",
    "nr": "1612",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.56028405,
      55.67445137,
      12.5636665,
      55.67762201
    ],
    "visueltcenter": [
      12.56267131,
      55.67560841
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191612"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1613",
    "nr": "1613",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.56090013,
      55.6737241,
      12.56247255,
      55.67514837
    ],
    "visueltcenter": [
      12.56162653,
      55.67452629
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191613"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1614",
    "nr": "1614",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.5599415,
      55.67333981,
      12.56136529,
      55.67460448
    ],
    "visueltcenter": [
      12.56065848,
      55.67414128
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191614"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1615",
    "nr": "1615",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55845807,
      55.6732233,
      12.55976308,
      55.67420732
    ],
    "visueltcenter": [
      12.55909001,
      55.67390932
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191615"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1616",
    "nr": "1616",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55633476,
      55.67264647,
      12.55801923,
      55.67415454
    ],
    "visueltcenter": [
      12.55710699,
      55.67309834
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-12-13T23:24:26.040Z",
    "geo_version": 2,
    "dagi_id": "191616"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1617",
    "nr": "1617",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55397849,
      55.67252681,
      12.55513347,
      55.67341485
    ],
    "visueltcenter": [
      12.55457903,
      55.67294594
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191617"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1618",
    "nr": "1618",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.5501002,
      55.67322919,
      12.55202883,
      55.67416456
    ],
    "visueltcenter": [
      12.55126144,
      55.67382242
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191618"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1619",
    "nr": "1619",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54969226,
      55.67276284,
      12.55058116,
      55.67436203
    ],
    "visueltcenter": [
      12.55017806,
      55.67376086
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191619"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1620",
    "nr": "1620",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.53750627,
      55.66814199,
      12.56824155,
      55.67678093
    ],
    "visueltcenter": [
      12.55913327,
      55.67264298
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2020-03-02T22:15:14.069Z",
    "geo_ændret": "2020-03-02T22:15:14.069Z",
    "geo_version": 5,
    "dagi_id": "191620"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1621",
    "nr": "1621",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54694335,
      55.67245633,
      12.54949806,
      55.67326453
    ],
    "visueltcenter": [
      12.54769433,
      55.67293333
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191625"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1622",
    "nr": "1622",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54506115,
      55.67209092,
      12.54707502,
      55.6727931
    ],
    "visueltcenter": [
      12.54658832,
      55.67245693
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "375292"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1623",
    "nr": "1623",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54460521,
      55.67160424,
      12.54584259,
      55.67224916
    ],
    "visueltcenter": [
      12.54526736,
      55.67193979
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191623"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1624",
    "nr": "1624",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54553142,
      55.67180427,
      12.546499,
      55.67243397
    ],
    "visueltcenter": [
      12.54598034,
      55.67212888
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "290944"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1631",
    "nr": "1631",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55453031,
      55.68194576,
      12.55628081,
      55.68323586
    ],
    "visueltcenter": [
      12.55548923,
      55.6825235
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-12-13T23:24:26.040Z",
    "geo_version": 2,
    "dagi_id": "375293"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1632",
    "nr": "1632",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55409206,
      55.68186865,
      12.55590853,
      55.68371781
    ],
    "visueltcenter": [
      12.55545314,
      55.68343153
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-12-13T23:24:26.040Z",
    "geo_version": 2,
    "dagi_id": "191643"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1633",
    "nr": "1633",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55567955,
      55.68190212,
      12.55703789,
      55.68276664
    ],
    "visueltcenter": [
      12.5564173,
      55.68231545
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191645"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1634",
    "nr": "1634",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55394691,
      55.68151509,
      12.55810649,
      55.68220664
    ],
    "visueltcenter": [
      12.55549264,
      55.68187637
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191648"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1635",
    "nr": "1635",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55529144,
      55.68202825,
      12.55804174,
      55.68339674
    ],
    "visueltcenter": [
      12.55627711,
      55.68284156
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191635"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1650",
    "nr": "1650",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54756424,
      55.6667999,
      12.56358927,
      55.67301058
    ],
    "visueltcenter": [
      12.55197362,
      55.66872787
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191650"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1651",
    "nr": "1651",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.56187014,
      55.67050918,
      12.56681878,
      55.67393005
    ],
    "visueltcenter": [
      12.56525239,
      55.67129215
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191651"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1652",
    "nr": "1652",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.56096987,
      55.6702786,
      12.56452782,
      55.6733482
    ],
    "visueltcenter": [
      12.56197922,
      55.67242389
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191652"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1653",
    "nr": "1653",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.56002902,
      55.67079472,
      12.56229057,
      55.67311162
    ],
    "visueltcenter": [
      12.5609699,
      55.67234568
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-06-07T21:08:14.307Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191653"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1654",
    "nr": "1654",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55752662,
      55.67065827,
      12.56174531,
      55.67222957
    ],
    "visueltcenter": [
      12.55933466,
      55.67158989
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191654"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1655",
    "nr": "1655",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55643038,
      55.66988499,
      12.56022318,
      55.67257875
    ],
    "visueltcenter": [
      12.55816022,
      55.67121581
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191655"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1656",
    "nr": "1656",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55494859,
      55.66941199,
      12.5590683,
      55.67224401
    ],
    "visueltcenter": [
      12.55631521,
      55.67137912
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191656"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1657",
    "nr": "1657",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55406949,
      55.66881765,
      12.55786426,
      55.67172743
    ],
    "visueltcenter": [
      12.55581577,
      55.67050611
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191657"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1658",
    "nr": "1658",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.5526064,
      55.66811931,
      12.55706993,
      55.67252153
    ],
    "visueltcenter": [
      12.55413977,
      55.67049852
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191658"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1659",
    "nr": "1659",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55411484,
      55.67152558,
      12.55506033,
      55.67201534
    ],
    "visueltcenter": [
      12.55451216,
      55.67182348
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191659"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1660",
    "nr": "1660",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55161354,
      55.66725623,
      12.55430054,
      55.67260817
    ],
    "visueltcenter": [
      12.55264927,
      55.67089792
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191660"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1661",
    "nr": "1661",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55117232,
      55.66998099,
      12.5526202,
      55.67258476
    ],
    "visueltcenter": [
      12.55188166,
      55.67164674
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191661"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1662",
    "nr": "1662",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54955492,
      55.66640199,
      12.55271509,
      55.67267979
    ],
    "visueltcenter": [
      12.55084057,
      55.67152277
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2018-01-16T22:10:42.686Z",
    "geo_version": 3,
    "dagi_id": "191662"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1663",
    "nr": "1663",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54867992,
      55.66552366,
      12.55220292,
      55.67245535
    ],
    "visueltcenter": [
      12.54995271,
      55.67061559
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191663"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1664",
    "nr": "1664",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54877709,
      55.67163791,
      12.54975801,
      55.67228858
    ],
    "visueltcenter": [
      12.54919832,
      55.67191845
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191664"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1665",
    "nr": "1665",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54696249,
      55.665516,
      12.55030432,
      55.67218268
    ],
    "visueltcenter": [
      12.54858405,
      55.67082135
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191665"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1666",
    "nr": "1666",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54541825,
      55.66893592,
      12.55290116,
      55.67140265
    ],
    "visueltcenter": [
      12.54734503,
      55.66979331
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2018-01-16T22:10:42.686Z",
    "geo_version": 2,
    "dagi_id": "191666"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1667",
    "nr": "1667",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54538612,
      55.66873952,
      12.54887914,
      55.66936331
    ],
    "visueltcenter": [
      12.54621289,
      55.66906572
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2018-01-16T22:10:42.686Z",
    "geo_version": 2,
    "dagi_id": "191667"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1668",
    "nr": "1668",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54735967,
      55.66769665,
      12.54861292,
      55.66918661
    ],
    "visueltcenter": [
      12.54794234,
      55.66882907
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191668"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1669",
    "nr": "1669",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54619823,
      55.66513266,
      12.54850635,
      55.66913538
    ],
    "visueltcenter": [
      12.54769264,
      55.66573477
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191669"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1670",
    "nr": "1670",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54360179,
      55.66586685,
      12.54809836,
      55.66811913
    ],
    "visueltcenter": [
      12.54504302,
      55.66709135
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191670"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1671",
    "nr": "1671",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54429765,
      55.66472504,
      12.54739067,
      55.6690857
    ],
    "visueltcenter": [
      12.54530588,
      55.66836707
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2017-11-01T22:10:42.685Z",
    "geo_version": 3,
    "dagi_id": "191671"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1672",
    "nr": "1672",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54574968,
      55.66824243,
      12.54706408,
      55.6688564
    ],
    "visueltcenter": [
      12.54639085,
      55.6685544
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191672"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1673",
    "nr": "1673",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54580003,
      55.66771023,
      12.54719714,
      55.66832892
    ],
    "visueltcenter": [
      12.5465375,
      55.66799575
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191673"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1674",
    "nr": "1674",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54087358,
      55.66286091,
      12.54659482,
      55.67162321
    ],
    "visueltcenter": [
      12.54309456,
      55.66560066
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2019-11-19T22:14:33.184Z",
    "geo_ændret": "2019-11-19T22:14:33.184Z",
    "geo_version": 2,
    "dagi_id": "191674"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1675",
    "nr": "1675",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54636904,
      55.66621087,
      12.5473361,
      55.66673841
    ],
    "visueltcenter": [
      12.54685256,
      55.66647464
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191675"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1676",
    "nr": "1676",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54778981,
      55.66532214,
      12.54910961,
      55.66702163
    ],
    "visueltcenter": [
      12.54842401,
      55.66619152
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191676"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1677",
    "nr": "1677",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.5464361,
      55.66572304,
      12.54747973,
      55.66628536
    ],
    "visueltcenter": [
      12.54700046,
      55.66602386
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191677"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1699",
    "nr": "1699",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55883606,
      55.66550942,
      12.56475127,
      55.66918442
    ],
    "visueltcenter": [
      12.56294693,
      55.66810542
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2019-03-26T22:05:46.768Z",
    "geo_ændret": "2019-03-26T22:05:46.768Z",
    "geo_version": 4,
    "dagi_id": "191776"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1700",
    "nr": "1700",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55579055,
      55.66743617,
      12.56368212,
      55.67158832
    ],
    "visueltcenter": [
      12.56228976,
      55.66964694
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2019-03-26T22:05:46.768Z",
    "geo_ændret": "2019-03-26T22:05:46.768Z",
    "geo_version": 4,
    "dagi_id": "191700"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1701",
    "nr": "1701",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.56275923,
      55.67148978,
      12.56381947,
      55.67207594
    ],
    "visueltcenter": [
      12.56331688,
      55.67179515
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191701"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1702",
    "nr": "1702",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.56301834,
      55.67079098,
      12.56447874,
      55.67171364
    ],
    "visueltcenter": [
      12.5637674,
      55.67134085
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191702"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1703",
    "nr": "1703",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.56224378,
      55.67023062,
      12.56370195,
      55.67108568
    ],
    "visueltcenter": [
      12.56292145,
      55.67056976
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191703"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1704",
    "nr": "1704",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.5627126,
      55.66819655,
      12.57287899,
      55.67402018
    ],
    "visueltcenter": [
      12.5699623,
      55.67305739
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-11-05T22:14:33.117Z",
    "geo_ændret": "2018-11-05T22:14:33.117Z",
    "geo_version": 3,
    "dagi_id": "191704"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1705",
    "nr": "1705",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54159001,
      55.66211232,
      12.56496401,
      55.66977566
    ],
    "visueltcenter": [
      12.56022595,
      55.66511088
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-11-05T22:14:33.117Z",
    "geo_ændret": "2018-11-05T22:14:33.117Z",
    "geo_version": 7,
    "dagi_id": "191705"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1706",
    "nr": "1706",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55939076,
      55.67046,
      12.56094029,
      55.67116983
    ],
    "visueltcenter": [
      12.55994889,
      55.67074811
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191706"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1707",
    "nr": "1707",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.56034742,
      55.6715879,
      12.56093686,
      55.67205134
    ],
    "visueltcenter": [
      12.56062207,
      55.6718825
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191707"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1708",
    "nr": "1708",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55683845,
      55.66932579,
      12.55816585,
      55.6699711
    ],
    "visueltcenter": [
      12.55745219,
      55.6696213
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191708"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1709",
    "nr": "1709",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55336113,
      55.66761555,
      12.55525359,
      55.6694468
    ],
    "visueltcenter": [
      12.55436089,
      55.66829956
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191709"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1710",
    "nr": "1710",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.56155952,
      55.66980786,
      12.56350343,
      55.67085332
    ],
    "visueltcenter": [
      12.56287023,
      55.67015852
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191710"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1711",
    "nr": "1711",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55737659,
      55.66641965,
      12.56182317,
      55.67047928
    ],
    "visueltcenter": [
      12.5594416,
      55.66770326
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-05-18T21:15:28.822Z",
    "geo_ændret": "2018-05-18T21:15:28.822Z",
    "geo_version": 3,
    "dagi_id": "191711"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1712",
    "nr": "1712",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55685626,
      55.66771592,
      12.55858425,
      55.66882034
    ],
    "visueltcenter": [
      12.55783183,
      55.66837077
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191712"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1714",
    "nr": "1714",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.5576128,
      55.66555675,
      12.55974309,
      55.66695667
    ],
    "visueltcenter": [
      12.5584376,
      55.66603571
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-05-18T21:15:28.822Z",
    "geo_ændret": "2018-05-18T21:15:28.822Z",
    "geo_version": 3,
    "dagi_id": "191714"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1715",
    "nr": "1715",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55934166,
      55.66736756,
      12.56241147,
      55.66962663
    ],
    "visueltcenter": [
      12.56063662,
      55.66870393
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-05-18T21:15:28.822Z",
    "geo_ændret": "2018-05-18T21:15:28.822Z",
    "geo_version": 3,
    "dagi_id": "191715"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1716",
    "nr": "1716",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55770995,
      55.66836953,
      12.55978485,
      55.66940219
    ],
    "visueltcenter": [
      12.55886941,
      55.66884414
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191716"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1717",
    "nr": "1717",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55550321,
      55.66553318,
      12.55889194,
      55.6679915
    ],
    "visueltcenter": [
      12.55731893,
      55.66707261
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-05-18T21:15:28.822Z",
    "geo_ændret": "2018-05-18T21:15:28.822Z",
    "geo_version": 2,
    "dagi_id": "191717"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1718",
    "nr": "1718",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55463789,
      55.66555522,
      12.5575119,
      55.66768392
    ],
    "visueltcenter": [
      12.55560361,
      55.66711053
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191718"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1719",
    "nr": "1719",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55396872,
      55.66501027,
      12.55700685,
      55.66736286
    ],
    "visueltcenter": [
      12.55476615,
      55.66698025
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191719"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1720",
    "nr": "1720",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.53733857,
      55.66270469,
      12.55628306,
      55.66893945
    ],
    "visueltcenter": [
      12.54064325,
      55.66359129
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-12-14T23:21:45.039Z",
    "geo_version": 3,
    "dagi_id": "191720"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1721",
    "nr": "1721",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54762621,
      55.66523171,
      12.55799055,
      55.66732661
    ],
    "visueltcenter": [
      12.55326244,
      55.66624074
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-12-14T23:21:45.039Z",
    "geo_version": 3,
    "dagi_id": "191721"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1722",
    "nr": "1722",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55324072,
      55.66500321,
      12.55610116,
      55.66695403
    ],
    "visueltcenter": [
      12.55418453,
      55.66657955
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191722"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1723",
    "nr": "1723",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.5516869,
      55.66701261,
      12.55328591,
      55.66767924
    ],
    "visueltcenter": [
      12.5522664,
      55.66726948
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191723"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1724",
    "nr": "1724",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55124572,
      55.66765547,
      12.55299159,
      55.66838119
    ],
    "visueltcenter": [
      12.55200311,
      55.66809061
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191724"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1725",
    "nr": "1725",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55299215,
      55.66480529,
      12.55515436,
      55.6661754
    ],
    "visueltcenter": [
      12.55379172,
      55.66565101
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191725"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1726",
    "nr": "1726",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55182215,
      55.66470209,
      12.55399158,
      55.66618706
    ],
    "visueltcenter": [
      12.55256569,
      55.66564743
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191726"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1727",
    "nr": "1727",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.55070743,
      55.66451803,
      12.55271202,
      55.66588032
    ],
    "visueltcenter": [
      12.55157104,
      55.66529918
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191727"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1728",
    "nr": "1728",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.5495978,
      55.66427799,
      12.55142674,
      55.66550816
    ],
    "visueltcenter": [
      12.55064723,
      55.66481971
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191728"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1729",
    "nr": "1729",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54852916,
      55.66413959,
      12.54997486,
      55.66515936
    ],
    "visueltcenter": [
      12.54915072,
      55.66458741
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191729"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1730",
    "nr": "1730",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54752891,
      55.66393095,
      12.5488956,
      55.66485272
    ],
    "visueltcenter": [
      12.54822289,
      55.66439812
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191730"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1731",
    "nr": "1731",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54626122,
      55.66376682,
      12.54743372,
      55.66437929
    ],
    "visueltcenter": [
      12.54688486,
      55.66411741
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191731"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1732",
    "nr": "1732",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54526936,
      55.6635789,
      12.54637501,
      55.66434113
    ],
    "visueltcenter": [
      12.54573859,
      55.66393593
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191732"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1733",
    "nr": "1733",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54374535,
      55.66346648,
      12.54478193,
      55.66396596
    ],
    "visueltcenter": [
      12.54431734,
      55.66368886
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191733"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1734",
    "nr": "1734",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54515029,
      55.66497853,
      12.54618253,
      55.66552735
    ],
    "visueltcenter": [
      12.54568262,
      55.66523209
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-12-14T23:21:45.039Z",
    "geo_version": 2,
    "dagi_id": "191734"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1735",
    "nr": "1735",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54451429,
      55.6645331,
      12.54603835,
      55.66557318
    ],
    "visueltcenter": [
      12.54537668,
      55.66479718
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2019-01-10T22:10:34.887Z",
    "geo_ændret": "2019-01-10T22:10:34.887Z",
    "geo_version": 3,
    "dagi_id": "191735"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1736",
    "nr": "1736",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54313492,
      55.66499667,
      12.54500765,
      55.66570342
    ],
    "visueltcenter": [
      12.54364271,
      55.66524968
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2019-01-10T22:10:34.887Z",
    "geo_ændret": "2019-01-10T22:10:34.887Z",
    "geo_version": 3,
    "dagi_id": "191736"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1737",
    "nr": "1737",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54378453,
      55.66437744,
      12.54517872,
      55.66532215
    ],
    "visueltcenter": [
      12.54447071,
      55.66484287
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191737"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1738",
    "nr": "1738",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54273411,
      55.66419689,
      12.54433588,
      55.6651467
    ],
    "visueltcenter": [
      12.54375074,
      55.66449069
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191738"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1739",
    "nr": "1739",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54249235,
      55.66412079,
      12.54341528,
      55.66466716
    ],
    "visueltcenter": [
      12.54299209,
      55.664438
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191739"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1749",
    "nr": "1749",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.53573939,
      55.66902225,
      12.53780922,
      55.67013666
    ],
    "visueltcenter": [
      12.53704743,
      55.66952603
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191298"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1750",
    "nr": "1750",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.53733211,
      55.66378776,
      12.54084972,
      55.67027873
    ],
    "visueltcenter": [
      12.5398063,
      55.66769158
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2020-03-02T22:15:14.069Z",
    "geo_ændret": "2020-03-02T22:15:14.069Z",
    "geo_version": 3,
    "dagi_id": "191750"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1751",
    "nr": "1751",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54337332,
      55.66939187,
      12.54564973,
      55.67110942
    ],
    "visueltcenter": [
      12.54477144,
      55.67008603
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191751"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1752",
    "nr": "1752",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54151709,
      55.66947091,
      12.54451737,
      55.67065863
    ],
    "visueltcenter": [
      12.54294527,
      55.67003659
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191752"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1753",
    "nr": "1753",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54284083,
      55.66929624,
      12.54382365,
      55.66985408
    ],
    "visueltcenter": [
      12.54335814,
      55.66961867
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191753"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1754",
    "nr": "1754",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54303078,
      55.66837689,
      12.54495338,
      55.67012061
    ],
    "visueltcenter": [
      12.54411696,
      55.66925047
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191754"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1755",
    "nr": "1755",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54235329,
      55.66926116,
      12.54313258,
      55.66991
    ],
    "visueltcenter": [
      12.54272296,
      55.66967755
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191755"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1756",
    "nr": "1756",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54045304,
      55.6682865,
      12.54334555,
      55.67036252
    ],
    "visueltcenter": [
      12.54203009,
      55.66900959
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191756"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1757",
    "nr": "1757",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.53995059,
      55.66864038,
      12.54144398,
      55.66956475
    ],
    "visueltcenter": [
      12.54082339,
      55.66915035
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191757"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1758",
    "nr": "1758",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54011151,
      55.6659992,
      12.54414924,
      55.6687756
    ],
    "visueltcenter": [
      12.54297343,
      55.66758517
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2019-11-19T22:14:33.184Z",
    "geo_ændret": "2019-11-19T22:14:33.184Z",
    "geo_version": 2,
    "dagi_id": "191758"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1759",
    "nr": "1759",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54244298,
      55.66794374,
      12.54462085,
      55.66948499
    ],
    "visueltcenter": [
      12.5432819,
      55.66851692
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191759"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1760",
    "nr": "1760",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.53916551,
      55.66499691,
      12.54806709,
      55.66669306
    ],
    "visueltcenter": [
      12.54546668,
      55.66571623
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2019-01-10T22:10:34.887Z",
    "geo_ændret": "2019-01-10T22:10:34.887Z",
    "geo_version": 4,
    "dagi_id": "191760"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1761",
    "nr": "1761",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.53916381,
      55.66507141,
      12.54231442,
      55.66833693
    ],
    "visueltcenter": [
      12.54124611,
      55.66772397
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-05-18T21:15:28.822Z",
    "geo_ændret": "2018-05-18T21:15:28.822Z",
    "geo_version": 2,
    "dagi_id": "191761"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1762",
    "nr": "1762",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.53950988,
      55.66379944,
      12.54167079,
      55.66618591
    ],
    "visueltcenter": [
      12.54086863,
      55.66555484
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-05-18T21:15:28.822Z",
    "geo_ændret": "2018-05-18T21:15:28.822Z",
    "geo_version": 2,
    "dagi_id": "191762"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1763",
    "nr": "1763",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54073073,
      55.66399549,
      12.54271366,
      55.66618397
    ],
    "visueltcenter": [
      12.54207733,
      55.6656842
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191763"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1764",
    "nr": "1764",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.53885635,
      55.66456319,
      12.54258635,
      55.66555655
    ],
    "visueltcenter": [
      12.53940436,
      55.66497095
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191764"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1765",
    "nr": "1765",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.53792445,
      55.66397081,
      12.54019122,
      55.66483646
    ],
    "visueltcenter": [
      12.53905783,
      55.66440364
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191765"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1766",
    "nr": "1766",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.54010244,
      55.66394587,
      12.54122623,
      55.664332
    ],
    "visueltcenter": [
      12.54067769,
      55.66414411
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191766"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1770",
    "nr": "1770",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.53611707,
      55.66791763,
      12.53990567,
      55.66931985
    ],
    "visueltcenter": [
      12.5365578,
      55.66822916
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2020-03-02T22:15:14.069Z",
    "geo_ændret": "2020-03-02T22:15:14.069Z",
    "geo_version": 2,
    "dagi_id": "191770"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1771",
    "nr": "1771",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.53867614,
      55.66819065,
      12.5396968,
      55.66880207
    ],
    "visueltcenter": [
      12.53917356,
      55.66852133
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191771"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1772",
    "nr": "1772",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.53810159,
      55.66803697,
      12.53910847,
      55.66864843
    ],
    "visueltcenter": [
      12.53864545,
      55.6682938
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191772"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1773",
    "nr": "1773",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.53750955,
      55.66788102,
      12.53852408,
      55.66849494
    ],
    "visueltcenter": [
      12.53801681,
      55.66818798
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191773"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1774",
    "nr": "1774",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.53668885,
      55.66719348,
      12.5399279,
      55.66849765
    ],
    "visueltcenter": [
      12.53904722,
      55.66797162
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191774"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1775",
    "nr": "1775",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.53633215,
      55.66757304,
      12.53734387,
      55.66818758
    ],
    "visueltcenter": [
      12.53683801,
      55.66788031
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191775"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1777",
    "nr": "1777",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.5368321,
      55.66670836,
      12.53820304,
      55.66834065
    ],
    "visueltcenter": [
      12.53764622,
      55.66702322
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191777"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1799",
    "nr": "1799",
    "navn": "København V",
    "stormodtageradresser": null,
    "bbox": [
      12.52685318,
      55.66287991,
      12.53974527,
      55.66980744
    ],
    "visueltcenter": [
      12.53344813,
      55.66585361
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2021-01-01T22:14:53.992Z",
    "geo_ændret": "2021-01-01T22:14:53.992Z",
    "geo_version": 6,
    "dagi_id": "191799"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1800",
    "nr": "1800",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53112703,
      55.6699467,
      12.53951205,
      55.67167835
    ],
    "visueltcenter": [
      12.53600765,
      55.67047098
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-05-18T21:15:28.822Z",
    "geo_ændret": "2018-05-18T21:15:28.822Z",
    "geo_version": 2,
    "dagi_id": "191800"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1801",
    "nr": "1801",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53123601,
      55.66785616,
      12.53760489,
      55.67036062
    ],
    "visueltcenter": [
      12.53414281,
      55.6690663
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2021-01-01T22:14:53.992Z",
    "geo_ændret": "2021-01-01T22:14:53.992Z",
    "geo_version": 4,
    "dagi_id": "191801"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1802",
    "nr": "1802",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53250544,
      55.66910087,
      12.53527932,
      55.67050244
    ],
    "visueltcenter": [
      12.53457921,
      55.67007767
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191802"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1803",
    "nr": "1803",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53279869,
      55.66960266,
      12.53416858,
      55.67053288
    ],
    "visueltcenter": [
      12.53340416,
      55.66997053
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191803"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1804",
    "nr": "1804",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53114539,
      55.66878765,
      12.53302828,
      55.67061203
    ],
    "visueltcenter": [
      12.53225891,
      55.67003692
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191804"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1805",
    "nr": "1805",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53048803,
      55.6678647,
      12.53171617,
      55.66853356
    ],
    "visueltcenter": [
      12.5311529,
      55.66804842
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2018-01-17T22:14:03.693Z",
    "geo_version": 2,
    "dagi_id": "191805"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1806",
    "nr": "1806",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53158833,
      55.67178218,
      12.53520785,
      55.67269377
    ],
    "visueltcenter": [
      12.53424914,
      55.6721564
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191806"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1807",
    "nr": "1807",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53271199,
      55.67077608,
      12.53415782,
      55.67204214
    ],
    "visueltcenter": [
      12.53331808,
      55.67115759
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191807"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1808",
    "nr": "1808",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53337504,
      55.67104072,
      12.53481102,
      55.67191328
    ],
    "visueltcenter": [
      12.53426314,
      55.67159046
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191808"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1809",
    "nr": "1809",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.5345564,
      55.67070064,
      12.53692522,
      55.67441679
    ],
    "visueltcenter": [
      12.53554461,
      55.6716243
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191809"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1810",
    "nr": "1810",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53822347,
      55.67015158,
      12.54128759,
      55.67381504
    ],
    "visueltcenter": [
      12.53960125,
      55.67146453
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191810"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1811",
    "nr": "1811",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53629081,
      55.67223338,
      12.53759688,
      55.67401639
    ],
    "visueltcenter": [
      12.53677431,
      55.6725422
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191811"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1812",
    "nr": "1812",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.5360374,
      55.67051134,
      12.53875108,
      55.67398038
    ],
    "visueltcenter": [
      12.53698945,
      55.67120846
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191812"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1813",
    "nr": "1813",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53756893,
      55.67028262,
      12.53963071,
      55.67389477
    ],
    "visueltcenter": [
      12.53865345,
      55.67238106
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191813"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1814",
    "nr": "1814",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54049374,
      55.67104351,
      12.54253688,
      55.6736416
    ],
    "visueltcenter": [
      12.54130724,
      55.67153382
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191814"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1815",
    "nr": "1815",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54170515,
      55.67212226,
      12.54308363,
      55.67356427
    ],
    "visueltcenter": [
      12.54237549,
      55.6727428
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191815"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1816",
    "nr": "1816",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54292343,
      55.67234493,
      12.54424978,
      55.67362131
    ],
    "visueltcenter": [
      12.54338951,
      55.67280184
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191816"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1817",
    "nr": "1817",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54121665,
      55.67139256,
      12.54457858,
      55.67293519
    ],
    "visueltcenter": [
      12.54298543,
      55.6720535
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191817"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1818",
    "nr": "1818",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54370382,
      55.67201469,
      12.54544416,
      55.67341423
    ],
    "visueltcenter": [
      12.54488147,
      55.67247144
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191818"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1819",
    "nr": "1819",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54841623,
      55.67267434,
      12.55021682,
      55.67458632
    ],
    "visueltcenter": [
      12.54936765,
      55.67398318
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191819"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1820",
    "nr": "1820",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53196642,
      55.67198705,
      12.5500766,
      55.67540515
    ],
    "visueltcenter": [
      12.53462279,
      55.67407429
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191820"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1822",
    "nr": "1822",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.5452084,
      55.67229053,
      12.54660214,
      55.67289829
    ],
    "visueltcenter": [
      12.54608419,
      55.67268282
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191822"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1823",
    "nr": "1823",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54453537,
      55.67256319,
      12.54616596,
      55.67321527
    ],
    "visueltcenter": [
      12.54538991,
      55.67285485
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191823"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1824",
    "nr": "1824",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54589833,
      55.67340303,
      12.54812443,
      55.67510493
    ],
    "visueltcenter": [
      12.54712394,
      55.67414951
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191824"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1825",
    "nr": "1825",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.5446783,
      55.67382293,
      12.54665304,
      55.67519312
    ],
    "visueltcenter": [
      12.5454441,
      55.67422815
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191825"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1826",
    "nr": "1826",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54344935,
      55.67383451,
      12.54557435,
      55.67571592
    ],
    "visueltcenter": [
      12.54450577,
      55.67479317
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191826"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1827",
    "nr": "1827",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54214795,
      55.67366838,
      12.54427477,
      55.67594832
    ],
    "visueltcenter": [
      12.54312856,
      55.67472699
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191827"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1828",
    "nr": "1828",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54106652,
      55.67405152,
      12.54302304,
      55.67607858
    ],
    "visueltcenter": [
      12.5419349,
      55.67482887
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191828"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1829",
    "nr": "1829",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54001809,
      55.67414745,
      12.54213449,
      55.67636401
    ],
    "visueltcenter": [
      12.54143187,
      55.67594343
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191829"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1850",
    "nr": "1850",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53323743,
      55.67354776,
      12.55564825,
      55.6787422
    ],
    "visueltcenter": [
      12.5362512,
      55.67774692
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191850"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1851",
    "nr": "1851",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53815258,
      55.67425819,
      12.54100905,
      55.6767054
    ],
    "visueltcenter": [
      12.53965438,
      55.67572373
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191851"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1852",
    "nr": "1852",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53723584,
      55.67438844,
      12.53941201,
      55.67698211
    ],
    "visueltcenter": [
      12.53849568,
      55.67648934
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191852"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1853",
    "nr": "1853",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53461963,
      55.67473862,
      12.53788566,
      55.67580406
    ],
    "visueltcenter": [
      12.53701022,
      55.67529526
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191853"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1854",
    "nr": "1854",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53392991,
      55.67474827,
      12.53570562,
      55.67632082
    ],
    "visueltcenter": [
      12.53452691,
      55.67515529
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191854"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1855",
    "nr": "1855",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53325061,
      55.67553081,
      12.53828249,
      55.67666647
    ],
    "visueltcenter": [
      12.53573489,
      55.67620071
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191855"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1856",
    "nr": "1856",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53598333,
      55.6760465,
      12.53806884,
      55.67746333
    ],
    "visueltcenter": [
      12.53738999,
      55.67661415
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191856"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1857",
    "nr": "1857",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53420494,
      55.67644603,
      12.53711393,
      55.67747074
    ],
    "visueltcenter": [
      12.53561489,
      55.67693751
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191857"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1860",
    "nr": "1860",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53410455,
      55.67988945,
      12.53871654,
      55.68168371
    ],
    "visueltcenter": [
      12.53719762,
      55.68109331
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191860"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1861",
    "nr": "1861",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53430532,
      55.6783989,
      12.53557681,
      55.68019548
    ],
    "visueltcenter": [
      12.53508153,
      55.67979174
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191861"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1862",
    "nr": "1862",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.5347221,
      55.67794226,
      12.53714713,
      55.68091026
    ],
    "visueltcenter": [
      12.53618519,
      55.68004417
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2019-07-03T21:05:34.440Z",
    "geo_ændret": "2019-07-03T21:05:34.440Z",
    "geo_version": 3,
    "dagi_id": "191862"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1863",
    "nr": "1863",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53635242,
      55.67863917,
      12.5387045,
      55.68106454
    ],
    "visueltcenter": [
      12.53725843,
      55.67918107
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191863"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1864",
    "nr": "1864",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53358423,
      55.67729045,
      12.54209041,
      55.67935982
    ],
    "visueltcenter": [
      12.53709225,
      55.6783961
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2019-07-03T21:05:34.440Z",
    "geo_ændret": "2019-07-03T21:05:34.440Z",
    "geo_version": 4,
    "dagi_id": "191864"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1865",
    "nr": "1865",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.5378799,
      55.67863863,
      12.53904739,
      55.67938111
    ],
    "visueltcenter": [
      12.53840772,
      55.67906052
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-05-18T21:15:28.822Z",
    "geo_ændret": "2018-05-18T21:15:28.822Z",
    "geo_version": 2,
    "dagi_id": "191865"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1866",
    "nr": "1866",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53739141,
      55.67722833,
      12.53916783,
      55.6785783
    ],
    "visueltcenter": [
      12.53802154,
      55.67777926
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191866"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1867",
    "nr": "1867",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.5394151,
      55.67829106,
      12.54042823,
      55.67885298
    ],
    "visueltcenter": [
      12.53980769,
      55.67856694
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191867"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1868",
    "nr": "1868",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53897571,
      55.67685946,
      12.54121322,
      55.67813161
    ],
    "visueltcenter": [
      12.54048726,
      55.67769198
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191868"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1870",
    "nr": "1870",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53765887,
      55.67637149,
      12.54789461,
      55.68632256
    ],
    "visueltcenter": [
      12.54099176,
      55.68052551
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-05-18T21:15:28.822Z",
    "geo_ændret": "2018-05-18T21:15:28.822Z",
    "geo_version": 2,
    "dagi_id": "191870"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1871",
    "nr": "1871",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53898214,
      55.67994324,
      12.54876944,
      55.68366203
    ],
    "visueltcenter": [
      12.54543129,
      55.68087799
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-11-23T22:09:27.297Z",
    "geo_ændret": "2018-11-23T22:09:27.297Z",
    "geo_version": 6,
    "dagi_id": "191871"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1872",
    "nr": "1872",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54434469,
      55.67987673,
      12.54651547,
      55.68050838
    ],
    "visueltcenter": [
      12.54486513,
      55.68027144
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191872"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1873",
    "nr": "1873",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.5434827,
      55.67955436,
      12.5457123,
      55.68034289
    ],
    "visueltcenter": [
      12.54531212,
      55.67981424
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191873"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1874",
    "nr": "1874",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54522968,
      55.678779,
      12.54754239,
      55.68047793
    ],
    "visueltcenter": [
      12.546312,
      55.6794665
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2021-02-15T22:16:11.159Z",
    "geo_ændret": "2021-02-15T22:16:11.159Z",
    "geo_version": 3,
    "dagi_id": "191874"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1875",
    "nr": "1875",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54259004,
      55.678007,
      12.54750806,
      55.67999815
    ],
    "visueltcenter": [
      12.54439633,
      55.67914852
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2021-02-15T22:16:11.159Z",
    "geo_ændret": "2021-02-15T22:16:11.159Z",
    "geo_version": 3,
    "dagi_id": "191875"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1876",
    "nr": "1876",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54217774,
      55.67732622,
      12.54669803,
      55.67886873
    ],
    "visueltcenter": [
      12.54513765,
      55.67796084
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191876"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1877",
    "nr": "1877",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54226412,
      55.67621172,
      12.54446389,
      55.6779179
    ],
    "visueltcenter": [
      12.54314122,
      55.676822
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191877"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1878",
    "nr": "1878",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54351101,
      55.67579488,
      12.54567206,
      55.677692
    ],
    "visueltcenter": [
      12.54447671,
      55.67659707
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191878"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1879",
    "nr": "1879",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54493535,
      55.67552919,
      12.55224213,
      55.68514764
    ],
    "visueltcenter": [
      12.54791386,
      55.67944885
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-12-14T23:21:45.039Z",
    "geo_version": 2,
    "dagi_id": "191879"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1900",
    "nr": "1900",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.55194775,
      55.67363469,
      12.55889385,
      55.68170341
    ],
    "visueltcenter": [
      12.55571599,
      55.67568449
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2020-08-14T21:12:37.212Z",
    "geo_ændret": "2020-08-14T21:12:37.212Z",
    "geo_version": 5,
    "dagi_id": "191900"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1901",
    "nr": "1901",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54579067,
      55.67534759,
      12.54710734,
      55.67660948
    ],
    "visueltcenter": [
      12.54638197,
      55.67596832
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191901"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1902",
    "nr": "1902",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54655217,
      55.67519446,
      12.54996645,
      55.67963438
    ],
    "visueltcenter": [
      12.54764912,
      55.67688481
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-12-14T23:21:45.039Z",
    "geo_version": 2,
    "dagi_id": "191902"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1903",
    "nr": "1903",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54760687,
      55.67499307,
      12.55095247,
      55.67935024
    ],
    "visueltcenter": [
      12.54962625,
      55.67760453
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191903"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1904",
    "nr": "1904",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54875616,
      55.67462838,
      12.5525937,
      55.67803846
    ],
    "visueltcenter": [
      12.5503761,
      55.67662945
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191904"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1905",
    "nr": "1905",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.5495036,
      55.67457564,
      12.55202226,
      55.67713938
    ],
    "visueltcenter": [
      12.55032219,
      55.67498553
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191905"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1906",
    "nr": "1906",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.55065236,
      55.67420798,
      12.55216648,
      55.67625095
    ],
    "visueltcenter": [
      12.55138892,
      55.67534886
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191906"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1908",
    "nr": "1908",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.55260448,
      55.67383841,
      12.55459267,
      55.67561553
    ],
    "visueltcenter": [
      12.55353922,
      55.67446845
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191908"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1909",
    "nr": "1909",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.55088468,
      55.6745147,
      12.55447628,
      55.6773185
    ],
    "visueltcenter": [
      12.55272852,
      55.67558015
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191909"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1910",
    "nr": "1910",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54858988,
      55.67778189,
      12.55830433,
      55.68018746
    ],
    "visueltcenter": [
      12.55012682,
      55.67957423
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2020-05-19T21:17:26.374Z",
    "geo_ændret": "2020-05-19T21:17:26.374Z",
    "geo_version": 6,
    "dagi_id": "191910"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1911",
    "nr": "1911",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54752994,
      55.67696873,
      12.55432881,
      55.67899864
    ],
    "visueltcenter": [
      12.55287463,
      55.67759267
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-05-18T21:15:28.822Z",
    "geo_ændret": "2018-05-18T21:15:28.822Z",
    "geo_version": 3,
    "dagi_id": "191911"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1912",
    "nr": "1912",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.55048503,
      55.67814801,
      12.55233081,
      55.67987545
    ],
    "visueltcenter": [
      12.55170183,
      55.67947352
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-05-18T21:15:28.822Z",
    "geo_ændret": "2018-05-18T21:15:28.822Z",
    "geo_version": 3,
    "dagi_id": "191912"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1913",
    "nr": "1913",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.55143624,
      55.67776877,
      12.55345178,
      55.67958746
    ],
    "visueltcenter": [
      12.55215583,
      55.67825536
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-05-18T21:15:28.822Z",
    "geo_ændret": "2018-05-18T21:15:28.822Z",
    "geo_version": 2,
    "dagi_id": "191913"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1914",
    "nr": "1914",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.55272409,
      55.67754399,
      12.55415279,
      55.67869926
    ],
    "visueltcenter": [
      12.55360844,
      55.67839105
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191914"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1915",
    "nr": "1915",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.55118085,
      55.67812177,
      12.55465448,
      55.67945337
    ],
    "visueltcenter": [
      12.55191481,
      55.67886014
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191915"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1916",
    "nr": "1916",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.55431325,
      55.67698427,
      12.55809124,
      55.67821986
    ],
    "visueltcenter": [
      12.55696888,
      55.67755331
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2020-05-19T21:17:26.374Z",
    "geo_ændret": "2020-05-19T21:17:26.374Z",
    "geo_version": 4,
    "dagi_id": "191916"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1917",
    "nr": "1917",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.55426756,
      55.67635148,
      12.55783684,
      55.67736045
    ],
    "visueltcenter": [
      12.55519671,
      55.67684523
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191917"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1920",
    "nr": "1920",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54853005,
      55.67898019,
      12.55496656,
      55.6808525
    ],
    "visueltcenter": [
      12.54975215,
      55.68036477
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-08-27T21:07:14.553Z",
    "geo_ændret": "2018-08-27T21:07:14.553Z",
    "geo_version": 5,
    "dagi_id": "191920"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1921",
    "nr": "1921",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.55175641,
      55.6795836,
      12.5538096,
      55.68023236
    ],
    "visueltcenter": [
      12.55335595,
      55.67989699
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191921"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1922",
    "nr": "1922",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.55104259,
      55.67946621,
      12.55488781,
      55.68101841
    ],
    "visueltcenter": [
      12.55207833,
      55.68060854
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-05-18T21:15:28.822Z",
    "geo_ændret": "2018-05-18T21:15:28.822Z",
    "geo_version": 2,
    "dagi_id": "191922"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1923",
    "nr": "1923",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54936582,
      55.68067356,
      12.55131691,
      55.68161521
    ],
    "visueltcenter": [
      12.55021079,
      55.6811586
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191923"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1924",
    "nr": "1924",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54984711,
      55.68031135,
      12.55293268,
      55.68201047
    ],
    "visueltcenter": [
      12.55169309,
      55.68134585
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191924"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1925",
    "nr": "1925",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.55220288,
      55.6800959,
      12.55462149,
      55.68181527
    ],
    "visueltcenter": [
      12.55346186,
      55.68101368
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191925"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1926",
    "nr": "1926",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.5553425,
      55.67950438,
      12.55866279,
      55.68040221
    ],
    "visueltcenter": [
      12.5563497,
      55.68001631
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2020-08-14T21:12:37.212Z",
    "geo_ændret": "2020-08-14T21:12:37.212Z",
    "geo_version": 3,
    "dagi_id": "191926"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1927",
    "nr": "1927",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.55478385,
      55.67888043,
      12.55850185,
      55.67978468
    ],
    "visueltcenter": [
      12.55644595,
      55.67936955
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191927"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1928",
    "nr": "1928",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.55501865,
      55.6782778,
      12.55833596,
      55.67918752
    ],
    "visueltcenter": [
      12.55637458,
      55.67873506
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191928"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1950",
    "nr": "1950",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53485035,
      55.68091934,
      12.54067514,
      55.6825972
    ],
    "visueltcenter": [
      12.53569241,
      55.68169351
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-11-20T22:13:37.996Z",
    "geo_ændret": "2018-11-20T22:13:37.996Z",
    "geo_version": 2,
    "dagi_id": "191950"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1951",
    "nr": "1951",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.5353871,
      55.68165983,
      12.53738991,
      55.68246673
    ],
    "visueltcenter": [
      12.53659621,
      55.68207494
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191951"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1952",
    "nr": "1952",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53527326,
      55.68182486,
      12.53863055,
      55.68275303
    ],
    "visueltcenter": [
      12.53583526,
      55.68244847
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191952"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1953",
    "nr": "1953",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53613153,
      55.68217279,
      12.53931413,
      55.68354105
    ],
    "visueltcenter": [
      12.53719507,
      55.68293731
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191953"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1954",
    "nr": "1954",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53750205,
      55.68252311,
      12.54101818,
      55.68510625
    ],
    "visueltcenter": [
      12.5391162,
      55.68414812
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191954"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1955",
    "nr": "1955",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53877541,
      55.68249249,
      12.54148763,
      55.68461379
    ],
    "visueltcenter": [
      12.54040474,
      55.6836199
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191955"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1956",
    "nr": "1956",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53981626,
      55.68231394,
      12.54268111,
      55.68442446
    ],
    "visueltcenter": [
      12.54129064,
      55.6833691
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191956"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1957",
    "nr": "1957",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54023337,
      55.68199075,
      12.5436302,
      55.68424304
    ],
    "visueltcenter": [
      12.54256238,
      55.68329671
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-11-20T22:13:37.996Z",
    "geo_ændret": "2018-11-20T22:13:37.996Z",
    "geo_version": 2,
    "dagi_id": "191957"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1958",
    "nr": "1958",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.53845461,
      55.68326847,
      12.54658373,
      55.68718175
    ],
    "visueltcenter": [
      12.54350985,
      55.68504127
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2019-10-24T21:10:42.637Z",
    "geo_ændret": "2019-10-24T21:10:42.637Z",
    "geo_version": 5,
    "dagi_id": "191958"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1959",
    "nr": "1959",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54013601,
      55.68491813,
      12.54275783,
      55.68688129
    ],
    "visueltcenter": [
      12.54131491,
      55.68571394
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2020-09-23T21:11:42.703Z",
    "geo_ændret": "2020-09-23T21:11:42.703Z",
    "geo_version": 4,
    "dagi_id": "191959"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1960",
    "nr": "1960",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54724599,
      55.68366606,
      12.55570427,
      55.6852561
    ],
    "visueltcenter": [
      12.54945121,
      55.68491069
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-05-18T21:15:28.822Z",
    "geo_ændret": "2018-05-18T21:15:28.822Z",
    "geo_version": 2,
    "dagi_id": "191960"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1961",
    "nr": "1961",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54579012,
      55.68063433,
      12.54821834,
      55.68238178
    ],
    "visueltcenter": [
      12.54710267,
      55.6819061
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191961"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1962",
    "nr": "1962",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54573374,
      55.68163568,
      12.54933967,
      55.68312007
    ],
    "visueltcenter": [
      12.54653218,
      55.68262187
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191962"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1963",
    "nr": "1963",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54700637,
      55.68071575,
      12.54903233,
      55.68188825
    ],
    "visueltcenter": [
      12.54809396,
      55.68129366
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191963"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1964",
    "nr": "1964",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.546115,
      55.68326298,
      12.54936044,
      55.68523123
    ],
    "visueltcenter": [
      12.54841915,
      55.68439097
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191964"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1965",
    "nr": "1965",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54773474,
      55.68306331,
      12.54880744,
      55.68375061
    ],
    "visueltcenter": [
      12.54828201,
      55.68343992
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191965"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1966",
    "nr": "1966",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54802724,
      55.68260296,
      12.55219173,
      55.68399491
    ],
    "visueltcenter": [
      12.54945981,
      55.68336984
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191966"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1967",
    "nr": "1967",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54864913,
      55.68352026,
      12.55081628,
      55.68487701
    ],
    "visueltcenter": [
      12.54978855,
      55.68419509
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191967"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1970",
    "nr": "1970",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.54560694,
      55.68104568,
      12.55851383,
      55.68372546
    ],
    "visueltcenter": [
      12.54880394,
      55.68275244
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-04-09T08:23:44.102Z",
    "geo_version": 2,
    "dagi_id": "191970"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1971",
    "nr": "1971",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.55147594,
      55.68360777,
      12.55309642,
      55.68452788
    ],
    "visueltcenter": [
      12.55227545,
      55.68421574
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191971"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1972",
    "nr": "1972",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.5513634,
      55.6823251,
      12.55386435,
      55.68488915
    ],
    "visueltcenter": [
      12.55268155,
      55.68366621
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191972"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1973",
    "nr": "1973",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.55241682,
      55.68236607,
      12.55526966,
      55.6843465
    ],
    "visueltcenter": [
      12.55429159,
      55.68367036
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "191973"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/1974",
    "nr": "1974",
    "navn": "Frederiksberg C",
    "stormodtageradresser": null,
    "bbox": [
      12.55318751,
      55.68171205,
      12.55555052,
      55.68388089
    ],
    "visueltcenter": [
      12.55381232,
      55.6822106
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-05-18T21:15:28.822Z",
    "geo_ændret": "2018-05-18T21:15:28.822Z",
    "geo_version": 3,
    "dagi_id": "191974"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2000",
    "nr": "2000",
    "navn": "Frederiksberg",
    "stormodtageradresser": null,
    "bbox": [
      12.49027635,
      55.66596808,
      12.54387063,
      55.69871449
    ],
    "visueltcenter": [
      12.51562935,
      55.67945641
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2020-09-23T21:11:42.703Z",
    "geo_ændret": "2020-09-23T21:11:42.703Z",
    "geo_version": 8,
    "dagi_id": "192000"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2100",
    "nr": "2100",
    "navn": "København Ø",
    "stormodtageradresser": null,
    "bbox": [
      12.5393404,
      55.68784134,
      12.61013836,
      55.72485838
    ],
    "visueltcenter": [
      12.57364633,
      55.7109795
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2020-11-03T22:16:32.984Z",
    "geo_ændret": "2020-11-03T22:16:32.984Z",
    "geo_version": 5,
    "dagi_id": "192100"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2150",
    "nr": "2150",
    "navn": "Nordhavn",
    "stormodtageradresser": null,
    "bbox": [
      12.58997808,
      55.704108,
      12.64018556,
      55.74496496
    ],
    "visueltcenter": [
      12.61612961,
      55.72293927
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2020-11-03T22:16:32.984Z",
    "geo_ændret": "2020-11-03T22:16:32.984Z",
    "geo_version": 3,
    "dagi_id": "413080"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2200",
    "nr": "2200",
    "navn": "København N",
    "stormodtageradresser": null,
    "bbox": [
      12.5248903,
      55.6816636,
      12.57220713,
      55.71214273
    ],
    "visueltcenter": [
      12.54914572,
      55.6940569
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2019-10-24T21:10:42.637Z",
    "geo_ændret": "2019-10-24T21:10:42.637Z",
    "geo_version": 7,
    "dagi_id": "192200"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2300",
    "nr": "2300",
    "navn": "København S",
    "stormodtageradresser": null,
    "bbox": [
      12.50581966,
      55.60982719,
      12.71611239,
      55.70883554
    ],
    "visueltcenter": [
      12.6554181,
      55.67362511
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0185",
        "kode": "0185",
        "navn": "Tårnby"
      }
    ],
    "ændret": "2019-03-14T22:13:12.119Z",
    "geo_ændret": "2019-03-14T22:13:12.119Z",
    "geo_version": 8,
    "dagi_id": "192300"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2400",
    "nr": "2400",
    "navn": "København NV",
    "stormodtageradresser": null,
    "bbox": [
      12.49710054,
      55.69016217,
      12.54778975,
      55.72845169
    ],
    "visueltcenter": [
      12.52876704,
      55.71256101
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2019-08-09T21:11:20.875Z",
    "geo_ændret": "2019-08-09T21:11:20.875Z",
    "geo_version": 7,
    "dagi_id": "192400"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2450",
    "nr": "2450",
    "navn": "København SV",
    "stormodtageradresser": null,
    "bbox": [
      12.4994164,
      55.62580603,
      12.56551165,
      55.66664379
    ],
    "visueltcenter": [
      12.52293879,
      55.64003572
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2021-02-22T22:10:35.831Z",
    "geo_ændret": "2021-02-22T22:10:35.831Z",
    "geo_version": 10,
    "dagi_id": "192450"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2500",
    "nr": "2500",
    "navn": "Valby",
    "stormodtageradresser": null,
    "bbox": [
      12.47581783,
      55.64089399,
      12.53191572,
      55.67967632
    ],
    "visueltcenter": [
      12.49869399,
      55.65941452
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2019-01-11T22:10:34.106Z",
    "geo_ændret": "2019-01-11T22:10:34.106Z",
    "geo_version": 5,
    "dagi_id": "192500"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2600",
    "nr": "2600",
    "navn": "Glostrup",
    "stormodtageradresser": null,
    "bbox": [
      12.36696804,
      55.65732844,
      12.43412537,
      55.71057327
    ],
    "visueltcenter": [
      12.39697754,
      55.6741838
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0153",
        "kode": "0153",
        "navn": "Brøndby"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0161",
        "kode": "0161",
        "navn": "Glostrup"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0165",
        "kode": "0165",
        "navn": "Albertslund"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-04-09T08:23:44.102Z",
    "geo_version": 2,
    "dagi_id": "192600"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2605",
    "nr": "2605",
    "navn": "Brøndby",
    "stormodtageradresser": null,
    "bbox": [
      12.36186355,
      55.62717495,
      12.46196927,
      55.67366986
    ],
    "visueltcenter": [
      12.40439537,
      55.64503648
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0153",
        "kode": "0153",
        "navn": "Brøndby"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0161",
        "kode": "0161",
        "navn": "Glostrup"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "192605"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2610",
    "nr": "2610",
    "navn": "Rødovre",
    "stormodtageradresser": null,
    "bbox": [
      12.42236245,
      55.66385152,
      12.48270985,
      55.71569607
    ],
    "visueltcenter": [
      12.44863533,
      55.68182922
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0167",
        "kode": "0167",
        "navn": "Hvidovre"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0175",
        "kode": "0175",
        "navn": "Rødovre"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2018-01-15T22:11:36.827Z",
    "geo_version": 2,
    "dagi_id": "192610"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2620",
    "nr": "2620",
    "navn": "Albertslund",
    "stormodtageradresser": null,
    "bbox": [
      12.30677454,
      55.64059508,
      12.39688294,
      55.71059785
    ],
    "visueltcenter": [
      12.34477556,
      55.6869516
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0151",
        "kode": "0151",
        "navn": "Ballerup"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0153",
        "kode": "0153",
        "navn": "Brøndby"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0165",
        "kode": "0165",
        "navn": "Albertslund"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-04-09T08:23:44.102Z",
    "geo_version": 2,
    "dagi_id": "192620"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2625",
    "nr": "2625",
    "navn": "Vallensbæk",
    "stormodtageradresser": null,
    "bbox": [
      12.34005142,
      55.6255043,
      12.39348859,
      55.65682186
    ],
    "visueltcenter": [
      12.36481979,
      55.63922355
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0187",
        "kode": "0187",
        "navn": "Vallensbæk"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "192625"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2630",
    "nr": "2630",
    "navn": "Taastrup",
    "stormodtageradresser": null,
    "bbox": [
      12.22298219,
      55.63069998,
      12.35246466,
      55.70917439
    ],
    "visueltcenter": [
      12.28083382,
      55.6579409
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0165",
        "kode": "0165",
        "navn": "Albertslund"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0169",
        "kode": "0169",
        "navn": "Høje-Taastrup"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0183",
        "kode": "0183",
        "navn": "Ishøj"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "192630"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2635",
    "nr": "2635",
    "navn": "Ishøj",
    "stormodtageradresser": null,
    "bbox": [
      12.21409981,
      55.51700548,
      12.44520046,
      55.6408744
    ],
    "visueltcenter": [
      12.39483896,
      55.58776321
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0183",
        "kode": "0183",
        "navn": "Ishøj"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "192635"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2640",
    "nr": "2640",
    "navn": "Hedehusene",
    "stormodtageradresser": null,
    "bbox": [
      12.14525663,
      55.60379925,
      12.245463,
      55.7062013
    ],
    "visueltcenter": [
      12.19945854,
      55.66357137
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0169",
        "kode": "0169",
        "navn": "Høje-Taastrup"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0183",
        "kode": "0183",
        "navn": "Ishøj"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0253",
        "kode": "0253",
        "navn": "Greve"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0265",
        "kode": "0265",
        "navn": "Roskilde"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "192640"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2650",
    "nr": "2650",
    "navn": "Hvidovre",
    "stormodtageradresser": null,
    "bbox": [
      12.41229061,
      55.51903058,
      12.52432726,
      55.66644458
    ],
    "visueltcenter": [
      12.46097002,
      55.58934868
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0153",
        "kode": "0153",
        "navn": "Brøndby"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0167",
        "kode": "0167",
        "navn": "Hvidovre"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2018-01-15T22:11:36.827Z",
    "geo_version": 2,
    "dagi_id": "192650"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2660",
    "nr": "2660",
    "navn": "Brøndby Strand",
    "stormodtageradresser": null,
    "bbox": [
      12.38937473,
      55.59119526,
      12.45059918,
      55.63410063
    ],
    "visueltcenter": [
      12.42233883,
      55.61998785
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0153",
        "kode": "0153",
        "navn": "Brøndby"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0167",
        "kode": "0167",
        "navn": "Hvidovre"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "192660"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2665",
    "nr": "2665",
    "navn": "Vallensbæk Strand",
    "stormodtageradresser": null,
    "bbox": [
      12.36055835,
      55.6032138,
      12.41217571,
      55.63117919
    ],
    "visueltcenter": [
      12.38841202,
      55.61964832
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0187",
        "kode": "0187",
        "navn": "Vallensbæk"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "192665"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2670",
    "nr": "2670",
    "navn": "Greve",
    "stormodtageradresser": null,
    "bbox": [
      12.20047087,
      55.4869933,
      12.44197192,
      55.62378765
    ],
    "visueltcenter": [
      12.3529229,
      55.54103195
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0253",
        "kode": "0253",
        "navn": "Greve"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "192670"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2680",
    "nr": "2680",
    "navn": "Solrød Strand",
    "stormodtageradresser": null,
    "bbox": [
      12.13646941,
      55.4740699,
      12.37938418,
      55.56038585
    ],
    "visueltcenter": [
      12.26072581,
      55.5150734
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0269",
        "kode": "0269",
        "navn": "Solrød"
      }
    ],
    "ændret": "2020-03-02T22:15:14.069Z",
    "geo_ændret": "2020-03-02T22:15:14.069Z",
    "geo_version": 2,
    "dagi_id": "192680"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2690",
    "nr": "2690",
    "navn": "Karlslunde",
    "stormodtageradresser": null,
    "bbox": [
      12.17632813,
      55.51991509,
      12.32140375,
      55.58879774
    ],
    "visueltcenter": [
      12.21374503,
      55.5692769
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0253",
        "kode": "0253",
        "navn": "Greve"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0269",
        "kode": "0269",
        "navn": "Solrød"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "192690"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2700",
    "nr": "2700",
    "navn": "Brønshøj",
    "stormodtageradresser": null,
    "bbox": [
      12.45157144,
      55.6936924,
      12.51853518,
      55.72561554
    ],
    "visueltcenter": [
      12.48463422,
      55.70964347
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-09-01T21:42:42.766Z",
    "geo_version": 2,
    "dagi_id": "192700"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2720",
    "nr": "2720",
    "navn": "Vanløse",
    "stormodtageradresser": null,
    "bbox": [
      12.46136466,
      55.67163122,
      12.52163525,
      55.70133045
    ],
    "visueltcenter": [
      12.48194421,
      55.6883311
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0147",
        "kode": "0147",
        "navn": "Frederiksberg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-09-01T21:42:42.766Z",
    "geo_version": 3,
    "dagi_id": "192720"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2730",
    "nr": "2730",
    "navn": "Herlev",
    "stormodtageradresser": null,
    "bbox": [
      12.38770445,
      55.7068072,
      12.46287363,
      55.759942
    ],
    "visueltcenter": [
      12.42581802,
      55.74057031
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0151",
        "kode": "0151",
        "navn": "Ballerup"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0163",
        "kode": "0163",
        "navn": "Herlev"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "192730"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2740",
    "nr": "2740",
    "navn": "Skovlunde",
    "stormodtageradresser": null,
    "bbox": [
      12.36866168,
      55.69628333,
      12.42750127,
      55.73100744
    ],
    "visueltcenter": [
      12.39724634,
      55.71528471
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0151",
        "kode": "0151",
        "navn": "Ballerup"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0163",
        "kode": "0163",
        "navn": "Herlev"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-09-01T21:42:42.766Z",
    "geo_version": 2,
    "dagi_id": "192740"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2750",
    "nr": "2750",
    "navn": "Ballerup",
    "stormodtageradresser": null,
    "bbox": [
      12.31850847,
      55.70170697,
      12.41173792,
      55.76271478
    ],
    "visueltcenter": [
      12.3638449,
      55.73668449
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0151",
        "kode": "0151",
        "navn": "Ballerup"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0240",
        "kode": "0240",
        "navn": "Egedal"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-09-01T21:42:42.766Z",
    "geo_version": 2,
    "dagi_id": "192750"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2760",
    "nr": "2760",
    "navn": "Måløv",
    "stormodtageradresser": null,
    "bbox": [
      12.26308588,
      55.74002576,
      12.33864442,
      55.7643737
    ],
    "visueltcenter": [
      12.32084626,
      55.75323652
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0151",
        "kode": "0151",
        "navn": "Ballerup"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-09-01T21:42:42.766Z",
    "geo_version": 2,
    "dagi_id": "192760"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2765",
    "nr": "2765",
    "navn": "Smørum",
    "stormodtageradresser": null,
    "bbox": [
      12.20560353,
      55.69291304,
      12.34656161,
      55.75363863
    ],
    "visueltcenter": [
      12.28121197,
      55.72556424
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0240",
        "kode": "0240",
        "navn": "Egedal"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "192765"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2770",
    "nr": "2770",
    "navn": "Kastrup",
    "stormodtageradresser": null,
    "bbox": [
      12.497591,
      55.54861792,
      12.89935784,
      55.70859573
    ],
    "visueltcenter": [
      12.78698239,
      55.63352245
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0185",
        "kode": "0185",
        "navn": "Tårnby"
      }
    ],
    "ændret": "2019-03-14T22:13:12.119Z",
    "geo_ændret": "2019-03-14T22:13:12.119Z",
    "geo_version": 5,
    "dagi_id": "192770"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2791",
    "nr": "2791",
    "navn": "Dragør",
    "stormodtageradresser": null,
    "bbox": [
      12.418205,
      55.42586159,
      12.79881301,
      55.62447352
    ],
    "visueltcenter": [
      12.59121712,
      55.5081121
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0155",
        "kode": "0155",
        "navn": "Dragør"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0185",
        "kode": "0185",
        "navn": "Tårnby"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "192791"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2800",
    "nr": "2800",
    "navn": "Kongens Lyngby",
    "stormodtageradresser": null,
    "bbox": [
      12.41145743,
      55.74830002,
      12.58343013,
      55.81431067
    ],
    "visueltcenter": [
      12.52083673,
      55.78572204
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0157",
        "kode": "0157",
        "navn": "Gentofte"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0159",
        "kode": "0159",
        "navn": "Gladsaxe"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0173",
        "kode": "0173",
        "navn": "Lyngby-Taarbæk"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0230",
        "kode": "0230",
        "navn": "Rudersdal"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-08-25T20:22:34.901Z",
    "geo_version": 3,
    "dagi_id": "192800"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2820",
    "nr": "2820",
    "navn": "Gentofte",
    "stormodtageradresser": null,
    "bbox": [
      12.49741422,
      55.73613701,
      12.559439,
      55.77489768
    ],
    "visueltcenter": [
      12.53343057,
      55.75145806
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0157",
        "kode": "0157",
        "navn": "Gentofte"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0159",
        "kode": "0159",
        "navn": "Gladsaxe"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-08-25T20:22:34.901Z",
    "geo_version": 2,
    "dagi_id": "192820"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2830",
    "nr": "2830",
    "navn": "Virum",
    "stormodtageradresser": null,
    "bbox": [
      12.41449399,
      55.77709934,
      12.50540496,
      55.80630786
    ],
    "visueltcenter": [
      12.46836985,
      55.78962271
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0173",
        "kode": "0173",
        "navn": "Lyngby-Taarbæk"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0230",
        "kode": "0230",
        "navn": "Rudersdal"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "192830"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2840",
    "nr": "2840",
    "navn": "Holte",
    "stormodtageradresser": null,
    "bbox": [
      12.41158852,
      55.79209206,
      12.52942107,
      55.85211196
    ],
    "visueltcenter": [
      12.48640452,
      55.81684549
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0173",
        "kode": "0173",
        "navn": "Lyngby-Taarbæk"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0230",
        "kode": "0230",
        "navn": "Rudersdal"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "192840"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2850",
    "nr": "2850",
    "navn": "Nærum",
    "stormodtageradresser": null,
    "bbox": [
      12.50967874,
      55.80135061,
      12.57204656,
      55.83527153
    ],
    "visueltcenter": [
      12.54388723,
      55.81751707
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0230",
        "kode": "0230",
        "navn": "Rudersdal"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "192850"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2860",
    "nr": "2860",
    "navn": "Søborg",
    "stormodtageradresser": null,
    "bbox": [
      12.44299453,
      55.71481093,
      12.52977715,
      55.75793061
    ],
    "visueltcenter": [
      12.48425242,
      55.73800813
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0159",
        "kode": "0159",
        "navn": "Gladsaxe"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-09-01T21:42:42.766Z",
    "geo_version": 2,
    "dagi_id": "192860"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2870",
    "nr": "2870",
    "navn": "Dyssegård",
    "stormodtageradresser": null,
    "bbox": [
      12.51640506,
      55.72628763,
      12.54027449,
      55.74150786
    ],
    "visueltcenter": [
      12.52794393,
      55.73206862
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0157",
        "kode": "0157",
        "navn": "Gentofte"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0159",
        "kode": "0159",
        "navn": "Gladsaxe"
      }
    ],
    "ændret": "2019-03-14T22:13:12.119Z",
    "geo_ændret": "2019-03-14T22:13:12.119Z",
    "geo_version": 3,
    "dagi_id": "401217"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2880",
    "nr": "2880",
    "navn": "Bagsværd",
    "stormodtageradresser": null,
    "bbox": [
      12.41447348,
      55.73991836,
      12.4819921,
      55.77742634
    ],
    "visueltcenter": [
      12.44587672,
      55.76191781
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0159",
        "kode": "0159",
        "navn": "Gladsaxe"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0163",
        "kode": "0163",
        "navn": "Herlev"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0190",
        "kode": "0190",
        "navn": "Furesø"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "192880"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2900",
    "nr": "2900",
    "navn": "Hellerup",
    "stormodtageradresser": null,
    "bbox": [
      12.52959474,
      55.71943028,
      12.60998894,
      55.74796667
    ],
    "visueltcenter": [
      12.56946744,
      55.73442051
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0101",
        "kode": "0101",
        "navn": "København"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0157",
        "kode": "0157",
        "navn": "Gentofte"
      }
    ],
    "ændret": "2019-03-13T22:10:56.201Z",
    "geo_ændret": "2019-03-13T22:10:56.201Z",
    "geo_version": 3,
    "dagi_id": "192900"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2920",
    "nr": "2920",
    "navn": "Charlottenlund",
    "stormodtageradresser": null,
    "bbox": [
      12.5429827,
      55.73325617,
      12.74509983,
      55.7976614
    ],
    "visueltcenter": [
      12.6282041,
      55.75754187
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0157",
        "kode": "0157",
        "navn": "Gentofte"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-08-25T20:22:34.901Z",
    "geo_version": 3,
    "dagi_id": "192920"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2930",
    "nr": "2930",
    "navn": "Klampenborg",
    "stormodtageradresser": null,
    "bbox": [
      12.54626282,
      55.75780293,
      12.74832925,
      55.83121294
    ],
    "visueltcenter": [
      12.62132919,
      55.79609961
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0157",
        "kode": "0157",
        "navn": "Gentofte"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0173",
        "kode": "0173",
        "navn": "Lyngby-Taarbæk"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-04-13T20:29:46.699Z",
    "geo_version": 3,
    "dagi_id": "192930"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2942",
    "nr": "2942",
    "navn": "Skodsborg",
    "stormodtageradresser": null,
    "bbox": [
      12.55358686,
      55.80368053,
      12.70857931,
      55.84343755
    ],
    "visueltcenter": [
      12.59260158,
      55.82842884
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0230",
        "kode": "0230",
        "navn": "Rudersdal"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "192942"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2950",
    "nr": "2950",
    "navn": "Vedbæk",
    "stormodtageradresser": null,
    "bbox": [
      12.50306131,
      55.8232852,
      12.7030598,
      55.90219363
    ],
    "visueltcenter": [
      12.64375317,
      55.86532817
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0223",
        "kode": "0223",
        "navn": "Hørsholm"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0230",
        "kode": "0230",
        "navn": "Rudersdal"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-09-01T21:42:42.766Z",
    "geo_version": 2,
    "dagi_id": "192950"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2960",
    "nr": "2960",
    "navn": "Rungsted Kyst",
    "stormodtageradresser": null,
    "bbox": [
      12.50660013,
      55.85803985,
      12.6773178,
      55.92930885
    ],
    "visueltcenter": [
      12.54954886,
      55.88693173
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0223",
        "kode": "0223",
        "navn": "Hørsholm"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-09-01T21:42:42.766Z",
    "geo_version": 2,
    "dagi_id": "192960"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2970",
    "nr": "2970",
    "navn": "Hørsholm",
    "stormodtageradresser": null,
    "bbox": [
      12.38298528,
      55.83839351,
      12.64726171,
      55.93273166
    ],
    "visueltcenter": [
      12.43890506,
      55.88126308
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0201",
        "kode": "0201",
        "navn": "Allerød"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0210",
        "kode": "0210",
        "navn": "Fredensborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0223",
        "kode": "0223",
        "navn": "Hørsholm"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0230",
        "kode": "0230",
        "navn": "Rudersdal"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "192970"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2980",
    "nr": "2980",
    "navn": "Kokkedal",
    "stormodtageradresser": null,
    "bbox": [
      12.36327404,
      55.88958938,
      12.64829635,
      55.94439745
    ],
    "visueltcenter": [
      12.45605071,
      55.91992432
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0210",
        "kode": "0210",
        "navn": "Fredensborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0223",
        "kode": "0223",
        "navn": "Hørsholm"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "192980"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/2990",
    "nr": "2990",
    "navn": "Nivå",
    "stormodtageradresser": null,
    "bbox": [
      12.47527203,
      55.90923647,
      12.64612626,
      55.94771062
    ],
    "visueltcenter": [
      12.51321363,
      55.92959715
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0210",
        "kode": "0210",
        "navn": "Fredensborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "192990"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3000",
    "nr": "3000",
    "navn": "Helsingør",
    "stormodtageradresser": null,
    "bbox": [
      12.47476231,
      56.00835031,
      12.6613549,
      56.08110327
    ],
    "visueltcenter": [
      12.61245096,
      56.03943161
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0217",
        "kode": "0217",
        "navn": "Helsingør"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2018-01-17T22:14:03.693Z",
    "geo_version": 2,
    "dagi_id": "193000"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3050",
    "nr": "3050",
    "navn": "Humlebæk",
    "stormodtageradresser": null,
    "bbox": [
      12.47603137,
      55.9346529,
      12.65289411,
      55.98796273
    ],
    "visueltcenter": [
      12.53687938,
      55.9604599
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0210",
        "kode": "0210",
        "navn": "Fredensborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0217",
        "kode": "0217",
        "navn": "Helsingør"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "193050"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3060",
    "nr": "3060",
    "navn": "Espergærde",
    "stormodtageradresser": null,
    "bbox": [
      12.51246201,
      55.96318117,
      12.65641144,
      56.02422592
    ],
    "visueltcenter": [
      12.54283631,
      55.99425687
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0210",
        "kode": "0210",
        "navn": "Fredensborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0217",
        "kode": "0217",
        "navn": "Helsingør"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "193060"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3070",
    "nr": "3070",
    "navn": "Snekkersten",
    "stormodtageradresser": null,
    "bbox": [
      12.53843766,
      55.98707817,
      12.65614485,
      56.02394415
    ],
    "visueltcenter": [
      12.59499744,
      56.00467892
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0217",
        "kode": "0217",
        "navn": "Helsingør"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "193070"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3080",
    "nr": "3080",
    "navn": "Tikøb",
    "stormodtageradresser": null,
    "bbox": [
      12.38742425,
      56.00260564,
      12.49259504,
      56.0477676
    ],
    "visueltcenter": [
      12.45560033,
      56.02560338
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0217",
        "kode": "0217",
        "navn": "Helsingør"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "193080"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3100",
    "nr": "3100",
    "navn": "Hornbæk",
    "stormodtageradresser": null,
    "bbox": [
      12.39633682,
      56.03849296,
      12.52235716,
      56.16071804
    ],
    "visueltcenter": [
      12.45420474,
      56.10118532
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0217",
        "kode": "0217",
        "navn": "Helsingør"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0270",
        "kode": "0270",
        "navn": "Gribskov"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "193100"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3120",
    "nr": "3120",
    "navn": "Dronningmølle",
    "stormodtageradresser": null,
    "bbox": [
      12.35100039,
      56.0612942,
      12.42107758,
      56.14451942
    ],
    "visueltcenter": [
      12.38687452,
      56.10714196
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0270",
        "kode": "0270",
        "navn": "Gribskov"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "193120"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3140",
    "nr": "3140",
    "navn": "Ålsgårde",
    "stormodtageradresser": null,
    "bbox": [
      12.49218611,
      56.05466682,
      12.58281066,
      56.13110854
    ],
    "visueltcenter": [
      12.5430865,
      56.09086037
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0217",
        "kode": "0217",
        "navn": "Helsingør"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2018-01-17T22:14:03.693Z",
    "geo_version": 2,
    "dagi_id": "193140"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3150",
    "nr": "3150",
    "navn": "Hellebæk",
    "stormodtageradresser": null,
    "bbox": [
      12.52272244,
      56.0400907,
      12.60984434,
      56.09601533
    ],
    "visueltcenter": [
      12.5478008,
      56.06020462
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0217",
        "kode": "0217",
        "navn": "Helsingør"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "193150"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3200",
    "nr": "3200",
    "navn": "Helsinge",
    "stormodtageradresser": null,
    "bbox": [
      12.07606524,
      55.96480636,
      12.32011258,
      56.08429043
    ],
    "visueltcenter": [
      12.1715219,
      56.01099572
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0219",
        "kode": "0219",
        "navn": "Hillerød"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0270",
        "kode": "0270",
        "navn": "Gribskov"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "193200"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3210",
    "nr": "3210",
    "navn": "Vejby",
    "stormodtageradresser": null,
    "bbox": [
      11.94572308,
      56.03381475,
      12.20165298,
      56.22585257
    ],
    "visueltcenter": [
      12.13066388,
      56.09457775
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0270",
        "kode": "0270",
        "navn": "Gribskov"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "193210"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3220",
    "nr": "3220",
    "navn": "Tisvildeleje",
    "stormodtageradresser": null,
    "bbox": [
      11.87807319,
      56.0186119,
      12.13209531,
      56.18598666
    ],
    "visueltcenter": [
      11.95917304,
      56.12775807
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0270",
        "kode": "0270",
        "navn": "Gribskov"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "193220"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3230",
    "nr": "3230",
    "navn": "Græsted",
    "stormodtageradresser": null,
    "bbox": [
      11.97277434,
      55.99894058,
      12.44519285,
      56.30373082
    ],
    "visueltcenter": [
      12.06980339,
      56.22745639
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0217",
        "kode": "0217",
        "navn": "Helsingør"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0219",
        "kode": "0219",
        "navn": "Hillerød"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0270",
        "kode": "0270",
        "navn": "Gribskov"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "193230"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3250",
    "nr": "3250",
    "navn": "Gilleleje",
    "stormodtageradresser": null,
    "bbox": [
      12.06677394,
      56.06324397,
      12.45739001,
      56.30153087
    ],
    "visueltcenter": [
      12.29353244,
      56.16514414
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0270",
        "kode": "0270",
        "navn": "Gribskov"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "193250"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3300",
    "nr": "3300",
    "navn": "Frederiksværk",
    "stormodtageradresser": null,
    "bbox": [
      11.89051199,
      55.92227578,
      12.13761685,
      56.11218381
    ],
    "visueltcenter": [
      12.05634805,
      55.97774198
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0260",
        "kode": "0260",
        "navn": "Halsnæs"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0270",
        "kode": "0270",
        "navn": "Gribskov"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "193300"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3310",
    "nr": "3310",
    "navn": "Ølsted",
    "stormodtageradresser": null,
    "bbox": [
      12.01138866,
      55.87784242,
      12.12016039,
      55.95082022
    ],
    "visueltcenter": [
      12.06925064,
      55.91156813
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0219",
        "kode": "0219",
        "navn": "Hillerød"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0260",
        "kode": "0260",
        "navn": "Halsnæs"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-04-06T20:28:54.793Z",
    "geo_version": 2,
    "dagi_id": "193310"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3320",
    "nr": "3320",
    "navn": "Skævinge",
    "stormodtageradresser": null,
    "bbox": [
      12.08007846,
      55.87722109,
      12.21415343,
      55.97351267
    ],
    "visueltcenter": [
      12.15008815,
      55.93559156
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0219",
        "kode": "0219",
        "navn": "Hillerød"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0260",
        "kode": "0260",
        "navn": "Halsnæs"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-04-06T20:28:54.793Z",
    "geo_version": 3,
    "dagi_id": "193320"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3330",
    "nr": "3330",
    "navn": "Gørløse",
    "stormodtageradresser": null,
    "bbox": [
      12.16946213,
      55.86479963,
      12.24966144,
      55.91081626
    ],
    "visueltcenter": [
      12.2099944,
      55.8837888
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0219",
        "kode": "0219",
        "navn": "Hillerød"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "193330"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3360",
    "nr": "3360",
    "navn": "Liseleje",
    "stormodtageradresser": null,
    "bbox": [
      11.81173519,
      55.99777577,
      12.03339448,
      56.1270799
    ],
    "visueltcenter": [
      11.87609952,
      56.09213437
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0260",
        "kode": "0260",
        "navn": "Halsnæs"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "193360"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3370",
    "nr": "3370",
    "navn": "Melby",
    "stormodtageradresser": null,
    "bbox": [
      11.92790941,
      55.9483716,
      12.01066608,
      56.0088664
    ],
    "visueltcenter": [
      11.97025413,
      55.99124483
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0260",
        "kode": "0260",
        "navn": "Halsnæs"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "193370"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3390",
    "nr": "3390",
    "navn": "Hundested",
    "stormodtageradresser": null,
    "bbox": [
      11.27344353,
      55.91112655,
      12.14511579,
      56.52496644
    ],
    "visueltcenter": [
      11.6705916,
      56.26445585
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0260",
        "kode": "0260",
        "navn": "Halsnæs"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "193390"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3400",
    "nr": "3400",
    "navn": "Hillerød",
    "stormodtageradresser": null,
    "bbox": [
      12.16293165,
      55.87200389,
      12.40750648,
      55.99925729
    ],
    "visueltcenter": [
      12.28696917,
      55.92123871
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0201",
        "kode": "0201",
        "navn": "Allerød"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0210",
        "kode": "0210",
        "navn": "Fredensborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0219",
        "kode": "0219",
        "navn": "Hillerød"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "193400"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3450",
    "nr": "3450",
    "navn": "Allerød",
    "stormodtageradresser": null,
    "bbox": [
      12.27134568,
      55.83751768,
      12.40943755,
      55.91439593
    ],
    "visueltcenter": [
      12.33661891,
      55.86605512
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0201",
        "kode": "0201",
        "navn": "Allerød"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0210",
        "kode": "0210",
        "navn": "Fredensborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0219",
        "kode": "0219",
        "navn": "Hillerød"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "193450"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3460",
    "nr": "3460",
    "navn": "Birkerød",
    "stormodtageradresser": null,
    "bbox": [
      12.35316744,
      55.80847001,
      12.48881329,
      55.87853836
    ],
    "visueltcenter": [
      12.41757019,
      55.84270608
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0190",
        "kode": "0190",
        "navn": "Furesø"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0201",
        "kode": "0201",
        "navn": "Allerød"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0230",
        "kode": "0230",
        "navn": "Rudersdal"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-04-06T20:28:54.793Z",
    "geo_version": 2,
    "dagi_id": "193460"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3480",
    "nr": "3480",
    "navn": "Fredensborg",
    "stormodtageradresser": null,
    "bbox": [
      12.2823419,
      55.92988779,
      12.49794228,
      56.01969385
    ],
    "visueltcenter": [
      12.40200836,
      55.97086354
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0210",
        "kode": "0210",
        "navn": "Fredensborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0217",
        "kode": "0217",
        "navn": "Helsingør"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0219",
        "kode": "0219",
        "navn": "Hillerød"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "193480"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3490",
    "nr": "3490",
    "navn": "Kvistgård",
    "stormodtageradresser": null,
    "bbox": [
      12.43292969,
      55.96636792,
      12.5304187,
      56.03257939
    ],
    "visueltcenter": [
      12.49542003,
      55.99928886
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0210",
        "kode": "0210",
        "navn": "Fredensborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0217",
        "kode": "0217",
        "navn": "Helsingør"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "193490"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3500",
    "nr": "3500",
    "navn": "Værløse",
    "stormodtageradresser": null,
    "bbox": [
      12.29194174,
      55.75196492,
      12.43017233,
      55.80776933
    ],
    "visueltcenter": [
      12.35591788,
      55.77912974
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0151",
        "kode": "0151",
        "navn": "Ballerup"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0159",
        "kode": "0159",
        "navn": "Gladsaxe"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0173",
        "kode": "0173",
        "navn": "Lyngby-Taarbæk"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0190",
        "kode": "0190",
        "navn": "Furesø"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0240",
        "kode": "0240",
        "navn": "Egedal"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "193500"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3520",
    "nr": "3520",
    "navn": "Farum",
    "stormodtageradresser": null,
    "bbox": [
      12.30725242,
      55.79734077,
      12.42418534,
      55.84425255
    ],
    "visueltcenter": [
      12.35067108,
      55.81981968
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0190",
        "kode": "0190",
        "navn": "Furesø"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0201",
        "kode": "0201",
        "navn": "Allerød"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0240",
        "kode": "0240",
        "navn": "Egedal"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-04-06T20:28:54.793Z",
    "geo_version": 2,
    "dagi_id": "193520"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3540",
    "nr": "3540",
    "navn": "Lynge",
    "stormodtageradresser": null,
    "bbox": [
      12.20620811,
      55.81444702,
      12.33596603,
      55.88424261
    ],
    "visueltcenter": [
      12.28059858,
      55.8397364
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0190",
        "kode": "0190",
        "navn": "Furesø"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0201",
        "kode": "0201",
        "navn": "Allerød"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0219",
        "kode": "0219",
        "navn": "Hillerød"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0240",
        "kode": "0240",
        "navn": "Egedal"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "193540"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3550",
    "nr": "3550",
    "navn": "Slangerup",
    "stormodtageradresser": null,
    "bbox": [
      12.11731105,
      55.80426058,
      12.27412542,
      55.88651134
    ],
    "visueltcenter": [
      12.17447416,
      55.85027208
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0201",
        "kode": "0201",
        "navn": "Allerød"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0219",
        "kode": "0219",
        "navn": "Hillerød"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0240",
        "kode": "0240",
        "navn": "Egedal"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0250",
        "kode": "0250",
        "navn": "Frederikssund"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-03-10T21:33:45.448Z",
    "geo_version": 2,
    "dagi_id": "193550"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3600",
    "nr": "3600",
    "navn": "Frederikssund",
    "stormodtageradresser": null,
    "bbox": [
      12.02650865,
      55.77375995,
      12.15235871,
      55.89618284
    ],
    "visueltcenter": [
      12.07923445,
      55.85479562
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0219",
        "kode": "0219",
        "navn": "Hillerød"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0250",
        "kode": "0250",
        "navn": "Frederikssund"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0260",
        "kode": "0260",
        "navn": "Halsnæs"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-10-28T21:38:11.296Z",
    "geo_version": 2,
    "dagi_id": "193600"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3630",
    "nr": "3630",
    "navn": "Jægerspris",
    "stormodtageradresser": null,
    "bbox": [
      11.78826282,
      55.77579315,
      12.07864833,
      55.95321859
    ],
    "visueltcenter": [
      11.91680034,
      55.86932225
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0250",
        "kode": "0250",
        "navn": "Frederikssund"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "193630"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3650",
    "nr": "3650",
    "navn": "Ølstykke",
    "stormodtageradresser": null,
    "bbox": [
      12.10723215,
      55.75521582,
      12.2021247,
      55.82902311
    ],
    "visueltcenter": [
      12.1609202,
      55.78991195
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0240",
        "kode": "0240",
        "navn": "Egedal"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-10-28T21:38:11.296Z",
    "geo_version": 2,
    "dagi_id": "193650"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3660",
    "nr": "3660",
    "navn": "Stenløse",
    "stormodtageradresser": null,
    "bbox": [
      12.14729127,
      55.74268062,
      12.33510691,
      55.8247117
    ],
    "visueltcenter": [
      12.27836793,
      55.80077298
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0240",
        "kode": "0240",
        "navn": "Egedal"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-04-05T20:38:54.234Z",
    "geo_version": 4,
    "dagi_id": "193660"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3670",
    "nr": "3670",
    "navn": "Veksø Sjælland",
    "stormodtageradresser": null,
    "bbox": [
      12.16620018,
      55.71743258,
      12.27860512,
      55.7986249
    ],
    "visueltcenter": [
      12.23729507,
      55.75497247
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0240",
        "kode": "0240",
        "navn": "Egedal"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0265",
        "kode": "0265",
        "navn": "Roskilde"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-04-05T20:38:54.234Z",
    "geo_version": 2,
    "dagi_id": "193670"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3700",
    "nr": "3700",
    "navn": "Rønne",
    "stormodtageradresser": null,
    "bbox": [
      13.94963367,
      54.58065045,
      14.91352725,
      55.21910589
    ],
    "visueltcenter": [
      14.40163467,
      54.98495945
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0400",
        "kode": "0400",
        "navn": "Bornholm"
      }
    ],
    "ændret": "2020-05-26T21:14:32.122Z",
    "geo_ændret": "2020-05-26T21:14:32.122Z",
    "geo_version": 4,
    "dagi_id": "193700"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3720",
    "nr": "3720",
    "navn": "Aakirkeby",
    "stormodtageradresser": null,
    "bbox": [
      14.52093745,
      54.52054994,
      15.04025414,
      55.15499378
    ],
    "visueltcenter": [
      14.7409871,
      54.6920138
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0400",
        "kode": "0400",
        "navn": "Bornholm"
      }
    ],
    "ændret": "2020-05-26T21:14:32.122Z",
    "geo_ændret": "2020-05-26T21:14:32.122Z",
    "geo_version": 4,
    "dagi_id": "193720"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3730",
    "nr": "3730",
    "navn": "Nexø",
    "stormodtageradresser": null,
    "bbox": [
      14.86701893,
      54.51935454,
      15.99662167,
      55.14296521
    ],
    "visueltcenter": [
      15.39301087,
      54.87126103
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0400",
        "kode": "0400",
        "navn": "Bornholm"
      }
    ],
    "ændret": "2020-05-26T21:14:32.122Z",
    "geo_ændret": "2020-05-26T21:14:32.122Z",
    "geo_version": 2,
    "dagi_id": "193730"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3740",
    "nr": "3740",
    "navn": "Svaneke",
    "stormodtageradresser": null,
    "bbox": [
      15.05554034,
      55.0091866,
      16.54105056,
      55.5918292
    ],
    "visueltcenter": [
      15.67679007,
      55.30340399
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0400",
        "kode": "0400",
        "navn": "Bornholm"
      }
    ],
    "ændret": "2020-05-26T21:14:32.122Z",
    "geo_ændret": "2020-05-26T21:14:32.122Z",
    "geo_version": 2,
    "dagi_id": "193740"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3751",
    "nr": "3751",
    "navn": "Østermarie",
    "stormodtageradresser": null,
    "bbox": [
      14.94468707,
      55.07877065,
      15.24212038,
      55.32885451
    ],
    "visueltcenter": [
      15.02005943,
      55.1396331
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0400",
        "kode": "0400",
        "navn": "Bornholm"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-10-28T21:38:11.296Z",
    "geo_version": 2,
    "dagi_id": "193751"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3760",
    "nr": "3760",
    "navn": "Gudhjem",
    "stormodtageradresser": null,
    "bbox": [
      14.82597658,
      55.10385041,
      15.59285012,
      55.69740823
    ],
    "visueltcenter": [
      15.21574747,
      55.51191781
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0400",
        "kode": "0400",
        "navn": "Bornholm"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0411",
        "kode": "0411",
        "navn": "Christiansø"
      }
    ],
    "ændret": "2020-05-26T21:14:32.122Z",
    "geo_ændret": "2020-05-26T21:14:32.122Z",
    "geo_version": 3,
    "dagi_id": "193760"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3770",
    "nr": "3770",
    "navn": "Allinge",
    "stormodtageradresser": null,
    "bbox": [
      14.46291472,
      55.20266244,
      15.0417672,
      55.71507365
    ],
    "visueltcenter": [
      14.76377199,
      55.36353136
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0400",
        "kode": "0400",
        "navn": "Bornholm"
      }
    ],
    "ændret": "2020-05-26T21:14:32.122Z",
    "geo_ændret": "2020-05-26T21:14:32.122Z",
    "geo_version": 3,
    "dagi_id": "193770"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3782",
    "nr": "3782",
    "navn": "Klemensker",
    "stormodtageradresser": null,
    "bbox": [
      14.75792389,
      55.14563014,
      14.89093119,
      55.22457048
    ],
    "visueltcenter": [
      14.81018781,
      55.19264146
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0400",
        "kode": "0400",
        "navn": "Bornholm"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-10-28T21:38:11.296Z",
    "geo_version": 2,
    "dagi_id": "193782"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/3790",
    "nr": "3790",
    "navn": "Hasle",
    "stormodtageradresser": null,
    "bbox": [
      14.26625118,
      55.13482795,
      14.79847688,
      55.33050772
    ],
    "visueltcenter": [
      14.52165638,
      55.2284316
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0400",
        "kode": "0400",
        "navn": "Bornholm"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-10-28T21:38:11.296Z",
    "geo_version": 2,
    "dagi_id": "193790"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4000",
    "nr": "4000",
    "navn": "Roskilde",
    "stormodtageradresser": null,
    "bbox": [
      11.8990238,
      55.57583784,
      12.25479624,
      55.76445992
    ],
    "visueltcenter": [
      12.06373356,
      55.65647451
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0169",
        "kode": "0169",
        "navn": "Høje-Taastrup"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0265",
        "kode": "0265",
        "navn": "Roskilde"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0350",
        "kode": "0350",
        "navn": "Lejre"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194000"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4030",
    "nr": "4030",
    "navn": "Tune",
    "stormodtageradresser": null,
    "bbox": [
      12.13741376,
      55.57643731,
      12.22580462,
      55.61331939
    ],
    "visueltcenter": [
      12.16990416,
      55.59602755
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0253",
        "kode": "0253",
        "navn": "Greve"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0265",
        "kode": "0265",
        "navn": "Roskilde"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "401855"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4040",
    "nr": "4040",
    "navn": "Jyllinge",
    "stormodtageradresser": null,
    "bbox": [
      12.06901761,
      55.72206053,
      12.13701257,
      55.78340278
    ],
    "visueltcenter": [
      12.10404063,
      55.75603087
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0265",
        "kode": "0265",
        "navn": "Roskilde"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194040"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4050",
    "nr": "4050",
    "navn": "Skibby",
    "stormodtageradresser": null,
    "bbox": [
      11.82145507,
      55.70287803,
      12.1106687,
      55.81006524
    ],
    "visueltcenter": [
      12.01351904,
      55.75873813
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0250",
        "kode": "0250",
        "navn": "Frederikssund"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194050"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4060",
    "nr": "4060",
    "navn": "Kirke Såby",
    "stormodtageradresser": null,
    "bbox": [
      11.77531345,
      55.61978226,
      11.93929184,
      55.68518731
    ],
    "visueltcenter": [
      11.87375746,
      55.65437818
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0350",
        "kode": "0350",
        "navn": "Lejre"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-08-24T20:32:22.085Z",
    "geo_version": 3,
    "dagi_id": "194060"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4070",
    "nr": "4070",
    "navn": "Kirke Hyllinge",
    "stormodtageradresser": null,
    "bbox": [
      11.8101792,
      55.66050575,
      12.00833299,
      55.74826839
    ],
    "visueltcenter": [
      11.92418149,
      55.70417096
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0350",
        "kode": "0350",
        "navn": "Lejre"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-10-28T21:38:11.296Z",
    "geo_version": 2,
    "dagi_id": "194070"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4100",
    "nr": "4100",
    "navn": "Ringsted",
    "stormodtageradresser": null,
    "bbox": [
      11.63694319,
      55.35153721,
      12.06473784,
      55.53251441
    ],
    "visueltcenter": [
      11.82805925,
      55.43053197
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0259",
        "kode": "0259",
        "navn": "Køge"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0316",
        "kode": "0316",
        "navn": "Holbæk"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0320",
        "kode": "0320",
        "navn": "Faxe"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0329",
        "kode": "0329",
        "navn": "Ringsted"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0340",
        "kode": "0340",
        "navn": "Sorø"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0370",
        "kode": "0370",
        "navn": "Næstved"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-10-28T21:38:11.296Z",
    "geo_version": 2,
    "dagi_id": "194100"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4130",
    "nr": "4130",
    "navn": "Viby Sjælland",
    "stormodtageradresser": null,
    "bbox": [
      11.97035533,
      55.48957751,
      12.07681758,
      55.5874193
    ],
    "visueltcenter": [
      12.00904287,
      55.55011926
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0259",
        "kode": "0259",
        "navn": "Køge"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0265",
        "kode": "0265",
        "navn": "Roskilde"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194130"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4140",
    "nr": "4140",
    "navn": "Borup",
    "stormodtageradresser": null,
    "bbox": [
      11.87775357,
      55.45632596,
      12.05791871,
      55.53417361
    ],
    "visueltcenter": [
      11.97092667,
      55.49411646
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0259",
        "kode": "0259",
        "navn": "Køge"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0265",
        "kode": "0265",
        "navn": "Roskilde"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0329",
        "kode": "0329",
        "navn": "Ringsted"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194140"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4160",
    "nr": "4160",
    "navn": "Herlufmagle",
    "stormodtageradresser": null,
    "bbox": [
      11.63725709,
      55.26949277,
      11.85850479,
      55.38127701
    ],
    "visueltcenter": [
      11.77284465,
      55.32910688
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0320",
        "kode": "0320",
        "navn": "Faxe"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0370",
        "kode": "0370",
        "navn": "Næstved"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194160"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4171",
    "nr": "4171",
    "navn": "Glumsø",
    "stormodtageradresser": null,
    "bbox": [
      11.56230226,
      55.3221822,
      11.77706167,
      55.41267025
    ],
    "visueltcenter": [
      11.67300466,
      55.36237671
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0370",
        "kode": "0370",
        "navn": "Næstved"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194171"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4173",
    "nr": "4173",
    "navn": "Fjenneslev",
    "stormodtageradresser": null,
    "bbox": [
      11.60533131,
      55.38605621,
      11.73172845,
      55.4827092
    ],
    "visueltcenter": [
      11.66419067,
      55.43261731
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0329",
        "kode": "0329",
        "navn": "Ringsted"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0340",
        "kode": "0340",
        "navn": "Sorø"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-10-28T21:38:11.296Z",
    "geo_version": 2,
    "dagi_id": "194173"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4174",
    "nr": "4174",
    "navn": "Jystrup Midtsj",
    "stormodtageradresser": null,
    "bbox": [
      11.79559524,
      55.49078615,
      11.93609918,
      55.55610442
    ],
    "visueltcenter": [
      11.85552289,
      55.52537395
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0259",
        "kode": "0259",
        "navn": "Køge"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0329",
        "kode": "0329",
        "navn": "Ringsted"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194174"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4180",
    "nr": "4180",
    "navn": "Sorø",
    "stormodtageradresser": null,
    "bbox": [
      11.46226014,
      55.360438,
      11.67960294,
      55.53086533
    ],
    "visueltcenter": [
      11.55727004,
      55.40880423
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0329",
        "kode": "0329",
        "navn": "Ringsted"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0330",
        "kode": "0330",
        "navn": "Slagelse"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0340",
        "kode": "0340",
        "navn": "Sorø"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194180"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4190",
    "nr": "4190",
    "navn": "Munke Bjergby",
    "stormodtageradresser": null,
    "bbox": [
      11.4213671,
      55.47109979,
      11.55525038,
      55.52431117
    ],
    "visueltcenter": [
      11.52243487,
      55.50160113
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0330",
        "kode": "0330",
        "navn": "Slagelse"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0340",
        "kode": "0340",
        "navn": "Sorø"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-10-28T21:38:11.296Z",
    "geo_version": 2,
    "dagi_id": "194190"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4200",
    "nr": "4200",
    "navn": "Slagelse",
    "stormodtageradresser": null,
    "bbox": [
      11.04331771,
      55.28928227,
      11.52905037,
      55.50670368
    ],
    "visueltcenter": [
      11.37134678,
      55.40887345
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0326",
        "kode": "0326",
        "navn": "Kalundborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0330",
        "kode": "0330",
        "navn": "Slagelse"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0340",
        "kode": "0340",
        "navn": "Sorø"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0370",
        "kode": "0370",
        "navn": "Næstved"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-10-30T21:37:34.814Z",
    "geo_version": 3,
    "dagi_id": "194200"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4220",
    "nr": "4220",
    "navn": "Korsør",
    "stormodtageradresser": null,
    "bbox": [
      10.88067114,
      55.24435883,
      11.24519266,
      55.44271614
    ],
    "visueltcenter": [
      11.02962796,
      55.34799675
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0330",
        "kode": "0330",
        "navn": "Slagelse"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194220"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4230",
    "nr": "4230",
    "navn": "Skælskør",
    "stormodtageradresser": null,
    "bbox": [
      11.19130838,
      55.09668618,
      11.52551122,
      55.30354252
    ],
    "visueltcenter": [
      11.34912168,
      55.17717085
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0330",
        "kode": "0330",
        "navn": "Slagelse"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2017-05-01T21:13:25.676Z",
    "geo_version": 2,
    "dagi_id": "194230"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4241",
    "nr": "4241",
    "navn": "Vemmelev",
    "stormodtageradresser": null,
    "bbox": [
      11.2159486,
      55.3228584,
      11.30281117,
      55.39565061
    ],
    "visueltcenter": [
      11.26373834,
      55.36414208
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0330",
        "kode": "0330",
        "navn": "Slagelse"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194241"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4242",
    "nr": "4242",
    "navn": "Boeslunde",
    "stormodtageradresser": null,
    "bbox": [
      11.22020962,
      55.27631568,
      11.33770633,
      55.33122776
    ],
    "visueltcenter": [
      11.26828566,
      55.3061459
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0330",
        "kode": "0330",
        "navn": "Slagelse"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194242"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4243",
    "nr": "4243",
    "navn": "Rude",
    "stormodtageradresser": null,
    "bbox": [
      11.42298437,
      55.15785387,
      11.54221782,
      55.26228846
    ],
    "visueltcenter": [
      11.46287897,
      55.22423719
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0330",
        "kode": "0330",
        "navn": "Slagelse"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0370",
        "kode": "0370",
        "navn": "Næstved"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-10-30T21:37:34.814Z",
    "geo_version": 2,
    "dagi_id": "194243"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4244",
    "nr": "4244",
    "navn": "Agersø",
    "stormodtageradresser": null,
    "bbox": [
      11.01585709,
      55.1730033,
      11.24032041,
      55.27984364
    ],
    "visueltcenter": [
      11.12804101,
      55.22799697
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0330",
        "kode": "0330",
        "navn": "Slagelse"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2017-05-01T21:13:25.676Z",
    "geo_version": 1,
    "dagi_id": "377944"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4245",
    "nr": "4245",
    "navn": "Omø",
    "stormodtageradresser": null,
    "bbox": [
      11.03648777,
      55.04696777,
      11.28041004,
      55.18482148
    ],
    "visueltcenter": [
      11.15794643,
      55.1182759
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0330",
        "kode": "0330",
        "navn": "Slagelse"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2017-05-01T21:13:25.676Z",
    "geo_version": 1,
    "dagi_id": "377942"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4250",
    "nr": "4250",
    "navn": "Fuglebjerg",
    "stormodtageradresser": null,
    "bbox": [
      11.45858331,
      55.26762408,
      11.65951619,
      55.3765453
    ],
    "visueltcenter": [
      11.55229442,
      55.31864081
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0330",
        "kode": "0330",
        "navn": "Slagelse"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0370",
        "kode": "0370",
        "navn": "Næstved"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-10-30T21:37:34.814Z",
    "geo_version": 2,
    "dagi_id": "194250"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4261",
    "nr": "4261",
    "navn": "Dalmose",
    "stormodtageradresser": null,
    "bbox": [
      11.34827271,
      55.23894685,
      11.4839543,
      55.32327397
    ],
    "visueltcenter": [
      11.42947642,
      55.28221993
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0330",
        "kode": "0330",
        "navn": "Slagelse"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0370",
        "kode": "0370",
        "navn": "Næstved"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-10-30T21:37:34.814Z",
    "geo_version": 2,
    "dagi_id": "194261"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4262",
    "nr": "4262",
    "navn": "Sandved",
    "stormodtageradresser": null,
    "bbox": [
      11.46280462,
      55.19672402,
      11.58513034,
      55.29093627
    ],
    "visueltcenter": [
      11.528121,
      55.25079677
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0330",
        "kode": "0330",
        "navn": "Slagelse"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0370",
        "kode": "0370",
        "navn": "Næstved"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-10-30T21:37:34.814Z",
    "geo_version": 2,
    "dagi_id": "194262"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4270",
    "nr": "4270",
    "navn": "Høng",
    "stormodtageradresser": null,
    "bbox": [
      11.23290983,
      55.45996505,
      11.37320802,
      55.57379434
    ],
    "visueltcenter": [
      11.30548124,
      55.50308551
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0326",
        "kode": "0326",
        "navn": "Kalundborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0330",
        "kode": "0330",
        "navn": "Slagelse"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194270"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4281",
    "nr": "4281",
    "navn": "Gørlev",
    "stormodtageradresser": null,
    "bbox": [
      10.92871704,
      55.42891076,
      11.29661623,
      55.5849127
    ],
    "visueltcenter": [
      11.04711131,
      55.51887316
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0326",
        "kode": "0326",
        "navn": "Kalundborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-10-30T21:37:34.814Z",
    "geo_version": 3,
    "dagi_id": "194281"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4291",
    "nr": "4291",
    "navn": "Ruds Vedby",
    "stormodtageradresser": null,
    "bbox": [
      11.28599202,
      55.48779296,
      11.46082673,
      55.59626304
    ],
    "visueltcenter": [
      11.38504044,
      55.55253436
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0326",
        "kode": "0326",
        "navn": "Kalundborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0340",
        "kode": "0340",
        "navn": "Sorø"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194291"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4293",
    "nr": "4293",
    "navn": "Dianalund",
    "stormodtageradresser": null,
    "bbox": [
      11.41037955,
      55.49350773,
      11.54473605,
      55.59068425
    ],
    "visueltcenter": [
      11.48228398,
      55.53579978
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0326",
        "kode": "0326",
        "navn": "Kalundborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0340",
        "kode": "0340",
        "navn": "Sorø"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194293"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4295",
    "nr": "4295",
    "navn": "Stenlille",
    "stormodtageradresser": null,
    "bbox": [
      11.50622666,
      55.50248861,
      11.63669125,
      55.59190219
    ],
    "visueltcenter": [
      11.57749201,
      55.55246779
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0316",
        "kode": "0316",
        "navn": "Holbæk"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0340",
        "kode": "0340",
        "navn": "Sorø"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194295"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4296",
    "nr": "4296",
    "navn": "Nyrup",
    "stormodtageradresser": null,
    "bbox": [
      11.61484383,
      55.52345316,
      11.6847003,
      55.56848874
    ],
    "visueltcenter": [
      11.6485466,
      55.54056312
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0316",
        "kode": "0316",
        "navn": "Holbæk"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0340",
        "kode": "0340",
        "navn": "Sorø"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194296"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4300",
    "nr": "4300",
    "navn": "Holbæk",
    "stormodtageradresser": null,
    "bbox": [
      11.5804923,
      55.61930274,
      11.82861306,
      55.81380458
    ],
    "visueltcenter": [
      11.69985606,
      55.73825064
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0316",
        "kode": "0316",
        "navn": "Holbæk"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2017-05-01T21:13:25.676Z",
    "geo_version": 3,
    "dagi_id": "194300"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4305",
    "nr": "4305",
    "navn": "Orø",
    "stormodtageradresser": null,
    "bbox": [
      11.76363159,
      55.73307612,
      11.8577135,
      55.8400409
    ],
    "visueltcenter": [
      11.80968567,
      55.78240201
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0316",
        "kode": "0316",
        "navn": "Holbæk"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2017-05-01T21:13:25.676Z",
    "geo_version": 1,
    "dagi_id": "377940"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4320",
    "nr": "4320",
    "navn": "Lejre",
    "stormodtageradresser": null,
    "bbox": [
      11.90301945,
      55.5250348,
      12.01369642,
      55.63425754
    ],
    "visueltcenter": [
      11.95696429,
      55.59456942
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0259",
        "kode": "0259",
        "navn": "Køge"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0265",
        "kode": "0265",
        "navn": "Roskilde"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0350",
        "kode": "0350",
        "navn": "Lejre"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194320"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4330",
    "nr": "4330",
    "navn": "Hvalsø",
    "stormodtageradresser": null,
    "bbox": [
      11.79674331,
      55.53045752,
      11.9363392,
      55.63865845
    ],
    "visueltcenter": [
      11.86940589,
      55.58583604
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0316",
        "kode": "0316",
        "navn": "Holbæk"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0329",
        "kode": "0329",
        "navn": "Ringsted"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0350",
        "kode": "0350",
        "navn": "Lejre"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-05-27T21:48:08.912Z",
    "geo_version": 2,
    "dagi_id": "194330"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4340",
    "nr": "4340",
    "navn": "Tølløse",
    "stormodtageradresser": null,
    "bbox": [
      11.54018644,
      55.57294613,
      11.82748822,
      55.65548902
    ],
    "visueltcenter": [
      11.74532267,
      55.61818163
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0316",
        "kode": "0316",
        "navn": "Holbæk"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0350",
        "kode": "0350",
        "navn": "Lejre"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-05-27T21:48:08.912Z",
    "geo_version": 2,
    "dagi_id": "194340"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4350",
    "nr": "4350",
    "navn": "Ugerløse",
    "stormodtageradresser": null,
    "bbox": [
      11.57010202,
      55.56363607,
      11.701564,
      55.62621818
    ],
    "visueltcenter": [
      11.64996808,
      55.59017034
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0316",
        "kode": "0316",
        "navn": "Holbæk"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194350"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4360",
    "nr": "4360",
    "navn": "Kirke Eskilstrup",
    "stormodtageradresser": null,
    "bbox": [
      11.71701366,
      55.53886689,
      11.82958177,
      55.5948964
    ],
    "visueltcenter": [
      11.78695601,
      55.56442046
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0316",
        "kode": "0316",
        "navn": "Holbæk"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0329",
        "kode": "0329",
        "navn": "Ringsted"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194360"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4370",
    "nr": "4370",
    "navn": "Store Merløse",
    "stormodtageradresser": null,
    "bbox": [
      11.64598937,
      55.51409393,
      11.81478802,
      55.5885938
    ],
    "visueltcenter": [
      11.7241173,
      55.54054576
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0316",
        "kode": "0316",
        "navn": "Holbæk"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0329",
        "kode": "0329",
        "navn": "Ringsted"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0340",
        "kode": "0340",
        "navn": "Sorø"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194370"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4390",
    "nr": "4390",
    "navn": "Vipperød",
    "stormodtageradresser": null,
    "bbox": [
      11.68375221,
      55.63521768,
      11.81156033,
      55.69663826
    ],
    "visueltcenter": [
      11.74969494,
      55.66270448
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0316",
        "kode": "0316",
        "navn": "Holbæk"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-08-24T20:32:22.085Z",
    "geo_version": 2,
    "dagi_id": "194390"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4400",
    "nr": "4400",
    "navn": "Kalundborg",
    "stormodtageradresser": null,
    "bbox": [
      10.71128308,
      55.56109231,
      11.26813541,
      55.8676522
    ],
    "visueltcenter": [
      10.97431629,
      55.70601705
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0326",
        "kode": "0326",
        "navn": "Kalundborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194400"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4420",
    "nr": "4420",
    "navn": "Regstrup",
    "stormodtageradresser": null,
    "bbox": [
      11.53388774,
      55.61208435,
      11.66522063,
      55.71812081
    ],
    "visueltcenter": [
      11.60711139,
      55.66048124
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0316",
        "kode": "0316",
        "navn": "Holbæk"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194420"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4440",
    "nr": "4440",
    "navn": "Mørkøv",
    "stormodtageradresser": null,
    "bbox": [
      11.4085584,
      55.57396378,
      11.58148119,
      55.70277354
    ],
    "visueltcenter": [
      11.49619782,
      55.62211279
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0316",
        "kode": "0316",
        "navn": "Holbæk"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194440"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4450",
    "nr": "4450",
    "navn": "Jyderup",
    "stormodtageradresser": null,
    "bbox": [
      11.31321612,
      55.57679703,
      11.47035503,
      55.71566859
    ],
    "visueltcenter": [
      11.38733175,
      55.61622153
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0316",
        "kode": "0316",
        "navn": "Holbæk"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0326",
        "kode": "0326",
        "navn": "Kalundborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194450"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4460",
    "nr": "4460",
    "navn": "Snertinge",
    "stormodtageradresser": null,
    "bbox": [
      11.34350158,
      55.6784504,
      11.42770551,
      55.73400129
    ],
    "visueltcenter": [
      11.37072627,
      55.6969919
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0316",
        "kode": "0316",
        "navn": "Holbæk"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0326",
        "kode": "0326",
        "navn": "Kalundborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194460"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4470",
    "nr": "4470",
    "navn": "Svebølle",
    "stormodtageradresser": null,
    "bbox": [
      11.22645192,
      55.59782698,
      11.36275517,
      55.68699949
    ],
    "visueltcenter": [
      11.29429706,
      55.65722471
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0316",
        "kode": "0316",
        "navn": "Holbæk"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0326",
        "kode": "0326",
        "navn": "Kalundborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194470"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4480",
    "nr": "4480",
    "navn": "Store Fuglede",
    "stormodtageradresser": null,
    "bbox": [
      11.07350337,
      55.5474048,
      11.29230444,
      55.60348013
    ],
    "visueltcenter": [
      11.15168308,
      55.5741086
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0326",
        "kode": "0326",
        "navn": "Kalundborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194480"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4490",
    "nr": "4490",
    "navn": "Jerslev Sjælland",
    "stormodtageradresser": null,
    "bbox": [
      11.16933416,
      55.57232376,
      11.32329868,
      55.65960607
    ],
    "visueltcenter": [
      11.23183281,
      55.61730235
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0326",
        "kode": "0326",
        "navn": "Kalundborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194490"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4500",
    "nr": "4500",
    "navn": "Nykøbing Sj",
    "stormodtageradresser": null,
    "bbox": [
      11.43214156,
      55.80765998,
      11.82484337,
      56.11389276
    ],
    "visueltcenter": [
      11.56343652,
      55.9928841
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0306",
        "kode": "0306",
        "navn": "Odsherred"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194500"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4520",
    "nr": "4520",
    "navn": "Svinninge",
    "stormodtageradresser": null,
    "bbox": [
      11.4071062,
      55.6861426,
      11.60166333,
      55.77471242
    ],
    "visueltcenter": [
      11.48951817,
      55.71737311
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0316",
        "kode": "0316",
        "navn": "Holbæk"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194520"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4532",
    "nr": "4532",
    "navn": "Gislinge",
    "stormodtageradresser": null,
    "bbox": [
      11.51611242,
      55.70816882,
      11.61571058,
      55.76158986
    ],
    "visueltcenter": [
      11.56410432,
      55.73610011
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0316",
        "kode": "0316",
        "navn": "Holbæk"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194532"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4534",
    "nr": "4534",
    "navn": "Hørve",
    "stormodtageradresser": null,
    "bbox": [
      11.32706403,
      55.721698,
      11.53534091,
      55.79128216
    ],
    "visueltcenter": [
      11.40509,
      55.76162863
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0306",
        "kode": "0306",
        "navn": "Odsherred"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0326",
        "kode": "0326",
        "navn": "Kalundborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-10-30T21:37:34.814Z",
    "geo_version": 2,
    "dagi_id": "194534"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4540",
    "nr": "4540",
    "navn": "Fårevejle",
    "stormodtageradresser": null,
    "bbox": [
      11.27881522,
      55.76916277,
      11.54070632,
      55.90166125
    ],
    "visueltcenter": [
      11.37403038,
      55.85310811
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0306",
        "kode": "0306",
        "navn": "Odsherred"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194540"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4550",
    "nr": "4550",
    "navn": "Asnæs",
    "stormodtageradresser": null,
    "bbox": [
      11.45032486,
      55.77855295,
      11.54695615,
      55.88674858
    ],
    "visueltcenter": [
      11.49734325,
      55.81190532
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0306",
        "kode": "0306",
        "navn": "Odsherred"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194550"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4560",
    "nr": "4560",
    "navn": "Vig",
    "stormodtageradresser": null,
    "bbox": [
      11.44947584,
      55.79746555,
      11.75125451,
      55.89151504
    ],
    "visueltcenter": [
      11.56036901,
      55.84646728
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0306",
        "kode": "0306",
        "navn": "Odsherred"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194560"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4571",
    "nr": "4571",
    "navn": "Grevinge",
    "stormodtageradresser": null,
    "bbox": [
      11.52624111,
      55.76608624,
      11.68299521,
      55.8373433
    ],
    "visueltcenter": [
      11.58697448,
      55.79587455
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0306",
        "kode": "0306",
        "navn": "Odsherred"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194571"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4572",
    "nr": "4572",
    "navn": "Nørre Asmindrup",
    "stormodtageradresser": null,
    "bbox": [
      11.55743571,
      55.86023306,
      11.66531827,
      55.89918713
    ],
    "visueltcenter": [
      11.62326767,
      55.88038731
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0306",
        "kode": "0306",
        "navn": "Odsherred"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194572"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4573",
    "nr": "4573",
    "navn": "Højby",
    "stormodtageradresser": null,
    "bbox": [
      11.4366698,
      55.87318027,
      11.63531782,
      55.938583
    ],
    "visueltcenter": [
      11.55659445,
      55.90779403
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0306",
        "kode": "0306",
        "navn": "Odsherred"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194573"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4581",
    "nr": "4581",
    "navn": "Rørvig",
    "stormodtageradresser": null,
    "bbox": [
      11.64037038,
      55.91846645,
      11.82308869,
      56.08574891
    ],
    "visueltcenter": [
      11.71725292,
      56.01043371
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0306",
        "kode": "0306",
        "navn": "Odsherred"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194581"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4583",
    "nr": "4583",
    "navn": "Sjællands Odde",
    "stormodtageradresser": null,
    "bbox": [
      10.99876819,
      55.89504497,
      11.48616839,
      56.25002296
    ],
    "visueltcenter": [
      11.26204837,
      56.08627395
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0306",
        "kode": "0306",
        "navn": "Odsherred"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194583"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4591",
    "nr": "4591",
    "navn": "Føllenslev",
    "stormodtageradresser": null,
    "bbox": [
      11.18024371,
      55.69162966,
      11.40083544,
      55.83806615
    ],
    "visueltcenter": [
      11.27498796,
      55.79702076
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0306",
        "kode": "0306",
        "navn": "Odsherred"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0326",
        "kode": "0326",
        "navn": "Kalundborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-10-30T21:37:34.814Z",
    "geo_version": 2,
    "dagi_id": "194591"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4592",
    "nr": "4592",
    "navn": "Sejerø",
    "stormodtageradresser": null,
    "bbox": [
      10.86997524,
      55.80364153,
      11.31789765,
      56.088805
    ],
    "visueltcenter": [
      11.03916639,
      55.92069678
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0326",
        "kode": "0326",
        "navn": "Kalundborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194592"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4593",
    "nr": "4593",
    "navn": "Eskebjerg",
    "stormodtageradresser": null,
    "bbox": [
      11.17886117,
      55.67486861,
      11.36620805,
      55.78619848
    ],
    "visueltcenter": [
      11.23692126,
      55.7277303
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0326",
        "kode": "0326",
        "navn": "Kalundborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194593"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4600",
    "nr": "4600",
    "navn": "Køge",
    "stormodtageradresser": null,
    "bbox": [
      12.05230441,
      55.37018231,
      12.32941675,
      55.5185536
    ],
    "visueltcenter": [
      12.22015308,
      55.45527126
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0259",
        "kode": "0259",
        "navn": "Køge"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0336",
        "kode": "0336",
        "navn": "Stevns"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2018-01-16T22:10:42.686Z",
    "geo_version": 4,
    "dagi_id": "194600"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4621",
    "nr": "4621",
    "navn": "Gadstrup",
    "stormodtageradresser": null,
    "bbox": [
      12.04016291,
      55.54493339,
      12.19186296,
      55.59414733
    ],
    "visueltcenter": [
      12.08017443,
      55.5745913
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0265",
        "kode": "0265",
        "navn": "Roskilde"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0350",
        "kode": "0350",
        "navn": "Lejre"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194621"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4622",
    "nr": "4622",
    "navn": "Havdrup",
    "stormodtageradresser": null,
    "bbox": [
      12.05684828,
      55.51563793,
      12.18172654,
      55.56750698
    ],
    "visueltcenter": [
      12.11265454,
      55.54041131
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0265",
        "kode": "0265",
        "navn": "Roskilde"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0269",
        "kode": "0269",
        "navn": "Solrød"
      }
    ],
    "ændret": "2020-03-02T22:15:14.069Z",
    "geo_ændret": "2020-03-02T22:15:14.069Z",
    "geo_version": 2,
    "dagi_id": "194622"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4623",
    "nr": "4623",
    "navn": "Lille Skensved",
    "stormodtageradresser": null,
    "bbox": [
      12.04617985,
      55.47036946,
      12.19015237,
      55.52884996
    ],
    "visueltcenter": [
      12.08511883,
      55.50779016
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0259",
        "kode": "0259",
        "navn": "Køge"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0265",
        "kode": "0265",
        "navn": "Roskilde"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0269",
        "kode": "0269",
        "navn": "Solrød"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2018-01-16T22:10:42.686Z",
    "geo_version": 4,
    "dagi_id": "194623"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4632",
    "nr": "4632",
    "navn": "Bjæverskov",
    "stormodtageradresser": null,
    "bbox": [
      11.98386418,
      55.41514604,
      12.0852994,
      55.48303707
    ],
    "visueltcenter": [
      12.02691725,
      55.44838852
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0259",
        "kode": "0259",
        "navn": "Køge"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194632"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4640",
    "nr": "4640",
    "navn": "Faxe",
    "stormodtageradresser": null,
    "bbox": [
      12.03254935,
      55.12689381,
      12.29092119,
      55.31006772
    ],
    "visueltcenter": [
      12.12411652,
      55.2685839
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0320",
        "kode": "0320",
        "navn": "Faxe"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0370",
        "kode": "0370",
        "navn": "Næstved"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194640"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4652",
    "nr": "4652",
    "navn": "Hårlev",
    "stormodtageradresser": null,
    "bbox": [
      12.129061,
      55.30192576,
      12.30793483,
      55.39519311
    ],
    "visueltcenter": [
      12.22317915,
      55.36245892
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0320",
        "kode": "0320",
        "navn": "Faxe"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0336",
        "kode": "0336",
        "navn": "Stevns"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194652"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4653",
    "nr": "4653",
    "navn": "Karise",
    "stormodtageradresser": null,
    "bbox": [
      12.09666619,
      55.26301405,
      12.28507964,
      55.34421269
    ],
    "visueltcenter": [
      12.21696415,
      55.29633252
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0320",
        "kode": "0320",
        "navn": "Faxe"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0336",
        "kode": "0336",
        "navn": "Stevns"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194653"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4654",
    "nr": "4654",
    "navn": "Faxe Ladeplads",
    "stormodtageradresser": null,
    "bbox": [
      12.10690721,
      55.16218812,
      12.27143962,
      55.24589346
    ],
    "visueltcenter": [
      12.19373813,
      55.19490482
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0320",
        "kode": "0320",
        "navn": "Faxe"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194654"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4660",
    "nr": "4660",
    "navn": "Store Heddinge",
    "stormodtageradresser": null,
    "bbox": [
      12.25318175,
      55.14915387,
      12.81903682,
      55.45924234
    ],
    "visueltcenter": [
      12.51110642,
      55.33554618
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0336",
        "kode": "0336",
        "navn": "Stevns"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-10-30T21:37:34.814Z",
    "geo_version": 2,
    "dagi_id": "194660"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4671",
    "nr": "4671",
    "navn": "Strøby",
    "stormodtageradresser": null,
    "bbox": [
      12.2518202,
      55.3581441,
      12.38270086,
      55.4926395
    ],
    "visueltcenter": [
      12.31112895,
      55.42088958
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0336",
        "kode": "0336",
        "navn": "Stevns"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194671"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4672",
    "nr": "4672",
    "navn": "Klippinge",
    "stormodtageradresser": null,
    "bbox": [
      12.27437637,
      55.31179762,
      12.54627901,
      55.49904244
    ],
    "visueltcenter": [
      12.4342892,
      55.44595315
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0336",
        "kode": "0336",
        "navn": "Stevns"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-10-30T21:37:34.814Z",
    "geo_version": 2,
    "dagi_id": "194672"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4673",
    "nr": "4673",
    "navn": "Rødvig Stevns",
    "stormodtageradresser": null,
    "bbox": [
      12.26935751,
      55.12671072,
      12.6350008,
      55.29029088
    ],
    "visueltcenter": [
      12.38750748,
      55.20085779
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0336",
        "kode": "0336",
        "navn": "Stevns"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194673"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4681",
    "nr": "4681",
    "navn": "Herfølge",
    "stormodtageradresser": null,
    "bbox": [
      12.06087758,
      55.37511149,
      12.17075083,
      55.44186664
    ],
    "visueltcenter": [
      12.14194267,
      55.41715543
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0259",
        "kode": "0259",
        "navn": "Køge"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194681"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4682",
    "nr": "4682",
    "navn": "Tureby",
    "stormodtageradresser": null,
    "bbox": [
      11.9956202,
      55.30623186,
      12.15727819,
      55.41915075
    ],
    "visueltcenter": [
      12.08719823,
      55.38830546
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0259",
        "kode": "0259",
        "navn": "Køge"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0320",
        "kode": "0320",
        "navn": "Faxe"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0336",
        "kode": "0336",
        "navn": "Stevns"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194682"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4683",
    "nr": "4683",
    "navn": "Rønnede",
    "stormodtageradresser": null,
    "bbox": [
      11.96805487,
      55.20153455,
      12.07511594,
      55.29090725
    ],
    "visueltcenter": [
      12.02277028,
      55.23758668
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0320",
        "kode": "0320",
        "navn": "Faxe"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0370",
        "kode": "0370",
        "navn": "Næstved"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194683"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4684",
    "nr": "4684",
    "navn": "Holmegaard",
    "stormodtageradresser": null,
    "bbox": [
      11.77354366,
      55.21266158,
      11.98661148,
      55.30321543
    ],
    "visueltcenter": [
      11.86408266,
      55.25979308
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0320",
        "kode": "0320",
        "navn": "Faxe"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0370",
        "kode": "0370",
        "navn": "Næstved"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194684"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4690",
    "nr": "4690",
    "navn": "Haslev",
    "stormodtageradresser": null,
    "bbox": [
      11.81687533,
      55.2392304,
      12.10289657,
      55.40654498
    ],
    "visueltcenter": [
      11.95519764,
      55.32031014
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0320",
        "kode": "0320",
        "navn": "Faxe"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0329",
        "kode": "0329",
        "navn": "Ringsted"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0370",
        "kode": "0370",
        "navn": "Næstved"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194690"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4700",
    "nr": "4700",
    "navn": "Næstved",
    "stormodtageradresser": null,
    "bbox": [
      11.50453782,
      55.10457009,
      11.94538049,
      55.31233942
    ],
    "visueltcenter": [
      11.77357353,
      55.19504272
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0370",
        "kode": "0370",
        "navn": "Næstved"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194700"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4720",
    "nr": "4720",
    "navn": "Præstø",
    "stormodtageradresser": null,
    "bbox": [
      11.90462544,
      55.04429036,
      12.34023291,
      55.17431738
    ],
    "visueltcenter": [
      12.19166049,
      55.12208613
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0390",
        "kode": "0390",
        "navn": "Vordingborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-03-08T21:29:38.875Z",
    "geo_version": 2,
    "dagi_id": "194720"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4733",
    "nr": "4733",
    "navn": "Tappernøje",
    "stormodtageradresser": null,
    "bbox": [
      11.89637944,
      55.13011961,
      12.06159412,
      55.22673914
    ],
    "visueltcenter": [
      11.98569733,
      55.18127292
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0320",
        "kode": "0320",
        "navn": "Faxe"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0370",
        "kode": "0370",
        "navn": "Næstved"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0390",
        "kode": "0390",
        "navn": "Vordingborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194733"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4735",
    "nr": "4735",
    "navn": "Mern",
    "stormodtageradresser": null,
    "bbox": [
      11.97984139,
      55.00731429,
      12.16980142,
      55.07302334
    ],
    "visueltcenter": [
      12.06283657,
      55.04277496
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0390",
        "kode": "0390",
        "navn": "Vordingborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194735"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4736",
    "nr": "4736",
    "navn": "Karrebæksminde",
    "stormodtageradresser": null,
    "bbox": [
      11.56474807,
      55.11927567,
      11.71961267,
      55.2159652
    ],
    "visueltcenter": [
      11.6361743,
      55.15521424
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0370",
        "kode": "0370",
        "navn": "Næstved"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194736"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4750",
    "nr": "4750",
    "navn": "Lundby",
    "stormodtageradresser": null,
    "bbox": [
      11.64453255,
      55.05961193,
      11.98269051,
      55.16178797
    ],
    "visueltcenter": [
      11.78064612,
      55.10685595
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0370",
        "kode": "0370",
        "navn": "Næstved"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0390",
        "kode": "0390",
        "navn": "Vordingborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-10-30T21:37:34.814Z",
    "geo_version": 2,
    "dagi_id": "194750"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4760",
    "nr": "4760",
    "navn": "Vordingborg",
    "stormodtageradresser": null,
    "bbox": [
      11.47912842,
      54.95363251,
      12.02140031,
      55.12885118
    ],
    "visueltcenter": [
      11.8960716,
      55.02464842
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0390",
        "kode": "0390",
        "navn": "Vordingborg"
      }
    ],
    "ændret": "2020-01-06T22:17:24.796Z",
    "geo_ændret": "2020-01-06T22:17:24.796Z",
    "geo_version": 4,
    "dagi_id": "194760"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4771",
    "nr": "4771",
    "navn": "Kalvehave",
    "stormodtageradresser": null,
    "bbox": [
      12.10076965,
      54.97782723,
      12.25401876,
      55.03866964
    ],
    "visueltcenter": [
      12.15645358,
      55.00889353
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0390",
        "kode": "0390",
        "navn": "Vordingborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194771"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4772",
    "nr": "4772",
    "navn": "Langebæk",
    "stormodtageradresser": null,
    "bbox": [
      12.03271902,
      54.93814666,
      12.14691512,
      55.02755594
    ],
    "visueltcenter": [
      12.08817698,
      54.99961116
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0390",
        "kode": "0390",
        "navn": "Vordingborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194772"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4773",
    "nr": "4773",
    "navn": "Stensved",
    "stormodtageradresser": null,
    "bbox": [
      11.98043721,
      54.94512264,
      12.07614206,
      55.02249549
    ],
    "visueltcenter": [
      12.03439585,
      54.97060588
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0390",
        "kode": "0390",
        "navn": "Vordingborg"
      }
    ],
    "ændret": "2020-01-06T22:17:24.796Z",
    "geo_ændret": "2020-01-06T22:17:24.796Z",
    "geo_version": 2,
    "dagi_id": "194773"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4780",
    "nr": "4780",
    "navn": "Stege",
    "stormodtageradresser": null,
    "bbox": [
      12.09566568,
      54.81312983,
      12.45434224,
      55.1473341
    ],
    "visueltcenter": [
      12.31627561,
      54.9362949
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0390",
        "kode": "0390",
        "navn": "Vordingborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194780"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4791",
    "nr": "4791",
    "navn": "Borre",
    "stormodtageradresser": null,
    "bbox": [
      12.38194941,
      54.71565304,
      13.16063771,
      55.17531285
    ],
    "visueltcenter": [
      12.7284169,
      54.97429881
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0390",
        "kode": "0390",
        "navn": "Vordingborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194791"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4792",
    "nr": "4792",
    "navn": "Askeby",
    "stormodtageradresser": null,
    "bbox": [
      12.07164623,
      54.70131216,
      12.64351,
      54.94490822
    ],
    "visueltcenter": [
      12.52545007,
      54.7375487
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0390",
        "kode": "0390",
        "navn": "Vordingborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194792"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4793",
    "nr": "4793",
    "navn": "Bogø By",
    "stormodtageradresser": null,
    "bbox": [
      11.95310641,
      54.90000587,
      12.10340292,
      54.96188712
    ],
    "visueltcenter": [
      12.05993905,
      54.92382082
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0390",
        "kode": "0390",
        "navn": "Vordingborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194793"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4800",
    "nr": "4800",
    "navn": "Nykøbing F",
    "stormodtageradresser": null,
    "bbox": [
      11.78074953,
      54.68397754,
      12.28501423,
      54.84809627
    ],
    "visueltcenter": [
      11.90585409,
      54.79083949
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0376",
        "kode": "0376",
        "navn": "Guldborgsund"
      }
    ],
    "ændret": "2018-08-13T21:06:24.470Z",
    "geo_ændret": "2018-08-13T21:06:24.470Z",
    "geo_version": 2,
    "dagi_id": "194800"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4840",
    "nr": "4840",
    "navn": "Nørre Alslev",
    "stormodtageradresser": null,
    "bbox": [
      11.62702086,
      54.83324047,
      11.98798467,
      55.00669832
    ],
    "visueltcenter": [
      11.78722678,
      54.93527293
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0376",
        "kode": "0376",
        "navn": "Guldborgsund"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194840"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4850",
    "nr": "4850",
    "navn": "Stubbekøbing",
    "stormodtageradresser": null,
    "bbox": [
      11.91093913,
      54.66232452,
      12.53669731,
      54.94211469
    ],
    "visueltcenter": [
      12.41175242,
      54.71290684
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0376",
        "kode": "0376",
        "navn": "Guldborgsund"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194850"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4862",
    "nr": "4862",
    "navn": "Guldborg",
    "stormodtageradresser": null,
    "bbox": [
      11.56556843,
      54.81783417,
      11.81353908,
      54.94927886
    ],
    "visueltcenter": [
      11.62528305,
      54.90483893
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0376",
        "kode": "0376",
        "navn": "Guldborgsund"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194862"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4863",
    "nr": "4863",
    "navn": "Eskilstrup",
    "stormodtageradresser": null,
    "bbox": [
      11.79250455,
      54.82343601,
      11.99434121,
      54.88555738
    ],
    "visueltcenter": [
      11.90575722,
      54.86008913
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0376",
        "kode": "0376",
        "navn": "Guldborgsund"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194863"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4871",
    "nr": "4871",
    "navn": "Horbelev",
    "stormodtageradresser": null,
    "bbox": [
      11.97441943,
      54.63430027,
      12.43158572,
      54.85755681
    ],
    "visueltcenter": [
      12.29995982,
      54.69990623
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0376",
        "kode": "0376",
        "navn": "Guldborgsund"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194871"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4872",
    "nr": "4872",
    "navn": "Idestrup",
    "stormodtageradresser": null,
    "bbox": [
      11.92421331,
      54.61449087,
      12.38984602,
      54.76121633
    ],
    "visueltcenter": [
      12.11249782,
      54.70210055
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0376",
        "kode": "0376",
        "navn": "Guldborgsund"
      }
    ],
    "ændret": "2018-08-13T21:06:24.470Z",
    "geo_ændret": "2018-08-13T21:06:24.470Z",
    "geo_version": 2,
    "dagi_id": "194872"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4873",
    "nr": "4873",
    "navn": "Væggerløse",
    "stormodtageradresser": null,
    "bbox": [
      11.83247645,
      54.60289563,
      12.3656361,
      54.74330809
    ],
    "visueltcenter": [
      11.98479974,
      54.66359732
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0376",
        "kode": "0376",
        "navn": "Guldborgsund"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194873"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4874",
    "nr": "4874",
    "navn": "Gedser",
    "stormodtageradresser": null,
    "bbox": [
      11.66819743,
      54.3457944,
      12.35925793,
      54.66957705
    ],
    "visueltcenter": [
      11.9502463,
      54.49608541
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0376",
        "kode": "0376",
        "navn": "Guldborgsund"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194874"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4880",
    "nr": "4880",
    "navn": "Nysted",
    "stormodtageradresser": null,
    "bbox": [
      11.61989533,
      54.53151447,
      11.86866085,
      54.74177049
    ],
    "visueltcenter": [
      11.74490279,
      54.62941506
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0376",
        "kode": "0376",
        "navn": "Guldborgsund"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-10-30T21:37:34.814Z",
    "geo_version": 2,
    "dagi_id": "194880"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4891",
    "nr": "4891",
    "navn": "Toreby L",
    "stormodtageradresser": null,
    "bbox": [
      11.70920334,
      54.70978785,
      11.84873194,
      54.80812567
    ],
    "visueltcenter": [
      11.77556621,
      54.7578349
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0376",
        "kode": "0376",
        "navn": "Guldborgsund"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194891"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4892",
    "nr": "4892",
    "navn": "Kettinge",
    "stormodtageradresser": null,
    "bbox": [
      11.66671214,
      54.67465299,
      11.87409961,
      54.73649171
    ],
    "visueltcenter": [
      11.74606046,
      54.70725618
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0376",
        "kode": "0376",
        "navn": "Guldborgsund"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-10-30T21:37:34.814Z",
    "geo_version": 2,
    "dagi_id": "194892"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4894",
    "nr": "4894",
    "navn": "Øster Ulslev",
    "stormodtageradresser": null,
    "bbox": [
      11.54957462,
      54.61041666,
      11.66071143,
      54.74337278
    ],
    "visueltcenter": [
      11.60726017,
      54.68554952
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0360",
        "kode": "0360",
        "navn": "Lolland"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0376",
        "kode": "0376",
        "navn": "Guldborgsund"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194894"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4895",
    "nr": "4895",
    "navn": "Errindlev",
    "stormodtageradresser": null,
    "bbox": [
      11.44836265,
      54.61290029,
      11.6025389,
      54.69085672
    ],
    "visueltcenter": [
      11.53487222,
      54.63787516
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0360",
        "kode": "0360",
        "navn": "Lolland"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194895"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4900",
    "nr": "4900",
    "navn": "Nakskov",
    "stormodtageradresser": null,
    "bbox": [
      10.86109654,
      54.62906422,
      11.26483605,
      55.0085531
    ],
    "visueltcenter": [
      10.98835691,
      54.75748092
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0360",
        "kode": "0360",
        "navn": "Lolland"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194900"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4912",
    "nr": "4912",
    "navn": "Harpelunde",
    "stormodtageradresser": null,
    "bbox": [
      10.91755223,
      54.81581078,
      11.11423927,
      54.97234099
    ],
    "visueltcenter": [
      10.98695965,
      54.90510445
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0360",
        "kode": "0360",
        "navn": "Lolland"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194912"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4913",
    "nr": "4913",
    "navn": "Horslunde",
    "stormodtageradresser": null,
    "bbox": [
      11.024166,
      54.8745413,
      11.35203367,
      55.05523274
    ],
    "visueltcenter": [
      11.20918569,
      54.96838447
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0360",
        "kode": "0360",
        "navn": "Lolland"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194913"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4920",
    "nr": "4920",
    "navn": "Søllested",
    "stormodtageradresser": null,
    "bbox": [
      11.20415274,
      54.73242534,
      11.38480975,
      54.85633135
    ],
    "visueltcenter": [
      11.29197531,
      54.79935911
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0360",
        "kode": "0360",
        "navn": "Lolland"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194920"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4930",
    "nr": "4930",
    "navn": "Maribo",
    "stormodtageradresser": null,
    "bbox": [
      11.33945561,
      54.71620321,
      11.60356659,
      54.85103243
    ],
    "visueltcenter": [
      11.49815546,
      54.76659931
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0360",
        "kode": "0360",
        "navn": "Lolland"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0376",
        "kode": "0376",
        "navn": "Guldborgsund"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2017-05-01T21:13:25.676Z",
    "geo_version": 3,
    "dagi_id": "194930"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4941",
    "nr": "4941",
    "navn": "Bandholm",
    "stormodtageradresser": null,
    "bbox": [
      11.40371497,
      54.80405975,
      11.5418989,
      54.88272186
    ],
    "visueltcenter": [
      11.49059609,
      54.83767284
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0360",
        "kode": "0360",
        "navn": "Lolland"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194941"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4942",
    "nr": "4942",
    "navn": "Askø",
    "stormodtageradresser": null,
    "bbox": [
      11.41422487,
      54.86043159,
      11.5788727,
      54.93799936
    ],
    "visueltcenter": [
      11.5111678,
      54.89908661
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0360",
        "kode": "0360",
        "navn": "Lolland"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2017-05-01T21:13:25.676Z",
    "geo_version": 1,
    "dagi_id": "377946"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4943",
    "nr": "4943",
    "navn": "Torrig L",
    "stormodtageradresser": null,
    "bbox": [
      11.18318189,
      54.8764049,
      11.50560836,
      55.11859005
    ],
    "visueltcenter": [
      11.36808859,
      55.05662783
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0360",
        "kode": "0360",
        "navn": "Lolland"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194943"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4944",
    "nr": "4944",
    "navn": "Fejø",
    "stormodtageradresser": null,
    "bbox": [
      11.32629219,
      54.89815774,
      11.5110907,
      55.01172543
    ],
    "visueltcenter": [
      11.4069268,
      54.95857211
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0360",
        "kode": "0360",
        "navn": "Lolland"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194944"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4945",
    "nr": "4945",
    "navn": "Femø",
    "stormodtageradresser": null,
    "bbox": [
      11.43558864,
      54.91693046,
      11.6680162,
      55.06913774
    ],
    "visueltcenter": [
      11.56847203,
      54.9844243
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0360",
        "kode": "0360",
        "navn": "Lolland"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2017-03-01T22:16:27.696Z",
    "geo_version": 1,
    "dagi_id": "377871"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4951",
    "nr": "4951",
    "navn": "Nørreballe",
    "stormodtageradresser": null,
    "bbox": [
      11.39855664,
      54.79218416,
      11.46405785,
      54.84094833
    ],
    "visueltcenter": [
      11.4281128,
      54.81506841
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0360",
        "kode": "0360",
        "navn": "Lolland"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194951"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4952",
    "nr": "4952",
    "navn": "Stokkemarke",
    "stormodtageradresser": null,
    "bbox": [
      11.30788055,
      54.80624479,
      11.43380636,
      54.90223663
    ],
    "visueltcenter": [
      11.37909951,
      54.84533107
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0360",
        "kode": "0360",
        "navn": "Lolland"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194952"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4953",
    "nr": "4953",
    "navn": "Vesterborg",
    "stormodtageradresser": null,
    "bbox": [
      11.23017428,
      54.8351371,
      11.41222096,
      54.90672926
    ],
    "visueltcenter": [
      11.28637518,
      54.863378
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0360",
        "kode": "0360",
        "navn": "Lolland"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194953"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4960",
    "nr": "4960",
    "navn": "Holeby",
    "stormodtageradresser": null,
    "bbox": [
      11.42347005,
      54.65546148,
      11.5752407,
      54.7345553
    ],
    "visueltcenter": [
      11.53543477,
      54.70172856
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0360",
        "kode": "0360",
        "navn": "Lolland"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194960"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4970",
    "nr": "4970",
    "navn": "Rødby",
    "stormodtageradresser": null,
    "bbox": [
      11.19346774,
      54.36202717,
      11.75325031,
      54.77747932
    ],
    "visueltcenter": [
      11.57693074,
      54.53635729
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0360",
        "kode": "0360",
        "navn": "Lolland"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194970"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4983",
    "nr": "4983",
    "navn": "Dannemare",
    "stormodtageradresser": null,
    "bbox": [
      11.07111474,
      54.6053587,
      11.29017951,
      54.80757216
    ],
    "visueltcenter": [
      11.17254811,
      54.71726169
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0360",
        "kode": "0360",
        "navn": "Lolland"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194983"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/4990",
    "nr": "4990",
    "navn": "Sakskøbing",
    "stormodtageradresser": null,
    "bbox": [
      11.5155207,
      54.71487463,
      11.80302656,
      54.89001465
    ],
    "visueltcenter": [
      11.63568865,
      54.8143415
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0376",
        "kode": "0376",
        "navn": "Guldborgsund"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "194990"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5000",
    "nr": "5000",
    "navn": "Odense C",
    "stormodtageradresser": null,
    "bbox": [
      10.34894851,
      55.37338238,
      10.45288809,
      55.47000409
    ],
    "visueltcenter": [
      10.39174411,
      55.403021
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0461",
        "kode": "0461",
        "navn": "Odense"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195000"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5200",
    "nr": "5200",
    "navn": "Odense V",
    "stormodtageradresser": null,
    "bbox": [
      10.22552078,
      55.37416867,
      10.36602287,
      55.41778588
    ],
    "visueltcenter": [
      10.32843174,
      55.39401435
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0461",
        "kode": "0461",
        "navn": "Odense"
      }
    ],
    "ændret": "2018-08-13T21:06:24.470Z",
    "geo_ændret": "2018-08-13T21:06:24.470Z",
    "geo_version": 2,
    "dagi_id": "195200"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5210",
    "nr": "5210",
    "navn": "Odense NV",
    "stormodtageradresser": null,
    "bbox": [
      10.22051547,
      55.39934173,
      10.36366466,
      55.44545331
    ],
    "visueltcenter": [
      10.30695242,
      55.41754658
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0461",
        "kode": "0461",
        "navn": "Odense"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-10-30T21:37:34.814Z",
    "geo_version": 2,
    "dagi_id": "195210"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5220",
    "nr": "5220",
    "navn": "Odense SØ",
    "stormodtageradresser": null,
    "bbox": [
      10.41236214,
      55.30982558,
      10.57878526,
      55.40159995
    ],
    "visueltcenter": [
      10.49825565,
      55.34929575
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0461",
        "kode": "0461",
        "navn": "Odense"
      }
    ],
    "ændret": "2020-10-05T21:12:44.055Z",
    "geo_ændret": "2020-10-05T21:12:44.055Z",
    "geo_version": 3,
    "dagi_id": "195220"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5230",
    "nr": "5230",
    "navn": "Odense M",
    "stormodtageradresser": null,
    "bbox": [
      10.37139745,
      55.36272641,
      10.44126743,
      55.39376747
    ],
    "visueltcenter": [
      10.4114235,
      55.37859541
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0461",
        "kode": "0461",
        "navn": "Odense"
      }
    ],
    "ændret": "2020-10-05T21:12:44.055Z",
    "geo_ændret": "2020-10-05T21:12:44.055Z",
    "geo_version": 3,
    "dagi_id": "195230"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5240",
    "nr": "5240",
    "navn": "Odense NØ",
    "stormodtageradresser": null,
    "bbox": [
      10.41659152,
      55.38825395,
      10.50571224,
      55.45802817
    ],
    "visueltcenter": [
      10.44332209,
      55.42123207
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0461",
        "kode": "0461",
        "navn": "Odense"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195240"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5250",
    "nr": "5250",
    "navn": "Odense SV",
    "stormodtageradresser": null,
    "bbox": [
      10.22428578,
      55.30973897,
      10.38419661,
      55.3880061
    ],
    "visueltcenter": [
      10.29791607,
      55.35251254
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0461",
        "kode": "0461",
        "navn": "Odense"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195250"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5260",
    "nr": "5260",
    "navn": "Odense S",
    "stormodtageradresser": null,
    "bbox": [
      10.27862785,
      55.28636361,
      10.45943732,
      55.3730708
    ],
    "visueltcenter": [
      10.39917484,
      55.34939755
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0461",
        "kode": "0461",
        "navn": "Odense"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-04-09T08:23:44.102Z",
    "geo_version": 2,
    "dagi_id": "195260"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5270",
    "nr": "5270",
    "navn": "Odense N",
    "stormodtageradresser": null,
    "bbox": [
      10.25488304,
      55.41128817,
      10.44122465,
      55.48351107
    ],
    "visueltcenter": [
      10.35714765,
      55.44966319
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0461",
        "kode": "0461",
        "navn": "Odense"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0480",
        "kode": "0480",
        "navn": "Nordfyns"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195270"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5290",
    "nr": "5290",
    "navn": "Marslev",
    "stormodtageradresser": null,
    "bbox": [
      10.47845998,
      55.36105566,
      10.57923117,
      55.42646868
    ],
    "visueltcenter": [
      10.53251585,
      55.396433
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0440",
        "kode": "0440",
        "navn": "Kerteminde"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195290"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5300",
    "nr": "5300",
    "navn": "Kerteminde",
    "stormodtageradresser": null,
    "bbox": [
      10.54283312,
      55.38593584,
      10.96086465,
      55.61098116
    ],
    "visueltcenter": [
      10.8508032,
      55.49807432
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0440",
        "kode": "0440",
        "navn": "Kerteminde"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195300"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5320",
    "nr": "5320",
    "navn": "Agedrup",
    "stormodtageradresser": null,
    "bbox": [
      10.45857958,
      55.40907488,
      10.52441299,
      55.45533453
    ],
    "visueltcenter": [
      10.48909454,
      55.43442698
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0461",
        "kode": "0461",
        "navn": "Odense"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195320"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5330",
    "nr": "5330",
    "navn": "Munkebo",
    "stormodtageradresser": null,
    "bbox": [
      10.45321724,
      55.4152277,
      10.59363754,
      55.50316933
    ],
    "visueltcenter": [
      10.53070411,
      55.46833207
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0440",
        "kode": "0440",
        "navn": "Kerteminde"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0461",
        "kode": "0461",
        "navn": "Odense"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195330"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5350",
    "nr": "5350",
    "navn": "Rynkeby",
    "stormodtageradresser": null,
    "bbox": [
      10.57944869,
      55.3861875,
      10.6483297,
      55.42318684
    ],
    "visueltcenter": [
      10.62525952,
      55.40654304
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0440",
        "kode": "0440",
        "navn": "Kerteminde"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195350"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5370",
    "nr": "5370",
    "navn": "Mesinge",
    "stormodtageradresser": null,
    "bbox": [
      10.54820767,
      55.46875732,
      10.70704138,
      55.55280187
    ],
    "visueltcenter": [
      10.64470255,
      55.48986232
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0440",
        "kode": "0440",
        "navn": "Kerteminde"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195370"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5380",
    "nr": "5380",
    "navn": "Dalby",
    "stormodtageradresser": null,
    "bbox": [
      10.61904763,
      55.49833066,
      10.75892342,
      55.53906668
    ],
    "visueltcenter": [
      10.7017613,
      55.51715531
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0440",
        "kode": "0440",
        "navn": "Kerteminde"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195380"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5390",
    "nr": "5390",
    "navn": "Martofte",
    "stormodtageradresser": null,
    "bbox": [
      10.47680669,
      55.52051353,
      10.82357162,
      55.70054676
    ],
    "visueltcenter": [
      10.65770823,
      55.61575822
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0440",
        "kode": "0440",
        "navn": "Kerteminde"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195390"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5400",
    "nr": "5400",
    "navn": "Bogense",
    "stormodtageradresser": null,
    "bbox": [
      9.92173145,
      55.50078647,
      10.30135193,
      55.71403232
    ],
    "visueltcenter": [
      10.15483531,
      55.6129967
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0480",
        "kode": "0480",
        "navn": "Nordfyns"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195400"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5450",
    "nr": "5450",
    "navn": "Otterup",
    "stormodtageradresser": null,
    "bbox": [
      10.23750958,
      55.45606903,
      10.57492281,
      55.69021035
    ],
    "visueltcenter": [
      10.41038685,
      55.56662852
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0480",
        "kode": "0480",
        "navn": "Nordfyns"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195450"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5462",
    "nr": "5462",
    "navn": "Morud",
    "stormodtageradresser": null,
    "bbox": [
      10.12706576,
      55.40501719,
      10.26032701,
      55.46790227
    ],
    "visueltcenter": [
      10.19234031,
      55.43415758
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0480",
        "kode": "0480",
        "navn": "Nordfyns"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-10-30T21:37:34.814Z",
    "geo_version": 2,
    "dagi_id": "195462"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5463",
    "nr": "5463",
    "navn": "Harndrup",
    "stormodtageradresser": null,
    "bbox": [
      9.9742564,
      55.42429526,
      10.08084538,
      55.48845446
    ],
    "visueltcenter": [
      10.02916475,
      55.45477943
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0410",
        "kode": "0410",
        "navn": "Middelfart"
      }
    ],
    "ændret": "2021-01-01T22:14:53.992Z",
    "geo_ændret": "2021-01-01T22:14:53.992Z",
    "geo_version": 3,
    "dagi_id": "195463"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5464",
    "nr": "5464",
    "navn": "Brenderup Fyn",
    "stormodtageradresser": null,
    "bbox": [
      9.91037867,
      55.45537677,
      10.04003937,
      55.56239716
    ],
    "visueltcenter": [
      9.98162512,
      55.49127877
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0410",
        "kode": "0410",
        "navn": "Middelfart"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0480",
        "kode": "0480",
        "navn": "Nordfyns"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195464"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5466",
    "nr": "5466",
    "navn": "Asperup",
    "stormodtageradresser": null,
    "bbox": [
      9.84331833,
      55.47010561,
      9.95530887,
      55.54626475
    ],
    "visueltcenter": [
      9.90219075,
      55.50180178
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0410",
        "kode": "0410",
        "navn": "Middelfart"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-03-09T21:29:17.842Z",
    "geo_version": 2,
    "dagi_id": "195466"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5471",
    "nr": "5471",
    "navn": "Søndersø",
    "stormodtageradresser": null,
    "bbox": [
      10.02742138,
      55.43905083,
      10.31194488,
      55.55478838
    ],
    "visueltcenter": [
      10.08371535,
      55.48691259
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0480",
        "kode": "0480",
        "navn": "Nordfyns"
      }
    ],
    "ændret": "2021-01-01T22:14:53.992Z",
    "geo_ændret": "2021-01-01T22:14:53.992Z",
    "geo_version": 3,
    "dagi_id": "195471"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5474",
    "nr": "5474",
    "navn": "Veflinge",
    "stormodtageradresser": null,
    "bbox": [
      10.09262682,
      55.42401062,
      10.17948578,
      55.49508369
    ],
    "visueltcenter": [
      10.14425747,
      55.46255575
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0480",
        "kode": "0480",
        "navn": "Nordfyns"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195474"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5485",
    "nr": "5485",
    "navn": "Skamby",
    "stormodtageradresser": null,
    "bbox": [
      10.23262778,
      55.49072326,
      10.32977428,
      55.54166512
    ],
    "visueltcenter": [
      10.26859858,
      55.51969426
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0480",
        "kode": "0480",
        "navn": "Nordfyns"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195485"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5491",
    "nr": "5491",
    "navn": "Blommenslyst",
    "stormodtageradresser": null,
    "bbox": [
      10.18231238,
      55.35028109,
      10.2977239,
      55.41956283
    ],
    "visueltcenter": [
      10.22531693,
      55.38883518
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0461",
        "kode": "0461",
        "navn": "Odense"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0480",
        "kode": "0480",
        "navn": "Nordfyns"
      }
    ],
    "ændret": "2018-08-13T21:06:24.470Z",
    "geo_ændret": "2018-08-13T21:06:24.470Z",
    "geo_version": 2,
    "dagi_id": "195491"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5492",
    "nr": "5492",
    "navn": "Vissenbjerg",
    "stormodtageradresser": null,
    "bbox": [
      10.06389252,
      55.35515908,
      10.20661679,
      55.43185776
    ],
    "visueltcenter": [
      10.13229762,
      55.39228422
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0420",
        "kode": "0420",
        "navn": "Assens"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0461",
        "kode": "0461",
        "navn": "Odense"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0480",
        "kode": "0480",
        "navn": "Nordfyns"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195492"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5500",
    "nr": "5500",
    "navn": "Middelfart",
    "stormodtageradresser": null,
    "bbox": [
      9.65350407,
      55.44158204,
      9.93064539,
      55.58790511
    ],
    "visueltcenter": [
      9.78489314,
      55.48934885
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0410",
        "kode": "0410",
        "navn": "Middelfart"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195500"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5540",
    "nr": "5540",
    "navn": "Ullerslev",
    "stormodtageradresser": null,
    "bbox": [
      10.60112687,
      55.2912709,
      10.82547927,
      55.44805605
    ],
    "visueltcenter": [
      10.67264084,
      55.38540272
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0440",
        "kode": "0440",
        "navn": "Kerteminde"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0450",
        "kode": "0450",
        "navn": "Nyborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195540"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5550",
    "nr": "5550",
    "navn": "Langeskov",
    "stormodtageradresser": null,
    "bbox": [
      10.54144269,
      55.32088203,
      10.64714335,
      55.39414134
    ],
    "visueltcenter": [
      10.58947313,
      55.36673317
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0430",
        "kode": "0430",
        "navn": "Faaborg-Midtfyn"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0440",
        "kode": "0440",
        "navn": "Kerteminde"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0450",
        "kode": "0450",
        "navn": "Nyborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195550"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5560",
    "nr": "5560",
    "navn": "Aarup",
    "stormodtageradresser": null,
    "bbox": [
      9.92330884,
      55.32689631,
      10.16154205,
      55.44645544
    ],
    "visueltcenter": [
      10.03851749,
      55.37369367
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0410",
        "kode": "0410",
        "navn": "Middelfart"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0420",
        "kode": "0420",
        "navn": "Assens"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0480",
        "kode": "0480",
        "navn": "Nordfyns"
      }
    ],
    "ændret": "2020-09-23T21:11:42.703Z",
    "geo_ændret": "2020-09-23T21:11:42.703Z",
    "geo_version": 2,
    "dagi_id": "195560"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5580",
    "nr": "5580",
    "navn": "Nørre Aaby",
    "stormodtageradresser": null,
    "bbox": [
      9.69853705,
      55.38039597,
      9.94306884,
      55.48500642
    ],
    "visueltcenter": [
      9.77417672,
      55.41549178
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0410",
        "kode": "0410",
        "navn": "Middelfart"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-03-09T21:29:17.842Z",
    "geo_version": 2,
    "dagi_id": "195580"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5591",
    "nr": "5591",
    "navn": "Gelsted",
    "stormodtageradresser": null,
    "bbox": [
      9.87529331,
      55.35334033,
      10.00581522,
      55.43818804
    ],
    "visueltcenter": [
      9.96614107,
      55.39954114
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0410",
        "kode": "0410",
        "navn": "Middelfart"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195591"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5592",
    "nr": "5592",
    "navn": "Ejby",
    "stormodtageradresser": null,
    "bbox": [
      9.76044098,
      55.33572464,
      10.00029096,
      55.47338749
    ],
    "visueltcenter": [
      9.82415972,
      55.36518649
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0410",
        "kode": "0410",
        "navn": "Middelfart"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195592"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5600",
    "nr": "5600",
    "navn": "Faaborg",
    "stormodtageradresser": null,
    "bbox": [
      9.96877195,
      55.02571796,
      10.41138117,
      55.20230769
    ],
    "visueltcenter": [
      10.30952407,
      55.11203154
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0430",
        "kode": "0430",
        "navn": "Faaborg-Midtfyn"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2017-10-02T21:13:55.995Z",
    "geo_version": 4,
    "dagi_id": "195600"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5601",
    "nr": "5601",
    "navn": "Lyø",
    "stormodtageradresser": null,
    "bbox": [
      10.04334019,
      54.98097425,
      10.24075751,
      55.0722716
    ],
    "visueltcenter": [
      10.1289297,
      55.02895047
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0430",
        "kode": "0430",
        "navn": "Faaborg-Midtfyn"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2017-10-02T21:13:55.995Z",
    "geo_version": 1,
    "dagi_id": "378463"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5602",
    "nr": "5602",
    "navn": "Avernakø",
    "stormodtageradresser": null,
    "bbox": [
      10.19725513,
      54.97317031,
      10.37723997,
      55.05387857
    ],
    "visueltcenter": [
      10.2827681,
      55.00930386
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0430",
        "kode": "0430",
        "navn": "Faaborg-Midtfyn"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2017-06-01T21:16:01.264Z",
    "geo_version": 1,
    "dagi_id": "378121"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5603",
    "nr": "5603",
    "navn": "Bjørnø",
    "stormodtageradresser": null,
    "bbox": [
      10.22670321,
      55.04561519,
      10.29172244,
      55.07914477
    ],
    "visueltcenter": [
      10.25360285,
      55.06201633
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0430",
        "kode": "0430",
        "navn": "Faaborg-Midtfyn"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2017-05-01T21:13:25.676Z",
    "geo_version": 1,
    "dagi_id": "377938"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5610",
    "nr": "5610",
    "navn": "Assens",
    "stormodtageradresser": null,
    "bbox": [
      9.65845769,
      55.17560739,
      10.05726618,
      55.40608712
    ],
    "visueltcenter": [
      9.8932785,
      55.29165859
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0410",
        "kode": "0410",
        "navn": "Middelfart"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0420",
        "kode": "0420",
        "navn": "Assens"
      }
    ],
    "ændret": "2018-10-08T21:09:24.676Z",
    "geo_ændret": "2018-10-08T21:09:24.676Z",
    "geo_version": 2,
    "dagi_id": "195610"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5620",
    "nr": "5620",
    "navn": "Glamsbjerg",
    "stormodtageradresser": null,
    "bbox": [
      10.0068247,
      55.21991594,
      10.17827149,
      55.34798115
    ],
    "visueltcenter": [
      10.10343663,
      55.27213799
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0420",
        "kode": "0420",
        "navn": "Assens"
      }
    ],
    "ændret": "2020-09-23T21:11:42.703Z",
    "geo_ændret": "2020-09-23T21:11:42.703Z",
    "geo_version": 3,
    "dagi_id": "195620"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5631",
    "nr": "5631",
    "navn": "Ebberup",
    "stormodtageradresser": null,
    "bbox": [
      9.81517949,
      55.07260448,
      10.06885962,
      55.26934075
    ],
    "visueltcenter": [
      9.96382534,
      55.13177189
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0420",
        "kode": "0420",
        "navn": "Assens"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195631"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5642",
    "nr": "5642",
    "navn": "Millinge",
    "stormodtageradresser": null,
    "bbox": [
      10.05467345,
      55.11464356,
      10.2389449,
      55.17967837
    ],
    "visueltcenter": [
      10.12933606,
      55.15062316
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0420",
        "kode": "0420",
        "navn": "Assens"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0430",
        "kode": "0430",
        "navn": "Faaborg-Midtfyn"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195642"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5672",
    "nr": "5672",
    "navn": "Broby",
    "stormodtageradresser": null,
    "bbox": [
      10.16700867,
      55.15164283,
      10.3614458,
      55.29886833
    ],
    "visueltcenter": [
      10.25211529,
      55.24506199
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0430",
        "kode": "0430",
        "navn": "Faaborg-Midtfyn"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195672"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5683",
    "nr": "5683",
    "navn": "Haarby",
    "stormodtageradresser": null,
    "bbox": [
      9.94413738,
      55.15178978,
      10.22625912,
      55.24881393
    ],
    "visueltcenter": [
      10.07032627,
      55.18924968
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0420",
        "kode": "0420",
        "navn": "Assens"
      }
    ],
    "ændret": "2018-09-04T21:05:17.039Z",
    "geo_ændret": "2018-09-04T21:05:17.039Z",
    "geo_version": 2,
    "dagi_id": "195683"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5690",
    "nr": "5690",
    "navn": "Tommerup",
    "stormodtageradresser": null,
    "bbox": [
      10.11765294,
      55.26387394,
      10.29456308,
      55.36822305
    ],
    "visueltcenter": [
      10.19834407,
      55.3195544
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0420",
        "kode": "0420",
        "navn": "Assens"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195690"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5700",
    "nr": "5700",
    "navn": "Svendborg",
    "stormodtageradresser": null,
    "bbox": [
      10.29551536,
      54.92792852,
      10.78016497,
      55.13871419
    ],
    "visueltcenter": [
      10.59802878,
      55.01443666
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0479",
        "kode": "0479",
        "navn": "Svendborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195700"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5750",
    "nr": "5750",
    "navn": "Ringe",
    "stormodtageradresser": null,
    "bbox": [
      10.28533543,
      55.11343263,
      10.58933562,
      55.31441881
    ],
    "visueltcenter": [
      10.42313811,
      55.2209888
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0430",
        "kode": "0430",
        "navn": "Faaborg-Midtfyn"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195750"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5762",
    "nr": "5762",
    "navn": "Vester Skerninge",
    "stormodtageradresser": null,
    "bbox": [
      10.36836144,
      55.00898512,
      10.54023964,
      55.12035202
    ],
    "visueltcenter": [
      10.45011017,
      55.06342876
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0430",
        "kode": "0430",
        "navn": "Faaborg-Midtfyn"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0479",
        "kode": "0479",
        "navn": "Svendborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195762"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5771",
    "nr": "5771",
    "navn": "Stenstrup",
    "stormodtageradresser": null,
    "bbox": [
      10.40778544,
      55.08284113,
      10.60425324,
      55.16753312
    ],
    "visueltcenter": [
      10.52352287,
      55.12556284
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0430",
        "kode": "0430",
        "navn": "Faaborg-Midtfyn"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0479",
        "kode": "0479",
        "navn": "Svendborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195771"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5772",
    "nr": "5772",
    "navn": "Kværndrup",
    "stormodtageradresser": null,
    "bbox": [
      10.4531578,
      55.1478033,
      10.62583681,
      55.21932114
    ],
    "visueltcenter": [
      10.54250568,
      55.18424637
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0430",
        "kode": "0430",
        "navn": "Faaborg-Midtfyn"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0479",
        "kode": "0479",
        "navn": "Svendborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-08-25T20:22:34.901Z",
    "geo_version": 2,
    "dagi_id": "195772"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5792",
    "nr": "5792",
    "navn": "Årslev",
    "stormodtageradresser": null,
    "bbox": [
      10.33240106,
      55.25768495,
      10.55625601,
      55.33456467
    ],
    "visueltcenter": [
      10.3936597,
      55.2906707
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0430",
        "kode": "0430",
        "navn": "Faaborg-Midtfyn"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195792"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5800",
    "nr": "5800",
    "navn": "Nyborg",
    "stormodtageradresser": null,
    "bbox": [
      10.6858601,
      55.25231834,
      10.9662614,
      55.44508925
    ],
    "visueltcenter": [
      10.79790443,
      55.35301317
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0450",
        "kode": "0450",
        "navn": "Nyborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195800"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5853",
    "nr": "5853",
    "navn": "Ørbæk",
    "stormodtageradresser": null,
    "bbox": [
      10.52897631,
      55.18322231,
      10.73253282,
      55.3321465
    ],
    "visueltcenter": [
      10.63473931,
      55.2661035
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0430",
        "kode": "0430",
        "navn": "Faaborg-Midtfyn"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0450",
        "kode": "0450",
        "navn": "Nyborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195853"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5854",
    "nr": "5854",
    "navn": "Gislev",
    "stormodtageradresser": null,
    "bbox": [
      10.54350322,
      55.18720827,
      10.66243221,
      55.23960531
    ],
    "visueltcenter": [
      10.60886979,
      55.2122797
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0430",
        "kode": "0430",
        "navn": "Faaborg-Midtfyn"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0479",
        "kode": "0479",
        "navn": "Svendborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-08-25T20:22:34.901Z",
    "geo_version": 2,
    "dagi_id": "195854"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5856",
    "nr": "5856",
    "navn": "Ryslinge",
    "stormodtageradresser": null,
    "bbox": [
      10.5086107,
      55.22261189,
      10.60029311,
      55.2609759
    ],
    "visueltcenter": [
      10.54368086,
      55.23725238
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0430",
        "kode": "0430",
        "navn": "Faaborg-Midtfyn"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195856"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5863",
    "nr": "5863",
    "navn": "Ferritslev Fyn",
    "stormodtageradresser": null,
    "bbox": [
      10.54400468,
      55.29062838,
      10.61529475,
      55.32589619
    ],
    "visueltcenter": [
      10.58601195,
      55.31045491
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0430",
        "kode": "0430",
        "navn": "Faaborg-Midtfyn"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195863"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5871",
    "nr": "5871",
    "navn": "Frørup",
    "stormodtageradresser": null,
    "bbox": [
      10.69126497,
      55.19974987,
      10.87346099,
      55.27414612
    ],
    "visueltcenter": [
      10.81307692,
      55.23864739
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0450",
        "kode": "0450",
        "navn": "Nyborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195871"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5874",
    "nr": "5874",
    "navn": "Hesselager",
    "stormodtageradresser": null,
    "bbox": [
      10.67494538,
      55.11818798,
      11.02330961,
      55.26714293
    ],
    "visueltcenter": [
      10.79995852,
      55.17539817
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0450",
        "kode": "0450",
        "navn": "Nyborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0479",
        "kode": "0479",
        "navn": "Svendborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-11-02T21:45:22.952Z",
    "geo_version": 2,
    "dagi_id": "195874"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5881",
    "nr": "5881",
    "navn": "Skårup Fyn",
    "stormodtageradresser": null,
    "bbox": [
      10.63492565,
      55.03732768,
      10.8103113,
      55.11482007
    ],
    "visueltcenter": [
      10.76263715,
      55.06735111
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0479",
        "kode": "0479",
        "navn": "Svendborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195881"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5882",
    "nr": "5882",
    "navn": "Vejstrup",
    "stormodtageradresser": null,
    "bbox": [
      10.61268548,
      55.07442567,
      10.82975624,
      55.1317017
    ],
    "visueltcenter": [
      10.79980106,
      55.09510217
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0479",
        "kode": "0479",
        "navn": "Svendborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-01-19T21:35:42.201Z",
    "geo_version": 3,
    "dagi_id": "195882"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5883",
    "nr": "5883",
    "navn": "Oure",
    "stormodtageradresser": null,
    "bbox": [
      10.66089541,
      55.10467628,
      10.83366989,
      55.14068754
    ],
    "visueltcenter": [
      10.77337328,
      55.11784585
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0479",
        "kode": "0479",
        "navn": "Svendborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-11-02T21:45:22.952Z",
    "geo_version": 2,
    "dagi_id": "195883"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5884",
    "nr": "5884",
    "navn": "Gudme",
    "stormodtageradresser": null,
    "bbox": [
      10.67295783,
      55.13099458,
      10.75249223,
      55.18611694
    ],
    "visueltcenter": [
      10.7120614,
      55.1496317
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0479",
        "kode": "0479",
        "navn": "Svendborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195884"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5892",
    "nr": "5892",
    "navn": "Gudbjerg Sydfyn",
    "stormodtageradresser": null,
    "bbox": [
      10.58512784,
      55.12301073,
      10.69485222,
      55.195758
    ],
    "visueltcenter": [
      10.63638217,
      55.15512414
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0479",
        "kode": "0479",
        "navn": "Svendborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-01-19T21:35:42.201Z",
    "geo_version": 3,
    "dagi_id": "195892"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5900",
    "nr": "5900",
    "navn": "Rudkøbing",
    "stormodtageradresser": null,
    "bbox": [
      10.61333287,
      54.80108556,
      10.9312906,
      54.98034588
    ],
    "visueltcenter": [
      10.80968538,
      54.88518842
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0482",
        "kode": "0482",
        "navn": "Langeland"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2017-05-01T21:13:25.676Z",
    "geo_version": 2,
    "dagi_id": "195900"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5932",
    "nr": "5932",
    "navn": "Humble",
    "stormodtageradresser": null,
    "bbox": [
      10.52192113,
      54.75161913,
      10.87355934,
      54.87158494
    ],
    "visueltcenter": [
      10.63436277,
      54.81821541
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0482",
        "kode": "0482",
        "navn": "Langeland"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195932"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5935",
    "nr": "5935",
    "navn": "Bagenkop",
    "stormodtageradresser": null,
    "bbox": [
      10.36935747,
      54.53838447,
      10.90829863,
      54.78262536
    ],
    "visueltcenter": [
      10.66497262,
      54.65753168
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0482",
        "kode": "0482",
        "navn": "Langeland"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195935"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5943",
    "nr": "5943",
    "navn": "Strynø",
    "stormodtageradresser": null,
    "bbox": [
      10.52882803,
      54.86816565,
      10.64787756,
      54.94102536
    ],
    "visueltcenter": [
      10.58807706,
      54.90089651
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0482",
        "kode": "0482",
        "navn": "Langeland"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2017-05-01T21:13:25.676Z",
    "geo_version": 1,
    "dagi_id": "377936"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5953",
    "nr": "5953",
    "navn": "Tranekær",
    "stormodtageradresser": null,
    "bbox": [
      10.7200531,
      54.91228321,
      11.10323945,
      55.2396206
    ],
    "visueltcenter": [
      10.94268354,
      55.06361655
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0482",
        "kode": "0482",
        "navn": "Langeland"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2017-05-01T21:13:25.676Z",
    "geo_version": 2,
    "dagi_id": "195953"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5960",
    "nr": "5960",
    "navn": "Marstal",
    "stormodtageradresser": null,
    "bbox": [
      10.4278717,
      54.76218014,
      10.57063769,
      54.94374057
    ],
    "visueltcenter": [
      10.51672958,
      54.85672022
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0492",
        "kode": "0492",
        "navn": "Ærø"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2017-07-01T21:13:54.123Z",
    "geo_version": 2,
    "dagi_id": "195960"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5965",
    "nr": "5965",
    "navn": "Birkholm",
    "stormodtageradresser": null,
    "bbox": [
      10.47333257,
      54.91849916,
      10.5509204,
      54.94942403
    ],
    "visueltcenter": [
      10.50923834,
      54.9345412
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0492",
        "kode": "0492",
        "navn": "Ærø"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2017-07-01T21:13:54.123Z",
    "geo_version": 1,
    "dagi_id": "378214"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5970",
    "nr": "5970",
    "navn": "Ærøskøbing",
    "stormodtageradresser": null,
    "bbox": [
      10.17488894,
      54.61670513,
      10.53176512,
      54.94561817
    ],
    "visueltcenter": [
      10.33601694,
      54.79716403
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0492",
        "kode": "0492",
        "navn": "Ærø"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195970"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/5985",
    "nr": "5985",
    "navn": "Søby Ærø",
    "stormodtageradresser": null,
    "bbox": [
      10.10633582,
      54.86538789,
      10.36111896,
      55.00608855
    ],
    "visueltcenter": [
      10.21809442,
      54.94375791
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0492",
        "kode": "0492",
        "navn": "Ærø"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "195985"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6000",
    "nr": "6000",
    "navn": "Kolding",
    "stormodtageradresser": null,
    "bbox": [
      9.35455648,
      55.41134277,
      9.60414105,
      55.57502984
    ],
    "visueltcenter": [
      9.46847631,
      55.50221297
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0621",
        "kode": "0621",
        "navn": "Kolding"
      }
    ],
    "ændret": "2021-02-02T22:16:56.070Z",
    "geo_ændret": "2021-02-02T22:16:56.070Z",
    "geo_version": 6,
    "dagi_id": "196000"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6040",
    "nr": "6040",
    "navn": "Egtved",
    "stormodtageradresser": null,
    "bbox": [
      9.18466637,
      55.53514854,
      9.49886928,
      55.67190567
    ],
    "visueltcenter": [
      9.29089234,
      55.61664144
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0621",
        "kode": "0621",
        "navn": "Kolding"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0630",
        "kode": "0630",
        "navn": "Vejle"
      }
    ],
    "ændret": "2021-01-01T22:14:53.992Z",
    "geo_ændret": "2021-01-01T22:14:53.992Z",
    "geo_version": 3,
    "dagi_id": "196040"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6051",
    "nr": "6051",
    "navn": "Almind",
    "stormodtageradresser": null,
    "bbox": [
      9.42140533,
      55.53984276,
      9.55950327,
      55.58142584
    ],
    "visueltcenter": [
      9.46401838,
      55.55907816
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0621",
        "kode": "0621",
        "navn": "Kolding"
      }
    ],
    "ændret": "2021-02-02T22:16:56.070Z",
    "geo_ændret": "2021-02-02T22:16:56.070Z",
    "geo_version": 3,
    "dagi_id": "196051"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6052",
    "nr": "6052",
    "navn": "Viuf",
    "stormodtageradresser": null,
    "bbox": [
      9.44850093,
      55.56752606,
      9.53698816,
      55.64136834
    ],
    "visueltcenter": [
      9.49835852,
      55.58677181
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0621",
        "kode": "0621",
        "navn": "Kolding"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0630",
        "kode": "0630",
        "navn": "Vejle"
      }
    ],
    "ændret": "2021-01-01T22:14:53.992Z",
    "geo_ændret": "2021-01-01T22:14:53.992Z",
    "geo_version": 3,
    "dagi_id": "196052"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6064",
    "nr": "6064",
    "navn": "Jordrup",
    "stormodtageradresser": null,
    "bbox": [
      9.26197756,
      55.51291554,
      9.36828771,
      55.58577026
    ],
    "visueltcenter": [
      9.3183142,
      55.55380577
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0621",
        "kode": "0621",
        "navn": "Kolding"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-01-19T21:35:42.201Z",
    "geo_version": 2,
    "dagi_id": "196064"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6070",
    "nr": "6070",
    "navn": "Christiansfeld",
    "stormodtageradresser": null,
    "bbox": [
      9.30207346,
      55.30611528,
      9.58982956,
      55.42476008
    ],
    "visueltcenter": [
      9.44750863,
      55.35487274
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0510",
        "kode": "0510",
        "navn": "Haderslev"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0621",
        "kode": "0621",
        "navn": "Kolding"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-01-19T21:35:42.201Z",
    "geo_version": 2,
    "dagi_id": "196070"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6091",
    "nr": "6091",
    "navn": "Bjert",
    "stormodtageradresser": null,
    "bbox": [
      9.51101128,
      55.41741602,
      9.61826627,
      55.50162519
    ],
    "visueltcenter": [
      9.55990342,
      55.44548525
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0621",
        "kode": "0621",
        "navn": "Kolding"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196091"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6092",
    "nr": "6092",
    "navn": "Sønder Stenderup",
    "stormodtageradresser": null,
    "bbox": [
      9.58941678,
      55.4021071,
      9.71464251,
      55.50527637
    ],
    "visueltcenter": [
      9.65720072,
      55.44711912
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0621",
        "kode": "0621",
        "navn": "Kolding"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196092"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6093",
    "nr": "6093",
    "navn": "Sjølund",
    "stormodtageradresser": null,
    "bbox": [
      9.49706778,
      55.37575548,
      9.62098547,
      55.43368205
    ],
    "visueltcenter": [
      9.53828204,
      55.4068472
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0621",
        "kode": "0621",
        "navn": "Kolding"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-01-19T21:35:42.201Z",
    "geo_version": 2,
    "dagi_id": "196093"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6094",
    "nr": "6094",
    "navn": "Hejls",
    "stormodtageradresser": null,
    "bbox": [
      9.54046278,
      55.35205295,
      9.6873117,
      55.41648383
    ],
    "visueltcenter": [
      9.62026417,
      55.38893478
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0510",
        "kode": "0510",
        "navn": "Haderslev"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0621",
        "kode": "0621",
        "navn": "Kolding"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-01-19T21:35:42.201Z",
    "geo_version": 2,
    "dagi_id": "196094"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6100",
    "nr": "6100",
    "navn": "Haderslev",
    "stormodtageradresser": null,
    "bbox": [
      9.39561944,
      55.11666339,
      9.82739702,
      55.371969
    ],
    "visueltcenter": [
      9.61996216,
      55.24192755
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0510",
        "kode": "0510",
        "navn": "Haderslev"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0621",
        "kode": "0621",
        "navn": "Kolding"
      }
    ],
    "ændret": "2018-10-08T21:09:24.676Z",
    "geo_ændret": "2018-10-08T21:09:24.676Z",
    "geo_version": 2,
    "dagi_id": "196100"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6200",
    "nr": "6200",
    "navn": "Aabenraa",
    "stormodtageradresser": null,
    "bbox": [
      9.27317051,
      54.90676818,
      9.63932784,
      55.12458135
    ],
    "visueltcenter": [
      9.49975045,
      55.02899493
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0580",
        "kode": "0580",
        "navn": "Aabenraa"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2018-01-16T22:10:42.686Z",
    "geo_version": 3,
    "dagi_id": "196200"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6210",
    "nr": "6210",
    "navn": "Barsø",
    "stormodtageradresser": null,
    "bbox": [
      9.52881243,
      55.09038707,
      9.65760351,
      55.15183953
    ],
    "visueltcenter": [
      9.58941416,
      55.1226369
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0580",
        "kode": "0580",
        "navn": "Aabenraa"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2017-05-01T21:13:25.676Z",
    "geo_version": 1,
    "dagi_id": "377934"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6230",
    "nr": "6230",
    "navn": "Rødekro",
    "stormodtageradresser": null,
    "bbox": [
      9.11217749,
      55.00470583,
      9.47903503,
      55.16831972
    ],
    "visueltcenter": [
      9.28037618,
      55.07437317
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0580",
        "kode": "0580",
        "navn": "Aabenraa"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196230"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6240",
    "nr": "6240",
    "navn": "Løgumkloster",
    "stormodtageradresser": null,
    "bbox": [
      8.8649784,
      54.9668049,
      9.14137362,
      55.13549769
    ],
    "visueltcenter": [
      8.96415413,
      55.06237954
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0550",
        "kode": "0550",
        "navn": "Tønder"
      }
    ],
    "ændret": "2020-08-27T21:10:28.421Z",
    "geo_ændret": "2020-08-27T21:10:28.421Z",
    "geo_version": 4,
    "dagi_id": "196240"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6261",
    "nr": "6261",
    "navn": "Bredebro",
    "stormodtageradresser": null,
    "bbox": [
      8.58758802,
      54.98884267,
      8.87493167,
      55.16084043
    ],
    "visueltcenter": [
      8.68091354,
      55.07311357
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0550",
        "kode": "0550",
        "navn": "Tønder"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-01-19T21:35:42.201Z",
    "geo_version": 2,
    "dagi_id": "196261"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6270",
    "nr": "6270",
    "navn": "Tønder",
    "stormodtageradresser": null,
    "bbox": [
      8.74118417,
      54.88109517,
      9.04121386,
      55.03751238
    ],
    "visueltcenter": [
      8.89453488,
      54.95248429
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0550",
        "kode": "0550",
        "navn": "Tønder"
      }
    ],
    "ændret": "2020-08-27T21:10:28.421Z",
    "geo_ændret": "2020-08-27T21:10:28.421Z",
    "geo_version": 5,
    "dagi_id": "196270"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6280",
    "nr": "6280",
    "navn": "Højer",
    "stormodtageradresser": null,
    "bbox": [
      8.50169643,
      54.89182983,
      8.82761659,
      55.05576112
    ],
    "visueltcenter": [
      8.65269961,
      54.97473424
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0550",
        "kode": "0550",
        "navn": "Tønder"
      }
    ],
    "ændret": "2020-06-29T21:08:26.329Z",
    "geo_ændret": "2020-06-29T21:08:26.329Z",
    "geo_version": 2,
    "dagi_id": "196280"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6300",
    "nr": "6300",
    "navn": "Gråsten",
    "stormodtageradresser": null,
    "bbox": [
      9.46169268,
      54.86264803,
      9.64017799,
      54.96300025
    ],
    "visueltcenter": [
      9.55791076,
      54.91730187
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0540",
        "kode": "0540",
        "navn": "Sønderborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0580",
        "kode": "0580",
        "navn": "Aabenraa"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196300"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6310",
    "nr": "6310",
    "navn": "Broager",
    "stormodtageradresser": null,
    "bbox": [
      9.60525127,
      54.82276984,
      9.8346056,
      54.92444646
    ],
    "visueltcenter": [
      9.68079394,
      54.8713804
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0540",
        "kode": "0540",
        "navn": "Sønderborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-01-19T21:35:42.201Z",
    "geo_version": 2,
    "dagi_id": "196310"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6320",
    "nr": "6320",
    "navn": "Egernsund",
    "stormodtageradresser": null,
    "bbox": [
      9.59112251,
      54.88176326,
      9.64322815,
      54.92155623
    ],
    "visueltcenter": [
      9.61059297,
      54.90003298
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0540",
        "kode": "0540",
        "navn": "Sønderborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-01-19T21:35:42.201Z",
    "geo_version": 2,
    "dagi_id": "196320"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6330",
    "nr": "6330",
    "navn": "Padborg",
    "stormodtageradresser": null,
    "bbox": [
      9.23140549,
      54.79994762,
      9.43577321,
      54.92598349
    ],
    "visueltcenter": [
      9.30983473,
      54.84549059
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0580",
        "kode": "0580",
        "navn": "Aabenraa"
      }
    ],
    "ændret": "2020-06-29T21:08:26.329Z",
    "geo_ændret": "2020-06-29T21:08:26.329Z",
    "geo_version": 4,
    "dagi_id": "196330"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6340",
    "nr": "6340",
    "navn": "Kruså",
    "stormodtageradresser": null,
    "bbox": [
      9.37187939,
      54.82656749,
      9.54048051,
      54.90910122
    ],
    "visueltcenter": [
      9.46328593,
      54.8687306
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0580",
        "kode": "0580",
        "navn": "Aabenraa"
      }
    ],
    "ændret": "2020-06-29T21:08:26.329Z",
    "geo_ændret": "2020-06-29T21:08:26.329Z",
    "geo_version": 3,
    "dagi_id": "196340"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6360",
    "nr": "6360",
    "navn": "Tinglev",
    "stormodtageradresser": null,
    "bbox": [
      9.10715995,
      54.84967661,
      9.36995396,
      54.99729652
    ],
    "visueltcenter": [
      9.20354055,
      54.90310801
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0580",
        "kode": "0580",
        "navn": "Aabenraa"
      }
    ],
    "ændret": "2020-06-29T21:08:26.329Z",
    "geo_ændret": "2020-06-29T21:08:26.329Z",
    "geo_version": 3,
    "dagi_id": "196360"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6372",
    "nr": "6372",
    "navn": "Bylderup-Bov",
    "stormodtageradresser": null,
    "bbox": [
      9.00494291,
      54.8700452,
      9.2216849,
      55.05738556
    ],
    "visueltcenter": [
      9.14165594,
      55.01708464
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0580",
        "kode": "0580",
        "navn": "Aabenraa"
      }
    ],
    "ændret": "2020-06-29T21:08:26.329Z",
    "geo_ændret": "2020-06-29T21:08:26.329Z",
    "geo_version": 3,
    "dagi_id": "196372"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6392",
    "nr": "6392",
    "navn": "Bolderslev",
    "stormodtageradresser": null,
    "bbox": [
      9.16674475,
      54.95492994,
      9.36548009,
      55.02944298
    ],
    "visueltcenter": [
      9.20811159,
      54.9843364
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0580",
        "kode": "0580",
        "navn": "Aabenraa"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-03-15T21:27:32.466Z",
    "geo_version": 2,
    "dagi_id": "196392"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6400",
    "nr": "6400",
    "navn": "Sønderborg",
    "stormodtageradresser": null,
    "bbox": [
      9.58073951,
      54.85683123,
      9.87882269,
      55.02224606
    ],
    "visueltcenter": [
      9.71079556,
      54.9528525
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0540",
        "kode": "0540",
        "navn": "Sønderborg"
      }
    ],
    "ændret": "2018-10-08T21:09:24.676Z",
    "geo_ændret": "2018-10-08T21:09:24.676Z",
    "geo_version": 2,
    "dagi_id": "196400"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6430",
    "nr": "6430",
    "navn": "Nordborg",
    "stormodtageradresser": null,
    "bbox": [
      9.59673354,
      54.96609915,
      10.00073977,
      55.16087669
    ],
    "visueltcenter": [
      9.77014411,
      55.06940698
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0540",
        "kode": "0540",
        "navn": "Sønderborg"
      }
    ],
    "ændret": "2019-02-01T22:12:27.978Z",
    "geo_ændret": "2019-02-01T22:12:27.978Z",
    "geo_version": 3,
    "dagi_id": "196430"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6440",
    "nr": "6440",
    "navn": "Augustenborg",
    "stormodtageradresser": null,
    "bbox": [
      9.81417259,
      54.93292713,
      10.11440221,
      55.05468296
    ],
    "visueltcenter": [
      10.00776445,
      54.98937381
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0540",
        "kode": "0540",
        "navn": "Sønderborg"
      }
    ],
    "ændret": "2019-02-26T22:13:28.040Z",
    "geo_ændret": "2019-02-26T22:13:28.040Z",
    "geo_version": 5,
    "dagi_id": "196440"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6470",
    "nr": "6470",
    "navn": "Sydals",
    "stormodtageradresser": null,
    "bbox": [
      9.81867975,
      54.7575004,
      10.19984277,
      54.97237184
    ],
    "visueltcenter": [
      10.03587811,
      54.87308589
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0540",
        "kode": "0540",
        "navn": "Sønderborg"
      }
    ],
    "ændret": "2018-10-08T21:09:24.676Z",
    "geo_ændret": "2018-10-08T21:09:24.676Z",
    "geo_version": 2,
    "dagi_id": "196470"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6500",
    "nr": "6500",
    "navn": "Vojens",
    "stormodtageradresser": null,
    "bbox": [
      9.07480215,
      55.13055068,
      9.42449892,
      55.31654855
    ],
    "visueltcenter": [
      9.31835235,
      55.2436827
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0510",
        "kode": "0510",
        "navn": "Haderslev"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196500"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6510",
    "nr": "6510",
    "navn": "Gram",
    "stormodtageradresser": null,
    "bbox": [
      8.88500234,
      55.19171266,
      9.11670806,
      55.33801066
    ],
    "visueltcenter": [
      9.01601909,
      55.27645397
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0510",
        "kode": "0510",
        "navn": "Haderslev"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196510"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6520",
    "nr": "6520",
    "navn": "Toftlund",
    "stormodtageradresser": null,
    "bbox": [
      8.88479924,
      55.12834888,
      9.15525869,
      55.24168389
    ],
    "visueltcenter": [
      9.0451996,
      55.18306206
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0510",
        "kode": "0510",
        "navn": "Haderslev"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0550",
        "kode": "0550",
        "navn": "Tønder"
      }
    ],
    "ændret": "2021-01-01T22:14:53.992Z",
    "geo_ændret": "2021-01-01T22:14:53.992Z",
    "geo_version": 3,
    "dagi_id": "196520"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6534",
    "nr": "6534",
    "navn": "Agerskov",
    "stormodtageradresser": null,
    "bbox": [
      9.08753304,
      55.09019911,
      9.24987256,
      55.17489775
    ],
    "visueltcenter": [
      9.15854853,
      55.12832485
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0510",
        "kode": "0510",
        "navn": "Haderslev"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0550",
        "kode": "0550",
        "navn": "Tønder"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0580",
        "kode": "0580",
        "navn": "Aabenraa"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196534"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6535",
    "nr": "6535",
    "navn": "Branderup J",
    "stormodtageradresser": null,
    "bbox": [
      8.98178015,
      55.08318849,
      9.14559523,
      55.14501131
    ],
    "visueltcenter": [
      9.05916508,
      55.12085206
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0550",
        "kode": "0550",
        "navn": "Tønder"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196535"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6541",
    "nr": "6541",
    "navn": "Bevtoft",
    "stormodtageradresser": null,
    "bbox": [
      9.10505235,
      55.15210635,
      9.2560291,
      55.23489328
    ],
    "visueltcenter": [
      9.19750089,
      55.19588025
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0510",
        "kode": "0510",
        "navn": "Haderslev"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0550",
        "kode": "0550",
        "navn": "Tønder"
      }
    ],
    "ændret": "2021-01-01T22:14:53.992Z",
    "geo_ændret": "2021-01-01T22:14:53.992Z",
    "geo_version": 3,
    "dagi_id": "196541"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6560",
    "nr": "6560",
    "navn": "Sommersted",
    "stormodtageradresser": null,
    "bbox": [
      9.17957176,
      55.29006577,
      9.36540959,
      55.38328892
    ],
    "visueltcenter": [
      9.26072776,
      55.32447339
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0510",
        "kode": "0510",
        "navn": "Haderslev"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0621",
        "kode": "0621",
        "navn": "Kolding"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196560"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6580",
    "nr": "6580",
    "navn": "Vamdrup",
    "stormodtageradresser": null,
    "bbox": [
      9.21472988,
      55.3681849,
      9.45741603,
      55.46410788
    ],
    "visueltcenter": [
      9.34398711,
      55.41366579
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0621",
        "kode": "0621",
        "navn": "Kolding"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196580"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6600",
    "nr": "6600",
    "navn": "Vejen",
    "stormodtageradresser": null,
    "bbox": [
      9.03274125,
      55.43195312,
      9.30420206,
      55.59561733
    ],
    "visueltcenter": [
      9.11204215,
      55.4784284
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0575",
        "kode": "0575",
        "navn": "Vejen"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-03-15T21:27:32.466Z",
    "geo_version": 2,
    "dagi_id": "196600"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6621",
    "nr": "6621",
    "navn": "Gesten",
    "stormodtageradresser": null,
    "bbox": [
      9.14241467,
      55.5066268,
      9.2668009,
      55.56095316
    ],
    "visueltcenter": [
      9.1837296,
      55.53607493
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0575",
        "kode": "0575",
        "navn": "Vejen"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196621"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6622",
    "nr": "6622",
    "navn": "Bække",
    "stormodtageradresser": null,
    "bbox": [
      9.07771244,
      55.53373231,
      9.23237387,
      55.61905327
    ],
    "visueltcenter": [
      9.15666611,
      55.58270689
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0575",
        "kode": "0575",
        "navn": "Vejen"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196622"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6623",
    "nr": "6623",
    "navn": "Vorbasse",
    "stormodtageradresser": null,
    "bbox": [
      8.97006913,
      55.59340379,
      9.18910743,
      55.70431915
    ],
    "visueltcenter": [
      9.09958348,
      55.63499139
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0530",
        "kode": "0530",
        "navn": "Billund"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0575",
        "kode": "0575",
        "navn": "Vejen"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0630",
        "kode": "0630",
        "navn": "Vejle"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196623"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6630",
    "nr": "6630",
    "navn": "Rødding",
    "stormodtageradresser": null,
    "bbox": [
      8.87315633,
      55.28920649,
      9.28899869,
      55.45122345
    ],
    "visueltcenter": [
      9.14057169,
      55.38472069
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0510",
        "kode": "0510",
        "navn": "Haderslev"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0575",
        "kode": "0575",
        "navn": "Vejen"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196630"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6640",
    "nr": "6640",
    "navn": "Lunderskov",
    "stormodtageradresser": null,
    "bbox": [
      9.25253682,
      55.44610822,
      9.40485569,
      55.56217217
    ],
    "visueltcenter": [
      9.32882435,
      55.4763505
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0621",
        "kode": "0621",
        "navn": "Kolding"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-03-15T21:27:32.466Z",
    "geo_version": 3,
    "dagi_id": "196640"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6650",
    "nr": "6650",
    "navn": "Brørup",
    "stormodtageradresser": null,
    "bbox": [
      8.94131061,
      55.43609206,
      9.09233662,
      55.60609813
    ],
    "visueltcenter": [
      8.99731666,
      55.49880374
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0575",
        "kode": "0575",
        "navn": "Vejen"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-03-15T21:27:32.466Z",
    "geo_version": 2,
    "dagi_id": "196650"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6660",
    "nr": "6660",
    "navn": "Lintrup",
    "stormodtageradresser": null,
    "bbox": [
      8.90671807,
      55.37576842,
      9.04380041,
      55.44090655
    ],
    "visueltcenter": [
      8.99575864,
      55.41777362
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0575",
        "kode": "0575",
        "navn": "Vejen"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196660"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6670",
    "nr": "6670",
    "navn": "Holsted",
    "stormodtageradresser": null,
    "bbox": [
      8.82293036,
      55.45836641,
      8.97007529,
      55.59641831
    ],
    "visueltcenter": [
      8.90149179,
      55.51123398
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0561",
        "kode": "0561",
        "navn": "Esbjerg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0575",
        "kode": "0575",
        "navn": "Vejen"
      }
    ],
    "ændret": "2018-08-23T21:06:23.768Z",
    "geo_ændret": "2018-08-23T21:06:23.768Z",
    "geo_version": 2,
    "dagi_id": "196670"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6682",
    "nr": "6682",
    "navn": "Hovborg",
    "stormodtageradresser": null,
    "bbox": [
      8.87571941,
      55.57318602,
      8.99822959,
      55.64459065
    ],
    "visueltcenter": [
      8.94671137,
      55.60367953
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0530",
        "kode": "0530",
        "navn": "Billund"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0573",
        "kode": "0573",
        "navn": "Varde"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0575",
        "kode": "0575",
        "navn": "Vejen"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196682"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6683",
    "nr": "6683",
    "navn": "Føvling",
    "stormodtageradresser": null,
    "bbox": [
      8.84402063,
      55.4070463,
      8.97833768,
      55.47100322
    ],
    "visueltcenter": [
      8.90966334,
      55.43530414
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0561",
        "kode": "0561",
        "navn": "Esbjerg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0575",
        "kode": "0575",
        "navn": "Vejen"
      }
    ],
    "ændret": "2018-08-23T21:06:23.768Z",
    "geo_ændret": "2018-08-23T21:06:23.768Z",
    "geo_version": 2,
    "dagi_id": "196683"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6690",
    "nr": "6690",
    "navn": "Gørding",
    "stormodtageradresser": null,
    "bbox": [
      8.74970152,
      55.42857187,
      8.87888563,
      55.51025051
    ],
    "visueltcenter": [
      8.82201058,
      55.4813413
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0561",
        "kode": "0561",
        "navn": "Esbjerg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0575",
        "kode": "0575",
        "navn": "Vejen"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-03-15T21:27:32.466Z",
    "geo_version": 2,
    "dagi_id": "196690"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6700",
    "nr": "6700",
    "navn": "Esbjerg",
    "stormodtageradresser": null,
    "bbox": [
      8.38028812,
      55.43338552,
      8.51534216,
      55.4959104
    ],
    "visueltcenter": [
      8.46174114,
      55.45935717
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0561",
        "kode": "0561",
        "navn": "Esbjerg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2018-01-18T22:08:56.476Z",
    "geo_version": 2,
    "dagi_id": "196700"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6705",
    "nr": "6705",
    "navn": "Esbjerg Ø",
    "stormodtageradresser": null,
    "bbox": [
      8.45941099,
      55.41275082,
      8.63298928,
      55.53367654
    ],
    "visueltcenter": [
      8.50670361,
      55.49119455
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0561",
        "kode": "0561",
        "navn": "Esbjerg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2018-01-18T22:08:56.476Z",
    "geo_version": 4,
    "dagi_id": "196705"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6710",
    "nr": "6710",
    "navn": "Esbjerg V",
    "stormodtageradresser": null,
    "bbox": [
      8.26114667,
      55.47968,
      8.44665225,
      55.59754479
    ],
    "visueltcenter": [
      8.34866677,
      55.55262453
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0561",
        "kode": "0561",
        "navn": "Esbjerg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196710"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6715",
    "nr": "6715",
    "navn": "Esbjerg N",
    "stormodtageradresser": null,
    "bbox": [
      8.42331043,
      55.488315,
      8.60081506,
      55.58743679
    ],
    "visueltcenter": [
      8.5093547,
      55.55332885
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0561",
        "kode": "0561",
        "navn": "Esbjerg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-08-25T20:22:34.901Z",
    "geo_version": 3,
    "dagi_id": "196715"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6720",
    "nr": "6720",
    "navn": "Fanø",
    "stormodtageradresser": null,
    "bbox": [
      6.57947446,
      55.15003752,
      8.57822207,
      55.48294238
    ],
    "visueltcenter": [
      7.56593986,
      55.28286185
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0563",
        "kode": "0563",
        "navn": "Fanø"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196720"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6731",
    "nr": "6731",
    "navn": "Tjæreborg",
    "stormodtageradresser": null,
    "bbox": [
      8.5325833,
      55.38693677,
      8.64624839,
      55.50116573
    ],
    "visueltcenter": [
      8.59046906,
      55.46029975
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0561",
        "kode": "0561",
        "navn": "Esbjerg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196731"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6740",
    "nr": "6740",
    "navn": "Bramming",
    "stormodtageradresser": null,
    "bbox": [
      8.58025469,
      55.39357196,
      8.85982705,
      55.55391798
    ],
    "visueltcenter": [
      8.70168486,
      55.46065157
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0561",
        "kode": "0561",
        "navn": "Esbjerg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-03-15T21:27:32.466Z",
    "geo_version": 2,
    "dagi_id": "196740"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6752",
    "nr": "6752",
    "navn": "Glejbjerg",
    "stormodtageradresser": null,
    "bbox": [
      8.76193868,
      55.51961742,
      8.92546302,
      55.59557321
    ],
    "visueltcenter": [
      8.83181793,
      55.55973335
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0575",
        "kode": "0575",
        "navn": "Vejen"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196752"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6753",
    "nr": "6753",
    "navn": "Agerbæk",
    "stormodtageradresser": null,
    "bbox": [
      8.73827841,
      55.57992851,
      8.89200713,
      55.63140939
    ],
    "visueltcenter": [
      8.82421529,
      55.60852142
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0573",
        "kode": "0573",
        "navn": "Varde"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0575",
        "kode": "0575",
        "navn": "Vejen"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196753"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6760",
    "nr": "6760",
    "navn": "Ribe",
    "stormodtageradresser": null,
    "bbox": [
      8.29544147,
      55.20610088,
      8.93899951,
      55.4154466
    ],
    "visueltcenter": [
      8.76358629,
      55.31137673
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0561",
        "kode": "0561",
        "navn": "Esbjerg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196760"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6771",
    "nr": "6771",
    "navn": "Gredstedbro",
    "stormodtageradresser": null,
    "bbox": [
      8.55538272,
      55.33867221,
      8.8687706,
      55.44169301
    ],
    "visueltcenter": [
      8.60307494,
      55.37115323
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0561",
        "kode": "0561",
        "navn": "Esbjerg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196771"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6780",
    "nr": "6780",
    "navn": "Skærbæk",
    "stormodtageradresser": null,
    "bbox": [
      8.5926978,
      55.08023308,
      8.93722596,
      55.24501421
    ],
    "visueltcenter": [
      8.79091021,
      55.17738587
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0550",
        "kode": "0550",
        "navn": "Tønder"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-01-19T21:35:42.201Z",
    "geo_version": 2,
    "dagi_id": "196780"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6792",
    "nr": "6792",
    "navn": "Rømø",
    "stormodtageradresser": null,
    "bbox": [
      7.54794193,
      54.99257653,
      8.64231177,
      55.23431717
    ],
    "visueltcenter": [
      8.29013955,
      55.14881868
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0550",
        "kode": "0550",
        "navn": "Tønder"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196792"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6800",
    "nr": "6800",
    "navn": "Varde",
    "stormodtageradresser": null,
    "bbox": [
      8.35405614,
      55.54569619,
      8.77531554,
      55.79692077
    ],
    "visueltcenter": [
      8.52854473,
      55.65607154
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0573",
        "kode": "0573",
        "navn": "Varde"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196800"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6818",
    "nr": "6818",
    "navn": "Årre",
    "stormodtageradresser": null,
    "bbox": [
      8.54570649,
      55.51249859,
      8.77784615,
      55.63563142
    ],
    "visueltcenter": [
      8.71514349,
      55.57688969
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0561",
        "kode": "0561",
        "navn": "Esbjerg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0573",
        "kode": "0573",
        "navn": "Varde"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196818"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6823",
    "nr": "6823",
    "navn": "Ansager",
    "stormodtageradresser": null,
    "bbox": [
      8.65554676,
      55.6664288,
      8.83765049,
      55.77665847
    ],
    "visueltcenter": [
      8.75126746,
      55.72123589
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0530",
        "kode": "0530",
        "navn": "Billund"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0573",
        "kode": "0573",
        "navn": "Varde"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196823"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6830",
    "nr": "6830",
    "navn": "Nørre Nebel",
    "stormodtageradresser": null,
    "bbox": [
      7.89005885,
      55.72052192,
      8.47037286,
      55.93177251
    ],
    "visueltcenter": [
      8.22547945,
      55.7873441
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0573",
        "kode": "0573",
        "navn": "Varde"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0760",
        "kode": "0760",
        "navn": "Ringkøbing-Skjern"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196830"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6840",
    "nr": "6840",
    "navn": "Oksbøl",
    "stormodtageradresser": null,
    "bbox": [
      4.39115147,
      55.49328033,
      8.33242625,
      55.74995883
    ],
    "visueltcenter": [
      8.2413214,
      55.63843566
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0573",
        "kode": "0573",
        "navn": "Varde"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196840"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6851",
    "nr": "6851",
    "navn": "Janderup Vestj",
    "stormodtageradresser": null,
    "bbox": [
      8.32422911,
      55.60675955,
      8.43591561,
      55.67778655
    ],
    "visueltcenter": [
      8.38971082,
      55.63374455
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0573",
        "kode": "0573",
        "navn": "Varde"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196851"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6852",
    "nr": "6852",
    "navn": "Billum",
    "stormodtageradresser": null,
    "bbox": [
      8.26690634,
      55.57662989,
      8.36708187,
      55.65505957
    ],
    "visueltcenter": [
      8.306516,
      55.59542013
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0573",
        "kode": "0573",
        "navn": "Varde"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196852"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6853",
    "nr": "6853",
    "navn": "Vejers Strand",
    "stormodtageradresser": null,
    "bbox": [
      4.48465166,
      55.21849745,
      8.19834617,
      55.67700869
    ],
    "visueltcenter": [
      5.24215492,
      55.43289751
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0573",
        "kode": "0573",
        "navn": "Varde"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196853"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6854",
    "nr": "6854",
    "navn": "Henne",
    "stormodtageradresser": null,
    "bbox": [
      4.24754191,
      55.58710609,
      8.33312335,
      55.86022595
    ],
    "visueltcenter": [
      4.43594447,
      55.71982269
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0573",
        "kode": "0573",
        "navn": "Varde"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196854"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6855",
    "nr": "6855",
    "navn": "Outrup",
    "stormodtageradresser": null,
    "bbox": [
      8.29184677,
      55.67073727,
      8.47879834,
      55.75042341
    ],
    "visueltcenter": [
      8.37405373,
      55.70803366
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0573",
        "kode": "0573",
        "navn": "Varde"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196855"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6857",
    "nr": "6857",
    "navn": "Blåvand",
    "stormodtageradresser": null,
    "bbox": [
      5.74280567,
      55.33383426,
      8.3479393,
      55.60715518
    ],
    "visueltcenter": [
      6.6069035,
      55.45206547
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0573",
        "kode": "0573",
        "navn": "Varde"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196857"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6862",
    "nr": "6862",
    "navn": "Tistrup",
    "stormodtageradresser": null,
    "bbox": [
      8.55691209,
      55.673139,
      8.70211646,
      55.75124366
    ],
    "visueltcenter": [
      8.63263378,
      55.71896752
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0573",
        "kode": "0573",
        "navn": "Varde"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196862"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6870",
    "nr": "6870",
    "navn": "Ølgod",
    "stormodtageradresser": null,
    "bbox": [
      8.5016525,
      55.74172669,
      8.75939952,
      55.85306007
    ],
    "visueltcenter": [
      8.61815877,
      55.79347704
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0573",
        "kode": "0573",
        "navn": "Varde"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0760",
        "kode": "0760",
        "navn": "Ringkøbing-Skjern"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196870"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6880",
    "nr": "6880",
    "navn": "Tarm",
    "stormodtageradresser": null,
    "bbox": [
      8.24825239,
      55.77197446,
      8.82510362,
      55.9345759
    ],
    "visueltcenter": [
      8.54104706,
      55.89016003
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0573",
        "kode": "0573",
        "navn": "Varde"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0760",
        "kode": "0760",
        "navn": "Ringkøbing-Skjern"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-03-14T21:26:02.663Z",
    "geo_version": 2,
    "dagi_id": "196880"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6893",
    "nr": "6893",
    "navn": "Hemmet",
    "stormodtageradresser": null,
    "bbox": [
      8.24828681,
      55.7971358,
      8.49006459,
      55.9318605
    ],
    "visueltcenter": [
      8.33561789,
      55.85259869
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0760",
        "kode": "0760",
        "navn": "Ringkøbing-Skjern"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-03-14T21:26:02.663Z",
    "geo_version": 2,
    "dagi_id": "196893"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6900",
    "nr": "6900",
    "navn": "Skjern",
    "stormodtageradresser": null,
    "bbox": [
      8.20889205,
      55.88096446,
      8.75271924,
      56.08083792
    ],
    "visueltcenter": [
      8.53885308,
      55.99089664
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0760",
        "kode": "0760",
        "navn": "Ringkøbing-Skjern"
      }
    ],
    "ændret": "2020-09-23T21:11:42.703Z",
    "geo_ændret": "2020-09-23T21:11:42.703Z",
    "geo_version": 4,
    "dagi_id": "196900"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6920",
    "nr": "6920",
    "navn": "Videbæk",
    "stormodtageradresser": null,
    "bbox": [
      8.52728274,
      55.96103633,
      8.83176108,
      56.14928532
    ],
    "visueltcenter": [
      8.66992988,
      56.08052418
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0760",
        "kode": "0760",
        "navn": "Ringkøbing-Skjern"
      }
    ],
    "ændret": "2020-09-23T21:11:42.703Z",
    "geo_ændret": "2020-09-23T21:11:42.703Z",
    "geo_version": 4,
    "dagi_id": "196920"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6933",
    "nr": "6933",
    "navn": "Kibæk",
    "stormodtageradresser": null,
    "bbox": [
      8.74376656,
      55.89070179,
      9.01279944,
      56.09470022
    ],
    "visueltcenter": [
      8.92947696,
      55.94883412
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0657",
        "kode": "0657",
        "navn": "Herning"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0760",
        "kode": "0760",
        "navn": "Ringkøbing-Skjern"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-04-28T20:28:06.414Z",
    "geo_version": 3,
    "dagi_id": "196933"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6940",
    "nr": "6940",
    "navn": "Lem St",
    "stormodtageradresser": null,
    "bbox": [
      8.32105536,
      56.01012058,
      8.48379101,
      56.08056351
    ],
    "visueltcenter": [
      8.42316735,
      56.05110041
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0760",
        "kode": "0760",
        "navn": "Ringkøbing-Skjern"
      }
    ],
    "ændret": "2020-09-18T21:08:37.887Z",
    "geo_ændret": "2020-09-18T21:08:37.887Z",
    "geo_version": 2,
    "dagi_id": "196940"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6950",
    "nr": "6950",
    "navn": "Ringkøbing",
    "stormodtageradresser": null,
    "bbox": [
      3.2188459,
      55.88343876,
      8.46481014,
      56.40880656
    ],
    "visueltcenter": [
      4.07461311,
      56.20098497
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0760",
        "kode": "0760",
        "navn": "Ringkøbing-Skjern"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196950"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6960",
    "nr": "6960",
    "navn": "Hvide Sande",
    "stormodtageradresser": null,
    "bbox": [
      3.24755504,
      55.6537631,
      8.25112349,
      56.21585104
    ],
    "visueltcenter": [
      4.28511819,
      55.93212987
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0760",
        "kode": "0760",
        "navn": "Ringkøbing-Skjern"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196960"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6971",
    "nr": "6971",
    "navn": "Spjald",
    "stormodtageradresser": null,
    "bbox": [
      8.35659792,
      56.07617719,
      8.59848695,
      56.19662457
    ],
    "visueltcenter": [
      8.50600358,
      56.15361133
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0760",
        "kode": "0760",
        "navn": "Ringkøbing-Skjern"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196971"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6973",
    "nr": "6973",
    "navn": "Ørnhøj",
    "stormodtageradresser": null,
    "bbox": [
      8.44349441,
      56.144087,
      8.64515554,
      56.25052414
    ],
    "visueltcenter": [
      8.52448598,
      56.21518023
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0657",
        "kode": "0657",
        "navn": "Herning"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0760",
        "kode": "0760",
        "navn": "Ringkøbing-Skjern"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-04-27T20:30:08.770Z",
    "geo_version": 4,
    "dagi_id": "196973"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6980",
    "nr": "6980",
    "navn": "Tim",
    "stormodtageradresser": null,
    "bbox": [
      8.14369289,
      56.16110424,
      8.47368135,
      56.23278921
    ],
    "visueltcenter": [
      8.19684723,
      56.18956069
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0760",
        "kode": "0760",
        "navn": "Ringkøbing-Skjern"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196980"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/6990",
    "nr": "6990",
    "navn": "Ulfborg",
    "stormodtageradresser": null,
    "bbox": [
      4.00658877,
      56.07597163,
      8.50897614,
      56.58225362
    ],
    "visueltcenter": [
      8.0070526,
      56.3042395
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0657",
        "kode": "0657",
        "navn": "Herning"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0661",
        "kode": "0661",
        "navn": "Holstebro"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0665",
        "kode": "0665",
        "navn": "Lemvig"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0760",
        "kode": "0760",
        "navn": "Ringkøbing-Skjern"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "196990"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7000",
    "nr": "7000",
    "navn": "Fredericia",
    "stormodtageradresser": null,
    "bbox": [
      9.50773806,
      55.49145648,
      9.98922003,
      55.65913538
    ],
    "visueltcenter": [
      9.66918005,
      55.57103757
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0607",
        "kode": "0607",
        "navn": "Fredericia"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0630",
        "kode": "0630",
        "navn": "Vejle"
      }
    ],
    "ændret": "2021-01-01T22:14:53.992Z",
    "geo_ændret": "2021-01-01T22:14:53.992Z",
    "geo_version": 3,
    "dagi_id": "197000"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7080",
    "nr": "7080",
    "navn": "Børkop",
    "stormodtageradresser": null,
    "bbox": [
      9.57471062,
      55.61463518,
      9.79041128,
      55.7024231
    ],
    "visueltcenter": [
      9.67048857,
      55.65719788
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0630",
        "kode": "0630",
        "navn": "Vejle"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2018-04-30T15:23:13.528Z",
    "geo_version": 5,
    "dagi_id": "197080"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7100",
    "nr": "7100",
    "navn": "Vejle",
    "stormodtageradresser": null,
    "bbox": [
      9.34437136,
      55.61215238,
      9.62192238,
      55.82032729
    ],
    "visueltcenter": [
      9.5066267,
      55.69052913
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0630",
        "kode": "0630",
        "navn": "Vejle"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0766",
        "kode": "0766",
        "navn": "Hedensted"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2018-04-30T15:23:13.528Z",
    "geo_version": 5,
    "dagi_id": "197100"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7120",
    "nr": "7120",
    "navn": "Vejle Øst",
    "stormodtageradresser": null,
    "bbox": [
      9.5826596,
      55.69746869,
      9.68786533,
      55.75994605
    ],
    "visueltcenter": [
      9.63873738,
      55.7220274
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0630",
        "kode": "0630",
        "navn": "Vejle"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0766",
        "kode": "0766",
        "navn": "Hedensted"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197120"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7130",
    "nr": "7130",
    "navn": "Juelsminde",
    "stormodtageradresser": null,
    "bbox": [
      9.87588803,
      55.62265352,
      10.17912661,
      55.8538467
    ],
    "visueltcenter": [
      10.02918372,
      55.76046689
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0766",
        "kode": "0766",
        "navn": "Hedensted"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197130"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7140",
    "nr": "7140",
    "navn": "Stouby",
    "stormodtageradresser": null,
    "bbox": [
      9.71116506,
      55.64881396,
      9.88380819,
      55.75656393
    ],
    "visueltcenter": [
      9.78417201,
      55.69215915
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0766",
        "kode": "0766",
        "navn": "Hedensted"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197140"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7150",
    "nr": "7150",
    "navn": "Barrit",
    "stormodtageradresser": null,
    "bbox": [
      9.83497708,
      55.63789915,
      9.96289154,
      55.74659441
    ],
    "visueltcenter": [
      9.8978292,
      55.68695015
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0766",
        "kode": "0766",
        "navn": "Hedensted"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197150"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7160",
    "nr": "7160",
    "navn": "Tørring",
    "stormodtageradresser": null,
    "bbox": [
      9.38053389,
      55.79943514,
      9.61184419,
      55.92416145
    ],
    "visueltcenter": [
      9.50220604,
      55.87483844
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0630",
        "kode": "0630",
        "navn": "Vejle"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0756",
        "kode": "0756",
        "navn": "Ikast-Brande"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0766",
        "kode": "0766",
        "navn": "Hedensted"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-05-25T20:26:10.726Z",
    "geo_version": 3,
    "dagi_id": "197160"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7171",
    "nr": "7171",
    "navn": "Uldum",
    "stormodtageradresser": null,
    "bbox": [
      9.52629954,
      55.80771466,
      9.65256476,
      55.87283289
    ],
    "visueltcenter": [
      9.59518771,
      55.8358153
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0766",
        "kode": "0766",
        "navn": "Hedensted"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197171"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7173",
    "nr": "7173",
    "navn": "Vonge",
    "stormodtageradresser": null,
    "bbox": [
      9.36613252,
      55.82452164,
      9.46088953,
      55.90647394
    ],
    "visueltcenter": [
      9.42328648,
      55.86467774
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0630",
        "kode": "0630",
        "navn": "Vejle"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-03-10T21:33:45.448Z",
    "geo_version": 3,
    "dagi_id": "197173"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7182",
    "nr": "7182",
    "navn": "Bredsten",
    "stormodtageradresser": null,
    "bbox": [
      9.2622995,
      55.66164934,
      9.43077072,
      55.74556503
    ],
    "visueltcenter": [
      9.36512374,
      55.70610256
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0630",
        "kode": "0630",
        "navn": "Vejle"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197182"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7183",
    "nr": "7183",
    "navn": "Randbøl",
    "stormodtageradresser": null,
    "bbox": [
      9.15114267,
      55.62609383,
      9.32217547,
      55.74417204
    ],
    "visueltcenter": [
      9.26919785,
      55.67810764
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0630",
        "kode": "0630",
        "navn": "Vejle"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197183"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7184",
    "nr": "7184",
    "navn": "Vandel",
    "stormodtageradresser": null,
    "bbox": [
      9.14894582,
      55.67813169,
      9.24681778,
      55.76224087
    ],
    "visueltcenter": [
      9.19531768,
      55.71507833
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0630",
        "kode": "0630",
        "navn": "Vejle"
      }
    ],
    "ændret": "2019-05-22T21:04:56.812Z",
    "geo_ændret": "2019-05-22T21:04:56.812Z",
    "geo_version": 2,
    "dagi_id": "197184"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7190",
    "nr": "7190",
    "navn": "Billund",
    "stormodtageradresser": null,
    "bbox": [
      8.99133962,
      55.66348549,
      9.19927986,
      55.79031466
    ],
    "visueltcenter": [
      9.10071489,
      55.73792043
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0530",
        "kode": "0530",
        "navn": "Billund"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0630",
        "kode": "0630",
        "navn": "Vejle"
      }
    ],
    "ændret": "2020-09-23T21:11:42.703Z",
    "geo_ændret": "2020-09-23T21:11:42.703Z",
    "geo_version": 3,
    "dagi_id": "197190"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7200",
    "nr": "7200",
    "navn": "Grindsted",
    "stormodtageradresser": null,
    "bbox": [
      8.74683815,
      55.60607941,
      9.07799918,
      55.85065737
    ],
    "visueltcenter": [
      8.89887879,
      55.75986358
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0530",
        "kode": "0530",
        "navn": "Billund"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0573",
        "kode": "0573",
        "navn": "Varde"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0630",
        "kode": "0630",
        "navn": "Vejle"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0760",
        "kode": "0760",
        "navn": "Ringkøbing-Skjern"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197200"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7250",
    "nr": "7250",
    "navn": "Hejnsvig",
    "stormodtageradresser": null,
    "bbox": [
      8.88572214,
      55.62332258,
      9.09057362,
      55.73905518
    ],
    "visueltcenter": [
      8.96462269,
      55.69122218
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0530",
        "kode": "0530",
        "navn": "Billund"
      }
    ],
    "ændret": "2020-09-23T21:11:42.703Z",
    "geo_ændret": "2020-09-23T21:11:42.703Z",
    "geo_version": 2,
    "dagi_id": "197250"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7260",
    "nr": "7260",
    "navn": "Sønder Omme",
    "stormodtageradresser": null,
    "bbox": [
      8.79423454,
      55.79158646,
      8.98006148,
      55.90291612
    ],
    "visueltcenter": [
      8.89341393,
      55.85051521
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0530",
        "kode": "0530",
        "navn": "Billund"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0657",
        "kode": "0657",
        "navn": "Herning"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-04-06T20:28:54.793Z",
    "geo_version": 3,
    "dagi_id": "197260"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7270",
    "nr": "7270",
    "navn": "Stakroge",
    "stormodtageradresser": null,
    "bbox": [
      8.78972007,
      55.85035067,
      8.88469531,
      55.92691444
    ],
    "visueltcenter": [
      8.82814289,
      55.89268974
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0530",
        "kode": "0530",
        "navn": "Billund"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0657",
        "kode": "0657",
        "navn": "Herning"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0760",
        "kode": "0760",
        "navn": "Ringkøbing-Skjern"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197270"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7280",
    "nr": "7280",
    "navn": "Sønder Felding",
    "stormodtageradresser": null,
    "bbox": [
      8.72819679,
      55.896934,
      8.86920681,
      55.97808514
    ],
    "visueltcenter": [
      8.80469755,
      55.94233735
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0657",
        "kode": "0657",
        "navn": "Herning"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0760",
        "kode": "0760",
        "navn": "Ringkøbing-Skjern"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197280"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7300",
    "nr": "7300",
    "navn": "Jelling",
    "stormodtageradresser": null,
    "bbox": [
      9.32419204,
      55.7248602,
      9.51613164,
      55.83757106
    ],
    "visueltcenter": [
      9.43589642,
      55.76853547
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0630",
        "kode": "0630",
        "navn": "Vejle"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-04-09T08:23:44.102Z",
    "geo_version": 2,
    "dagi_id": "197300"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7321",
    "nr": "7321",
    "navn": "Gadbjerg",
    "stormodtageradresser": null,
    "bbox": [
      9.18193009,
      55.73265045,
      9.36522991,
      55.80076051
    ],
    "visueltcenter": [
      9.31066038,
      55.77031641
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0630",
        "kode": "0630",
        "navn": "Vejle"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197321"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7323",
    "nr": "7323",
    "navn": "Give",
    "stormodtageradresser": null,
    "bbox": [
      9.05476923,
      55.76386212,
      9.39864816,
      55.95236178
    ],
    "visueltcenter": [
      9.2710166,
      55.85744681
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0630",
        "kode": "0630",
        "navn": "Vejle"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0756",
        "kode": "0756",
        "navn": "Ikast-Brande"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-05-25T20:26:10.726Z",
    "geo_version": 3,
    "dagi_id": "197323"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7330",
    "nr": "7330",
    "navn": "Brande",
    "stormodtageradresser": null,
    "bbox": [
      8.94668117,
      55.83045311,
      9.21946285,
      56.02967819
    ],
    "visueltcenter": [
      9.0912202,
      55.93341595
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0630",
        "kode": "0630",
        "navn": "Vejle"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0657",
        "kode": "0657",
        "navn": "Herning"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0756",
        "kode": "0756",
        "navn": "Ikast-Brande"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-04-28T20:28:06.414Z",
    "geo_version": 4,
    "dagi_id": "197330"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7361",
    "nr": "7361",
    "navn": "Ejstrupholm",
    "stormodtageradresser": null,
    "bbox": [
      9.15225966,
      55.9309108,
      9.38283269,
      56.07647448
    ],
    "visueltcenter": [
      9.24826265,
      56.02375436
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0630",
        "kode": "0630",
        "navn": "Vejle"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0756",
        "kode": "0756",
        "navn": "Ikast-Brande"
      }
    ],
    "ændret": "2020-09-23T21:11:42.703Z",
    "geo_ændret": "2020-09-23T21:11:42.703Z",
    "geo_version": 2,
    "dagi_id": "197361"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7362",
    "nr": "7362",
    "navn": "Hampen",
    "stormodtageradresser": null,
    "bbox": [
      9.30736359,
      55.99061195,
      9.44035721,
      56.09997354
    ],
    "visueltcenter": [
      9.38166979,
      56.06515415
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0740",
        "kode": "0740",
        "navn": "Silkeborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0756",
        "kode": "0756",
        "navn": "Ikast-Brande"
      }
    ],
    "ændret": "2020-09-23T21:11:42.703Z",
    "geo_ændret": "2020-09-23T21:11:42.703Z",
    "geo_version": 2,
    "dagi_id": "197362"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7400",
    "nr": "7400",
    "navn": "Herning",
    "stormodtageradresser": null,
    "bbox": [
      8.7587575,
      55.98273993,
      9.18898152,
      56.28396245
    ],
    "visueltcenter": [
      8.98775376,
      56.11911505
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0657",
        "kode": "0657",
        "navn": "Herning"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0756",
        "kode": "0756",
        "navn": "Ikast-Brande"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0760",
        "kode": "0760",
        "navn": "Ringkøbing-Skjern"
      }
    ],
    "ændret": "2020-08-14T21:12:37.212Z",
    "geo_ændret": "2020-08-14T21:12:37.212Z",
    "geo_version": 6,
    "dagi_id": "197400"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7430",
    "nr": "7430",
    "navn": "Ikast",
    "stormodtageradresser": null,
    "bbox": [
      9.07515678,
      56.05663253,
      9.31997671,
      56.20985247
    ],
    "visueltcenter": [
      9.1925469,
      56.09377094
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0756",
        "kode": "0756",
        "navn": "Ikast-Brande"
      }
    ],
    "ændret": "2020-08-14T21:12:37.212Z",
    "geo_ændret": "2020-08-14T21:12:37.212Z",
    "geo_version": 2,
    "dagi_id": "197430"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7441",
    "nr": "7441",
    "navn": "Bording",
    "stormodtageradresser": null,
    "bbox": [
      9.11224287,
      56.08763494,
      9.42511297,
      56.27625967
    ],
    "visueltcenter": [
      9.25470923,
      56.15034765
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0740",
        "kode": "0740",
        "navn": "Silkeborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0756",
        "kode": "0756",
        "navn": "Ikast-Brande"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-04-20T20:29:59.208Z",
    "geo_version": 2,
    "dagi_id": "197441"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7442",
    "nr": "7442",
    "navn": "Engesvang",
    "stormodtageradresser": null,
    "bbox": [
      9.2216372,
      56.12888409,
      9.42416351,
      56.26991699
    ],
    "visueltcenter": [
      9.30391703,
      56.21005036
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0740",
        "kode": "0740",
        "navn": "Silkeborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0756",
        "kode": "0756",
        "navn": "Ikast-Brande"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-04-20T20:29:59.208Z",
    "geo_version": 2,
    "dagi_id": "197442"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7451",
    "nr": "7451",
    "navn": "Sunds",
    "stormodtageradresser": null,
    "bbox": [
      8.90906925,
      56.17297742,
      9.15687988,
      56.28647061
    ],
    "visueltcenter": [
      9.02585964,
      56.22960774
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0657",
        "kode": "0657",
        "navn": "Herning"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0756",
        "kode": "0756",
        "navn": "Ikast-Brande"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0791",
        "kode": "0791",
        "navn": "Viborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-04-28T20:28:06.414Z",
    "geo_version": 2,
    "dagi_id": "197451"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7470",
    "nr": "7470",
    "navn": "Karup J",
    "stormodtageradresser": null,
    "bbox": [
      9.01885086,
      56.21824702,
      9.32342093,
      56.41189235
    ],
    "visueltcenter": [
      9.17419358,
      56.32799021
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0657",
        "kode": "0657",
        "navn": "Herning"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0740",
        "kode": "0740",
        "navn": "Silkeborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0791",
        "kode": "0791",
        "navn": "Viborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197470"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7480",
    "nr": "7480",
    "navn": "Vildbjerg",
    "stormodtageradresser": null,
    "bbox": [
      8.61810085,
      56.12469321,
      8.87847551,
      56.23289876
    ],
    "visueltcenter": [
      8.74507312,
      56.18215286
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0657",
        "kode": "0657",
        "navn": "Herning"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0760",
        "kode": "0760",
        "navn": "Ringkøbing-Skjern"
      }
    ],
    "ændret": "2019-08-20T21:06:00.844Z",
    "geo_ændret": "2019-08-20T21:06:00.844Z",
    "geo_version": 6,
    "dagi_id": "197480"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7490",
    "nr": "7490",
    "navn": "Aulum",
    "stormodtageradresser": null,
    "bbox": [
      8.71190443,
      56.21713739,
      8.94757854,
      56.36320303
    ],
    "visueltcenter": [
      8.79948064,
      56.2699576
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0657",
        "kode": "0657",
        "navn": "Herning"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197490"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7500",
    "nr": "7500",
    "navn": "Holstebro",
    "stormodtageradresser": null,
    "bbox": [
      8.40579178,
      56.20522377,
      8.9332873,
      56.4322947
    ],
    "visueltcenter": [
      8.58519299,
      56.34794414
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0657",
        "kode": "0657",
        "navn": "Herning"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0661",
        "kode": "0661",
        "navn": "Holstebro"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0671",
        "kode": "0671",
        "navn": "Struer"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-04-27T20:30:08.770Z",
    "geo_version": 4,
    "dagi_id": "197500"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7540",
    "nr": "7540",
    "navn": "Haderup",
    "stormodtageradresser": null,
    "bbox": [
      8.86807369,
      56.26791263,
      9.08833686,
      56.42054698
    ],
    "visueltcenter": [
      8.9740962,
      56.32444075
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0657",
        "kode": "0657",
        "navn": "Herning"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0661",
        "kode": "0661",
        "navn": "Holstebro"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0791",
        "kode": "0791",
        "navn": "Viborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2017-09-28T21:12:41.586Z",
    "geo_version": 2,
    "dagi_id": "197540"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7550",
    "nr": "7550",
    "navn": "Sørvad",
    "stormodtageradresser": null,
    "bbox": [
      8.58710844,
      56.20632376,
      8.74020976,
      56.29883426
    ],
    "visueltcenter": [
      8.66525292,
      56.25194401
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0657",
        "kode": "0657",
        "navn": "Herning"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-04-27T20:30:08.770Z",
    "geo_version": 4,
    "dagi_id": "197550"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7560",
    "nr": "7560",
    "navn": "Hjerm",
    "stormodtageradresser": null,
    "bbox": [
      8.58732047,
      56.39868259,
      8.70960213,
      56.49810896
    ],
    "visueltcenter": [
      8.64900967,
      56.43288826
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0661",
        "kode": "0661",
        "navn": "Holstebro"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0671",
        "kode": "0671",
        "navn": "Struer"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-08-24T20:32:22.085Z",
    "geo_version": 3,
    "dagi_id": "197560"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7570",
    "nr": "7570",
    "navn": "Vemb",
    "stormodtageradresser": null,
    "bbox": [
      8.14732797,
      56.29829185,
      8.47854836,
      56.42072931
    ],
    "visueltcenter": [
      8.27244636,
      56.35236537
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0661",
        "kode": "0661",
        "navn": "Holstebro"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0665",
        "kode": "0665",
        "navn": "Lemvig"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197570"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7600",
    "nr": "7600",
    "navn": "Struer",
    "stormodtageradresser": null,
    "bbox": [
      8.38958963,
      56.38697991,
      8.69597014,
      56.5990049
    ],
    "visueltcenter": [
      8.60339354,
      56.52775554
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0661",
        "kode": "0661",
        "navn": "Holstebro"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0665",
        "kode": "0665",
        "navn": "Lemvig"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0671",
        "kode": "0671",
        "navn": "Struer"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-08-24T20:32:22.085Z",
    "geo_version": 3,
    "dagi_id": "197600"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7620",
    "nr": "7620",
    "navn": "Lemvig",
    "stormodtageradresser": null,
    "bbox": [
      5.01902856,
      56.36727225,
      8.52683418,
      56.76359848
    ],
    "visueltcenter": [
      8.33453584,
      56.54580784
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0665",
        "kode": "0665",
        "navn": "Lemvig"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0671",
        "kode": "0671",
        "navn": "Struer"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-04-20T20:29:59.208Z",
    "geo_version": 2,
    "dagi_id": "197620"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7650",
    "nr": "7650",
    "navn": "Bøvlingbjerg",
    "stormodtageradresser": null,
    "bbox": [
      4.73555631,
      56.33802347,
      8.28233941,
      56.65037438
    ],
    "visueltcenter": [
      5.04556549,
      56.54689645
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0665",
        "kode": "0665",
        "navn": "Lemvig"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-04-20T20:29:59.208Z",
    "geo_version": 2,
    "dagi_id": "197650"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7660",
    "nr": "7660",
    "navn": "Bækmarksbro",
    "stormodtageradresser": null,
    "bbox": [
      8.2227426,
      56.37527831,
      8.42250208,
      56.46641204
    ],
    "visueltcenter": [
      8.33184097,
      56.41551889
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0665",
        "kode": "0665",
        "navn": "Lemvig"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0671",
        "kode": "0671",
        "navn": "Struer"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197660"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7673",
    "nr": "7673",
    "navn": "Harboøre",
    "stormodtageradresser": null,
    "bbox": [
      5.41590645,
      56.49864995,
      8.31755672,
      56.83624656
    ],
    "visueltcenter": [
      8.06400269,
      56.62870473
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0665",
        "kode": "0665",
        "navn": "Lemvig"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197673"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7680",
    "nr": "7680",
    "navn": "Thyborøn",
    "stormodtageradresser": null,
    "bbox": [
      5.66807158,
      56.63119945,
      8.25060545,
      56.99288748
    ],
    "visueltcenter": [
      6.26580665,
      56.86776362
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0665",
        "kode": "0665",
        "navn": "Lemvig"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197680"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7700",
    "nr": "7700",
    "navn": "Thisted",
    "stormodtageradresser": null,
    "bbox": [
      7.48986551,
      56.86021389,
      8.97417353,
      57.50000114
    ],
    "visueltcenter": [
      7.90681299,
      57.32370377
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0787",
        "kode": "0787",
        "navn": "Thisted"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197700"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7730",
    "nr": "7730",
    "navn": "Hanstholm",
    "stormodtageradresser": null,
    "bbox": [
      7.97737797,
      57.05613836,
      8.8010349,
      57.61690217
    ],
    "visueltcenter": [
      8.33928238,
      57.44347291
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0787",
        "kode": "0787",
        "navn": "Thisted"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197730"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7741",
    "nr": "7741",
    "navn": "Frøstrup",
    "stormodtageradresser": null,
    "bbox": [
      8.45446375,
      57.04414821,
      9.17936439,
      57.694171
    ],
    "visueltcenter": [
      8.9170332,
      57.39912576
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0787",
        "kode": "0787",
        "navn": "Thisted"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197741"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7742",
    "nr": "7742",
    "navn": "Vesløs",
    "stormodtageradresser": null,
    "bbox": [
      8.86644192,
      56.94786553,
      9.12355547,
      57.05571623
    ],
    "visueltcenter": [
      9.03058677,
      57.00570708
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0787",
        "kode": "0787",
        "navn": "Thisted"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197742"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7752",
    "nr": "7752",
    "navn": "Snedsted",
    "stormodtageradresser": null,
    "bbox": [
      7.21397443,
      56.7925634,
      8.63979223,
      57.35630233
    ],
    "visueltcenter": [
      7.47858654,
      57.2597249
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0787",
        "kode": "0787",
        "navn": "Thisted"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197752"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7755",
    "nr": "7755",
    "navn": "Bedsted Thy",
    "stormodtageradresser": null,
    "bbox": [
      6.92930205,
      56.75911836,
      8.55856376,
      57.27349309
    ],
    "visueltcenter": [
      7.21613935,
      57.17740891
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0787",
        "kode": "0787",
        "navn": "Thisted"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197755"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7760",
    "nr": "7760",
    "navn": "Hurup Thy",
    "stormodtageradresser": null,
    "bbox": [
      8.28081144,
      56.62388919,
      8.55347442,
      56.78594151
    ],
    "visueltcenter": [
      8.42909599,
      56.72425361
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0671",
        "kode": "0671",
        "navn": "Struer"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0787",
        "kode": "0787",
        "navn": "Thisted"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197760"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7770",
    "nr": "7770",
    "navn": "Vestervig",
    "stormodtageradresser": null,
    "bbox": [
      6.22389645,
      56.64886528,
      8.37760784,
      57.18792664
    ],
    "visueltcenter": [
      6.93927002,
      57.02202911
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0787",
        "kode": "0787",
        "navn": "Thisted"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197770"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7790",
    "nr": "7790",
    "navn": "Thyholm",
    "stormodtageradresser": null,
    "bbox": [
      8.3733484,
      56.56305141,
      8.69448414,
      56.69702979
    ],
    "visueltcenter": [
      8.51363052,
      56.62765212
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0671",
        "kode": "0671",
        "navn": "Struer"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197790"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7800",
    "nr": "7800",
    "navn": "Skive",
    "stormodtageradresser": null,
    "bbox": [
      8.84953843,
      56.40471634,
      9.09611224,
      56.65111678
    ],
    "visueltcenter": [
      8.99505562,
      56.46317549
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0661",
        "kode": "0661",
        "navn": "Holstebro"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0779",
        "kode": "0779",
        "navn": "Skive"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0791",
        "kode": "0791",
        "navn": "Viborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2017-09-28T21:12:41.586Z",
    "geo_version": 2,
    "dagi_id": "197800"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7830",
    "nr": "7830",
    "navn": "Vinderup",
    "stormodtageradresser": null,
    "bbox": [
      8.66486362,
      56.37884524,
      8.97752383,
      56.56625933
    ],
    "visueltcenter": [
      8.80020865,
      56.47769058
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0657",
        "kode": "0657",
        "navn": "Herning"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0661",
        "kode": "0661",
        "navn": "Holstebro"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0671",
        "kode": "0671",
        "navn": "Struer"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0779",
        "kode": "0779",
        "navn": "Skive"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197830"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7840",
    "nr": "7840",
    "navn": "Højslev",
    "stormodtageradresser": null,
    "bbox": [
      9.0528607,
      56.5129132,
      9.30212165,
      56.6685926
    ],
    "visueltcenter": [
      9.16509632,
      56.59677993
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0779",
        "kode": "0779",
        "navn": "Skive"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0791",
        "kode": "0791",
        "navn": "Viborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197840"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7850",
    "nr": "7850",
    "navn": "Stoholm Jyll",
    "stormodtageradresser": null,
    "bbox": [
      9.03119183,
      56.43480813,
      9.21130504,
      56.52953898
    ],
    "visueltcenter": [
      9.12847686,
      56.48444375
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0791",
        "kode": "0791",
        "navn": "Viborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-04-20T20:29:59.208Z",
    "geo_version": 2,
    "dagi_id": "197850"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7860",
    "nr": "7860",
    "navn": "Spøttrup",
    "stormodtageradresser": null,
    "bbox": [
      8.64955217,
      56.5454971,
      8.98612246,
      56.70270043
    ],
    "visueltcenter": [
      8.82098992,
      56.62419195
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0779",
        "kode": "0779",
        "navn": "Skive"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197860"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7870",
    "nr": "7870",
    "navn": "Roslev",
    "stormodtageradresser": null,
    "bbox": [
      8.80652353,
      56.61847213,
      9.21405808,
      56.81784291
    ],
    "visueltcenter": [
      9.04159313,
      56.72437033
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0779",
        "kode": "0779",
        "navn": "Skive"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197870"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7884",
    "nr": "7884",
    "navn": "Fur",
    "stormodtageradresser": null,
    "bbox": [
      8.90229871,
      56.79302727,
      9.13705326,
      56.89286279
    ],
    "visueltcenter": [
      9.00553893,
      56.84193567
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0779",
        "kode": "0779",
        "navn": "Skive"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197884"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7900",
    "nr": "7900",
    "navn": "Nykøbing M",
    "stormodtageradresser": null,
    "bbox": [
      8.5813926,
      56.71058114,
      9.03175851,
      56.99095848
    ],
    "visueltcenter": [
      8.88457349,
      56.90798948
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0773",
        "kode": "0773",
        "navn": "Morsø"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197900"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7950",
    "nr": "7950",
    "navn": "Erslev",
    "stormodtageradresser": null,
    "bbox": [
      8.59714866,
      56.8038235,
      8.80896912,
      56.91986087
    ],
    "visueltcenter": [
      8.69843044,
      56.8517685
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0773",
        "kode": "0773",
        "navn": "Morsø"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197950"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7960",
    "nr": "7960",
    "navn": "Karby",
    "stormodtageradresser": null,
    "bbox": [
      8.49175754,
      56.69097552,
      8.6591195,
      56.80825303
    ],
    "visueltcenter": [
      8.56443606,
      56.76132134
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0773",
        "kode": "0773",
        "navn": "Morsø"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197960"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7970",
    "nr": "7970",
    "navn": "Redsted M",
    "stormodtageradresser": null,
    "bbox": [
      8.58541921,
      56.71053779,
      8.70468773,
      56.77135707
    ],
    "visueltcenter": [
      8.63946767,
      56.73993747
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0773",
        "kode": "0773",
        "navn": "Morsø"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197970"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7980",
    "nr": "7980",
    "navn": "Vils",
    "stormodtageradresser": null,
    "bbox": [
      8.68532846,
      56.7193029,
      8.75808363,
      56.78722989
    ],
    "visueltcenter": [
      8.72666914,
      56.74842812
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0773",
        "kode": "0773",
        "navn": "Morsø"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197980"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/7990",
    "nr": "7990",
    "navn": "Øster Assels",
    "stormodtageradresser": null,
    "bbox": [
      8.59102882,
      56.64778952,
      8.81035688,
      56.73670795
    ],
    "visueltcenter": [
      8.70032858,
      56.68763915
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0773",
        "kode": "0773",
        "navn": "Morsø"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "197990"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8000",
    "nr": "8000",
    "navn": "Aarhus C",
    "stormodtageradresser": null,
    "bbox": [
      10.17165345,
      56.11626813,
      10.33170603,
      56.17448857
    ],
    "visueltcenter": [
      10.27802097,
      56.15051496
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0751",
        "kode": "0751",
        "navn": "Aarhus"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-12-16T23:19:50.845Z",
    "geo_version": 2,
    "dagi_id": "198000"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8200",
    "nr": "8200",
    "navn": "Aarhus N",
    "stormodtageradresser": null,
    "bbox": [
      10.11312467,
      56.16759268,
      10.22440038,
      56.24137862
    ],
    "visueltcenter": [
      10.15922351,
      56.20167527
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0751",
        "kode": "0751",
        "navn": "Aarhus"
      }
    ],
    "ændret": "2019-11-01T22:14:20.338Z",
    "geo_ændret": "2019-11-01T22:14:20.338Z",
    "geo_version": 7,
    "dagi_id": "198200"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8210",
    "nr": "8210",
    "navn": "Aarhus V",
    "stormodtageradresser": null,
    "bbox": [
      10.11379647,
      56.1568982,
      10.19124054,
      56.18503979
    ],
    "visueltcenter": [
      10.15819911,
      56.17162148
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0751",
        "kode": "0751",
        "navn": "Aarhus"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-04-20T20:29:59.208Z",
    "geo_version": 3,
    "dagi_id": "198210"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8220",
    "nr": "8220",
    "navn": "Brabrand",
    "stormodtageradresser": null,
    "bbox": [
      10.0215633,
      56.13757284,
      10.14840279,
      56.18138974
    ],
    "visueltcenter": [
      10.06788413,
      56.15788265
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0751",
        "kode": "0751",
        "navn": "Aarhus"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198220"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8230",
    "nr": "8230",
    "navn": "Åbyhøj",
    "stormodtageradresser": null,
    "bbox": [
      10.13316829,
      56.13856371,
      10.18084458,
      56.16275101
    ],
    "visueltcenter": [
      10.1605019,
      56.15196052
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0751",
        "kode": "0751",
        "navn": "Aarhus"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198230"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8240",
    "nr": "8240",
    "navn": "Risskov",
    "stormodtageradresser": null,
    "bbox": [
      10.18336596,
      56.16803581,
      10.3173181,
      56.22417391
    ],
    "visueltcenter": [
      10.24680782,
      56.18719668
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0751",
        "kode": "0751",
        "navn": "Aarhus"
      }
    ],
    "ændret": "2019-11-01T22:14:20.338Z",
    "geo_ændret": "2019-11-01T22:14:20.338Z",
    "geo_version": 3,
    "dagi_id": "198240"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8250",
    "nr": "8250",
    "navn": "Egå",
    "stormodtageradresser": null,
    "bbox": [
      10.23924069,
      56.19778747,
      10.38763736,
      56.24509987
    ],
    "visueltcenter": [
      10.29989608,
      56.22214938
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0751",
        "kode": "0751",
        "navn": "Aarhus"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198250"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8260",
    "nr": "8260",
    "navn": "Viby J",
    "stormodtageradresser": null,
    "bbox": [
      10.02368933,
      56.10569398,
      10.19030486,
      56.14764523
    ],
    "visueltcenter": [
      10.13778473,
      56.12635244
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0751",
        "kode": "0751",
        "navn": "Aarhus"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2017-06-06T21:20:53.340Z",
    "geo_version": 3,
    "dagi_id": "198260"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8270",
    "nr": "8270",
    "navn": "Højbjerg",
    "stormodtageradresser": null,
    "bbox": [
      10.14477122,
      56.0721646,
      10.38012295,
      56.13360481
    ],
    "visueltcenter": [
      10.28961018,
      56.09903701
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0751",
        "kode": "0751",
        "navn": "Aarhus"
      }
    ],
    "ændret": "2020-03-02T22:15:14.069Z",
    "geo_ændret": "2020-03-02T22:15:14.069Z",
    "geo_version": 3,
    "dagi_id": "198270"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8300",
    "nr": "8300",
    "navn": "Odder",
    "stormodtageradresser": null,
    "bbox": [
      10.00876179,
      55.78997439,
      10.43711096,
      56.04388152
    ],
    "visueltcenter": [
      10.24464778,
      55.92457284
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0727",
        "kode": "0727",
        "navn": "Odder"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0751",
        "kode": "0751",
        "navn": "Aarhus"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2017-06-01T21:16:01.264Z",
    "geo_version": 4,
    "dagi_id": "198300"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8305",
    "nr": "8305",
    "navn": "Samsø",
    "stormodtageradresser": null,
    "bbox": [
      10.3719052,
      55.67427597,
      10.89580293,
      56.05769229
    ],
    "visueltcenter": [
      10.68434089,
      55.91180366
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0741",
        "kode": "0741",
        "navn": "Samsø"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198305"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8310",
    "nr": "8310",
    "navn": "Tranbjerg J",
    "stormodtageradresser": null,
    "bbox": [
      10.08674941,
      56.0548421,
      10.17622408,
      56.10968667
    ],
    "visueltcenter": [
      10.13924601,
      56.09423322
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0751",
        "kode": "0751",
        "navn": "Aarhus"
      }
    ],
    "ændret": "2020-03-02T22:15:14.069Z",
    "geo_ændret": "2020-03-02T22:15:14.069Z",
    "geo_version": 3,
    "dagi_id": "198310"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8320",
    "nr": "8320",
    "navn": "Mårslet",
    "stormodtageradresser": null,
    "bbox": [
      10.11493982,
      56.04175285,
      10.21512236,
      56.0995967
    ],
    "visueltcenter": [
      10.15312251,
      56.0658713
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0751",
        "kode": "0751",
        "navn": "Aarhus"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198320"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8330",
    "nr": "8330",
    "navn": "Beder",
    "stormodtageradresser": null,
    "bbox": [
      10.19188453,
      56.03736221,
      10.40201499,
      56.08844851
    ],
    "visueltcenter": [
      10.24584275,
      56.06302044
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0751",
        "kode": "0751",
        "navn": "Aarhus"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198330"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8340",
    "nr": "8340",
    "navn": "Malling",
    "stormodtageradresser": null,
    "bbox": [
      10.1146464,
      56.00375773,
      10.40532786,
      56.057939
    ],
    "visueltcenter": [
      10.2151148,
      56.02634501
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0727",
        "kode": "0727",
        "navn": "Odder"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0751",
        "kode": "0751",
        "navn": "Aarhus"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198340"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8350",
    "nr": "8350",
    "navn": "Hundslund",
    "stormodtageradresser": null,
    "bbox": [
      10.01484738,
      55.87209211,
      10.09510379,
      55.95805014
    ],
    "visueltcenter": [
      10.05739341,
      55.90552571
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0727",
        "kode": "0727",
        "navn": "Odder"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198350"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8355",
    "nr": "8355",
    "navn": "Solbjerg",
    "stormodtageradresser": null,
    "bbox": [
      10.03834349,
      55.99505555,
      10.13136357,
      56.06717707
    ],
    "visueltcenter": [
      10.0866212,
      56.03661436
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0751",
        "kode": "0751",
        "navn": "Aarhus"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198355"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8361",
    "nr": "8361",
    "navn": "Hasselager",
    "stormodtageradresser": null,
    "bbox": [
      10.04311577,
      56.0739985,
      10.1285811,
      56.12806136
    ],
    "visueltcenter": [
      10.08245179,
      56.10123046
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0751",
        "kode": "0751",
        "navn": "Aarhus"
      }
    ],
    "ændret": "2021-01-01T22:14:53.992Z",
    "geo_ændret": "2021-01-01T22:14:53.992Z",
    "geo_version": 3,
    "dagi_id": "198361"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8362",
    "nr": "8362",
    "navn": "Hørning",
    "stormodtageradresser": null,
    "bbox": [
      9.93357576,
      56.051527,
      10.09435505,
      56.12114016
    ],
    "visueltcenter": [
      10.0135704,
      56.09369625
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0746",
        "kode": "0746",
        "navn": "Skanderborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0751",
        "kode": "0751",
        "navn": "Aarhus"
      }
    ],
    "ændret": "2021-01-01T22:14:53.992Z",
    "geo_ændret": "2021-01-01T22:14:53.992Z",
    "geo_version": 3,
    "dagi_id": "198362"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8370",
    "nr": "8370",
    "navn": "Hadsten",
    "stormodtageradresser": null,
    "bbox": [
      9.93986904,
      56.28445924,
      10.24328493,
      56.39505942
    ],
    "visueltcenter": [
      10.0289852,
      56.33866468
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0710",
        "kode": "0710",
        "navn": "Favrskov"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198370"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8380",
    "nr": "8380",
    "navn": "Trige",
    "stormodtageradresser": null,
    "bbox": [
      10.11466245,
      56.21589725,
      10.19166817,
      56.30935704
    ],
    "visueltcenter": [
      10.16086107,
      56.27022295
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0710",
        "kode": "0710",
        "navn": "Favrskov"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0751",
        "kode": "0751",
        "navn": "Aarhus"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198380"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8381",
    "nr": "8381",
    "navn": "Tilst",
    "stormodtageradresser": null,
    "bbox": [
      10.03426133,
      56.1689403,
      10.13919117,
      56.2184898
    ],
    "visueltcenter": [
      10.09750556,
      56.18928869
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0751",
        "kode": "0751",
        "navn": "Aarhus"
      }
    ],
    "ændret": "2019-08-22T21:08:54.046Z",
    "geo_ændret": "2019-08-22T21:08:54.046Z",
    "geo_version": 3,
    "dagi_id": "198381"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8382",
    "nr": "8382",
    "navn": "Hinnerup",
    "stormodtageradresser": null,
    "bbox": [
      9.93860375,
      56.21581011,
      10.13648966,
      56.30723291
    ],
    "visueltcenter": [
      10.06719333,
      56.25949156
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0710",
        "kode": "0710",
        "navn": "Favrskov"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0751",
        "kode": "0751",
        "navn": "Aarhus"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198382"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8400",
    "nr": "8400",
    "navn": "Ebeltoft",
    "stormodtageradresser": null,
    "bbox": [
      10.52011728,
      56.01836611,
      11.11949313,
      56.33854024
    ],
    "visueltcenter": [
      10.82632626,
      56.14931257
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0706",
        "kode": "0706",
        "navn": "Syddjurs"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-08-24T20:32:22.085Z",
    "geo_version": 2,
    "dagi_id": "198400"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8410",
    "nr": "8410",
    "navn": "Rønde",
    "stormodtageradresser": null,
    "bbox": [
      10.36639642,
      56.25089985,
      10.68037663,
      56.38344282
    ],
    "visueltcenter": [
      10.48173371,
      56.30328793
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0706",
        "kode": "0706",
        "navn": "Syddjurs"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198410"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8420",
    "nr": "8420",
    "navn": "Knebel",
    "stormodtageradresser": null,
    "bbox": [
      10.30301948,
      56.03150611,
      10.68394884,
      56.27228248
    ],
    "visueltcenter": [
      10.45208963,
      56.15222183
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0706",
        "kode": "0706",
        "navn": "Syddjurs"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198420"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8444",
    "nr": "8444",
    "navn": "Balle",
    "stormodtageradresser": null,
    "bbox": [
      10.71498213,
      56.16968012,
      11.20176541,
      56.34351688
    ],
    "visueltcenter": [
      11.11035786,
      56.20496905
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0706",
        "kode": "0706",
        "navn": "Syddjurs"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0707",
        "kode": "0707",
        "navn": "Norddjurs"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-08-24T20:32:22.085Z",
    "geo_version": 2,
    "dagi_id": "198444"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8450",
    "nr": "8450",
    "navn": "Hammel",
    "stormodtageradresser": null,
    "bbox": [
      9.76608677,
      56.21256727,
      9.97474609,
      56.32226595
    ],
    "visueltcenter": [
      9.8817943,
      56.26356721
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0710",
        "kode": "0710",
        "navn": "Favrskov"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0740",
        "kode": "0740",
        "navn": "Silkeborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198450"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8462",
    "nr": "8462",
    "navn": "Harlev J",
    "stormodtageradresser": null,
    "bbox": [
      9.95094241,
      56.10729649,
      10.06304076,
      56.18010833
    ],
    "visueltcenter": [
      9.99991491,
      56.14059283
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0751",
        "kode": "0751",
        "navn": "Aarhus"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198462"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8464",
    "nr": "8464",
    "navn": "Galten",
    "stormodtageradresser": null,
    "bbox": [
      9.84978453,
      56.09711636,
      9.99767096,
      56.21526214
    ],
    "visueltcenter": [
      9.91179535,
      56.17800104
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0746",
        "kode": "0746",
        "navn": "Skanderborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0751",
        "kode": "0751",
        "navn": "Aarhus"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198464"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8471",
    "nr": "8471",
    "navn": "Sabro",
    "stormodtageradresser": null,
    "bbox": [
      9.90483013,
      56.17648041,
      10.10837555,
      56.25104254
    ],
    "visueltcenter": [
      10.01547147,
      56.21127538
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0710",
        "kode": "0710",
        "navn": "Favrskov"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0746",
        "kode": "0746",
        "navn": "Skanderborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0751",
        "kode": "0751",
        "navn": "Aarhus"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198471"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8472",
    "nr": "8472",
    "navn": "Sporup",
    "stormodtageradresser": null,
    "bbox": [
      9.7671975,
      56.17836137,
      9.89820257,
      56.23566346
    ],
    "visueltcenter": [
      9.83610781,
      56.20907595
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0710",
        "kode": "0710",
        "navn": "Favrskov"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0740",
        "kode": "0740",
        "navn": "Silkeborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0746",
        "kode": "0746",
        "navn": "Skanderborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198472"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8500",
    "nr": "8500",
    "navn": "Grenaa",
    "stormodtageradresser": null,
    "bbox": [
      10.70988716,
      56.20359818,
      11.47485627,
      56.87324606
    ],
    "visueltcenter": [
      11.08217337,
      56.43069147
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0707",
        "kode": "0707",
        "navn": "Norddjurs"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-07-06T20:21:24.650Z",
    "geo_version": 2,
    "dagi_id": "198500"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8520",
    "nr": "8520",
    "navn": "Lystrup",
    "stormodtageradresser": null,
    "bbox": [
      10.16633309,
      56.21482126,
      10.26593246,
      56.26457607
    ],
    "visueltcenter": [
      10.22716475,
      56.24438884
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0751",
        "kode": "0751",
        "navn": "Aarhus"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-09-01T21:42:42.766Z",
    "geo_version": 2,
    "dagi_id": "198520"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8530",
    "nr": "8530",
    "navn": "Hjortshøj",
    "stormodtageradresser": null,
    "bbox": [
      10.16164038,
      56.23416429,
      10.29922561,
      56.33099784
    ],
    "visueltcenter": [
      10.20502083,
      56.29464717
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0751",
        "kode": "0751",
        "navn": "Aarhus"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198530"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8541",
    "nr": "8541",
    "navn": "Skødstrup",
    "stormodtageradresser": null,
    "bbox": [
      10.23371613,
      56.23173093,
      10.4320249,
      56.31335954
    ],
    "visueltcenter": [
      10.36793908,
      56.26151082
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0751",
        "kode": "0751",
        "navn": "Aarhus"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198541"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8543",
    "nr": "8543",
    "navn": "Hornslet",
    "stormodtageradresser": null,
    "bbox": [
      10.15870897,
      56.27857956,
      10.39907896,
      56.36656267
    ],
    "visueltcenter": [
      10.29717184,
      56.33260255
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0706",
        "kode": "0706",
        "navn": "Syddjurs"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0751",
        "kode": "0751",
        "navn": "Aarhus"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198543"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8544",
    "nr": "8544",
    "navn": "Mørke",
    "stormodtageradresser": null,
    "bbox": [
      10.19205605,
      56.30615833,
      10.43480103,
      56.41006678
    ],
    "visueltcenter": [
      10.38246065,
      56.35508218
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0706",
        "kode": "0706",
        "navn": "Syddjurs"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-11-02T21:45:22.952Z",
    "geo_version": 3,
    "dagi_id": "198544"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8550",
    "nr": "8550",
    "navn": "Ryomgård",
    "stormodtageradresser": null,
    "bbox": [
      10.36666011,
      56.33951179,
      10.56927687,
      56.42052449
    ],
    "visueltcenter": [
      10.50027849,
      56.38481233
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0706",
        "kode": "0706",
        "navn": "Syddjurs"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0707",
        "kode": "0707",
        "navn": "Norddjurs"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198550"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8560",
    "nr": "8560",
    "navn": "Kolind",
    "stormodtageradresser": null,
    "bbox": [
      10.52256255,
      56.30290051,
      10.73340363,
      56.41775017
    ],
    "visueltcenter": [
      10.61106988,
      56.34682936
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0706",
        "kode": "0706",
        "navn": "Syddjurs"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0707",
        "kode": "0707",
        "navn": "Norddjurs"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198560"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8570",
    "nr": "8570",
    "navn": "Trustrup",
    "stormodtageradresser": null,
    "bbox": [
      10.67168737,
      56.31039192,
      10.84225746,
      56.40459267
    ],
    "visueltcenter": [
      10.74217288,
      56.36287517
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0706",
        "kode": "0706",
        "navn": "Syddjurs"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0707",
        "kode": "0707",
        "navn": "Norddjurs"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198570"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8581",
    "nr": "8581",
    "navn": "Nimtofte",
    "stormodtageradresser": null,
    "bbox": [
      10.49240369,
      56.38663761,
      10.67972477,
      56.47334215
    ],
    "visueltcenter": [
      10.53988094,
      56.42793946
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0706",
        "kode": "0706",
        "navn": "Syddjurs"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0707",
        "kode": "0707",
        "navn": "Norddjurs"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198581"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8585",
    "nr": "8585",
    "navn": "Glesborg",
    "stormodtageradresser": null,
    "bbox": [
      10.523658,
      56.4446311,
      10.80713859,
      56.81103656
    ],
    "visueltcenter": [
      10.64766235,
      56.58757292
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0707",
        "kode": "0707",
        "navn": "Norddjurs"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-07-06T20:21:24.650Z",
    "geo_version": 3,
    "dagi_id": "198585"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8586",
    "nr": "8586",
    "navn": "Ørum Djurs",
    "stormodtageradresser": null,
    "bbox": [
      10.56020514,
      56.40146419,
      10.74116337,
      56.48861638
    ],
    "visueltcenter": [
      10.62703709,
      56.44956016
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0707",
        "kode": "0707",
        "navn": "Norddjurs"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198586"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8592",
    "nr": "8592",
    "navn": "Anholt",
    "stormodtageradresser": null,
    "bbox": [
      10.92372946,
      56.40710988,
      12.19303494,
      57.09969105
    ],
    "visueltcenter": [
      11.49010919,
      56.77566641
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0707",
        "kode": "0707",
        "navn": "Norddjurs"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198592"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8600",
    "nr": "8600",
    "navn": "Silkeborg",
    "stormodtageradresser": null,
    "bbox": [
      9.3620449,
      56.09605109,
      9.76157469,
      56.26916226
    ],
    "visueltcenter": [
      9.4900253,
      56.17147071
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0740",
        "kode": "0740",
        "navn": "Silkeborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0746",
        "kode": "0746",
        "navn": "Skanderborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0756",
        "kode": "0756",
        "navn": "Ikast-Brande"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198600"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8620",
    "nr": "8620",
    "navn": "Kjellerup",
    "stormodtageradresser": null,
    "bbox": [
      9.25198316,
      56.20026007,
      9.5769845,
      56.37008322
    ],
    "visueltcenter": [
      9.39095532,
      56.2882506
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0740",
        "kode": "0740",
        "navn": "Silkeborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0791",
        "kode": "0791",
        "navn": "Viborg"
      }
    ],
    "ændret": "2020-09-18T21:08:37.887Z",
    "geo_ændret": "2020-09-18T21:08:37.887Z",
    "geo_version": 2,
    "dagi_id": "198620"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8632",
    "nr": "8632",
    "navn": "Lemming",
    "stormodtageradresser": null,
    "bbox": [
      9.46974859,
      56.20676946,
      9.60648127,
      56.26736073
    ],
    "visueltcenter": [
      9.55711799,
      56.24319222
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0740",
        "kode": "0740",
        "navn": "Silkeborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198632"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8641",
    "nr": "8641",
    "navn": "Sorring",
    "stormodtageradresser": null,
    "bbox": [
      9.73928244,
      56.15857543,
      9.8616471,
      56.20387666
    ],
    "visueltcenter": [
      9.77449496,
      56.17906349
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0710",
        "kode": "0710",
        "navn": "Favrskov"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0740",
        "kode": "0740",
        "navn": "Silkeborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198641"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8643",
    "nr": "8643",
    "navn": "Ans By",
    "stormodtageradresser": null,
    "bbox": [
      9.53993144,
      56.26023835,
      9.67940023,
      56.32015106
    ],
    "visueltcenter": [
      9.6247327,
      56.28777753
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0740",
        "kode": "0740",
        "navn": "Silkeborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0791",
        "kode": "0791",
        "navn": "Viborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198643"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8653",
    "nr": "8653",
    "navn": "Them",
    "stormodtageradresser": null,
    "bbox": [
      9.39583165,
      56.0453024,
      9.64191517,
      56.12629753
    ],
    "visueltcenter": [
      9.55044283,
      56.08668443
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0740",
        "kode": "0740",
        "navn": "Silkeborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198653"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8654",
    "nr": "8654",
    "navn": "Bryrup",
    "stormodtageradresser": null,
    "bbox": [
      9.41087216,
      55.99148323,
      9.60701004,
      56.06808443
    ],
    "visueltcenter": [
      9.47060993,
      56.02488921
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0615",
        "kode": "0615",
        "navn": "Horsens"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0740",
        "kode": "0740",
        "navn": "Silkeborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0756",
        "kode": "0756",
        "navn": "Ikast-Brande"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-04-27T20:30:08.770Z",
    "geo_version": 2,
    "dagi_id": "198654"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8660",
    "nr": "8660",
    "navn": "Skanderborg",
    "stormodtageradresser": null,
    "bbox": [
      9.65207402,
      55.94559088,
      10.06954249,
      56.14578767
    ],
    "visueltcenter": [
      9.89235588,
      56.02388208
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0615",
        "kode": "0615",
        "navn": "Horsens"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0727",
        "kode": "0727",
        "navn": "Odder"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0746",
        "kode": "0746",
        "navn": "Skanderborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0751",
        "kode": "0751",
        "navn": "Aarhus"
      }
    ],
    "ændret": "2020-10-05T21:12:44.055Z",
    "geo_ændret": "2020-10-05T21:12:44.055Z",
    "geo_version": 3,
    "dagi_id": "198660"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8670",
    "nr": "8670",
    "navn": "Låsby",
    "stormodtageradresser": null,
    "bbox": [
      9.74567383,
      56.1286736,
      9.87089002,
      56.17161213
    ],
    "visueltcenter": [
      9.79899795,
      56.14838747
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0746",
        "kode": "0746",
        "navn": "Skanderborg"
      }
    ],
    "ændret": "2018-07-23T21:12:25.101Z",
    "geo_ændret": "2018-07-23T21:12:25.101Z",
    "geo_version": 2,
    "dagi_id": "198670"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8680",
    "nr": "8680",
    "navn": "Ry",
    "stormodtageradresser": null,
    "bbox": [
      9.61252987,
      56.03770178,
      9.86530188,
      56.13878264
    ],
    "visueltcenter": [
      9.76890364,
      56.10415601
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0615",
        "kode": "0615",
        "navn": "Horsens"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0740",
        "kode": "0740",
        "navn": "Silkeborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0746",
        "kode": "0746",
        "navn": "Skanderborg"
      }
    ],
    "ændret": "2018-07-23T21:12:25.101Z",
    "geo_ændret": "2018-07-23T21:12:25.101Z",
    "geo_version": 2,
    "dagi_id": "198680"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8700",
    "nr": "8700",
    "navn": "Horsens",
    "stormodtageradresser": null,
    "bbox": [
      9.68335646,
      55.79859017,
      10.05384663,
      55.94265158
    ],
    "visueltcenter": [
      9.89301425,
      55.86597915
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0615",
        "kode": "0615",
        "navn": "Horsens"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0766",
        "kode": "0766",
        "navn": "Hedensted"
      }
    ],
    "ændret": "2021-01-01T22:14:53.992Z",
    "geo_ændret": "2021-01-01T22:14:53.992Z",
    "geo_version": 6,
    "dagi_id": "198700"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8721",
    "nr": "8721",
    "navn": "Daugård",
    "stormodtageradresser": null,
    "bbox": [
      9.66873318,
      55.68559889,
      9.77810532,
      55.75583779
    ],
    "visueltcenter": [
      9.70789065,
      55.72499013
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0630",
        "kode": "0630",
        "navn": "Vejle"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0766",
        "kode": "0766",
        "navn": "Hedensted"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198721"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8722",
    "nr": "8722",
    "navn": "Hedensted",
    "stormodtageradresser": null,
    "bbox": [
      9.58446234,
      55.73671129,
      9.81265301,
      55.79784709
    ],
    "visueltcenter": [
      9.71130088,
      55.76508387
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0766",
        "kode": "0766",
        "navn": "Hedensted"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-04-25T20:31:14.970Z",
    "geo_version": 2,
    "dagi_id": "198722"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8723",
    "nr": "8723",
    "navn": "Løsning",
    "stormodtageradresser": null,
    "bbox": [
      9.59246235,
      55.78171339,
      9.78611936,
      55.8432401
    ],
    "visueltcenter": [
      9.69416947,
      55.81385497
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0766",
        "kode": "0766",
        "navn": "Hedensted"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198723"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8732",
    "nr": "8732",
    "navn": "Hovedgård",
    "stormodtageradresser": null,
    "bbox": [
      9.87416385,
      55.92278023,
      10.03645802,
      55.99329184
    ],
    "visueltcenter": [
      9.98616441,
      55.95626604
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0615",
        "kode": "0615",
        "navn": "Horsens"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-08-22T20:24:26.163Z",
    "geo_version": 3,
    "dagi_id": "198732"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8740",
    "nr": "8740",
    "navn": "Brædstrup",
    "stormodtageradresser": null,
    "bbox": [
      9.46345239,
      55.90326949,
      9.75451244,
      56.07109987
    ],
    "visueltcenter": [
      9.59236493,
      55.96052136
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0615",
        "kode": "0615",
        "navn": "Horsens"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0740",
        "kode": "0740",
        "navn": "Silkeborg"
      }
    ],
    "ændret": "2021-01-01T22:14:53.992Z",
    "geo_ændret": "2021-01-01T22:14:53.992Z",
    "geo_version": 4,
    "dagi_id": "198740"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8751",
    "nr": "8751",
    "navn": "Gedved",
    "stormodtageradresser": null,
    "bbox": [
      9.80138422,
      55.91338653,
      9.92131182,
      55.97397354
    ],
    "visueltcenter": [
      9.86197889,
      55.93439431
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0615",
        "kode": "0615",
        "navn": "Horsens"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0746",
        "kode": "0746",
        "navn": "Skanderborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-08-22T20:24:26.163Z",
    "geo_version": 3,
    "dagi_id": "198751"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8752",
    "nr": "8752",
    "navn": "Østbirk",
    "stormodtageradresser": null,
    "bbox": [
      9.68351228,
      55.92898272,
      9.82089344,
      56.01962001
    ],
    "visueltcenter": [
      9.75737543,
      55.96891982
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0615",
        "kode": "0615",
        "navn": "Horsens"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198752"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8762",
    "nr": "8762",
    "navn": "Flemming",
    "stormodtageradresser": null,
    "bbox": [
      9.63347544,
      55.82509038,
      9.69681999,
      55.91469817
    ],
    "visueltcenter": [
      9.66642959,
      55.89442946
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0615",
        "kode": "0615",
        "navn": "Horsens"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0766",
        "kode": "0766",
        "navn": "Hedensted"
      }
    ],
    "ændret": "2021-01-01T22:14:53.992Z",
    "geo_ændret": "2021-01-01T22:14:53.992Z",
    "geo_version": 3,
    "dagi_id": "198762"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8763",
    "nr": "8763",
    "navn": "Rask Mølle",
    "stormodtageradresser": null,
    "bbox": [
      9.56434342,
      55.85778677,
      9.64862439,
      55.92514247
    ],
    "visueltcenter": [
      9.6137501,
      55.87835123
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0766",
        "kode": "0766",
        "navn": "Hedensted"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198763"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8765",
    "nr": "8765",
    "navn": "Klovborg",
    "stormodtageradresser": null,
    "bbox": [
      9.44002352,
      55.89942536,
      9.55985522,
      55.96876555
    ],
    "visueltcenter": [
      9.48555987,
      55.92463273
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0615",
        "kode": "0615",
        "navn": "Horsens"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0756",
        "kode": "0756",
        "navn": "Ikast-Brande"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198765"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8766",
    "nr": "8766",
    "navn": "Nørre Snede",
    "stormodtageradresser": null,
    "bbox": [
      9.32648204,
      55.90655871,
      9.50421934,
      56.02223838
    ],
    "visueltcenter": [
      9.41496508,
      55.96381394
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0756",
        "kode": "0756",
        "navn": "Ikast-Brande"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0766",
        "kode": "0766",
        "navn": "Hedensted"
      }
    ],
    "ændret": "2021-01-01T22:14:53.992Z",
    "geo_ændret": "2021-01-01T22:14:53.992Z",
    "geo_version": 3,
    "dagi_id": "198766"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8781",
    "nr": "8781",
    "navn": "Stenderup",
    "stormodtageradresser": null,
    "bbox": [
      9.76498442,
      55.76538492,
      9.85179273,
      55.82997439
    ],
    "visueltcenter": [
      9.80232769,
      55.79039351
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0615",
        "kode": "0615",
        "navn": "Horsens"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0766",
        "kode": "0766",
        "navn": "Hedensted"
      }
    ],
    "ændret": "2018-06-07T21:08:14.307Z",
    "geo_ændret": "2018-06-07T21:08:14.307Z",
    "geo_version": 3,
    "dagi_id": "198781"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8783",
    "nr": "8783",
    "navn": "Hornsyld",
    "stormodtageradresser": null,
    "bbox": [
      9.77499762,
      55.72841863,
      9.90791156,
      55.80865724
    ],
    "visueltcenter": [
      9.85180061,
      55.76234096
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0615",
        "kode": "0615",
        "navn": "Horsens"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0766",
        "kode": "0766",
        "navn": "Hedensted"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198783"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8789",
    "nr": "8789",
    "navn": "Endelave",
    "stormodtageradresser": null,
    "bbox": [
      10.14214999,
      55.68035297,
      10.46189506,
      55.86071415
    ],
    "visueltcenter": [
      10.30820541,
      55.75329623
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0615",
        "kode": "0615",
        "navn": "Horsens"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2017-06-01T21:16:01.264Z",
    "geo_version": 1,
    "dagi_id": "378119"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8799",
    "nr": "8799",
    "navn": "Tunø",
    "stormodtageradresser": null,
    "bbox": [
      10.33690799,
      55.88133575,
      10.49834183,
      56.04954575
    ],
    "visueltcenter": [
      10.44454187,
      55.9486075
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0727",
        "kode": "0727",
        "navn": "Odder"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2017-06-01T21:16:01.264Z",
    "geo_version": 1,
    "dagi_id": "378117"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8800",
    "nr": "8800",
    "navn": "Viborg",
    "stormodtageradresser": null,
    "bbox": [
      9.05093736,
      56.33963758,
      9.56674318,
      56.53262454
    ],
    "visueltcenter": [
      9.36897659,
      56.41681148
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0740",
        "kode": "0740",
        "navn": "Silkeborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0791",
        "kode": "0791",
        "navn": "Viborg"
      }
    ],
    "ændret": "2020-09-18T21:08:37.887Z",
    "geo_ændret": "2020-09-18T21:08:37.887Z",
    "geo_version": 3,
    "dagi_id": "198800"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8830",
    "nr": "8830",
    "navn": "Tjele",
    "stormodtageradresser": null,
    "bbox": [
      9.38282257,
      56.42420539,
      9.7969732,
      56.59156144
    ],
    "visueltcenter": [
      9.60208409,
      56.49762699
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0791",
        "kode": "0791",
        "navn": "Viborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198830"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8831",
    "nr": "8831",
    "navn": "Løgstrup",
    "stormodtageradresser": null,
    "bbox": [
      9.20440844,
      56.47556868,
      9.40945138,
      56.57594638
    ],
    "visueltcenter": [
      9.32656972,
      56.51759246
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0791",
        "kode": "0791",
        "navn": "Viborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198831"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8832",
    "nr": "8832",
    "navn": "Skals",
    "stormodtageradresser": null,
    "bbox": [
      9.26914517,
      56.53650429,
      9.47871797,
      56.6719278
    ],
    "visueltcenter": [
      9.35963562,
      56.59071095
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0791",
        "kode": "0791",
        "navn": "Viborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198832"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8840",
    "nr": "8840",
    "navn": "Rødkærsbro",
    "stormodtageradresser": null,
    "bbox": [
      9.43660554,
      56.30659887,
      9.58123206,
      56.38206168
    ],
    "visueltcenter": [
      9.50444327,
      56.35360504
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0740",
        "kode": "0740",
        "navn": "Silkeborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0791",
        "kode": "0791",
        "navn": "Viborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198840"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8850",
    "nr": "8850",
    "navn": "Bjerringbro",
    "stormodtageradresser": null,
    "bbox": [
      9.51431381,
      56.29911988,
      9.77603435,
      56.46780348
    ],
    "visueltcenter": [
      9.64257403,
      56.36051392
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0710",
        "kode": "0710",
        "navn": "Favrskov"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0791",
        "kode": "0791",
        "navn": "Viborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198850"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8860",
    "nr": "8860",
    "navn": "Ulstrup",
    "stormodtageradresser": null,
    "bbox": [
      9.71481752,
      56.32538567,
      9.85612419,
      56.45070912
    ],
    "visueltcenter": [
      9.78710533,
      56.3800683
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0710",
        "kode": "0710",
        "navn": "Favrskov"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0730",
        "kode": "0730",
        "navn": "Randers"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0791",
        "kode": "0791",
        "navn": "Viborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198860"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8870",
    "nr": "8870",
    "navn": "Langå",
    "stormodtageradresser": null,
    "bbox": [
      9.81796656,
      56.30620716,
      9.98583337,
      56.45100447
    ],
    "visueltcenter": [
      9.90941177,
      56.3806496
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0710",
        "kode": "0710",
        "navn": "Favrskov"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0730",
        "kode": "0730",
        "navn": "Randers"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198870"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8881",
    "nr": "8881",
    "navn": "Thorsø",
    "stormodtageradresser": null,
    "bbox": [
      9.69212179,
      56.26795745,
      9.85221604,
      56.34992056
    ],
    "visueltcenter": [
      9.79439741,
      56.3103006
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0710",
        "kode": "0710",
        "navn": "Favrskov"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0740",
        "kode": "0740",
        "navn": "Silkeborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198881"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8882",
    "nr": "8882",
    "navn": "Fårvang",
    "stormodtageradresser": null,
    "bbox": [
      9.65558127,
      56.23889701,
      9.78337021,
      56.30682436
    ],
    "visueltcenter": [
      9.70492587,
      56.27196318
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0710",
        "kode": "0710",
        "navn": "Favrskov"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0740",
        "kode": "0740",
        "navn": "Silkeborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198882"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8883",
    "nr": "8883",
    "navn": "Gjern",
    "stormodtageradresser": null,
    "bbox": [
      9.68737375,
      56.1885462,
      9.78776449,
      56.2633795
    ],
    "visueltcenter": [
      9.73984728,
      56.22223999
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0740",
        "kode": "0740",
        "navn": "Silkeborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198883"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8900",
    "nr": "8900",
    "navn": "Randers C",
    "stormodtageradresser": null,
    "bbox": [
      10.01189389,
      56.45501239,
      10.05369418,
      56.4720305
    ],
    "visueltcenter": [
      10.0331817,
      56.46401254
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0730",
        "kode": "0730",
        "navn": "Randers"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-06-16T20:26:20.284Z",
    "geo_version": 2,
    "dagi_id": "198900"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8920",
    "nr": "8920",
    "navn": "Randers NV",
    "stormodtageradresser": null,
    "bbox": [
      9.77549924,
      56.41177299,
      10.04404759,
      56.53537127
    ],
    "visueltcenter": [
      9.88044731,
      56.48050883
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0730",
        "kode": "0730",
        "navn": "Randers"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "402494"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8930",
    "nr": "8930",
    "navn": "Randers NØ",
    "stormodtageradresser": null,
    "bbox": [
      10.01917286,
      56.45485471,
      10.23410778,
      56.56564157
    ],
    "visueltcenter": [
      10.1724293,
      56.50265825
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0730",
        "kode": "0730",
        "navn": "Randers"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-06-16T20:26:20.284Z",
    "geo_version": 2,
    "dagi_id": "402495"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8940",
    "nr": "8940",
    "navn": "Randers SV",
    "stormodtageradresser": null,
    "bbox": [
      9.94477997,
      56.37167269,
      10.11724215,
      56.45827185
    ],
    "visueltcenter": [
      10.01231946,
      56.41476356
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0710",
        "kode": "0710",
        "navn": "Favrskov"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0730",
        "kode": "0730",
        "navn": "Randers"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "402496"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8950",
    "nr": "8950",
    "navn": "Ørsted",
    "stormodtageradresser": null,
    "bbox": [
      10.21635915,
      56.46633309,
      10.49187469,
      56.61003705
    ],
    "visueltcenter": [
      10.32882868,
      56.53413791
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0707",
        "kode": "0707",
        "navn": "Norddjurs"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198950"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8960",
    "nr": "8960",
    "navn": "Randers SØ",
    "stormodtageradresser": null,
    "bbox": [
      10.03756829,
      56.37071952,
      10.3080808,
      56.48083013
    ],
    "visueltcenter": [
      10.17671406,
      56.42912946
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0710",
        "kode": "0710",
        "navn": "Favrskov"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0730",
        "kode": "0730",
        "navn": "Randers"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-10-28T21:38:11.296Z",
    "geo_version": 2,
    "dagi_id": "402497"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8961",
    "nr": "8961",
    "navn": "Allingåbro",
    "stormodtageradresser": null,
    "bbox": [
      10.26295329,
      56.41792125,
      10.59348691,
      56.62048871
    ],
    "visueltcenter": [
      10.49037138,
      56.49705855
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0707",
        "kode": "0707",
        "navn": "Norddjurs"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-01-19T21:35:42.201Z",
    "geo_version": 2,
    "dagi_id": "198961"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8963",
    "nr": "8963",
    "navn": "Auning",
    "stormodtageradresser": null,
    "bbox": [
      10.26564938,
      56.38355462,
      10.51352753,
      56.47534075
    ],
    "visueltcenter": [
      10.38424574,
      56.42921057
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0706",
        "kode": "0706",
        "navn": "Syddjurs"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0707",
        "kode": "0707",
        "navn": "Norddjurs"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0730",
        "kode": "0730",
        "navn": "Randers"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2015-11-02T21:45:22.952Z",
    "geo_version": 3,
    "dagi_id": "198963"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8970",
    "nr": "8970",
    "navn": "Havndal",
    "stormodtageradresser": null,
    "bbox": [
      10.07896805,
      56.56120786,
      10.65875214,
      56.72276442
    ],
    "visueltcenter": [
      10.26167957,
      56.6517573
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0730",
        "kode": "0730",
        "navn": "Randers"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0846",
        "kode": "0846",
        "navn": "Mariagerfjord"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-08-25T20:22:34.901Z",
    "geo_version": 2,
    "dagi_id": "198970"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8981",
    "nr": "8981",
    "navn": "Spentrup",
    "stormodtageradresser": null,
    "bbox": [
      9.94703941,
      56.50419183,
      10.13616554,
      56.59697381
    ],
    "visueltcenter": [
      10.0192493,
      56.54082727
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0730",
        "kode": "0730",
        "navn": "Randers"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198981"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8983",
    "nr": "8983",
    "navn": "Gjerlev J",
    "stormodtageradresser": null,
    "bbox": [
      10.00680429,
      56.53658245,
      10.24795861,
      56.63789284
    ],
    "visueltcenter": [
      10.10145059,
      56.58233605
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0730",
        "kode": "0730",
        "navn": "Randers"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198983"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/8990",
    "nr": "8990",
    "navn": "Fårup",
    "stormodtageradresser": null,
    "bbox": [
      9.75004539,
      56.51649031,
      9.97347307,
      56.59575063
    ],
    "visueltcenter": [
      9.84538694,
      56.55632319
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0730",
        "kode": "0730",
        "navn": "Randers"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0846",
        "kode": "0846",
        "navn": "Mariagerfjord"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "198990"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9000",
    "nr": "9000",
    "navn": "Aalborg",
    "stormodtageradresser": null,
    "bbox": [
      9.75574674,
      57.0115898,
      9.97476555,
      57.08729727
    ],
    "visueltcenter": [
      9.8350492,
      57.05171431
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0851",
        "kode": "0851",
        "navn": "Aalborg"
      }
    ],
    "ændret": "2019-01-15T22:07:36.262Z",
    "geo_ændret": "2019-01-15T22:07:36.262Z",
    "geo_version": 3,
    "dagi_id": "199000"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9200",
    "nr": "9200",
    "navn": "Aalborg SV",
    "stormodtageradresser": null,
    "bbox": [
      9.77966613,
      56.99200338,
      9.91502709,
      57.02645665
    ],
    "visueltcenter": [
      9.86220561,
      57.00928951
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0851",
        "kode": "0851",
        "navn": "Aalborg"
      }
    ],
    "ændret": "2019-01-15T22:07:36.262Z",
    "geo_ændret": "2019-01-15T22:07:36.262Z",
    "geo_version": 2,
    "dagi_id": "199200"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9210",
    "nr": "9210",
    "navn": "Aalborg SØ",
    "stormodtageradresser": null,
    "bbox": [
      9.90268039,
      56.98179914,
      9.96789509,
      57.02685565
    ],
    "visueltcenter": [
      9.93295578,
      56.9991663
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0851",
        "kode": "0851",
        "navn": "Aalborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-04-26T20:40:58.218Z",
    "geo_version": 2,
    "dagi_id": "199210"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9220",
    "nr": "9220",
    "navn": "Aalborg Øst",
    "stormodtageradresser": null,
    "bbox": [
      9.95395948,
      57.00530139,
      10.08685339,
      57.0823778
    ],
    "visueltcenter": [
      10.00949733,
      57.05425152
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0851",
        "kode": "0851",
        "navn": "Aalborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-12-09T23:14:56.322Z",
    "geo_version": 2,
    "dagi_id": "199220"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9230",
    "nr": "9230",
    "navn": "Svenstrup J",
    "stormodtageradresser": null,
    "bbox": [
      9.76119094,
      56.91027222,
      9.96621894,
      57.00385401
    ],
    "visueltcenter": [
      9.86815189,
      56.9647583
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0840",
        "kode": "0840",
        "navn": "Rebild"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0851",
        "kode": "0851",
        "navn": "Aalborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199230"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9240",
    "nr": "9240",
    "navn": "Nibe",
    "stormodtageradresser": null,
    "bbox": [
      9.38921271,
      56.8327588,
      9.81029366,
      57.05500474
    ],
    "visueltcenter": [
      9.57985536,
      56.94881207
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0820",
        "kode": "0820",
        "navn": "Vesthimmerlands"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0840",
        "kode": "0840",
        "navn": "Rebild"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0851",
        "kode": "0851",
        "navn": "Aalborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2018-01-16T22:10:42.686Z",
    "geo_version": 2,
    "dagi_id": "199240"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9260",
    "nr": "9260",
    "navn": "Gistrup",
    "stormodtageradresser": null,
    "bbox": [
      9.91827376,
      56.88861053,
      10.09388057,
      57.02042775
    ],
    "visueltcenter": [
      10.01379029,
      56.93195612
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0840",
        "kode": "0840",
        "navn": "Rebild"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0851",
        "kode": "0851",
        "navn": "Aalborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199260"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9270",
    "nr": "9270",
    "navn": "Klarup",
    "stormodtageradresser": null,
    "bbox": [
      10.01238878,
      56.98833997,
      10.09636762,
      57.04732827
    ],
    "visueltcenter": [
      10.05566956,
      57.0195069
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0851",
        "kode": "0851",
        "navn": "Aalborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-12-09T23:14:56.322Z",
    "geo_version": 2,
    "dagi_id": "199270"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9280",
    "nr": "9280",
    "navn": "Storvorde",
    "stormodtageradresser": null,
    "bbox": [
      10.02049693,
      56.80630198,
      10.63589972,
      57.04081085
    ],
    "visueltcenter": [
      10.27569023,
      56.91678716
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0851",
        "kode": "0851",
        "navn": "Aalborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-08-22T20:24:26.163Z",
    "geo_version": 2,
    "dagi_id": "199280"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9293",
    "nr": "9293",
    "navn": "Kongerslev",
    "stormodtageradresser": null,
    "bbox": [
      10.03648749,
      56.83966347,
      10.17351839,
      56.94401767
    ],
    "visueltcenter": [
      10.1135906,
      56.89578273
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0840",
        "kode": "0840",
        "navn": "Rebild"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0851",
        "kode": "0851",
        "navn": "Aalborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199293"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9300",
    "nr": "9300",
    "navn": "Sæby",
    "stormodtageradresser": null,
    "bbox": [
      10.31941062,
      57.07273806,
      10.77127656,
      57.38011275
    ],
    "visueltcenter": [
      10.57327556,
      57.2843629
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0813",
        "kode": "0813",
        "navn": "Frederikshavn"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199300"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9310",
    "nr": "9310",
    "navn": "Vodskov",
    "stormodtageradresser": null,
    "bbox": [
      9.97668813,
      57.02578261,
      10.1984267,
      57.17328642
    ],
    "visueltcenter": [
      10.09257652,
      57.09538719
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0851",
        "kode": "0851",
        "navn": "Aalborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199310"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9320",
    "nr": "9320",
    "navn": "Hjallerup",
    "stormodtageradresser": null,
    "bbox": [
      10.0210368,
      57.11164459,
      10.23295296,
      57.25043876
    ],
    "visueltcenter": [
      10.13790859,
      57.17870119
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0810",
        "kode": "0810",
        "navn": "Brønderslev"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0851",
        "kode": "0851",
        "navn": "Aalborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199320"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9330",
    "nr": "9330",
    "navn": "Dronninglund",
    "stormodtageradresser": null,
    "bbox": [
      10.18149359,
      57.10230731,
      10.46672614,
      57.26908812
    ],
    "visueltcenter": [
      10.31020366,
      57.21127809
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0810",
        "kode": "0810",
        "navn": "Brønderslev"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0813",
        "kode": "0813",
        "navn": "Frederikshavn"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199330"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9340",
    "nr": "9340",
    "navn": "Asaa",
    "stormodtageradresser": null,
    "bbox": [
      10.3169984,
      57.03886576,
      10.78326397,
      57.19733588
    ],
    "visueltcenter": [
      10.44405468,
      57.13326579
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0810",
        "kode": "0810",
        "navn": "Brønderslev"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0813",
        "kode": "0813",
        "navn": "Frederikshavn"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199340"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9352",
    "nr": "9352",
    "navn": "Dybvad",
    "stormodtageradresser": null,
    "bbox": [
      10.20091126,
      57.23276349,
      10.43986754,
      57.31749538
    ],
    "visueltcenter": [
      10.32334518,
      57.28497909
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0810",
        "kode": "0810",
        "navn": "Brønderslev"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0813",
        "kode": "0813",
        "navn": "Frederikshavn"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199352"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9362",
    "nr": "9362",
    "navn": "Gandrup",
    "stormodtageradresser": null,
    "bbox": [
      10.12733485,
      56.99293337,
      10.26394211,
      57.11132523
    ],
    "visueltcenter": [
      10.1977671,
      57.05355839
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0851",
        "kode": "0851",
        "navn": "Aalborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199362"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9370",
    "nr": "9370",
    "navn": "Hals",
    "stormodtageradresser": null,
    "bbox": [
      10.19434273,
      56.85177401,
      10.9394163,
      57.1108717
    ],
    "visueltcenter": [
      10.65368838,
      56.96384115
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0810",
        "kode": "0810",
        "navn": "Brønderslev"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0851",
        "kode": "0851",
        "navn": "Aalborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199370"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9380",
    "nr": "9380",
    "navn": "Vestbjerg",
    "stormodtageradresser": null,
    "bbox": [
      9.93918894,
      57.10338811,
      10.00368034,
      57.14687409
    ],
    "visueltcenter": [
      9.97550463,
      57.12732919
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0851",
        "kode": "0851",
        "navn": "Aalborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199380"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9381",
    "nr": "9381",
    "navn": "Sulsted",
    "stormodtageradresser": null,
    "bbox": [
      9.85125901,
      57.1336071,
      10.04798519,
      57.19203209
    ],
    "visueltcenter": [
      9.99174315,
      57.16265215
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0849",
        "kode": "0849",
        "navn": "Jammerbugt"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0851",
        "kode": "0851",
        "navn": "Aalborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199381"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9382",
    "nr": "9382",
    "navn": "Tylstrup",
    "stormodtageradresser": null,
    "bbox": [
      9.78234622,
      57.1716785,
      10.05191898,
      57.24319557
    ],
    "visueltcenter": [
      9.9350316,
      57.20308117
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0810",
        "kode": "0810",
        "navn": "Brønderslev"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0849",
        "kode": "0849",
        "navn": "Jammerbugt"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0851",
        "kode": "0851",
        "navn": "Aalborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199382"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9400",
    "nr": "9400",
    "navn": "Nørresundby",
    "stormodtageradresser": null,
    "bbox": [
      9.84425628,
      57.0507482,
      10.0020063,
      57.15290328
    ],
    "visueltcenter": [
      9.92809079,
      57.08119416
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0851",
        "kode": "0851",
        "navn": "Aalborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199400"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9430",
    "nr": "9430",
    "navn": "Vadum",
    "stormodtageradresser": null,
    "bbox": [
      9.77858136,
      57.07968765,
      9.92964576,
      57.18961905
    ],
    "visueltcenter": [
      9.8563612,
      57.11542161
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0849",
        "kode": "0849",
        "navn": "Jammerbugt"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0851",
        "kode": "0851",
        "navn": "Aalborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199430"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9440",
    "nr": "9440",
    "navn": "Aabybro",
    "stormodtageradresser": null,
    "bbox": [
      9.58876678,
      57.04744142,
      9.87887666,
      57.239173
    ],
    "visueltcenter": [
      9.76743314,
      57.16282327
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0810",
        "kode": "0810",
        "navn": "Brønderslev"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0849",
        "kode": "0849",
        "navn": "Jammerbugt"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0851",
        "kode": "0851",
        "navn": "Aalborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199440"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9460",
    "nr": "9460",
    "navn": "Brovst",
    "stormodtageradresser": null,
    "bbox": [
      9.19778002,
      57.01618494,
      9.6841278,
      57.38892623
    ],
    "visueltcenter": [
      9.5353613,
      57.10716869
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0849",
        "kode": "0849",
        "navn": "Jammerbugt"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199460"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9480",
    "nr": "9480",
    "navn": "Løkken",
    "stormodtageradresser": null,
    "bbox": [
      9.00495064,
      57.29277315,
      9.89330711,
      57.59279814
    ],
    "visueltcenter": [
      9.65510765,
      57.38233116
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0810",
        "kode": "0810",
        "navn": "Brønderslev"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0849",
        "kode": "0849",
        "navn": "Jammerbugt"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0860",
        "kode": "0860",
        "navn": "Hjørring"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199480"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9490",
    "nr": "9490",
    "navn": "Pandrup",
    "stormodtageradresser": null,
    "bbox": [
      9.24860795,
      57.16693786,
      9.74508252,
      57.36425514
    ],
    "visueltcenter": [
      9.65450533,
      57.20322545
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0849",
        "kode": "0849",
        "navn": "Jammerbugt"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199490"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9492",
    "nr": "9492",
    "navn": "Blokhus",
    "stormodtageradresser": null,
    "bbox": [
      9.14669996,
      57.21990536,
      9.65748067,
      57.45016818
    ],
    "visueltcenter": [
      9.57722094,
      57.25739854
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0849",
        "kode": "0849",
        "navn": "Jammerbugt"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199492"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9493",
    "nr": "9493",
    "navn": "Saltum",
    "stormodtageradresser": null,
    "bbox": [
      9.14576725,
      57.22422755,
      9.76779496,
      57.45150334
    ],
    "visueltcenter": [
      9.69608315,
      57.26602114
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0849",
        "kode": "0849",
        "navn": "Jammerbugt"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199493"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9500",
    "nr": "9500",
    "navn": "Hobro",
    "stormodtageradresser": null,
    "bbox": [
      9.52890955,
      56.54439397,
      10.00008069,
      56.74284181
    ],
    "visueltcenter": [
      9.77366901,
      56.6528756
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0791",
        "kode": "0791",
        "navn": "Viborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0840",
        "kode": "0840",
        "navn": "Rebild"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0846",
        "kode": "0846",
        "navn": "Mariagerfjord"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199500"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9510",
    "nr": "9510",
    "navn": "Arden",
    "stormodtageradresser": null,
    "bbox": [
      9.77136929,
      56.71761963,
      10.05810287,
      56.82687717
    ],
    "visueltcenter": [
      9.94260419,
      56.76264148
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0840",
        "kode": "0840",
        "navn": "Rebild"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0846",
        "kode": "0846",
        "navn": "Mariagerfjord"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199510"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9520",
    "nr": "9520",
    "navn": "Skørping",
    "stormodtageradresser": null,
    "bbox": [
      9.70229141,
      56.78604133,
      10.08495935,
      56.90797527
    ],
    "visueltcenter": [
      9.92696601,
      56.84944104
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0840",
        "kode": "0840",
        "navn": "Rebild"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-07-06T20:21:24.650Z",
    "geo_version": 2,
    "dagi_id": "199520"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9530",
    "nr": "9530",
    "navn": "Støvring",
    "stormodtageradresser": null,
    "bbox": [
      9.70294221,
      56.83635606,
      9.95338097,
      56.96584239
    ],
    "visueltcenter": [
      9.80945918,
      56.89267972
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0840",
        "kode": "0840",
        "navn": "Rebild"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0851",
        "kode": "0851",
        "navn": "Aalborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199530"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9541",
    "nr": "9541",
    "navn": "Suldrup",
    "stormodtageradresser": null,
    "bbox": [
      9.60429725,
      56.79350059,
      9.76884657,
      56.90450967
    ],
    "visueltcenter": [
      9.68119202,
      56.84292865
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0820",
        "kode": "0820",
        "navn": "Vesthimmerlands"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0840",
        "kode": "0840",
        "navn": "Rebild"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199541"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9550",
    "nr": "9550",
    "navn": "Mariager",
    "stormodtageradresser": null,
    "bbox": [
      9.86631597,
      56.56804624,
      10.11453438,
      56.69764259
    ],
    "visueltcenter": [
      10.0220988,
      56.63765951
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0730",
        "kode": "0730",
        "navn": "Randers"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0846",
        "kode": "0846",
        "navn": "Mariagerfjord"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-08-25T20:22:34.901Z",
    "geo_version": 2,
    "dagi_id": "199550"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9560",
    "nr": "9560",
    "navn": "Hadsund",
    "stormodtageradresser": null,
    "bbox": [
      9.94995946,
      56.66448035,
      10.86712139,
      56.87896836
    ],
    "visueltcenter": [
      10.41645939,
      56.77688954
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0840",
        "kode": "0840",
        "navn": "Rebild"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0846",
        "kode": "0846",
        "navn": "Mariagerfjord"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199560"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9574",
    "nr": "9574",
    "navn": "Bælum",
    "stormodtageradresser": null,
    "bbox": [
      10.07264922,
      56.78332756,
      10.23057687,
      56.87259235
    ],
    "visueltcenter": [
      10.12654857,
      56.8298458
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0840",
        "kode": "0840",
        "navn": "Rebild"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0851",
        "kode": "0851",
        "navn": "Aalborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-08-22T20:24:26.163Z",
    "geo_version": 2,
    "dagi_id": "199574"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9575",
    "nr": "9575",
    "navn": "Terndrup",
    "stormodtageradresser": null,
    "bbox": [
      9.97175741,
      56.76166819,
      10.10012753,
      56.83997542
    ],
    "visueltcenter": [
      10.04422607,
      56.81450196
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0840",
        "kode": "0840",
        "navn": "Rebild"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-07-06T20:21:24.650Z",
    "geo_version": 2,
    "dagi_id": "199575"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9600",
    "nr": "9600",
    "navn": "Aars",
    "stormodtageradresser": null,
    "bbox": [
      9.36321023,
      56.72633778,
      9.64116632,
      56.89925705
    ],
    "visueltcenter": [
      9.49610809,
      56.80003403
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0820",
        "kode": "0820",
        "navn": "Vesthimmerlands"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0840",
        "kode": "0840",
        "navn": "Rebild"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2018-01-16T22:10:42.686Z",
    "geo_version": 2,
    "dagi_id": "199600"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9610",
    "nr": "9610",
    "navn": "Nørager",
    "stormodtageradresser": null,
    "bbox": [
      9.56373321,
      56.67029404,
      9.81361854,
      56.80767887
    ],
    "visueltcenter": [
      9.63042311,
      56.74794316
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0820",
        "kode": "0820",
        "navn": "Vesthimmerlands"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0840",
        "kode": "0840",
        "navn": "Rebild"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199610"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9620",
    "nr": "9620",
    "navn": "Aalestrup",
    "stormodtageradresser": null,
    "bbox": [
      9.39001775,
      56.63070366,
      9.5994806,
      56.74340551
    ],
    "visueltcenter": [
      9.4967184,
      56.69297871
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0791",
        "kode": "0791",
        "navn": "Viborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0820",
        "kode": "0820",
        "navn": "Vesthimmerlands"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0840",
        "kode": "0840",
        "navn": "Rebild"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0846",
        "kode": "0846",
        "navn": "Mariagerfjord"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199620"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9631",
    "nr": "9631",
    "navn": "Gedsted",
    "stormodtageradresser": null,
    "bbox": [
      9.26313447,
      56.64897037,
      9.42290929,
      56.73231675
    ],
    "visueltcenter": [
      9.36542938,
      56.69090841
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0791",
        "kode": "0791",
        "navn": "Viborg"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0820",
        "kode": "0820",
        "navn": "Vesthimmerlands"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199631"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9632",
    "nr": "9632",
    "navn": "Møldrup",
    "stormodtageradresser": null,
    "bbox": [
      9.40233244,
      56.55873945,
      9.60019389,
      56.65243502
    ],
    "visueltcenter": [
      9.50805968,
      56.6088695
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0791",
        "kode": "0791",
        "navn": "Viborg"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199632"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9640",
    "nr": "9640",
    "navn": "Farsø",
    "stormodtageradresser": null,
    "bbox": [
      9.12287436,
      56.64687776,
      9.43673614,
      56.89849182
    ],
    "visueltcenter": [
      9.27748567,
      56.77310507
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0820",
        "kode": "0820",
        "navn": "Vesthimmerlands"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199640"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9670",
    "nr": "9670",
    "navn": "Løgstør",
    "stormodtageradresser": null,
    "bbox": [
      9.09793269,
      56.82890658,
      9.44002665,
      57.03083818
    ],
    "visueltcenter": [
      9.30949603,
      56.95819912
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0820",
        "kode": "0820",
        "navn": "Vesthimmerlands"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0849",
        "kode": "0849",
        "navn": "Jammerbugt"
      }
    ],
    "ændret": "2020-09-23T21:11:42.703Z",
    "geo_ændret": "2020-09-23T21:11:42.703Z",
    "geo_version": 2,
    "dagi_id": "199670"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9681",
    "nr": "9681",
    "navn": "Ranum",
    "stormodtageradresser": null,
    "bbox": [
      8.99886542,
      56.84952634,
      9.26422612,
      56.95841968
    ],
    "visueltcenter": [
      9.12839085,
      56.89861349
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0820",
        "kode": "0820",
        "navn": "Vesthimmerlands"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199681"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9690",
    "nr": "9690",
    "navn": "Fjerritslev",
    "stormodtageradresser": null,
    "bbox": [
      9.02986514,
      57.00567671,
      9.44368669,
      57.40418599
    ],
    "visueltcenter": [
      9.20878724,
      57.12742277
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0849",
        "kode": "0849",
        "navn": "Jammerbugt"
      }
    ],
    "ændret": "2020-09-23T21:11:42.703Z",
    "geo_ændret": "2020-09-23T21:11:42.703Z",
    "geo_version": 2,
    "dagi_id": "199690"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9700",
    "nr": "9700",
    "navn": "Brønderslev",
    "stormodtageradresser": null,
    "bbox": [
      9.69816746,
      57.19955835,
      10.14228991,
      57.34380261
    ],
    "visueltcenter": [
      9.88277134,
      57.28432949
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0810",
        "kode": "0810",
        "navn": "Brønderslev"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0849",
        "kode": "0849",
        "navn": "Jammerbugt"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199700"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9740",
    "nr": "9740",
    "navn": "Jerslev J",
    "stormodtageradresser": null,
    "bbox": [
      10.02613271,
      57.24523011,
      10.22394966,
      57.33968335
    ],
    "visueltcenter": [
      10.11084423,
      57.29699349
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0810",
        "kode": "0810",
        "navn": "Brønderslev"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0860",
        "kode": "0860",
        "navn": "Hjørring"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199740"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9750",
    "nr": "9750",
    "navn": "Østervrå",
    "stormodtageradresser": null,
    "bbox": [
      10.16309285,
      57.28146978,
      10.35805659,
      57.3855578
    ],
    "visueltcenter": [
      10.27081316,
      57.34034984
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0810",
        "kode": "0810",
        "navn": "Brønderslev"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0813",
        "kode": "0813",
        "navn": "Frederikshavn"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0860",
        "kode": "0860",
        "navn": "Hjørring"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199750"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9760",
    "nr": "9760",
    "navn": "Vrå",
    "stormodtageradresser": null,
    "bbox": [
      9.78326893,
      57.31366602,
      10.10563599,
      57.4111429
    ],
    "visueltcenter": [
      9.87742317,
      57.37190853
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0810",
        "kode": "0810",
        "navn": "Brønderslev"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0860",
        "kode": "0860",
        "navn": "Hjørring"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199760"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9800",
    "nr": "9800",
    "navn": "Hjørring",
    "stormodtageradresser": null,
    "bbox": [
      8.88468146,
      57.37320608,
      10.18224702,
      57.98645076
    ],
    "visueltcenter": [
      9.2956345,
      57.70230652
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0860",
        "kode": "0860",
        "navn": "Hjørring"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199800"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9830",
    "nr": "9830",
    "navn": "Tårs",
    "stormodtageradresser": null,
    "bbox": [
      10.04371089,
      57.32584872,
      10.24427038,
      57.42567241
    ],
    "visueltcenter": [
      10.14317436,
      57.37825187
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0813",
        "kode": "0813",
        "navn": "Frederikshavn"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0860",
        "kode": "0860",
        "navn": "Hjørring"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199830"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9850",
    "nr": "9850",
    "navn": "Hirtshals",
    "stormodtageradresser": null,
    "bbox": [
      9.37560448,
      57.50617738,
      10.10969969,
      58.26262592
    ],
    "visueltcenter": [
      9.71496643,
      57.94281241
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0860",
        "kode": "0860",
        "navn": "Hjørring"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199850"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9870",
    "nr": "9870",
    "navn": "Sindal",
    "stormodtageradresser": null,
    "bbox": [
      10.123732,
      57.37500922,
      10.39280131,
      57.55593402
    ],
    "visueltcenter": [
      10.24075136,
      57.4674892
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0813",
        "kode": "0813",
        "navn": "Frederikshavn"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0860",
        "kode": "0860",
        "navn": "Hjørring"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-04-20T20:29:59.208Z",
    "geo_version": 2,
    "dagi_id": "199870"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9881",
    "nr": "9881",
    "navn": "Bindslev",
    "stormodtageradresser": null,
    "bbox": [
      10.08245774,
      57.50358001,
      10.3430891,
      57.76334936
    ],
    "visueltcenter": [
      10.23668023,
      57.57139797
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0813",
        "kode": "0813",
        "navn": "Frederikshavn"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0860",
        "kode": "0860",
        "navn": "Hjørring"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199881"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9900",
    "nr": "9900",
    "navn": "Frederikshavn",
    "stormodtageradresser": null,
    "bbox": [
      10.29635591,
      57.34825549,
      11.04393242,
      57.61722487
    ],
    "visueltcenter": [
      10.72500277,
      57.52270103
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0813",
        "kode": "0813",
        "navn": "Frederikshavn"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0860",
        "kode": "0860",
        "navn": "Hjørring"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2016-04-20T20:29:59.208Z",
    "geo_version": 2,
    "dagi_id": "199900"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9940",
    "nr": "9940",
    "navn": "Læsø",
    "stormodtageradresser": null,
    "bbox": [
      10.68791713,
      56.85544166,
      11.72928781,
      57.65160008
    ],
    "visueltcenter": [
      11.14575743,
      57.18887848
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0825",
        "kode": "0825",
        "navn": "Læsø"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199940"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9970",
    "nr": "9970",
    "navn": "Strandby",
    "stormodtageradresser": null,
    "bbox": [
      10.43401211,
      57.475913,
      10.58653723,
      57.56490263
    ],
    "visueltcenter": [
      10.52915912,
      57.51074154
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0813",
        "kode": "0813",
        "navn": "Frederikshavn"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199970"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9981",
    "nr": "9981",
    "navn": "Jerup",
    "stormodtageradresser": null,
    "bbox": [
      10.34659163,
      57.49200445,
      10.59921075,
      57.58197217
    ],
    "visueltcenter": [
      10.43874206,
      57.53549926
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0813",
        "kode": "0813",
        "navn": "Frederikshavn"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199981"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9982",
    "nr": "9982",
    "navn": "Ålbæk",
    "stormodtageradresser": null,
    "bbox": [
      10.05551598,
      57.51966093,
      10.63889706,
      57.82712313
    ],
    "visueltcenter": [
      10.40473272,
      57.60572774
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0813",
        "kode": "0813",
        "navn": "Frederikshavn"
      },
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0860",
        "kode": "0860",
        "navn": "Hjørring"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199982"
  },
  {
    "href": "https://api.dataforsyningen.dk/postnumre/9990",
    "nr": "9990",
    "navn": "Skagen",
    "stormodtageradresser": null,
    "bbox": [
      9.9758433,
      57.58111866,
      11.24698377,
      58.25933653
    ],
    "visueltcenter": [
      10.48570715,
      57.85540126
    ],
    "kommuner": [
      {
        "href": "https://api.dataforsyningen.dk/kommuner/0813",
        "kode": "0813",
        "navn": "Frederikshavn"
      }
    ],
    "ændret": "2018-04-30T15:23:13.528Z",
    "geo_ændret": "2014-11-04T16:01:00.879Z",
    "geo_version": 1,
    "dagi_id": "199990"
  }
] as DawaZipCode[];