import {DocumentData, QueryDocumentSnapshot, FirestoreDataConverter} from '@firebase/firestore-types';
import {ArticleBrief} from "../../domain/article";

export default {
  fromFirestore(snapshot: QueryDocumentSnapshot): ArticleBrief {
    const data = snapshot.data();
    return {
      id: data.id,
      articleId: data.articleId,
      headline: data.headline,
      subHeadline: data.subHeadline,
      alternateColorHeadline: data.alternateColorHeadline,
      publishDate: data.publishDate.toDate(),
      image: data.image,
      theme: data.theme
    }
  },
  toFirestore(modelObject: ArticleBrief): DocumentData {
    return modelObject;
  }
} as FirestoreDataConverter<ArticleBrief>;