import firebase from "firebase";
import {useEffect, useReducer} from "react";
import {GarbageReport, OilReport, TagReport} from "../../../hmv-mobile/src/domain/report";
import GarbageReportConverter from '../../../hmv-mobile/src/repositories/converters/garbage-report-converter';
import TagReportConverter from '../../../hmv-mobile/src/repositories/converters/tag-report-converter';
import OilReportConverter from '../../../hmv-mobile/src/repositories/converters/oil-report-converter';
import {setHours, setMinutes} from "date-fns";

type ReportState = {
  oilReports: OilReport[];
  garbageReports: GarbageReport[];
  tagReports: TagReport[];
}

type Action = { type: 'add-oil-reports', reports: OilReport[] }
| { type: 'add-garbage-reports', reports: GarbageReport[] }
| { type: 'add-tag-reports', reports: TagReport[] };

function reducer(state: ReportState, action: Action): ReportState {
  const {reports} = action;
  if (action.type === "add-garbage-reports") {
    return {...state, garbageReports: reports};
  }

  if (action.type === "add-oil-reports") {
    return {...state, oilReports: reports as OilReport[]};
  }

  if (action.type === "add-tag-reports") {
    return {...state, tagReports: reports as TagReport[]};
  }

  return state;
}

export function useAllReports(from: Date, to: Date): [OilReport[], GarbageReport[], TagReport[]] {

  const [state, dispatch] = useReducer(reducer, { oilReports: [], garbageReports: [], tagReports: [] });
  const {oilReports, garbageReports, tagReports} = state;

  useEffect(() => {
    const endTo = setMinutes(setHours(to, 23), 59);

    firebase.firestore().collection('garbage-reports')
      .withConverter<GarbageReport>(GarbageReportConverter)
      .where('dateTime', ">=", from)
      .where('dateTime', '<', endTo)
      .orderBy('dateTime', 'desc')
      .onSnapshot((query) => {
        if (!query.empty) {
          const reports: GarbageReport[] = [];
          query.forEach((report) => reports.push({...report.data()}));
          dispatch({ type: 'add-garbage-reports', reports });
        }
      });

    firebase.firestore().collection('oil-reports')
      .withConverter<OilReport>(OilReportConverter)
      .where('dateTime', ">=", from)
      .where('dateTime', '<', endTo)
      .orderBy('dateTime', 'desc')
      .onSnapshot((query) => {
        if (!query.empty) {
          const reports: OilReport[] = [];
          query.forEach((report) => reports.push({...report.data()}));
          dispatch({ type: 'add-oil-reports', reports });
        }
      });

    firebase.firestore().collection('tag-reports')
      .withConverter<TagReport>(TagReportConverter)
      .where('dateTime', ">=", from)
      .where('dateTime', '<', endTo)
      .orderBy('dateTime', 'desc')
      .onSnapshot((query) => {
        if (!query.empty) {
          const reports: TagReport[] = [];
          query.forEach((report) => reports.push({...report.data()}));
          dispatch({ type: 'add-tag-reports', reports });
        }
      });
  }, [from, to]);

  return [oilReports, garbageReports, tagReports];
}