import {DocumentData, QueryDocumentSnapshot, FirestoreDataConverter} from '@firebase/firestore-types';
import JuniorSignup from '../../domain/junior-signup';

export default {
  fromFirestore(snapshot: QueryDocumentSnapshot): JuniorSignup {
    const data = snapshot.data();
    return {
      address: data.address,
      email: data.email,
      id: snapshot.id,
      firstName: data.firstName,
      lastName: data.lastName,
      parentId: data.parentId,
      signUpDate: data.signUpDate.toDate(),
      birthYear: data.birthYear
    };
  },
  toFirestore(juniorSignup: JuniorSignup): DocumentData {
   return juniorSignup;
  }
} as FirestoreDataConverter<JuniorSignup>;
