import React, {FunctionComponent, useState} from "react";
import {deleteSingleAdmin, useAdmins} from "../repository/admin-repository";
import styled from 'styled-components';
import {Button, CircularProgress, TextField} from "@material-ui/core";
import axios from 'axios';
import Dialog from '../components/dialog';
import {Administrator} from "../types";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
`;

export const Name = styled.p`
  font-size: 16px;
  margin-bottom: 0;
`;

const Email = styled.span`
  font-size: 12px;
  opacity: 0.7;
`;

export const CreateBox = styled.div`
  display: inline-block;
  margin: 20px 0;
  padding: 20px;
  background-color: rgba(216, 216, 216, 0.3);
  width: 500px;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const createUrl = 'https://us-central1-havmiljovogterne.cloudfunctions.net/createAdmin';
const AdminSettings: FunctionComponent = () => {
  const [admins, isLoadingAdmins] = useAdmins();
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [toDelete, setToDelete] = useState<Administrator | undefined>();

  const create = async () => {
    if (!email || !password || !name) return;
    setIsLoading(true);

    try {
      await axios.post<Administrator>(createUrl, {
        email,
        password,
        name
      });

      setName('');
      setEmail('');
      setPassword('');
      setShowCreate(false);
    } catch (error) {
      alert(error);
    } finally {
      setIsLoading(false);
    }
  }

  const deleteAdmin = async () => {
    if (!toDelete) return;

    setIsDeleting(true);
    await deleteSingleAdmin(toDelete.id);
    setShowDelete(false);
    setIsDeleting(false);
  };

  return <>
    <h3>Administratorer</h3>
    <Dialog open={showDelete} onClose={() => setShowDelete(false)} onAccept={deleteAdmin} title={'Slet admin?'} cancel={'Nej'} accept={'Ja, slet'}>
      <p>Bekræft venligst at du ønsker at slette {toDelete?.name}</p>

      {isDeleting && <CircularProgress style={{marginTop: '16px'}} />}
    </Dialog>

    <Button color={'primary'} variant={'contained'} onClick={() => setShowCreate(true)}>Opret administrator</Button>

    <div>
      {showCreate && <CreateBox>
          <h3 style={{marginTop: '0'}}>Opret administrator</h3>
          <TextField
              fullWidth
              style={{marginBottom: '16px'}}
              value={name}
              onChange={e => setName(e.target.value)}
              label={'Navn'}
          />
          <TextField
              fullWidth
              style={{marginBottom: '16px'}}
              label={'E-mail'}
              value={email}
              onChange={(e) => setEmail(e.target.value)}/>
          <TextField
              fullWidth
              label={'Password'}
              style={{marginBottom: '16px'}}
              onChange={(e) => setPassword(e.target.value)}
              value={password}/>
          <Buttons>
              <Button variant={'outlined'} onClick={() => {
                setEmail('');
                setPassword('');
                setShowCreate(false);
              }}>Annuller</Button>
              <Button color={'primary'} variant={'contained'} onClick={create}>Opret</Button>
          </Buttons>

        {isLoading && <CircularProgress style={{marginTop: '16px'}} />}
      </CreateBox>}
    </div>


    {!isLoadingAdmins && admins.map((admin) => {
      return <Row key={admin.id}>
        <div>
          <Name>{admin.name}</Name>
          <Email>{admin.id.replace('admin::', '')}</Email>
        </div>
        <div>
          <Button color={'primary'} variant={'contained'} onClick={() => {
            setShowDelete(true);
            setToDelete(admin);
          }}>Slet</Button>
        </div>
      </Row>
    })}

  </>

};

export default AdminSettings;