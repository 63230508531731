import * as React from 'react';
import AdminSettings from "../components/admin-settings";
import SignupSettings from '../components/signup-settings';
import OrderSettings from '../components/order-settings';
import JuniorSettings from '../components/junior-settings';
import AppSettings from '../components/app-settings';
import OilReportSettings from "../components/oil-report-settings";
import styled from 'styled-components';

const Spacer = styled.div`
  margin-bottom: 40px;
`;

const Settings: React.FC = () => {
  return <>
    <h1>
      Indstillinger
    </h1>

    <Spacer>
      <AppSettings />
    </Spacer>

    <Spacer>
      <AdminSettings/>
    </Spacer>

    <Spacer>
      <OilReportSettings />
    </Spacer>

    <Spacer>
      <SignupSettings />
    </Spacer>

    <Spacer>
      <OrderSettings />
    </Spacer>

    <Spacer>
      <JuniorSettings />
    </Spacer>
  </>
};

export default Settings;