import * as React from 'react';
import Article, {ArticleBrief} from "../../../hmv-mobile/src/domain/article";
import ArticleBriefConverter from '../../../hmv-mobile/src/repositories/converters/article-brief';
import ArticleConverter from '../../../hmv-mobile/src/repositories/converters/article-converter';
import firebase from "firebase";
const {useState, useEffect} = React;

export async function createArticle (article: Article): Promise<void> {
  const articleRef = firebase.firestore().collection('articles')
    .doc();

  const briefRef = firebase.firestore().collection('article-briefs')
    .doc(articleRef.id);

  const {
    headline,
    subHeadline,
    alternateColorHeadline,
    image,
    publishDate,
    theme
  } = article;

  const brief: ArticleBrief = {
    id: articleRef.id,
    headline,
    subHeadline,
    alternateColorHeadline,
    image,
    publishDate,
    theme,
    articleId: articleRef.id
  }

  await articleRef.set({...article, id: articleRef.id});
  await briefRef.set(brief);
}

export async function updateArticle(article: Article): Promise<void> {
  await firebase.firestore().collection('articles')
    .doc(article.id)
    .set(article);

  const {
    headline,
    subHeadline,
    alternateColorHeadline,
    image,
    publishDate,
    theme
  } = article;

  const brief: ArticleBrief = {
    id: article.id,
    headline,
    subHeadline,
    alternateColorHeadline,
    image,
    publishDate,
    theme,
    articleId: article.id
  }

  await firebase.firestore().collection('article-briefs')
    .doc(article.id)
    .set(brief);
}

export async function getArticle(articleId: string): Promise<Article | undefined> {
  const snapshot = await firebase.firestore().collection('articles')
    .withConverter<Article>(ArticleConverter)
    .doc(articleId)
    .get();

  return snapshot.data();
}

export async function deleteArticle(articleId: string): Promise<void> {
  await firebase.firestore().collection('articles')
    .doc(articleId)
    .delete();

  await firebase.firestore().collection('article-briefs')
    .doc(articleId)
    .delete();
}

export function useArticlesBriefsListener(): [ArticleBrief[], boolean] {
  const [articles, setArticles] = useState<ArticleBrief[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
      return firebase.firestore().collection('article-briefs')
        .withConverter<ArticleBrief>(ArticleBriefConverter)
        .orderBy('publishDate', 'desc')
        .onSnapshot((query) => {
          const articles: ArticleBrief[] = [];

          if (!query.empty) {
            query.forEach((snapshot) => articles.push({...snapshot.data(), id: snapshot.id}));
          }

          setArticles(articles);
          setIsLoading(false);
        });
    }, []);

  return [articles, isLoading];
}