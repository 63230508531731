import {ContaminationSize, WindDirection} from "../../../hmv-mobile/src/pages/report/report-oil";

export const mapContaminationSize = (contamination: ContaminationSize): string => {
  switch(contamination) {
    case "below-10":
      return 'Mindre end 10 m2';
    case "10-100":
      return 'Mellem 10-100 m2';
    case "100-200":
      return "Mellem 100-200 m2";
    case "above-200":
      return "Større end 200 m2";
    default:
      return 'Ukendt størrelsesomfang angivet';
  }
}

export const mapWindDirection = (windDirection: WindDirection): string => {
  switch (windDirection) {
    case "east":
      return 'Øst';
    case "north":
      return 'Nord';
    case "north-east":
      return "Nordøst";
    case "north-west":
      return 'Nordvest';
    case "south":
      return 'South';
    case "south-east":
      return 'Sydvest';
    case "south-west":
      return "Sydvest";
    default:
      return "Vest";
  }
}