enum ROUTES {
  'home' = '/',
  'login' = '/login',
  'user_details' = '/bruger-detaljer/:userId',
  'reports' = '/indberetninger',
  'articles' = '/artikler',
  'edit_article' = '/rediger-artikel/:articleId',
  'create_article' = '/opret-artikel',
  'notifications' = '/notifikationer',
  'notification_send' = '/send-notifikation',
  'settings' = '/indstillinger',
  'outlets' = '/udleveringssteder',
  'juniors' = '/junior-tilmeldinger',
  'orders' = '/bestillinger'
}

export default ROUTES;