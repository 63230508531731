import * as React from 'react';
import {DatePicker} from "@material-ui/pickers";
import {useState} from "react";
import {subDays} from 'date-fns';
import {useAllReports} from "../repository/report-repository";
import Accordion from "../components/accordion";
import OilReportContent from "../components/oil-report-details";
import GarbageCollectionDetails from "../components/garbage-collection-details";
import TagReportDetails from "../components/tag-report-details";
import {ReportHeader} from "./user-details";
import styled from 'styled-components';
import _ from 'lodash';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
`;

const Kpi = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-left: 20px;
  
  h1 {
    margin-top: 8px;
  }
`;

const Reports: React.FC = () => {
  const [from, setFrom] = useState<Date>(subDays(new Date, 7));
  const [to, setTo] = useState<Date>(new Date());

  const [oilReports, garbageReports, tagReports] = useAllReports(from, to);
  const numberOfBags = _.sumBy(garbageReports, 'numberOfBags');
  const numberOfSacks = _.sumBy(garbageReports, 'numberOfSacks');

  return <>
    <h1>
      Indberetninger
    </h1>

    <Container>
      <div>
        <DatePicker value={from} label={'Fra'} onChange={setFrom} disableFuture style={{marginRight: '16px'}}/>
        <DatePicker value={to} label={'Til'} onChange={setTo} disableFuture/>
      </div>

      <div>
        <Kpi>
          <span>
            Antal olieindberetninger
          </span>
          <h1>
            {oilReports.length}
          </h1>
        </Kpi>
        <Kpi>
          <span>
            Samlet havfald (poser / sække)
          </span>
          <h1>
            {numberOfBags} / {numberOfSacks}
          </h1>
        </Kpi>
        <Kpi>
          <span>
            Antal tags
          </span>
          <h1>
            {tagReports.length}
          </h1>
        </Kpi>
      </div>
    </Container>

    <h4>Olieindberetninger</h4>
    {oilReports.length > 0 && <Accordion
        items={oilReports}
        renderHeader={(report) => <ReportHeader report={report}/>}
        renderContent={(report) => <OilReportContent report={report}/>}
    />}

    <h4>Registreret opsamlet havfald</h4>
    {garbageReports.length > 0 && <Accordion
        items={garbageReports}
        renderHeader={(report) => <ReportHeader report={report}/>}
        renderContent={(report) => <GarbageCollectionDetails report={report} linkToUser={false}/>}
    />}

    <h4>Tagget havfald</h4>
    {tagReports.length > 0 && <Accordion
        items={tagReports}
        renderHeader={(report) => <ReportHeader report={report}/>}
        renderContent={(report) => <TagReportDetails report={report}/>}
    />}
  </>
};

export default Reports;