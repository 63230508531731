import * as React from 'react';
const {useEffect} = React;
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import {Switch, Route, useLocation} from 'react-router-dom';
import Menu from './menu/menu';
import Header from './header/header';
import Users from './pages/users';
import Reports from './pages/reports';
import Articles from './pages/articles';
import Outlets from "./pages/outlets";
import {CreateArticle, EditArticle} from "./pages/create-edit-article";
import Notifications from './pages/notifications';
import Settings from './pages/settings';
import ROUTES from "./routes";
import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';
import UserDetails from "./pages/user-details";
import Login from "./pages/login";
import JuniorSignups from './pages/junior-signups.page';
import Orders from './pages/orders.page';
import {useState} from "react";
import {useHistory} from "react-router";
import GlobalContainer from './components/global-container';
import NotificationSend from './pages/notification-send';

const firebaseConfig = {
  apiKey: "AIzaSyCXQRYLTP3AYKtRbFNiargQnaPA4PhSdr4",
  authDomain: "havmiljovogter-login.web.app",
  projectId: "havmiljovogterne",
  storageBucket: "havmiljovogterne.appspot.com",
  messagingSenderId: "472800310843",
  appId: "1:472800310843:web:92380b0cad142314c58e1e",
  measurementId: "G-WJ8555QCCN"
};

firebase.initializeApp(firebaseConfig);

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: 'auto',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }),
);

type LoginState = 'logged-in' | 'not-logged-in' | 'undetermined';

export default function App() {
  const classes = useStyles();
  const [loginState, setLoginState] = useState<LoginState>('undetermined');
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        setLoginState('not-logged-in');
      } else {
        setLoginState('logged-in');
        if (location.pathname === ROUTES.login)
          history.push(ROUTES.home);
      }
    });
  }, []);

  if (loginState === 'undetermined') return null;
  if (loginState === 'not-logged-in') return <Login />;

  return (
    <div className={classes.root}>
      <GlobalContainer>

        <CssBaseline />
        <Header className={classes.appBar} />
        <Menu classes={classes} />
        <main className={classes.content}>
          <Toolbar />

          <Switch>
            <Route exact path={ROUTES.home}>
              <Users />
            </Route>

            <Route exact path={ROUTES.juniors}>
              <JuniorSignups />
            </Route>

            <Route exact path={ROUTES.user_details}>
              <UserDetails />
            </Route>

            <Route exact path={ROUTES.reports}>
              <Reports />
            </Route>

            <Route exact path={ROUTES.articles}>
              <Articles />
            </Route>

            <Route exact path={ROUTES.create_article}>
              <CreateArticle />
            </Route>

            <Route exact path={ROUTES.edit_article}>
              <EditArticle />
            </Route>

            <Route exact path={ROUTES.settings}>
              <Settings />
            </Route>

            <Route exact path={ROUTES.notifications}>
              <Notifications />
            </Route>

            <Route exact path={ROUTES.notification_send}>
              <NotificationSend />
            </Route>

            <Route exact path={ROUTES.outlets}>
              <Outlets />
            </Route>

            <Route exact path={ROUTES.orders}>
              <Orders />
            </Route>

          </Switch>
        </main>
      </GlobalContainer>

    </div>
  );
}