import * as React from 'react';
const {useState} = React;
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {ArticleBrief} from "../../../hmv-mobile/src/domain/article";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {useHistory} from "react-router";
import Dialog from '../components/dialog';
import ROUTES from "../routes";
import {deleteArticle} from "../repository/content-repository";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
  root: {
    width: 345,
    height: 345,
    marginRight: 16,
    marginBottom: 16,
  },
  media: {
    height: 140,
  },
  content: {
    height: 158,
    overflow: 'hidden'
  }
});

type ArticleBriefProps = {
  articleBrief: ArticleBrief;
}

function ArticleBriefComponent({ articleBrief }: ArticleBriefProps) {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const edit = () => history.push(ROUTES.edit_article.replace(':articleId', articleBrief.articleId));

  const deleteArticleHandler = async () => {
    setIsDeleting(true);
    await deleteArticle(articleBrief.articleId);
    setOpen(false);
    setIsDeleting(false);
  }

  return (
    <>
      <Dialog open={open}
              onClose={() => setOpen(false)}
              onAccept={deleteArticleHandler}
              cancel={'Nej'}
              accept={'Ja, slet'}
              title={`Slet artikel?`}>
        <p>Bekræft venligst at du ønsker at slette "{articleBrief.headline}".</p>

        {isDeleting && <CircularProgress />}
      </Dialog>

      <Card className={classes.root} style={{display: 'flex', flexDirection: 'column'}}>
        <CardActionArea onClick={edit}>
          <CardMedia
            className={classes.media}
            image={articleBrief.image}
            title={articleBrief.headline}
          />
          <CardContent className={classes.content}>
            <Typography variant="body2" color="textSecondary" component="p">
              {articleBrief.subHeadline}
            </Typography>
            <Typography gutterBottom variant="h5" component="h2">
              {articleBrief.headline}
            </Typography>

          </CardContent>
        </CardActionArea>
        <CardActions style={{marginTop: 'auto'}}>
          <Button size="small"
                  color="primary"
                  onClick={edit}>
            Rediger
          </Button>
          <Button size="small" color="primary" onClick={() => setOpen(true)}>
            <span style={{marginRight: '4px'}}>Slet</span> <DeleteOutlineIcon />
          </Button>
        </CardActions>
      </Card>
    </>
  );
}

export default ArticleBriefComponent;