import * as React from 'react';
const {useMemo} = React;
import {RowContainer} from "./users";
import {CircularProgress} from "@material-ui/core";
import {DataGrid, GridColDef} from "@material-ui/data-grid";
import {format} from 'date-fns';
import {downloadAsCSV} from "../downloadAsCSV";
import Button from "@material-ui/core/Button";
import {GlobalContext, GlobalContextState} from "../components/global-container";
import {useContext} from "react";
import Address from "../../../hmv-mobile/src/domain/address";

type PresentationOrder = {
  id: string;
  name: string;
  address: string;
  sentAt: string;
  printIssue: string;
  bags: string;
  flag: string;
  sticker: string;
  fabricBrand: string;
  cap: string;
}

export const oneLineAddress = (address: Address): string => {
  const cRender = (prop?: string): string => {
    return prop ? ` ${prop}` : '';
  }

  return `${address.street}${cRender(address.houseNumber)}${cRender(address.door)}${cRender(address.floor)}, ${address.city.name} ${address.city.zipCode}`;
}

const isTrue = (value: boolean): string => value ? 'Ja' : 'Nej';

const columns: GridColDef[] = [
  { field: 'name', headerName: 'Navn', flex: 1 },
  { field: 'address', headerName: 'Adresse', flex: 1 },
  { field: 'sentAt', headerName: 'Dato', flex: 1 },
  { field: 'printIssue', headerName: 'Magasin' },
  { field: 'bags', headerName: 'Poser' },
  { field: 'flag', headerName: 'Vimpel' },
  { field: 'sticker', headerName: 'Klistermærke' },
  { field: 'fabricBrand', headerName: 'Stofmærke' },
  { field: 'cap', headerName: 'Hue' },
];

const Orders: React.FC = () => {
  const {orders} = useContext<GlobalContextState>(GlobalContext);
  const isLoading = orders.length === 0;

  const presentationOrders = useMemo<PresentationOrder[]>(() => {
    return orders.map((order) => ({
      name: `${order.firstName} ${order.lastName}`,
      address: oneLineAddress(order.address),
      printIssue: isTrue(order.printIssue),
      bags: isTrue(order.bags),
      flag: isTrue(order.flag),
      sticker: isTrue(order.sticker),
      fabricBrand: isTrue(order.fabricBrand),
      cap: isTrue(order.cap),
      sentAt: format(order.sentAt, 'dd-MM-yyyy HH:mm'),
      id: order.id
    }));
  }, [orders]);

  const download = () => {
    const lines = [
      '\ufeff',
      'Navn;Adresse;Dato;Magasin;Poser;Vimpel;Klistermærke;Stofmærke;Hue'
    ];

    presentationOrders.forEach((order) => {
      const {
        name, address, printIssue, bags, flag, sticker, fabricBrand, cap, sentAt
      } = order;
      lines.push(`${name};${address};${sentAt};${printIssue};${bags};${flag};${sticker};${fabricBrand};${cap}`);
    });

    downloadAsCSV(lines, 'bestillinger');
  }

  return <>
    <h1>Bestillinger</h1>

    {!isLoading && <>
        <Button
            onClick={download}
            style={{marginTop: '10px', marginBottom: '20px'}}
            variant={'outlined'}
        >Eksporter</Button>

        <div style={{height: 800, width: '100%'}}>
            <DataGrid columns={columns} rows={presentationOrders} />
        </div>
    </>}

    {isLoading && <RowContainer>
        <CircularProgress />
    </RowContainer>}

  </>;

};

export default Orders;