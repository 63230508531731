import {DocumentData, QueryDocumentSnapshot, FirestoreDataConverter} from '@firebase/firestore-types';
import MaterialOrder from "../../domain/material-order";
import {mapAddress} from "./user-converter";
import _ from 'lodash';
import Address from "../../domain/address";

export default {
  fromFirestore(snapshot: QueryDocumentSnapshot): MaterialOrder {
    const data = snapshot.data();
    return {
      id: snapshot.id,
      bags: data.bags,
      flag: data.flag,
      sticker: data.sticker,
      fabricBrand: data.fabricBrand,
      cap: data.cap,
      printIssue: data.printIssue,
      sentAt: data.sentAt.toDate(),
      userId: data.userId,
      address: mapAddress(data),
      firstName: data.firstName,
      lastName: data.lastName
    }
  },
  toFirestore(modelObject: MaterialOrder): DocumentData {
    // @ts-ignore
    modelObject.address = _.mapValues<Address>(modelObject.address, (prop) => {return prop === undefined ? null : prop});

    return modelObject;
  }
} as FirestoreDataConverter<MaterialOrder>;