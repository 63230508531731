import axios from 'axios';

type CompressionResponse = {
  result: string;
}
// const url = 'http://localhost:4000/compress-image';
const url = 'https://hmv-core-facade.herokuapp.com/compress-image';
export default async function compressImage (img: string): Promise<CompressionResponse> {
  const response = await axios.post<CompressionResponse>(url, {
    img
  }, {
    headers: {
      'Content-Type': 'application/json'
    }
  });

  return response.data;
};