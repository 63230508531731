import React, {FunctionComponent} from "react";
import {GarbageReport} from "../../../hmv-mobile/src/domain/report";
import {Grid} from "@material-ui/core";
import {Label, ValueContainer} from "./oil-report-details";
import {Link} from 'react-router-dom';
import ROUTES from "../routes";
import {useSingleUser} from "../repository/user-repository";
import PositionLink from "./position-link";

type GarbageCollectionDetailsProps = {
  report: GarbageReport;
  linkToUser?: boolean;
}

const GarbageCollectionDetails: FunctionComponent<GarbageCollectionDetailsProps> = ({report, linkToUser = true}) => {
  const {numberOfBags, numberOfSacks, userId} = report;
  const [reporter] = useSingleUser(userId);

  return <Grid container xs={12}>
    <Grid item xs={6}>
      <ValueContainer>
        <Label>Antal opsamlede poser</Label>
        <span>{numberOfBags}</span>
      </ValueContainer>
      <ValueContainer>
        <Label>Antal opsamlede sække</Label>
        <span>{numberOfSacks}</span>
      </ValueContainer>
      <ValueContainer>
        <Label>
          Opsamlet af
        </Label>
        {reporter && <Link to={ROUTES.user_details.replace(':userId', reporter.id)}>{reporter.firstName} {reporter.lastName}</Link>}
      </ValueContainer>
      <ValueContainer>
        <Label>Position</Label>
        <PositionLink position={report.position} rightAlign />
      </ValueContainer>
    </Grid>
    <Grid item xs={6} style={{textAlign: 'right'}}>
      {linkToUser && <Link to={ROUTES.user_details.replace(':userId', userId)}>Vis bruger</Link>}
    </Grid>
  </Grid>;

};

export default GarbageCollectionDetails;