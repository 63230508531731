import React, {FunctionComponent, useState} from "react";
import {useParams} from "react-router";
import {useReports, useSingleUser} from "../repository/user-repository";
import {Chip, CircularProgress, Grid, Button} from "@material-ui/core";
import styled from 'styled-components';
import Accordion from "../components/accordion";
import {format} from 'date-fns';
import {Report} from "../../../hmv-mobile/src/domain/report";
import OilReportContent from "../components/oil-report-details";
import GarbageCollectionDetails from "../components/garbage-collection-details";
import TagReportDetails from "../components/tag-report-details";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import firebase from 'firebase';
import Alert from '@material-ui/lab/Alert';

const Thick = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0;
`;

export const ReportHeader: FunctionComponent<{ report: Report }> = ({report}) => {
  const date = format(report.dateTime, 'dd-MM-yyyy HH:mm');
  return <span>{date}</span>;
}

const UserDetails: FunctionComponent = () => {
  const {userId} = useParams<{ userId: string }>();
  const [user, isLoading] = useSingleUser(userId);
  const [garbageReports, oilReports, tagReports] = useReports(userId);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const send = async () => {
    if (!user?.email) return;

    setIsSending(true);
    await firebase.auth().sendPasswordResetEmail(user.email);
    setIsSending(false);
    setShowConfirmation(true);
    setTimeout(() => setShowConfirmation(false), 2500);
  }

  return <>
    {isLoading && <CircularProgress/>}
    {!isLoading && user && <>
        {showConfirmation && <Alert severity={'success'}>Der er sendt en mail med nulstilling af password til brugeren.</Alert>}

        <h1>{user.firstName} {user.lastName}</h1>
        {user.pushToken && <p style={{marginBottom: '20px'}}><CheckCircleOutlineIcon style={{position: 'relative', top: '7px'}} /> <i>Denne bruger har accepteret push-notifikationer.</i></p>}
        <Button onClick={send} variant={'outlined'} disabled={isSending}>Send nulstilling af password</Button>

        <Grid container>
            <Grid item xs={4}>
                <h2>Personlige oplysninger</h2>
                <p>
                  {user.email}<br/>
                  {user.mobileNumber}
                </p>

                <Thick>Adresse</Thick>
                <p>
                  {user.address?.street} {user.address?.houseNumber}<br/>
                  {user.address?.secondAddressLine && <>{user.address?.secondAddressLine} <br/></>}
                  {user.address?.city.zipCode} {user.address?.city.name}
                </p>

                <Thick style={{marginBottom: '12px'}}>
                    Indsamlet havfald
                </Thick>

              {Object.entries(user.numberOfBagsCollected).map(([year, bags]) => (<div>
                <Chip label={year}/>
                <span style={{marginLeft: '12px'}}>{bags}</span>
              </div>))}

            </Grid>
            <Grid item xs={8}>
                <h2>Olieindberetninger</h2>
              {oilReports.length > 0 && <Accordion
                  items={oilReports}
                  renderHeader={(report) => <ReportHeader report={report}/>}
                  renderContent={(report) => <OilReportContent report={report}/>}
              />}

                <h2>Registreret opsamlet havfald</h2>
              {garbageReports.length > 0 && <Accordion
                  items={garbageReports}
                  renderHeader={(report) => <ReportHeader report={report}/>}
                  renderContent={(report) => <GarbageCollectionDetails report={report} linkToUser={false}/>}
              />}

                <h2>Tagget havfald</h2>
              {tagReports.length > 0 && <Accordion
                  items={tagReports}
                  renderHeader={(report) => <ReportHeader report={report}/>}
                  renderContent={(report) => <TagReportDetails report={report}/>}
              />}
            </Grid>
        </Grid>
    </>}
  </>
}

export default UserDetails;