import {DocumentData, QueryDocumentSnapshot, FirestoreDataConverter} from '@firebase/firestore-types';
import AppSettings from "../../domain/settings/app-settings";

export default {
  fromFirestore(snapshot: QueryDocumentSnapshot): AppSettings {
    const data = snapshot.data();
    return {
      liveStatsEnabled: data.liveStatsEnabled,
      placeholderNumber: data.placeholderNumber,
      coreFacadeEndpoint: data.coreFacadeEndpoint
    };
  },
  toFirestore(settings: AppSettings): DocumentData {
    return settings;
  }
} as FirestoreDataConverter<AppSettings>;