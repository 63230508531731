import {GarbageReport, OilReport, TagReport} from "../../../hmv-mobile/src/domain/report";
import {useEffect, useState} from "react";
import {getDownloadUrl} from "../../../hmv-mobile/src/repositories/storage-bucket";

function useReportImage(report?: OilReport | TagReport): string | undefined {
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    if (report?.image) {
      getDownloadUrl(report.image)
        .then((url) => {
          setUrl(url);
        });
    }
  }, [report]);

  return url;
}

export default useReportImage;