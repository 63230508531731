import React, {FunctionComponent, useState, useCallback, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import {Button} from "@material-ui/core";
import ROUTES from "../routes";
import {useHistory, useParams} from "react-router";
import Editor from '../components/editor';
import {DateTimePicker} from "@material-ui/pickers";
import Article, {ArticleTheme} from "../../../hmv-mobile/src/domain/article";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import compressImage from "../functions/compressImage";
import {createArticle, getArticle, updateArticle} from "../repository/content-repository";
import CircularProgress from '@material-ui/core/CircularProgress';

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ImagePreview = styled.img`
  width: 100%;
`;

type CreateEditArticleProps = {
  isEdit: boolean;
  article?: Article;
  save: (article: Article, shouldCompressImage: boolean) => Promise<void>;
}


export const CreateArticle: FunctionComponent = () => {
  const history = useHistory();
  const save = async (article: Article) => {
    const compressedImage = await compressImage(article.image);
    await createArticle({...article, image: compressedImage.result});
    history.push(ROUTES.articles);
  }

  return <CreateEditArticle isEdit={false} save={save}/>;
};

export const EditArticle: FunctionComponent = () => {
  const {articleId} = useParams() as { articleId: string };
  const [article, setArticle] = useState<Article | undefined>();

  useEffect(() => {
    getArticle(articleId)
      .then((article) => {
        if (article) {
          setArticle(article);
        }
      });
  }, [articleId]);

  const save = async (article: Article, shouldCompressImage: boolean) => {
    let image = article.image;
    if (shouldCompressImage) {
      const compressedImage = await compressImage(article.image);
      image = compressedImage.result;
    }

    await updateArticle({...article, image});
  }

  if (!article) return null;

  return <CreateEditArticle isEdit save={save} article={article}/>;
}

const CreateEditArticle: FunctionComponent<CreateEditArticleProps> = ({isEdit, article, save}) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [headline, setHeadline] = useState<string>(article?.headline || '');
  const [subHeadline, setSubHeadline] = useState<string>(article?.subHeadline || '');
  const [alternateColorHeadline, setAlternateColorHeadline] = useState<string>(article?.alternateColorHeadline || '');
  const [content, setContent] = useState<string>(article?.content || '');
  const [leadText, setLeadText] = useState<string>(article?.leadText || '');
  const [publishDate, setPublishDate] = useState<Date>(article?.publishDate || new Date());
  const [image, setImage] = useState<string>(article?.image || '');
  const [imageHasChanged, setImageHasChanged] = useState<boolean>(!isEdit);
  const [theme, setTheme] = useState<ArticleTheme>(article?.theme || 'light');
  const history = useHistory();

  const onSave = async () => {
    setIsSaving(true);

    try {
      await save({
        id: article?.id || '',
        headline,
        subHeadline,
        alternateColorHeadline,
        content,
        leadText,
        publishDate,
        image,
        theme
      }, imageHasChanged);
    } catch (error) {
      alert(`Der er en sket en fejl: ${error}`);
    } finally {
      setIsSaving(false);
    }
  }

  const handleFileChanged = useCallback((e) => {
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      // @ts-ignore
      const {target: {result}} = event;
      setImage(result);
      setImageHasChanged(true);
    };

    const file = e.target.files[0];
    const fileSize = file.size / 1000000;

    if (fileSize > 3.5) {
      alert('Det valgte billede er for stort.');
      return;
    }

    fileReader.readAsDataURL(file);
  }, []);

  const isValid = headline
    && content
    && image;

  return <>
    <h1>{isEdit ? 'Rediger artikel' : 'Opret artikel'}</h1>
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Grid item xs={12}>
          <TextField style={{marginBottom: '16px'}} fullWidth value={headline}
                     onChange={(e) => setHeadline(e.target.value)} label={'Overskrift'}/>
        </Grid>
        <Grid item xs={12}>
          <TextField style={{marginBottom: '16px'}} fullWidth value={subHeadline}
                     onChange={(e) => setSubHeadline(e.target.value)} label={'Sekundær overskrift'}/>
        </Grid>
        <Grid item xs={12}>
          <TextField fullWidth value={alternateColorHeadline}
                     onChange={(e) => setAlternateColorHeadline(e.target.value)}
                     label={'Tertiær overskrift for alternativ farve tema'}/>
        </Grid>
        <Grid item xs={12} style={{marginTop: '16px'}}>
          <TextField value={leadText}
                     fullWidth
                     multiline
                     label={"Underrubrik"}
                     rows={3}
                     onChange={(e) => setLeadText(e.target.value)}/>
        </Grid>
      </Grid>

      <Grid item xs={6}>
        {image && <ImagePreview src={image}/>}
      </Grid>

      <Grid container spacing={3} style={{padding: '12px'}}>
        <Grid item xs={2}>
          <DateTimePicker
            autoOk
            ampm={false}
            value={publishDate}
            onChange={setPublishDate}
            label="Udgivelsesdato"
          />
        </Grid>
        <Grid item xs={4}>
          <InputLabel>Palette</InputLabel>
          <Select value={theme} onChange={(e) => setTheme(e.target.value as ArticleTheme)}>
            <MenuItem value={'light'}>Lys</MenuItem>
            <MenuItem value={'color'}>Farvet</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={2}>
          <InputLabel>Billede (max 3,5MB)</InputLabel>
          <input
            type={'file'}
            style={{marginTop: '12px'}}
            accept={'.jpg,.jpeg,.png'}
            onChange={handleFileChanged}
          />
        </Grid>
      </Grid>


      <Grid item xs={12}>
        <Editor value={content} setValue={setContent}/>
      </Grid>

      <Grid item xs={12}>
        <Footer>
          <Button variant={'contained'} onClick={() => history.push(ROUTES.articles)}>{'<'} Annuller</Button>

          <span>
            {isSaving && <CircularProgress
              style={{width: '20px', height: '20px', position: 'relative', top: '4px', marginRight: '8px'}}/>}
            <Button variant={'contained'} color={'primary'} onClick={onSave} disabled={isSaving || !isValid}>Gem</Button>
          </span>

        </Footer>
      </Grid>
    </Grid>
  </>;

}