import * as React from 'react';
import firebase from "firebase";
import {Administrator} from "../types";
import {DocumentData, FirestoreDataConverter, QueryDocumentSnapshot} from "@firebase/firestore-types";
const {useState, useEffect} = React;

const adminConverter = {
  fromFirestore(snapshot: QueryDocumentSnapshot) {
    const data = snapshot.data();
    return {
      id: data.id,
      created: data.created.toDate(),
      name: data.name
    }
  },
  toFirestore(modelObject: Administrator): DocumentData {
    return modelObject;
  }

} as FirestoreDataConverter<Administrator>;

export function useAdmins(): [Administrator[], boolean] {
  const [admins, setAdmins] = useState<Administrator[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    firebase.firestore().collection('admin-users')
      .withConverter<Administrator>(adminConverter)
      .onSnapshot((query) => {
        const admins: Administrator[] = [];
        if (!query.empty) {
          query.forEach((adminSnapshot) =>
            admins.push({...adminSnapshot.data(), id: adminSnapshot.id}));
        }

        setAdmins(admins);
        setIsLoading(false);
      });
  }, []);

  return [admins, isLoading];
}

export const deleteSingleAdmin = async (id: string) => {
  await firebase.firestore().collection('admin-users')
    .doc(id)
    .delete();
}
