import {DocumentData, QueryDocumentSnapshot, FirestoreDataConverter} from '@firebase/firestore-types';
import {GarbageReport} from "../../domain/report";

export default {
  fromFirestore(snapshot: QueryDocumentSnapshot): GarbageReport {
    const data = snapshot.data();
    return {
      id: data.id,
      dateTime: data.dateTime.toDate(),
      userId: data.userId,
      position: data.position,
      numberOfBags: data.numberOfBags,
      numberOfSacks: data.numberOfSacks
    };
  },
  toFirestore(garbageReport: GarbageReport): DocumentData {
    return garbageReport;
  }
} as FirestoreDataConverter<GarbageReport>;