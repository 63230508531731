import firebase from "firebase";
import {useState, useEffect} from 'react';
import MaterialOrder from "../../../hmv-mobile/src/domain/material-order";
import MaterialOrderConverter from '../../../hmv-mobile/src/repositories/converters/material-order-converter';

export function useOrders(): [MaterialOrder[], boolean] {
  const [orders, setOrders] = useState<MaterialOrder[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    return firebase.firestore()
      .collection('material-orders')
      .withConverter<MaterialOrder>(MaterialOrderConverter)
      .onSnapshot((snapshots) => {
        const orders: MaterialOrder[] = [];

        if (!snapshots.empty) {
          snapshots.forEach((order) => orders.push({...order.data()}));
        }

        setOrders(orders);
        setIsLoading(false);
      });
  }, []);

  return [orders, isLoading];
}