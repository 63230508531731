import * as React from 'react';

const {useContext} = React;
import {useAllUsers, UserMetaSnap} from "../repository/user-repository";
import {CSSProperties, FunctionComponent, useState} from "react";
import {CircularProgress, TextField} from "@material-ui/core";
import styled from 'styled-components';
import {DataGrid, GridColDef} from '@material-ui/data-grid';
import {Link} from 'react-router-dom';
import ROUTES from "../routes";
import {GlobalContext, GlobalContextState} from "../components/global-container";
import {downloadAsCSV} from "../downloadAsCSV";
import {oneLineAddress} from "./orders.page";
import Button from "@material-ui/core/Button";

export const InnerRowContainer = styled.div`
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RightColumn = styled.div`
  text-align: right;
  width: 100%;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Label = styled.span`
  font-size: 12px;
  margin-top: -4px;
`;

const year = new Date().getFullYear();

const columns: GridColDef[] = [
  {field: 'firstName', headerName: 'Fornavn', flex: 1},
  {field: 'lastName', headerName: 'Efternavn', flex: 1},
  {field: 'email', headerName: 'E-mail', flex: 1},
  {field: 'mobileNumber', headerName: 'Mobilnummer', flex: 1},
  {field: 'membershipNumber', headerName: 'Medlemsnummer', flex: 1},
  {
    field: '', headerName: 'Detaljer', flex: 1, renderCell: (params) => {
      const userId = params.getValue('id') as string;
      return <RightColumn>
        {userId &&
        <Link to={ROUTES.user_details.replace(':userId', userId)} style={{display: 'inline-block'}}>Vis</Link>}
      </RightColumn>
    }
  }
];

const UserRow: FunctionComponent<{ index: number, style: CSSProperties, data: UserMetaSnap[] }> = ({
                                                                                                     style,
                                                                                                     index,
                                                                                                     data
                                                                                                   }) => {
  const user = data[index];

  return <InnerRowContainer style={style}>
    <span>
      <b>{user?.firstName} {user?.lastName}</b><br/>
      <Label>{user?.email}</Label>
    </span>

    <span>
      <b>Samlet havfald i {year}</b><br/>
      <Label>{user?.numberOfBagsCollected[year]}</Label>
    </span>
  </InnerRowContainer>
};

const Users: React.FC = () => {
  const {users} = useContext<GlobalContextState>(GlobalContext);
  const isLoading = users.length === 0;
  const [searchParameter, setSearchParameter] = useState<string>('');

  const filteredUsers = searchParameter ?
    users.filter((user) =>
      user.firstName && user.firstName.toLowerCase().indexOf(searchParameter.toLowerCase()) > -1
      || user.lastName && user.lastName.toLowerCase().indexOf(searchParameter.toLowerCase()) > -1
      || user.mobileNumber && user.mobileNumber.length > 0 && user.mobileNumber.indexOf(searchParameter) > -1
    )
    : users;

  const download = () => {
    const lines = [
      '\ufeff',
      'Navn;E-mail;Medlemsnummer;Mobilnummer;Adresse'
    ];

    filteredUsers.forEach((user) => {
      const {
        firstName, lastName, email, membershipNumber, mobileNumber, address
      } = user;
      lines.push(`${firstName} ${lastName};${email};${membershipNumber};${mobileNumber};${address && oneLineAddress(address) || ''}`);
    });

    downloadAsCSV(lines, 'havmiljøvogtere');
  }

  return <>
    <h1>
      Havmiljøvogtere
    </h1>

    {!isLoading && <>
        <RowContainer>
            <TextField label={"Søg"}
                       onChange={(e) => setSearchParameter(e.target.value)}
                       value={searchParameter}/>
            <span>Indlæst {users.length} brugere</span>
        </RowContainer>

        <Button onClick={download}
                style={{marginTop: '10px', marginBottom: '20px'}}
                variant={'outlined'}>Eksporter</Button>

        <div style={{height: 800, width: '100%'}}>
            <DataGrid columns={columns} rows={filteredUsers}/>
        </div>
    </>}


    {isLoading && <RowContainer>
        <CircularProgress style={{marginRight: '8px'}}/> <span>Indlæser...</span>
    </RowContainer>}

  </>
};

export default Users;