import React, {FunctionComponent, useState} from "react";
import styled from 'styled-components';
import {TextField, Button, CircularProgress} from "@material-ui/core";
import axios from 'axios';

// @ts-ignore
import logo from '../assets/logo.png';
import firebase from "firebase";

const Img = styled.img`
  width: 200px;
  margin-bottom: 20px;
`;

const Center = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  
`;

export const loginUrl = 'https://us-central1-havmiljovogterne.cloudfunctions.net/loginAdmin';
const Login: FunctionComponent = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const login = async () => {
    setIsLoading(true);

    try {
      const {data: {token}} = await axios.post<{token: string}>(loginUrl, {
        email,
        password
      });

      await firebase.auth().signInWithCustomToken(token);
    } catch (error) {
      setMessage('Der skete en fejl i login. ');
      setTimeout(() => setMessage(''), 2500);
    } finally {
      setIsLoading(false);
    }

  };

  return <Center>
    <Content>
      <Img src={logo} />
      <TextField value={email} onChange={(e) => setEmail(e.target.value)} label={'Email'} />
      <TextField value={password}
                 onChange={(e) => setPassword(e.target.value)}
                 label={'Password'}
                 type={'password'} />
      <Button onClick={login} variant={'outlined'} style={{marginTop: '20px'}} disabled={isLoading}>Login</Button>
      {isLoading && <CircularProgress style={{marginTop: '20px'}} />}
      {message && <p>{message}</p>}
    </Content>
  </Center>;

};

export default Login;

