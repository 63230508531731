import React, {FunctionComponent, useState} from "react";
import Button from "@material-ui/core/Button";
import {CircularProgress, createStyles, TextField} from "@material-ui/core";
import styled from 'styled-components';
import {Alert} from "@material-ui/lab";
import ZipCodes, {DawaZipCode} from '../data/zipcodes';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useArticlesBriefsListener} from "../repository/content-repository";
import Grid from '@material-ui/core/Grid';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import {makeStyles, Theme} from "@material-ui/core/styles";
import usePagination from "../hooks/use-pagination";
import {addNotification} from "../repository/notification-repository";
import Pagination from "@material-ui/lab/Pagination";
import {useHistory} from "react-router";
import ROUTES from "../routes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: 'inline',
    },
  }),
);

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const pageSize = 5;
const NotificationSend: FunctionComponent = () => {
  const [title, setTitle] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [selectedZipCodes, setSelectedZipCodes] = useState<DawaZipCode[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [selectedArticle, setSelectedArticle] = useState<string>();
  const [articles] = useArticlesBriefsListener();
  const [currentPageArticles, numberOfPages, page, setPage] = usePagination(articles, pageSize);
  const [isSending, setIsSending] = useState<boolean>(false);
  const history = useHistory();

  const classes = useStyles();

  const send = async () => {
    if (!title) {
      setErrorMessage('En notifikation skal sendes med en titel til modtagerne.');
      setTimeout(() => setErrorMessage(''), 2500);
      return;
    }

    if (!message) {
      setErrorMessage('En notifikation skal sendes med en besked til modtagerne.');
      setTimeout(() => setErrorMessage(''), 2500);
      return;
    }

    if (!selectedArticle) {
      setErrorMessage('Der skal vælges en artikel som notifikationen skal linke til.');
      setTimeout(() => setErrorMessage(''), 2500);
      return;
    }

    setIsSending(true);
    await addNotification({
      title,
      message,
      articleId: selectedArticle,
      sentAt: new Date(),
      zipCodes: selectedZipCodes.map((zc) => zc.nr)
    });

    history.push(ROUTES.notifications);
  };

  return <>
    <Alert severity={"info"}>Bemærk, denne feature er endnu ikke færdig implementeret. Intet sker når der trykkes
      "Send"</Alert>
    {errorMessage && <Alert severity={'error'}>{errorMessage}</Alert>}

    <h1>Send notifikation</h1>

    <Grid container spacing={4}>
      <Grid item xs={4}>
        <TextField fullWidth
                   label={'Titel'}
                   onChange={(e) => setTitle(e.target.value)}
                   value={title} />

        <TextField fullWidth
                   label={'Besked'}
                   onChange={(e) => setMessage(e.target.value)}
                   value={message} />

        <Autocomplete
          multiple
          style={{margin: '20px 0 40px'}}
          id={'zip-codes'}
          getOptionLabel={(option: DawaZipCode) => `${option.nr} - ${option.navn}`}
          renderInput={(params) => <TextField {...params} label={'Evt. postnumre'} placeholder={'Evt. postnumre'}/>}
          options={ZipCodes}
          onChange={(_, value) => setSelectedZipCodes(value as DawaZipCode[])}
        />
      </Grid>

      <Grid item xs={8}>
        <h3>Vælg artikel</h3>
        <List className={classes.root}>
          {currentPageArticles.map((article, index) => (<>
            <ListItem onClick={() => setSelectedArticle(article.id)}>
              <ListItemAvatar>
                {selectedArticle === article.id ?
                  <div style={{width: '56px', height: '40px'}}>
                    <CheckCircleOutlineIcon style={{width: '40px', height: '40px'}} />
                  </div>
                  : <Avatar src={article.image} />}
              </ListItemAvatar>
              <ListItemText
                style={{cursor: 'pointer'}}
                primary={article.headline}
              />
            </ListItem>
            {index < currentPageArticles.length - 1 && <Divider/>}
          </>))}
        </List>

        <div style={{marginTop: '16px'}}>
          <Pagination count={numberOfPages} page={page} onChange={((_, page) => setPage(page))} />
        </div>
      </Grid>
    </Grid>

    <ButtonContainer>
      <Button variant={'outlined'} onClick={() => history.push(ROUTES.notifications)}>Tilbage</Button>
      <div style={{display: 'inline-flex', flexDirection: 'column'}}>
        <Button variant={"contained"} color={"primary"} onClick={send} disabled={isSending}>Send notifikation</Button>
        {isSending && <CircularProgress />}
      </div>

    </ButtonContainer>
  </>;

};

export default NotificationSend;