import React, {FunctionComponent, useEffect, useState} from 'react';
import {TagReport} from "../../../hmv-mobile/src/domain/report";
import {Grid} from "@material-ui/core";
import UserMeta from "../../../hmv-mobile/src/domain/user-meta";
import {useSingleUser} from "../repository/user-repository";
import {Label, ValueContainer} from "./oil-report-details";
import {CauseForTagging} from "../../../hmv-mobile/src/pages/report/garbage-collection";
import {Link} from 'react-router-dom';
import ROUTES from "../routes";
import useReportImage from "../hooks/use-report-image";
import PositionLink from "./position-link";

type TagReportDetailsProps = {
  report: TagReport;
}

const mapCause = (cause: CauseForTagging): string => {
  switch (cause) {
    case 'too-big':
      return 'For stort til at samle op';
    default:
      return 'Ingen medbragte havfaldsposer';
  }
}

const TagReportDetails: FunctionComponent<TagReportDetailsProps> = ({report}) => {
  const {collected, collectedBy, causeForTagging, userId, position} = report;
  const [reporter] = useSingleUser(userId);
  const [collector] = useSingleUser(collectedBy);
  const imageUrl = useReportImage(report);

  return <Grid container xs={12}>
    <Grid item xs={6}>
      <ValueContainer>
        <Label>
          Årsag til at havfaldet ikke er samlet op
        </Label>
        <span>{mapCause(causeForTagging)}</span>
      </ValueContainer>
      <ValueContainer>
        <Label>
          Tagget af
        </Label>
        {reporter && <Link to={ROUTES.user_details.replace(':userId', reporter.id)}>{reporter.firstName} {reporter.lastName}</Link>}
      </ValueContainer>
      <ValueContainer>
        <Label>Er opsamlet?</Label>
        <span>{collected ? 'Ja' : 'Nej'}</span>
      </ValueContainer>
      {collector && <ValueContainer>
          <Label>Opsamlet af</Label>
          <Link to={ROUTES.user_details.replace(':userId', collector.id)}>{collector.firstName} {collector.lastName}</Link>
      </ValueContainer>}
      <ValueContainer>
        <Label>Position</Label>
        <PositionLink position={position} rightAlign />
      </ValueContainer>
    </Grid>

    <Grid item xs={6}>
      <div style={{textAlign: 'center'}}>
        {imageUrl && <img src={imageUrl} style={{width: '80%'}} />}
      </div>

    </Grid>
  </Grid>

};

export default TagReportDetails;