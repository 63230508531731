import {MailSettings} from "../types";
import firebase from "firebase";
import {useState, useEffect} from 'react';
import {DocumentData, FirestoreDataConverter, QueryDocumentSnapshot} from "@firebase/firestore-types";
import AppSettings from "../../../hmv-mobile/src/domain/settings/app-settings";
import AppSettingsConverter from '../../../hmv-mobile/src/repositories/converters/app-settings-converter';

const MailSettingsConverter = {
  fromFirestore(snapshot: QueryDocumentSnapshot) {
    const data = snapshot.data();
    return {
      recipients: data.recipients
    };
  },
  toFirestore(model: MailSettings): DocumentData {
    return model;
  }
} as FirestoreDataConverter<MailSettings>;

export function useAppSettings(): [AppSettings | undefined, boolean] {
  const [appSettings, setAppSettings] = useState<AppSettings | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    firebase.firestore().collection('settings')
      .doc('app-settings')
      .withConverter<AppSettings>(AppSettingsConverter)
      .onSnapshot((query) => {
        if (query.exists) {
          setAppSettings(query.data());
        }

        setIsLoading(false);
      });
  }, []);

  return [appSettings, isLoading];
}

export async function setLiveStats(enabled: boolean): Promise<void> {
  await firebase.firestore().collection('settings')
    .doc('app-settings')
    .update({liveStatsEnabled: enabled});
}

export async function savePlaceholder(placeholder: string): Promise<void> {
  await firebase.firestore().collection('settings')
    .doc('app-settings')
    .update({placeholderNumber: placeholder});
}

export function useUserNotificationSettings(): [MailSettings | undefined, boolean] {
  const [userNotificationSettings, setSettings] = useState<MailSettings | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    firebase.firestore().collection('settings')
      .doc('user-signups')
      .withConverter<MailSettings>(MailSettingsConverter)
      .onSnapshot((query) => {
        if (query.exists) {
          setSettings(query.data());
        }

        setIsLoading(false);
      });
  }, []);

  return [userNotificationSettings, isLoading];
}

export async function updateUserNotificationSettings(mailSettings: MailSettings): Promise<void> {
  await firebase.firestore().collection('settings')
    .doc('user-signups')
    .set(mailSettings);
}

export function useOrderSettings(): [MailSettings | undefined, boolean] {
  const [orderNotificationSettings, setSettings] = useState<MailSettings | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    firebase.firestore().collection('settings')
      .doc('materialOrders')
      .withConverter<MailSettings>(MailSettingsConverter)
      .onSnapshot((query) => {
        if (query.exists) {
          setSettings(query.data());
        }

        setIsLoading(false);
      });
  }, []);

  return [orderNotificationSettings, isLoading];
}

export async function updateOrderNotificationSettings(mailSettings: MailSettings): Promise<void> {
  await firebase.firestore().collection('settings')
    .doc('materialOrders')
    .set(mailSettings);
}

export function useJuniorSettings(): [MailSettings | undefined, boolean] {
  const [juniorNotificationSettings, setSettings] = useState<MailSettings | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    firebase.firestore().collection('settings')
      .doc('junior-signups')
      .withConverter<MailSettings>(MailSettingsConverter)
      .onSnapshot((query) => {
        if (query.exists) {
          setSettings(query.data());
        }

        setIsLoading(false);
      });
  }, []);

  return [juniorNotificationSettings, isLoading];
}

export async function updateJuniorNotificationSettings(mailSettings: MailSettings): Promise<void> {
  await firebase.firestore().collection('settings')
    .doc('junior-signups')
    .set(mailSettings);
}

export function useOilReportSettings(): [MailSettings | undefined, boolean] {
  const [oilReportSettings, setSettings] = useState<MailSettings | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    firebase.firestore().collection('settings')
      .doc('oil-report-settings')
      .withConverter<MailSettings>(MailSettingsConverter)
      .onSnapshot((query) => {
        if (query.exists) {
          setSettings(query.data());
        }

        setIsLoading(false);
      });
  }, []);

  return [oilReportSettings, isLoading];
}

export async function updateOilReportSettings(mailSettings: MailSettings): Promise<void> {
  await firebase.firestore().collection('settings')
    .doc('oil-report-settings')
    .set(mailSettings);
}
