import * as React from 'react';
import {useArticlesBriefsListener} from "../repository/content-repository";
import ArticleBriefComponent from '../components/article-brief';
import Pagination from '@material-ui/lab/Pagination';
import usePagination from "../hooks/use-pagination";
import _ from 'lodash';
import {ArticleBrief} from "../../../hmv-mobile/src/domain/article";
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import {useHistory} from "react-router";
import ROUTES from "../routes";

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Articles: React.FC = () => {
  const [articles, isLoading] = useArticlesBriefsListener();
  const [currentPageArticles, numberOfPages, page, setPage] = usePagination(articles);
  const rows = _.chunk<ArticleBrief>(currentPageArticles, 4);
  const history = useHistory();

  return <>
    <h1>
      Artikler
    </h1>

    <Button
      onClick={() => history.push(ROUTES.create_article)}
      variant={'contained'}
      color={'primary'}>Opret artikel</Button>

    {!isLoading && <div style={{marginTop: '16px'}}>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        {rows.map((articles, index) => <Row key={index}>{articles.map((articleBrief) =>
          <ArticleBriefComponent key={articleBrief.id} articleBrief={articleBrief} />)}</Row>)}
      </div>

      <div style={{marginTop: '16px'}}>
          <Pagination count={numberOfPages} page={page} onChange={((_, page) => setPage(page))} />
      </div>
    </div>}
  </>
};

export default Articles;