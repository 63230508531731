import React, {FunctionComponent, useState} from 'react';
import {useImportStatus, useOutlets} from "../repository/outlet-repository";
import Accordion from "../components/accordion";
import {CircularProgress} from "@material-ui/core";
import Outlet from "../../../hmv-mobile/src/domain/outlet";
import {format} from 'date-fns';
import PositionLink from "../components/position-link";
import styled from 'styled-components';
import Button from "@material-ui/core/Button";
import Alert from '@material-ui/lab/Alert';
import {useAppSettings} from "../repository/settings-repository";
import axios from 'axios';
import firebase from 'firebase';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const OutletHeader: FunctionComponent<{ outlet: Outlet }> = ({outlet}) => {
  const date = outlet.updated && format(outlet.updated, 'dd-MM-yyyy HH:mm') || 'Ukendt';

  return <>
    <span style={{fontSize: '12px'}}>{outlet.category}</span>
    <h4 style={{margin: '0'}}>{outlet.id} - {outlet.name}</h4>
    <span><i>Opdateret {date}</i></span>
  </>;
};

const OutletContent: FunctionComponent<{ outlet: Outlet }> = ({outlet}) => {
  return <>
    <p>
      <b>Adresse</b><br/>
      {outlet.address.street} {outlet.address.houseNumber}<br/>
      {outlet.address.secondAddressLine && <>{outlet.address.secondAddressLine} <br/></>}
      {outlet.address.city.zipCode} {outlet.address.city.name}
    </p>
    <p>
      <b>Position</b><br/>
      <PositionLink position={outlet.position}/>
    </p>
  </>
};

const Outlets: FunctionComponent = () => {
  const [outlets, isLoading] = useOutlets();
  const [importStatus] = useImportStatus();
  const [appSettings] = useAppSettings();
  const [showHasStarted, setShowHasStarted] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);

  const startImport = async () => {
    if (!appSettings?.coreFacadeEndpoint) return;
    setIsSending(true);

    try {
      const token = await firebase.auth().currentUser?.getIdToken();
      await axios.post(`${appSettings.coreFacadeEndpoint}/import-outlets`, null, {
        headers: {
          'Authorization':`Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      setShowHasStarted(true);
      setTimeout(() => setShowHasStarted(false), 3000);

    } catch (error) {
      alert(`Der skete en fejl i forsøget på at starte indlæsningen. ${JSON.stringify(error)}`);
    } finally {
      setIsSending(false);
    }

  };

  return <>
    {showHasStarted && <Alert>Indlæsningen af udleveringssteder er startet. Bemærk, det tager flere minutter at indlæse al data.</Alert>}
    <h1>Udleveringssteder</h1>
    {isLoading && <CircularProgress/>}
    {!isLoading && outlets.length > 0 && <>
        <Container>
            <p><b>{outlets.length} udleveringssteder</b></p>
            <div style={{textAlign: 'right'}}>
                <p>
                    <b>Seneste indlæsning af udleveringssteder</b>
                </p>

              {importStatus && <>
                  <p>
                      Startet: {format(importStatus.started, 'dd-MM-yyyy HH:mm')} <br/>

                    {importStatus.status === "in-progress" && <span><i>Igangværende indlæsning...</i></span>}
                    {importStatus.status === 'done' && <span>Indlæsning gennemført.</span>}
                    {importStatus.status === 'error' && <span>Afsluttet med fejl. {importStatus.message}</span>}
                  </p>

                {importStatus.status === 'done' && <Button disabled={isSending} onClick={startImport} variant={'outlined'} color={'primary'}>Start indlæsning</Button>}
              </>}
            </div>

        </Container>

        <Accordion
            items={outlets}
            renderHeader={(outlet) => <OutletHeader outlet={outlet}/>}
            renderContent={(outlet) => <OutletContent outlet={outlet}/>}
        />
    </>}

  </>;

};

export default Outlets;