import React, {useState, useEffect} from 'react';
import firebase from 'firebase';
import {Notification} from "../global/notification";
import {DocumentData, FirestoreDataConverter, QueryDocumentSnapshot} from "@firebase/firestore-types";

const NotificationConverter = {
  fromFirestore(snapshot: QueryDocumentSnapshot) {
    const data = snapshot.data();
    return {
      articleId: data.articleId,
      title: data.title,
      message: data.message,
      zipCodes: data.zipCodes,
      sentAt: data.sentAt.toDate()
    };
  },
  toFirestore(modelObject: Notification): DocumentData {
    return modelObject;
  }
} as FirestoreDataConverter<Notification>;

export async function addNotification(notification: Notification): Promise<void> {
  await firebase.firestore()
    .collection('notifications')
    .add(notification);
}

export function useNotifications(): [Notification[], boolean] {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    firebase.firestore()
      .collection('notifications')
      .withConverter<Notification>(NotificationConverter)
      .orderBy('sentAt', 'desc')
      .onSnapshot((snapshots) => {
        const notifications: Notification[] = [];
        snapshots.forEach((snapshot) => notifications.push({...snapshot.data()}));
        setNotifications(notifications);
        setIsLoading(false);
      });
  }, []);

  return [notifications, isLoading];
}