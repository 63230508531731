import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCXQRYLTP3AYKtRbFNiargQnaPA4PhSdr4",
  authDomain: "havmiljovogter-login.web.app",
  projectId: "havmiljovogterne",
  storageBucket: "havmiljovogterne.appspot.com",
  messagingSenderId: "472800310843",
  appId: "1:472800310843:web:92380b0cad142314c58e1e",
  measurementId: "G-WJ8555QCCN"
};

firebase.initializeApp(firebaseConfig);

firebase.auth().useDeviceLanguage();
export const auth = firebase.auth();
export const storage = firebase.storage();

export const signOut = async () => {
  await auth.signOut();
};

export default firebase.firestore();