import React, { FunctionComponent } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

type EditorProps = {
  value: string;
  setValue: (value: string) => void;
}

const Editor: FunctionComponent<EditorProps> = ({value, setValue}) => {
  return (
    <ReactQuill theme="snow" value={value} onChange={setValue}/>
  );
};

export default Editor;