import React, {FunctionComponent} from "react";
import {OilReport} from "../../../hmv-mobile/src/domain/report";
import {Grid} from "@material-ui/core";
import styled from "styled-components";
import useReportImage from "../hooks/use-report-image";
import {useSingleUser} from "../repository/user-repository";
import ROUTES from "../routes";
import {Link} from 'react-router-dom';
import PositionLink from "./position-link";
import {mapContaminationSize, mapWindDirection} from "./report-mappers";

export const Label = styled.div`
  display: inline-block;
  font-weight: bold;
`;

export const ValueContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 4px;
`;

const OilReportContent: FunctionComponent<{ report: OilReport }> = ({report}) => {
  const imageUrl = useReportImage(report);
  const [reporter] = useSingleUser(report.userId);

  return <Grid container xs={12}>
    <Grid item xs={6}>
      <ValueContainer>
        <Label>Omfang af forurening</Label>
        <span>{mapContaminationSize(report.contaminationSize)}</span>
      </ValueContainer>
      <ValueContainer>
        <Label>Vindretning</Label>
        <span>{mapWindDirection(report.windDirection)}</span>
      </ValueContainer>
      <ValueContainer>
        <Label>Indberettet af</Label>
        {reporter && <Link to={ROUTES.user_details.replace(':userId', report.userId)}>{reporter.firstName} {reporter.lastName}</Link>}
      </ValueContainer>
      <ValueContainer>
        <Label>Kommentar</Label>
        <span>{report.comment || <i>ingen</i>}</span>
      </ValueContainer>
      <ValueContainer>
        <Label>Position</Label>
        <PositionLink position={report.position} rightAlign />
      </ValueContainer>
    </Grid>
    <Grid item xs={6}>
      <div style={{textAlign: 'center'}}>
        {imageUrl && <img src={imageUrl} style={{width: '80%'}} />}
      </div>

    </Grid>

  </Grid>
}

export default OilReportContent;