import React, {FunctionComponent} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";

type DialogProps = {
  open: boolean;
  onClose: () => void;
  onAccept: () => void;
  title: string;
  cancel: string;
  accept: string;
}

const DialogComponent: FunctionComponent<DialogProps> = (props) => {

  const {children, open, onClose, onAccept, title, cancel, accept} = props;

  return <Dialog
    open={open}
    onClose={onClose}
  >
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {children}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        {cancel}
      </Button>
      <Button onClick={onAccept} color="primary" autoFocus>
        {accept}
      </Button>
    </DialogActions>
  </Dialog>

}

export default DialogComponent;
