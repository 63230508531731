import {DocumentData, QueryDocumentSnapshot, FirestoreDataConverter} from '@firebase/firestore-types';
import {TagReport} from "../../domain/report";

export default {
  fromFirestore(snapshot: QueryDocumentSnapshot): TagReport {
    const data = snapshot.data();
    return {
      id: data.id,
      dateTime: data.dateTime.toDate(),
      userId: data.userId,
      position: data.position,
      image: data.image,
      causeForTagging: data.causeForTagging,
      collected: data.collected,
      collectedBy: data.collectedBy,
      collectedAt: data.collectedAt && data.collectedAt.toDate(),
      numberOfBags: data.numberOfBags,
      numberOfSacks: data.numberOfSacks
    };
  },
  toFirestore(tagReport: TagReport): DocumentData {
    return tagReport;
  }
} as FirestoreDataConverter<TagReport>;