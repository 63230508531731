import * as React from 'react';
const {useMemo} = React;
import {RowContainer} from "./users";
import {CircularProgress} from "@material-ui/core";
import {DataGrid, GridColDef} from "@material-ui/data-grid";
import {format} from 'date-fns';
import {downloadAsCSV} from "../downloadAsCSV";
import Button from "@material-ui/core/Button";
import {GlobalContext, GlobalContextState} from "../components/global-container";
import {useContext} from "react";

const columns: GridColDef[] = [
  { field: 'firstName', headerName: 'Fornavn', flex: 1 },
  { field: 'lastName', headerName: 'Efternavn', flex: 1 },
  { field: 'signUpDate', headerName: 'Tilmeldelsesdato', flex: 1 },
  { field: 'birthDate', headerName: 'Fødselsdato', flex: 1},
  { field: 'address', headerName: 'Adresse', flex: 1 },
  { field: 'zipCode', headerName: 'Postnr'},
  { field: 'city', headerName: 'By', flex: 1 },
  { field: 'parentName', headerName: 'Forældrenavn', flex: 1 },
  { field: 'parentEmail', headerName: 'Forældre email', flex: 1 },
];

type ConvertedSignUp = {
  id: string;
  firstName: string;
  lastName: string;
  signUpDate: string;
  birthDate: string;
  address: string;
  zipCode: string;
  city: string;
  parentName: string;
  parentEmail: string;
}

const JuniorSignups: React.FC = () => {
  const {juniors: rawJuniors, usersByKey} = useContext<GlobalContextState>(GlobalContext);
  const isLoading = rawJuniors.length === 0;

  const juniors = useMemo<ConvertedSignUp[]>(() => {
    if (isLoading) return [];
    return rawJuniors.map((junior): ConvertedSignUp => {
      const {firstName, lastName, signUpDate, birthYear, address, id, parentId} = junior;
      const parent = usersByKey[parentId];
      return {
        id,
        firstName,
        lastName,
        signUpDate: format(signUpDate, 'dd-MM-yyyy HH:mm'),
        birthDate: format(new Date(birthYear), 'dd-MM-yyyy'),
        address: `${address.street} ${address.houseNumber} ${address.floor} ${address.door}`,
        zipCode: address.city.zipCode,
        city: address.city.name,
        parentName: `${parent?.firstName} ${parent?.lastName}`,
        parentEmail: parent?.email || '',
        //parentAddress: `${parent?.address?.street} ${parent?.address?.houseNumber} ${parent?.address?.door}, ${parent?.address?.floor}, ${parent?.address?.city.name} ${parent?.address?.city.zipCode}`
      }
    });
  }, [rawJuniors, isLoading]);

  const download = () => {
    const lines = [
      '\ufeff',
      'Fornavn;Efternavn;Tilmeldesesdato;Fødselsdato;Adresse;Postnr;By;Forældre navn;Forældre email;'
    ];

    juniors.forEach((junior) => {
      const {firstName, lastName, signUpDate, birthDate, address, zipCode, city, parentName, parentEmail} = junior;
      lines.push(`${firstName};${lastName};${signUpDate};${birthDate};${address};${zipCode};${city};${parentName};${parentEmail}`);
    });

    downloadAsCSV(lines, 'junior-vogtere');
  }

  return <>
    <h1>Junior Vogtere</h1>

    {!isLoading && <>
        <Button onClick={download}
                style={{marginTop: '10px', marginBottom: '20px'}}
                variant={'outlined'}>Eksporter</Button>

        <div style={{height: 800, width: '100%'}}>
            <DataGrid columns={columns} rows={juniors} />
        </div>
    </>}

    {isLoading && <RowContainer>
        <CircularProgress style={{marginRight: '8px'}} /> <span>Indlæser...</span>
    </RowContainer>}

  </>;

};

export default JuniorSignups;