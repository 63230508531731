import * as React from 'react';
import ReactDOM from 'react-dom';
import App from './src/app';
import {BrowserRouter} from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import da from "date-fns/locale/da";

const root = document.getElementById('root');

ReactDOM.render(
  <React.StrictMode>
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={da}>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </MuiPickersUtilsProvider>
  </React.StrictMode>,
  root
);