import React, {FunctionComponent, useMemo} from "react";
import UserMeta from "../../../hmv-mobile/src/domain/user-meta";
import {useAllUsers, useJuniors} from "../repository/user-repository";
import JuniorSignUp from "../../../hmv-mobile/src/domain/junior-signup";
import MaterialOrder from "../../../hmv-mobile/src/domain/material-order";
import {useOrders} from "../repository/order-repository";

export type GlobalContextState = {
  users: UserMeta[];
  usersByKey: Record<string, UserMeta>;
  juniors: JuniorSignUp[];
  orders: MaterialOrder[];
}

export const GlobalContext = React.createContext<GlobalContextState>({users: [], juniors: [], usersByKey: {}, orders: []});

const GlobalContainer: FunctionComponent = ({children}) => {
  const [users, isLoading] = useAllUsers();
  const [juniors, isLoadingJuniors] = useJuniors();
  const [orders, isLoadingOrders] = useOrders();

  const context = useMemo<GlobalContextState>(() => {
    return {
      users,
      juniors,
      usersByKey: users.reduce<Record<string, UserMeta>>((dictionary, current) => {
        return {...dictionary, [current.id]: current};
      }, {}),
      orders
    }
  }, [users, juniors, isLoading, isLoadingJuniors, orders, isLoadingOrders]);

  return <GlobalContext.Provider value={context}>
    {children}
  </GlobalContext.Provider>
};

export default GlobalContainer;