import {useState} from "react";

const defaultPageSize = 15;
function usePagination<T extends object>(items: Array<T>, customPageSize?: number): [Array<T>, number, number, (page:number) => void] {
  const [page, setPage] = useState<number>(1);
  const pageSize = customPageSize || defaultPageSize;

  const numberOfPages = Math.ceil(items.length / pageSize);
  const pageIndex = (page - 1) * pageSize;
  const currentPageItems = items.slice(pageIndex, pageIndex + pageSize);

  return [currentPageItems, numberOfPages, page, setPage];
}

export default usePagination;