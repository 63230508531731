import React, {FunctionComponent, ReactElement} from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }),
);

export type AccordionItem = { id: string };

type AccordionProps<T> = {
  items: Array<T>;
  renderHeader: (item: T) => ReactElement;
  renderContent: (item: T) => ReactElement;
}

function AccordionComponent<T extends AccordionItem>(props: AccordionProps<T>) {
  const {items, renderHeader, renderContent} = props;
  const classes = useStyles();

  return <div className={classes.root}>
    {items.map((item) => {
      const {id} = item;

      return <Accordion key={id}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{renderHeader(item)}</Typography>
        </AccordionSummary>
        <AccordionDetails style={{width: '100%'}}>
          <Typography style={{width: '100%'}}>
            {renderContent(item)}
          </Typography>
        </AccordionDetails>
      </Accordion>
    })}
  </div>;
}

export default AccordionComponent;