import {DocumentData, QueryDocumentSnapshot, FirestoreDataConverter} from '@firebase/firestore-types';
import {Article} from "../../domain/article";

export default {
  fromFirestore(snapshot: QueryDocumentSnapshot): Article {
    const data = snapshot.data();
    return {
      id: data.id,
      headline: data.headline,
      subHeadline: data.subHeadline,
      alternateColorHeadline: data.alternateColorHeadline,
      publishDate: data.publishDate.toDate(),
      image: data.image,
      theme: data.theme,
      leadText: data.leadText,
      content: data.content
    }
  },
  toFirestore(modelObject: Article): DocumentData {
    return modelObject;
  }
} as FirestoreDataConverter<Article>;