import * as React from 'react';
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import PeopleIcon from "@material-ui/icons/People";
import ListItemText from "@material-ui/core/ListItemText";
import FlagIcon from "@material-ui/icons/Flag";
import DescriptionIcon from "@material-ui/icons/Description";
import Divider from "@material-ui/core/Divider";
import SettingsIcon from "@material-ui/icons/Settings";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Drawer from "@material-ui/core/Drawer";
import {Link} from 'react-router-dom';
import ROUTES from "../routes";

type MenuProps = {
  classes: {
    drawer: string;
    drawerPaper: string;
    drawerContainer: string;
  }
}

const Menu: React.FC<MenuProps> = ({classes}) => {
  return <Drawer
    className={classes.drawer}
    variant="permanent"
    classes={{
      paper: classes.drawerPaper,
    }}
  >
    <Toolbar />
    <div className={classes.drawerContainer}>
      <List>
        <ListItem button component={Link} to={ROUTES.home} key={'guardians'}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary={'Havmiljøvogtere'} />
        </ListItem>

        <ListItem button component={Link} to={ROUTES.juniors} key={'juniors'}>
          <ListItemIcon>
            <ChildCareIcon />
          </ListItemIcon>
          <ListItemText primary={'Junior Vogtere'} />
        </ListItem>

        <ListItem button component={Link} to={ROUTES.orders} key={'orders'}>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary={'Bestillinger'} />
        </ListItem>

        <ListItem button key={'indberetninger'} component={Link} to={ROUTES.reports}>
          <ListItemIcon>
            <FlagIcon />
          </ListItemIcon>
          <ListItemText primary={'Indberetninger'} />
        </ListItem>

        <ListItem button key={'artikler'} component={Link} to={ROUTES.articles}>
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primary={'Artikler'} />
        </ListItem>

        <ListItem button key={'Push-notifikationer'} component={Link} to={ROUTES.notifications}>
          <ListItemIcon>
            <NotificationsIcon />
          </ListItemIcon>
          <ListItemText primary={'Push-notifikationer'} />
        </ListItem>

        <ListItem button key={'Udleveringssteder'} component={Link} to={ROUTES.outlets}>
          <ListItemIcon>
            <LocationOnIcon />
          </ListItemIcon>
          <ListItemText primary={'Udleveringssteder'} />
        </ListItem>

      </List>
      <Divider />

      <List>
        <ListItem button key={'Indstillinger'} component={Link} to={ROUTES.settings}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary={'Indstillinger'} />
        </ListItem>
      </List>
    </div>
  </Drawer>

};

export default Menu;