import firebase from 'firebase';
import {DocumentData, QueryDocumentSnapshot, FirestoreDataConverter} from '@firebase/firestore-types';
import Outlet from "../../../hmv-mobile/src/domain/outlet";
import {useEffect, useState} from "react";

export type OutletImportStatus = {
  started: Date;
  status: 'in-progress' | 'done' | 'error';
  message?: string;
}

const OutletConverter = {
  fromFirestore(snapshot: QueryDocumentSnapshot): Outlet {
    const data = snapshot.data();
    return {
      id: data.id,
      position: data.position,
      address: data.address,
      name: data.name,
      phoneNumber: data.phoneNumber,
      image: data.image,
      category: data.category,
      updated: data.updated?.toDate() || undefined
    }
  },
  toFirestore(model: Outlet): DocumentData {
    return model;
  }
} as FirestoreDataConverter<Outlet>;

const OutletImportStatusConverter = {
  fromFirestore(snapshot: QueryDocumentSnapshot): OutletImportStatus {
    const data = snapshot.data();
    return {
      started: data.started.toDate(),
      status: data.status,
      message: data.message
    }
  },
  toFirestore(modelObject: OutletImportStatus): DocumentData {
    return modelObject;
  }

} as FirestoreDataConverter<OutletImportStatus>;

export const useOutlets = (): [Outlet[], boolean] => {
  const [outlets, setOutlets] = useState<Outlet[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    firebase.firestore().collection('outlets')
      .withConverter<Outlet>(OutletConverter)
      .onSnapshot(query => {
        if (query.empty) return;

        const outlets: Outlet[] = [];
        query.forEach((snapshot) => outlets.push({...snapshot.data(), id: snapshot.id}));
        setOutlets(outlets);
        setIsLoading(false);
      });
  }, [])

  return [outlets, isLoading];
};


export const useImportStatus = (): [OutletImportStatus | undefined, boolean] => {
  const [importStatus, setImportStatus] = useState<OutletImportStatus>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    firebase.firestore().collection('outlet-imports')
      .withConverter<OutletImportStatus>(OutletImportStatusConverter)
      .orderBy('started', 'desc')
      .limit(1)
      .onSnapshot((query) => {
        if (!query.empty) {
          const result: OutletImportStatus[] = [];
          query.forEach((snapshot) => result.push({...snapshot.data()}));
          setImportStatus(result[0]);
        }

        setIsLoading(false);
      });
  }, []);

  return [importStatus, isLoading];
}