import React, {FunctionComponent, useState} from 'react';
import {Buttons, CreateBox, Name, Row} from './admin-settings';
import {updateJuniorNotificationSettings, useJuniorSettings} from "../repository/settings-repository";
import {Button, CircularProgress, TextField} from "@material-ui/core";
import Dialog from "./dialog";

const JuniorSettings: FunctionComponent = () => {
  const [juniorSettings, isLoadingSettings] = useJuniorSettings();
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [toDelete, setToDelete] = useState<string | undefined>();
  const [email, setEmail] = useState<string>('');

  const deleteRecipient = async () => {
    if (!juniorSettings) return;
    setIsDeleting(true);
    const updatedJuniorSettings = {...juniorSettings, recipients: juniorSettings.recipients.filter((r) => r !== toDelete)};
    await updateJuniorNotificationSettings(updatedJuniorSettings);
    setIsDeleting(false);
    setShowDelete(false);
    setToDelete(undefined);
  }

  const create = async () => {
    setIsLoading(true);
    const updatedJuniorSettings = {...juniorSettings, recipients: [...(juniorSettings?.recipients || []), email]};
    await updateJuniorNotificationSettings(updatedJuniorSettings);
    setIsLoading(false);
    setShowCreate(false);
    setEmail('');
  }

  return <>
    <h3>Notifikationer ved tilmelding af Junior Vogter</h3>

    <Dialog open={showDelete} onClose={() => setShowDelete(false)}
            onAccept={deleteRecipient} title={'Slet modtager?'} cancel={'Nej'} accept={'Ja, slet'}>
      <p>Bekræft venligst at du ønsker at slette {toDelete}</p>

      {isDeleting && <CircularProgress style={{marginTop: '16px'}}/>}
    </Dialog>

    <Button color={"primary"} variant={"contained"} onClick={() => setShowCreate(true)}>Tilføj modtager</Button>

    <div>
      {showCreate && <CreateBox><h3 style={{marginTop: '0'}}>Tilføj modtager</h3>
          <TextField
              fullWidth
              style={{marginBottom: '16px'}}
              value={email}
              label={'E-mail'}
              onChange={(e) => setEmail(e.target.value)}
          />
          <Buttons>
              <Button variant={'outlined'} onClick={() => {
                setEmail('');
                setShowCreate(false);
              }}>Anuller</Button>

              <Button color={'primary'} variant={'contained'} onClick={create}>
                  Opret
              </Button>
          </Buttons>

        {isLoading && <CircularProgress style={{marginTop: '16px'}}/>}
      </CreateBox>}
    </div>

    {!isLoadingSettings && juniorSettings && juniorSettings.recipients.map((recipient) => {
      return <Row key={recipient}>
        <div>
          <Name>{recipient}</Name>
        </div>
        <div>
          <Button color={"primary"} variant={"contained"} onClick={() => {
            setShowDelete(true);
            setToDelete(recipient);
          }}>Slet</Button>
        </div>
      </Row>
    })}

  </>;

};

export default JuniorSettings;