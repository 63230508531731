import {DocumentData, QueryDocumentSnapshot, FirestoreDataConverter} from '@firebase/firestore-types';
import UserMeta from '../../domain/user-meta';
import Address from "../../domain/address";

export const mapAddress = (data: DocumentData): Address => {
  return {
    id: data.address?.id,
    street: data.address?.street,
    secondAddressLine: data.address?.secondAddressLine,
    houseNumber: data.address?.houseNumber,
    floor: data.address?.floor,
    door: data.address?.door,
    city: {
      name: data.address?.city?.name,
      zipCode: data.address?.city?.zipCode
    }
  };
}

export default {
  fromFirestore(snapshot: QueryDocumentSnapshot): UserMeta {
    const data = snapshot.data();
    return {
      id: data.id,
      completedSignUp: data.completedSignUp,
      isExistingUser: data.isExistingUser,
      email: data.email,
      mobileNumber: data.mobileNumber,
      numberOfBagsCollected: data.numberOfBagsCollected || 0,
      firstName: data.firstName,
      lastName: data.lastName,
      address: mapAddress(data),
      membershipNumber: data.membershipNumber,
      birthYear: data.birthYear,
      acceptPhysicalMail: data.acceptPhysicalMail,
      acceptDigitalMail: data.acceptDigitalMail,
      pushToken: data.pushToken
    }
  },
  toFirestore(userMeta: UserMeta): DocumentData {
    return userMeta;
  }
} as FirestoreDataConverter<UserMeta>;