import React, {FunctionComponent, useState, useEffect} from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {CircularProgress} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import {setLiveStats, useAppSettings, savePlaceholder} from "../repository/settings-repository";
import Button from "@material-ui/core/Button";

const AppSettings: FunctionComponent = () => {
  const [appSettings, isLoading] = useAppSettings();
  const [isUpdatingStats, setIsUpdating] = useState<boolean>(false);
  const [isUpdatingPlaceholder, setIsUpdatingPlaceholder] = useState<boolean>(false);
  const [placeholder, setPlaceholder] = useState<string>('');

  useEffect(() => {
    if (appSettings?.placeholderNumber) {
      setPlaceholder(appSettings.placeholderNumber);
    }
  }, [appSettings?.placeholderNumber]);

  const updateStats = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsUpdating(true);
    await setLiveStats(event.target.checked);
    setIsUpdating(false);
  }

  const updatePlaceholder = async () => {
    setIsUpdatingPlaceholder(true);
    await savePlaceholder(placeholder);
    setIsUpdatingPlaceholder(false);
  }

  return <>
    <h3>App indstillinger</h3>

    {!isLoading && appSettings && <div style={{marginBottom: '20px'}}>
        <FormGroup row>
            <FormControlLabel
                control={<Switch color={'primary'}
                                 onChange={updateStats}
                                 checked={appSettings.liveStatsEnabled}/>}
                label={'Vis statistik'}/> {isUpdatingStats && <CircularProgress/>}
        </FormGroup>

        <div style={{marginTop: '20px', display: 'inline-flex', flexDirection: 'column'}}>
            <TextField
                value={placeholder} label={'Placeholder'}
                onChange={(e) => setPlaceholder(e.target.value)} />
            <Button onClick={updatePlaceholder}
                    style={{marginTop: '20px', marginBottom: '10px'}}
                    variant={'outlined'}>Gem</Button>

            {isUpdatingPlaceholder && <CircularProgress />}
        </div>

    </div>}

  </>

};

export default AppSettings;

