import firebase from "firebase";
import UserMeta from "../../../hmv-mobile/src/domain/user-meta";
import UserConverter from '../../../hmv-mobile/src/repositories/converters/user-converter';
import {useEffect, useState, useReducer, Reducer} from "react";
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import {GarbageReport, OilReport, TagReport} from "../../../hmv-mobile/src/domain/report";
import GarbageReportConverter from '../../../hmv-mobile/src/repositories/converters/garbage-report-converter';
import TagReportConverter from '../../../hmv-mobile/src/repositories/converters/tag-report-converter';
import OilReportConverter from '../../../hmv-mobile/src/repositories/converters/oil-report-converter';
import JuniorSignUp from "../../../hmv-mobile/src/domain/junior-signup";
import JuniorSignUpConverter from '../../../hmv-mobile/src/repositories/converters/junior-signup-converter';

export type UserMetaSnap = {
  snapshot: DocumentSnapshot;
} & UserMeta;

type UserState = {
  users: UserMetaSnap[];
  finished: boolean;
}

type Action = {
  type: 'add-users';
  users: UserMetaSnap[];
}

const PAGE_SIZE = 10;

function reducer(state: UserState, action: Action): UserState {
  if (action.type === 'add-users') {
    return {...state, users: [...state.users, ...action.users], finished: action.users.length < PAGE_SIZE};
  }

  return state;
}

export function useAllUsers(): [UserMeta[], boolean] {
  const [users, setUsers] = useState<UserMeta[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    return firebase.firestore()
      .collection('users')
      .withConverter<UserMeta>(UserConverter)
      .onSnapshot((snapshots) => {
        const users: UserMeta[] = [];

        if (!snapshots.empty) {
          snapshots.forEach((userMeta) =>
            users.push({...userMeta.data(), id: userMeta.id}));
        }

        setUsers(users);
        setIsLoading(false);
      });

  }, []);

  return [users, isLoading];
}

export function useJuniors(): [JuniorSignUp[], boolean] {
  const [juniors, setJuniors] = useState<JuniorSignUp[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    return firebase.firestore()
      .collection('junior-signups')
      .withConverter<JuniorSignUp>(JuniorSignUpConverter)
      .orderBy('signUpDate', 'desc')
      .onSnapshot((snapshots) => {
        const juniors: JuniorSignUp[] = [];

        if (!snapshots.empty) {
          snapshots.forEach((junior) => juniors.push({...junior.data()}));
        }

        setJuniors(juniors);
        setIsLoading(false);
      });
  }, []);

  return [juniors, isLoading];
}

export function useSingleUser(userId?: string): [UserMeta | undefined, boolean] {
  const [user, setUser] = useState<UserMeta>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!userId) return;

    return firebase.firestore()
      .collection('users')
      .withConverter<UserMeta>(UserConverter)
      .doc(userId)
      .onSnapshot((snapshot) => {
          const user = snapshot.data();
          setUser(user);
          setIsLoading(false);
      });
  }, [userId]);

  return [user, isLoading];
}

export function useReports(userId: string): [GarbageReport[], OilReport[], TagReport[]] {
  const [garbageReports, setGarbageReports] = useState<GarbageReport[]>([]);
  const [oilReports, setOilReports] = useState<OilReport[]>([]);
  const [tagReports, setTagReports] = useState<TagReport[]>([]);

  useEffect(() => {
    firebase.firestore().collection('garbage-reports')
      .withConverter<GarbageReport>(GarbageReportConverter)
      .where('userId', '==', userId)
      .orderBy('dateTime', 'desc')
      .onSnapshot((query) => {
        if (!query.empty) {
          const reports: GarbageReport[] = [];
          query.forEach((report) => reports.push({...report.data()}));
          setGarbageReports(reports);
        }
      });

    firebase.firestore().collection('oil-reports')
      .withConverter<OilReport>(OilReportConverter)
      .where('userId', '==', userId)
      .orderBy('dateTime', 'desc')
      .onSnapshot((query) => {
        if (!query.empty) {
          const reports: OilReport[] = [];
          query.forEach((report) => reports.push({...report.data()}));
          setOilReports(reports);
        }
      });

    firebase.firestore().collection('tag-reports')
      .withConverter<TagReport>(TagReportConverter)
      .where('userId', '==', userId)
      .orderBy('dateTime', 'desc')
      .onSnapshot((query) => {
        if (!query.empty) {
          const reports: TagReport[] = [];
          query.forEach((report) => reports.push({...report.data()}));
          setTagReports(reports);
        }
      });
  }, [userId]);


  return [garbageReports, oilReports, tagReports];
}

export function useUsers(startAt?: DocumentSnapshot): [UserMetaSnap[], boolean, boolean] {
  const [state, dispatch] = useReducer<Reducer<UserState, Action>>(reducer, {users: [], finished: false});
  const {users, finished} = state;
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    let query = firebase.firestore()
      .collection('users')
      .withConverter<UserMeta>(UserConverter)
      .limit(PAGE_SIZE);

    if (startAt) {
      query = query.startAt(startAt);
    }

    query.get()
      .then((snapshot) => {
        const newUsers: UserMetaSnap[] = [];
        if (snapshot.empty) return;

        snapshot.forEach((userMeta) =>
          newUsers.push({...userMeta.data(), id: userMeta.id, snapshot: userMeta}));

        dispatch({type: 'add-users', users: newUsers});
        setIsLoading(false);
      });


  }, [startAt]);

  return [users, isLoading, finished];
}
