import * as React from 'react';

const {useMemo} = React;
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router";
import ROUTES from "../routes";
import {Notification} from "../global/notification";
import {useNotifications} from "../repository/notification-repository";
import Accordion from "../components/accordion";
import {FunctionComponent} from "react";
import {format} from "date-fns";
import _ from 'lodash';

type NotificationItem = Notification & { id: string };

const Header: FunctionComponent<{ date: Date }> = ({date}) => {
  const formattedDate = format(date, 'dd-MM-yyyy HH:mm');
  return <span>{formattedDate}</span>;
}

const Notifications: React.FC = () => {
  const history = useHistory();
  const [notifications, isLoading] = useNotifications();

  const items = useMemo<NotificationItem[]>(() => {
    return notifications.map((notification) => ({...notification, id: _.uniqueId()}));
  }, [notifications]);

  return <>
    <h1>Push-notifikationer</h1>
    <Button onClick={() => history.push(ROUTES.notification_send)}
            variant={"contained"}
            color={"primary"}>Send ny notifikation</Button>

    <div style={{marginTop: '20px'}}>
      {!isLoading && notifications.length > 0 && <>
          <Accordion<NotificationItem>
              items={items}
              renderHeader={(item) => <Header date={item.sentAt}/>}
              renderContent={(item) => <div>
                <p style={{margin: '0'}}><b>{item.title}</b></p>
                <span>{item.message}</span>
                {item.zipCodes && item.zipCodes.length > 0 && <p>
                  <b>Valgte postnumre:</b><br/>
                  {item.zipCodes.map((zipCode) => <>
                    {zipCode} <br/>
                  </>)}
                </p>}
              </div>}
          />

      </>}
    </div>

  </>
};

export default Notifications;