import React, {FunctionComponent} from 'react';
import {Position} from "../../../hmv-mobile/src/components/map/map";

const PositionLink: FunctionComponent<{position: Position, rightAlign?: boolean}> = ({position, rightAlign = false}) => {
  const url = `https://www.google.com/maps/search/?api=1&query=${position.latitude},${position.longitude}`;

  return <div style={{display: 'inline-flex', flexDirection: 'column', alignItems: rightAlign ? 'flex-end' : 'flex-start'}}>
    <p style={{margin: '0'}}>Latitude: {position.latitude}, Longitude: {position.longitude}</p>
    <a style={{fontSize: '12px'}} href={url} target={'_blank'} rel={'nofollow'}>
      Vis på kort
    </a>
  </div>
};

export default PositionLink;