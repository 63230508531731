import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import {Button} from "@material-ui/core";
import firebase from 'firebase';

export default function ClippedDrawer({ className }: {className: string}) {
  const logout = async () => {
    await firebase.auth().signOut();
  }

  return (
    <AppBar position="fixed" className={className}>
      <Toolbar>
        <Typography variant="h6" noWrap>
          Havmiljøvogter
        </Typography>
        <Button style={{marginLeft: 'auto'}} onClick={logout}>
          <PowerSettingsNewIcon style={{marginRight: '8px'}}/> Log ud
        </Button>
      </Toolbar>
    </AppBar>
  );
}